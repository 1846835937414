import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/lib/Creatable';
import { get, find } from 'lodash';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 55,
    borderRadius: 0,
    borderWidth: 2,
    borderColor: (state.isFocused || state.menuIsOpen) ? '#76b900 !important' : 'hsla(0, 0%, 77.3%, 0.3) !important',
    boxShadow: 'none',
  }),
};

const AdminSchoolSelectInput = ({
  input,
  label,
  options,
  change,
  index,
  loading,
  placeholder,
  disabled,
  meta: { touched, error },
}) => (
  <div style={{ position: 'relative' }}>
    {label}
    <CreatableSelect
      styles={customStyles}
      getOptionLabel={o => (o.school ? `${o.school.name}` : o.label)}
      isDisabled={disabled}
      value={find(options, { label: input.value })}
      placeholder={placeholder}
      onChange={value => change(`schools[${index}]`, value.school)}
      options={options}
      isLoading={loading}
      getOptionValue={option => get(option, 'school.schoolId')}
      isValidNewOption={() => false}
    />
    {touched && error
    && (
      <span style={{
        position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
      }}
      >
        {error}
      </span>
    )}
  </div>
);

AdminSchoolSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

AdminSchoolSelectInput.defaultProps = {
  disabled: false,
};

export default AdminSchoolSelectInput;
