import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import AdminSiteProgramUsersPage from '../pages/AdminSiteProgramUsersPage';
import ApplicationKidsCafe from '../components/ApplicationKidsCafe';
import AdminSitesPage from '../pages/AdminSitesPage';
import AdminSiteProgramSettingsPage from '../pages/AdminSiteProgramSettingsPage';
import PrintComponent from '../components/PrintComponentKidsCafe';

const KidsCafeAdminRoutes = ({ match }) => (
  <Switch>
    <AuthRoute path={`${match.path}/sites`} component={AdminSitesPage} />
    <AuthRoute path={`${match.path}/users`} component={AdminSiteProgramUsersPage} />
    <AuthRoute exact path={`${match.path}/program-settings`} component={AdminSiteProgramSettingsPage} />
    <AuthRoute path={`${match.path}/application/:step/:id`} component={ApplicationKidsCafe} />
    <AuthRoute path={`${match.path}/print/:id/:term`} component={PrintComponent} />
    <Route component={() => <Redirect to={`${match.path}/sites`} />} />
  </Switch>
);

KidsCafeAdminRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default KidsCafeAdminRoutes;
