import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required, email } from '../../helpers';
import TextField from '../TextField';

const LoginForm = reduxForm({ form: 'login' })((props) => {
  const { handleSubmit, submitting, login } = props;

  return (
    <form onSubmit={handleSubmit(login)} className="login-form" id="email-form">
      <div className="field-combo stack">
        <Field
          label={<label htmlFor="Email-Log-In" className="field-label">Email</label>}
          component={TextField}
          validate={[required, email]}
          name="email"
          type="email"
          placeholder="you@example.com"
          autoComplete="email"
          className="text-field w-input"
        />
      </div>
      <div className="field-combo stack">
        <Field
          label={<label htmlFor="Password" className="field-label">Password</label>}
          component={TextField}
          validate={[required]}
          name="password"
          type="password"
          placeholder="Enter Password"
          autoComplete="current-password"
          className="text-field w-input"
        />
      </div>
      <div className="login-flex">
        <input
          type="submit"
          value={submitting ? 'Please wait...' : 'Log In'}
          disabled={submitting}
          className="btn-fill-p w-button"
        />
        <Link to="/forgot-password" className="text-link">Forgot Your Password?</Link>
      </div>
    </form>
  );
});

export default LoginForm;
