import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import readXlsxFile from 'read-excel-file';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { nth, toString } from 'lodash';
import StudentDetailsModal from './StudentDetailsModal';
import { ReportsDateContext } from './index';
import handleToastError from '../../helpers/handleToastError';
import downloadTemplate from '../../helpers/downloadTemplate';
import { schoolReportsQuery, copyStudentsMutation, uploadStudentsMutation } from '../../apollo';

const ReportDetailsPending = ({ termMonth, ...otherProps }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const resetInput = (e) => {
    e.target.value = null;
  };

  const parseTemplate = async (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.substr(file.name.length - 4);

    if (fileExtension !== 'xlsx') {
      toast('Upload must be a .xlsx file');
      return null;
    }

    const rows = await readXlsxFile(file);
    rows.shift();

    return rows.map(row => ({
      studentName: toString(nth(row, 0)).trim(),
      studentIdNumber: toString(nth(row, 1)).trim(),
    }));
  };

  return (
    <div>
      <StudentDetailsModal
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        termMonthId={termMonth.termMonthId}
        distributionDates={termMonth.distributionDates}
        form={`firstStudent_${termMonth.termMonthId}`}
        {...otherProps}
      />

      <div className="student-distribution-report">
        <h5 className="heading-4">Student Distribution Report</h5>
        <p className="paragraph-2">
          To start a report copy students from the previous month,
          upload the excel template, or add a student to start fresh.
        </p>
        <ReportsDateContext.Consumer>
          {context => (
            <Fragment>
              <Mutation
                mutation={copyStudentsMutation}
                onError={mutationError => handleToastError(mutationError)}
                onCompleted={() => toast('Copy Students Successful')}
                refetchQueries={[
                  {
                    query: schoolReportsQuery,
                    variables: { ...context.filterDates, schoolId: otherProps.selectedSchool },
                  },
                ]}
                awaitRefetchQueries
              >
                {(copyStudents, { loading }) => (
                  <button
                    className="btn-green space w-button"
                    disabled={loading}
                    onClick={() => {
                      copyStudents({ variables: { termMonthId: termMonth.termMonthId } });
                    }}
                    type="button"
                  >
                    {loading ? 'Copying...' : 'Copy Students'}
                  </button>
                )}
              </Mutation>
              <button className="btn-green space w-button" onClick={openModal} type="button">
                Add a Student
              </button>

              <Mutation
                mutation={uploadStudentsMutation}
                onError={mutationError => handleToastError(mutationError)}
                onCompleted={() => toast('Upload Successful')}
                refetchQueries={[
                  {
                    query: schoolReportsQuery,
                    variables: { ...context.filterDates, schoolId: otherProps.selectedSchool },
                  },
                ]}
                awaitRefetchQueries
              >
                {(uploadStudents, { loading }) => (
                  <Fragment>
                    <label htmlFor="template-upload" className="template-upload-label btn-green space w-button">
                      {loading ? 'Uploading...' : 'Upload Template'}
                    </label>
                    <input
                      disabled={loading}
                      style={{ display: 'none' }}
                      id="template-upload"
                      onClick={resetInput}
                      onChange={async (e) => {
                        const data = await parseTemplate(e);

                        if (data) {
                          uploadStudents({
                            variables: {
                              input: { termMonthId: termMonth.termMonthId, students: data },
                            },
                          });
                        }
                      }}
                      type="file"
                    />
                  </Fragment>
                )}
              </Mutation>

              <button className="btn-green download w-button" onClick={downloadTemplate} type="button">
                Download Template
              </button>
            </Fragment>
          )}
        </ReportsDateContext.Consumer>
      </div>
    </div>
  );
};

ReportDetailsPending.propTypes = {
  termMonth: PropTypes.object.isRequired,
};

export default ReportDetailsPending;
