import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { requiredTrue } from '../../helpers';
import Checkbox from '../Checkbox';
import isSite from '../../helpers/isSite';

const BackgroundVerification = (props) => {
  const {
    readOnly,
    termsAvailable,
    acceptingApplications,
    program,
  } = props;

  const { name } = program;

  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">
        Background Verification
      </h4>
      <p>
        {
          `By checking this box, the School/Partner Agency acknowledges that it understands
          and agrees to the National Background Search Policy of the Houston Food Bank as
          attached in the ${name} information packet. Further, your
          ${isSite(name) ? 'site' : 'school'}
          verifies that all individuals who participate in the ${name} Program who
          have direct repetitive contact with the children are listed here, and have undergone,
          and passed, a National Background Search. Your
          ${isSite(name) ? 'site' : 'school'} is required to submit this
          form each year when becoming a partner of Houston Food Bank and each time a new
          staff or volunteer with direct repetitive contact with children joins the program.`
        }
      </p>
      <br />
      <Field
        name="certififedVerification"
        validate={requiredTrue}
        component={Checkbox}
        className="checkbox-inline w-checkbox"
        label="I certify the above"
        disabled={readOnly || (isSite(name) ? !acceptingApplications : !termsAvailable)}
      />
    </Fragment>
  );
};

BackgroundVerification.propTypes = {
  program: PropTypes.object.isRequired,
  termsAvailable: PropTypes.bool,
  acceptingApplications: PropTypes.bool,
  readOnly: PropTypes.bool,
};

BackgroundVerification.defaultProps = {
  readOnly: false,
  termsAvailable: true,
  acceptingApplications: true,
};

export default BackgroundVerification;
