import React, { Fragment } from 'react';

const TeachersAidProgramAgreement = (props = null) => {
  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">Program Agreement</h4>
      <div className="agreement-div scroll">
        <h5 className="black-headline center">Exhibit D: Teachers Aid</h5>
        <h5 className="black-headline">Program Description</h5>
        <p>
          Many children can’t afford the most basic school supplies. Without the proper tools to do the work, children are challenged to achieve academic success. Many compassionate teachers pay for school supplies from their own pockets so their students have more productive, efficient learning experience. The Teachers Aid program provides the supplies kids need to succeed.
        </p>
        <h5 className="black-headline">Program Details – School Programs Partner’s Responsibilities</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item sub"><p><span className="line-item">1.</span> Schools participating must be a Title 1 school, 90% or higher (urban schools) and 70% or higher rural schools of the student’s population on the free and reduced-price school lunch program.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">2.</span> Site Coordinators has been assigned and agrees too: in the program all other staff members are not eligible to participate.</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">-</span> Assist the teachers with scheduling an in-person shopping day and time in appropriate system.</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">-</span> and inquiries.</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">-</span> Be responsible for keeping the teacher roster updated after its initial application submission.</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">-</span> Inform staff that only Counselors, CIS Representatives and Teachers can participate in the program all other staff members are not eligible to participate.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">3.</span> In-Store is only once a month;</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.</span> All in-store appointments must be made using out scheduling platform;</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.</span> A teacher must be added to the roster when it is completed and submitted along with the application;</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">6.</span> Consent to Client Data Privacy Statement. For any questions that you may have about the program, please contact the Teachers Aid staff at teachersaid@houstonfoodbank.org</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">1.</span> Schools participating must be a Title 1 school, 90% or higher (urban schools) and 70% or higher rural schools of the student’s population on the free and reduced-price school lunch program.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">2.</span> Site Coordinators has been assigned and agrees too: in the program all other staff members are not eligible to participate.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">3.</span> In-Store is only once a month;</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.</span> All in-store appointments must be made using out scheduling platform;</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.</span> A teacher must be added to the roster when it is completed and submitted along with the application;</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">6.</span> Consent to Client Data Privacy Statement. For any questions that you may have about the program, please contact the Teachers Aid staff at teachersaid@houstonfoodbank.org</p></li>
        </ul>
      </div>
    </Fragment>
  );
};

export default TeachersAidProgramAgreement;
