import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import handleToastError from '../../helpers/handleToastError';
import { uploadS3ObjectMutation, awsObjectsQuery } from '../../apollo';
import FileInput from '../FileInput';
import TextField from '../TextField';
import { required } from '../../helpers';
import { AWS_S3_DOCUMENT_BUCKET } from '../../config';

const DocumentForm = reduxForm({
  form: 'documentForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  dispatch,
  reset,
}) => {
  const [selectedFileName, setSelectedFileName] = useState(null);

  return (
    <Mutation
      mutation={uploadS3ObjectMutation}
      refetchQueries={[
        { query: awsObjectsQuery, variables: { input: { Bucket: AWS_S3_DOCUMENT_BUCKET } } },
      ]}
      onError={mutationError => handleToastError(mutationError)}
      onCompleted={() => {
        setSelectedFileName();
        toast('Upload Successful');
        dispatch(reset('documentForm'));
      }}
      awaitRefetchQueries
    >
      {(uploadS3Object) => {
        const submit = ({ newFile, title }) => {
          Object.defineProperty(newFile, 'name', {
            writable: true,
            value: title,
          });

          return uploadS3Object({ variables: { file: newFile, bucket: AWS_S3_DOCUMENT_BUCKET } });
        };

        return (
          <Form onSubmit={handleSubmit(submit.bind(this))}>
            <div className="field-combo-wrapper-2 w-clearfix">
              <div className="field-combo-2">
                <Field
                  label={<label htmlFor="First" className="field-label">Document Title</label>}
                  component={TextField}
                  validate={required}
                  disabled={submitting}
                  name="title"
                  className="text-field w-input"
                />
              </div>
              <div className="field-combo-2" style={{ position: 'relative' }}>
                <label htmlFor="file-upload" className="field-label">
                  Choose File
                </label>
                <label htmlFor="file-upload" className="text-field w-input file-upload-label">
                  {selectedFileName || 'No file chosen'}
                </label>
                <Field
                  name="newFile"
                  disabled={submitting}
                  component={FileInput}
                  validate={required}
                  setSelectedFileName={setSelectedFileName}
                />
              </div>
              <input
                type="submit"
                value={submitting ? 'Uploading...' : 'Add Document and Share'}
                disabled={submitting}
                className="btn-green solid top-space w-button"
              />
            </div>
          </Form>
        );
      }}
    </Mutation>
  );
});

export default DocumentForm;
