import gql from 'graphql-tag';

export default gql`
  mutation savePartnerServicesApplication($input: ApplicationPartnerServicesInput!) {
    savePartnerServicesApplication(input: $input) {
      applicationPartnerServicesId
      new
      isContinuation
      organization {
        organizationId
        name
        einNumber
        address1
        address2
        city
        county
        state
        zip
        organizationMembers {
          organizationMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        primaryPointOfContact {
          organizationMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        secondaryPointOfContact {
          organizationMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
      }
      operatingHoursStartTime
      operatingHoursEndTime
      gateCode
      isNonProfit
      isReligious
      servicesInterest
    }
  }
`;
