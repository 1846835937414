import gql from 'graphql-tag';

export default gql`
  query configTeachersAid {
    configTeachersAid {
      waitlistEnabled
      summerEnabled
      schoolEnabled
    }
  }
`;
