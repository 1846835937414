import React, { Fragment } from 'react';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { has } from 'lodash';
import handleToastError from '../../helpers/handleToastError';
import { required } from '../../helpers';
import removeTypename from '../../helpers/removeTypename';
import {
  adminArchiveDistrictMutation,
  adminRestoreDistrictMutation,
} from '../../apollo';
import TextField from '../TextField';
import moment from 'moment';

const SchoolDistrictDetailForm = reduxForm({
  form: 'districtDetailForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  onSubmit,
  initialValues,
  program: {
    programUrl,
  },
}) => {
  const existingDistrict = has(initialValues, 'id');

  const isDeleted = initialValues.deletedAt;

  const archiveRestoreMutation = isDeleted ? adminRestoreDistrictMutation : adminArchiveDistrictMutation;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="div-block-13">
        <div className="div-block-19">
          {
            existingDistrict ? (
              <Fragment>
                <h2>District Profile</h2>
                <div className="last-edit">{`Last Edited: ${moment.utc(initialValues.updatedAt).format('M/D/YY')}`}</div>
              </Fragment>
            ) : <h2>Create New District</h2>
          }
          <div className="contact-block">
            <div className="w-form">
              <div className="field-combo-2-wrapper-2 w-clearfix">
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">District Name</label>}
                    component={TextField}
                    validate={required}
                    name="name"
                    className="text-field w-input"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="contact-block last-contact">
            <div className="w-form">
              <br />
              <input
                type="submit"
                value={submitting ? 'Please wait...' : 'Save Changes'}
                disabled={submitting}
                className="btn-solid-green w-button"
              />
              {
                existingDistrict && (
                  <Mutation
                    mutation={archiveRestoreMutation}
                    variables={{
                      districtId: initialValues.districtId,
                    }}
                    onError={mutationError => handleToastError(mutationError)}
                    onCompleted={() => (isDeleted ? toast('District Archived') : toast('District Restored'))}
                  >
                    {(mutation, { data, loading }) => {
                      const confirmDeleteRestore = () => {
                        confirmAlert({
                          message: 'Are you sure?',
                          buttons: [
                            {
                              label: 'Cancel',
                            },
                            {
                              label: isDeleted ? 'Restore' : 'Archive',
                              onClick: mutation,
                            },
                          ],
                        });
                      };

                      if (data) {
                        return <Redirect to={`${programUrl}/districts`} />;
                      }

                      return (
                        <span
                          onClick={confirmDeleteRestore}
                          className={classnames('delete-link w-button', { 'disabled-link': loading })}
                        >
                          { isDeleted ? 'Restore ' : 'Archive ' }
                          District
                        </span>
                      );
                    }}
                  </Mutation>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
});

const mapStateToProps = (state, props) => {
  const { districtDetails } = props;

  return {
    initialValues: {
      ...removeTypename(districtDetails),
    },
  };
};

export default connect(mapStateToProps)(SchoolDistrictDetailForm);
