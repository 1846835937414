import gql from 'graphql-tag';

export default gql`
  mutation adminEditConfigBackpackBuddy($input: ConfigBackpackBuddyInput!) {
    adminEditConfigBackpackBuddy(input: $input) {
      id
      configBackpackBuddyId
      schoolEnabled
      summerEnabled
    }
  }
`;
