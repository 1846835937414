import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import { awsObjectsQuery } from '../apollo';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import withAdminLayout from '../helpers/withAdminLayout';
import ResourceForm from '../components/ResourceForm';
import ResourceTable from '../components/ResourceTable';
import { AWS_S3_RESOURCE_BUCKET } from '../config';

const AdminResourcesPage = () => (
  <Fragment>
    <h2>Resources</h2>
    <Query
      query={awsObjectsQuery}
      variables={{ input: { Bucket: AWS_S3_RESOURCE_BUCKET } }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, data, error }) => {
        if (loading) return <Loading />;
        if (error) return <BlankState title="Error" subtitle="There was an error loading" />;

        const resources = get(data, 's3.listObjects.Contents', []);

        return (
          <Fragment>
            <div className="text-block">Upload any important resources for use by program administrators only.</div>
            <div className="top-border">
              <div className="w-form">
                <ResourceForm />
              </div>
              <div className="table-wrapper-2">
                <ResourceTable resources={resources} />
              </div>
            </div>
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);

export default withAdminLayout(AdminResourcesPage);
