/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  required,
  requiredTrue,
  email,
  signeeEmailmatch,
} from '../../helpers';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import TeachersAidProgramAgreement from '../TeachersAidProgramAgreement';
import GeneralAgreement from '../GeneralAgreement';
import TeachersAidProgramAgreement2024 from '../TeachersAidProgramAgreement2024';
import GeneralAgreement2024 from '../GeneralAgreement2024';
import NonDiscriminationStament from '../NonDiscriminationStament';
import moment from 'moment';

const TeachersAidSignatureContent = ({
  isAdmin,
  school,
  signee,
  isApproved,
  application
}) => {
  const isPrev2024 = () => {
    const date = moment(application.submittedAt);
  
    return !!application.submittedAt && ((date.year() < 2024 || date.month() < 7) && date.year() <= 2024);
  };

  return <>
    <div className="bb-title">
      <h2 className="purple-headline half-width signature">
        <strong>Teachers Aid Program Agreement Acknowledgement</strong>
      </h2>
      <div className="agreement-site-info">
        <div className="site-info-line">
          <div className="site-info">School Name:</div>
          <div className="site-info-input">{school.name}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">School District:</div>
          <div className="site-info-input">{school.districtName}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Address Line 1:</div>
          <div className="site-info-input">{school.address1}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Address Line 2:</div>
          <div className="site-info-input">{school.address2}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">City:</div>
          <div className="site-info-input">{school.city}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">County:</div>
          <div className="site-info-input">{school.county}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">State:</div>
          <div className="site-info-input">{school.state}</div>
        </div>
        <div className="site-info-line">
          <div className="site-info">Postal Code:</div>
          <div className="site-info-input">{school.zip}</div>
        </div>
      </div>
    </div>
    {isPrev2024() ? <GeneralAgreement data={application} />: <GeneralAgreement2024 data={application} />}
    <div className="field-combo-wrapper">
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="Last" className="field-label">
                    Agreement Sign
                  </label>
                  <i>{application.educator.firstName} {application.educator.lastName}</i>
                </Fragment>
              )
            }
          component={TextField}
          placeholder="Enter Your Name"
          validate={[required]}
          disabled={true}
          name="nameSignFirst"
          className="text-field w-input"
        />
      </div>
      <div className="field-combo">
        <Field
          label={<strong>I Agree</strong>}
          name="agreeToProgramPurpose"
          validate={requiredTrue}
          component={Checkbox}
          className="checkbox-inline"
          disabled={true}
          {...{
            style: {paddingBottom: 35}
          }}
        />
      </div>
    </div>
    <NonDiscriminationStament data={application} />
    <div className="field-combo-wrapper">
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="Last" className="field-label">
                    Nondiscrimination Sign
                  </label>
                  <i>{application.educator.firstName} {application.educator.lastName}</i>
                </Fragment>
              )
            }
          component={TextField}
          placeholder="Enter Your Name"
          validate={[required]}
          disabled={true}
          name="nameSignSecond"
          className="text-field w-input"
        />
      </div>
      <div className="field-combo">
        <Field
          label={<strong>I Agree</strong>}
          name="agreeNondiscrimination"
          validate={requiredTrue}
          component={Checkbox}
          className="checkbox-inline"
          disabled={true}
          {...{
            style: {paddingBottom: 35}
          }}
        />
      </div>
    </div>
    {isPrev2024() ? <TeachersAidProgramAgreement />:<TeachersAidProgramAgreement2024/>} 
    <div className="field-combo-wrapper">
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="Last" className="field-label">
                    Program Sign
                  </label>
                  <i>{application.educator.firstName} {application.educator.lastName}</i>
                </Fragment>
              )
            }
          component={TextField}
          placeholder="Enter Your Name"
          validate={[required]}
          disabled={true}
          name="nameSignThird"
          className="text-field w-input"
        />
      </div>
      <div className="field-combo">
        <Field
          label={<strong>I Agree</strong>}
          name="agreeToProgramResponsibilities"
          validate={requiredTrue}
          component={Checkbox}
          className="checkbox-inline"
          disabled={true}
          {...{
            style: {paddingBottom: 35}
          }}
        />
      </div>
    </div>
    
    <p>
      Type your name and email as your acknowledgement.
    </p>
    <div className="field-combo-wrapper">
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    Principal Name
                  </label>
                  <i>{signee.fullName}</i>
                </Fragment>
              )
            }
          component={TextField}
          validate={required}
          disabled={isApproved || isAdmin}
          name="signeeName"
          className="text-field w-input"
        />
      </div>
      <div className="field-combo">
        <Field
          label={
              (
                <Fragment>
                  <label htmlFor="First-3" className="field-label">
                    Principal Email
                  </label>
                  <i>{signee.email}</i>
                </Fragment>
              )
            }
          component={TextField}
          validate={[required, email, signeeEmailmatch]}
          disabled={isApproved || isAdmin}
          name="signeeEmail"
          className="text-field w-input"
        />
      </div>

    </div>
  </>
};

TeachersAidSignatureContent.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  school: PropTypes.object.isRequired,
  signee: PropTypes.object.isRequired,
};

export default TeachersAidSignatureContent;
