import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import withSchoolLinks from '../helpers/withSchoolLinks';
import { awsObjectsQuery } from '../apollo';
import { AWS_S3_DOCUMENT_BUCKET } from '../config';
import DocumentTable from '../components/DocumentTable';

const EducatorDocumentsPage = ({ selectedSchool }) => {
  if (!selectedSchool) return <Redirect to="/dashboard" />;

  return (
    <div className="wrapper document">
      <h2>Document Library</h2>
      <Query
        query={awsObjectsQuery}
        variables={{ input: { Bucket: AWS_S3_DOCUMENT_BUCKET } }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <BlankState title="Error" subtitle="There was an error loading" />;

          const documents = get(data, 'aws.s3.listObjects.Contents', []);
          if (!documents.length) return <BlankState title="No Results" />;

          return (
            <div className="table-wrapper-2">
              <DocumentTable documents={documents} />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

EducatorDocumentsPage.propTypes = {
  selectedSchool: PropTypes.string.isRequired,
};

export default withSchoolLinks(EducatorDocumentsPage);
