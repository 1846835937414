import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Modal,
    makeStyles
  } from '@material-ui/core';
import BlankState from '../BlankState';
import Loading from '../Loading';
import React, { Fragment, useState } from 'react'
import { Query } from 'react-apollo';
import { applicationKidsCafeQuery, districtsQuery } from '../../apollo';
import { sortBy } from 'lodash';
import { get } from 'lodash';
import applicationKidsCafe from '../../apollo/queries/applicationKidsCafe';
import kidsCafeServiceInterests from '../../constants/kidsCafeServiceInterests';
import { goBack } from 'connected-react-router';

function getModalStyle() {
      
    return {
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
    },
  }));

 const schoolType = {
    ELEMENTARY: 'Elementary',
    MIDDLE: 'Middle',
    HIGH: 'High',
    NON_TRADITIONAL: 'Non-traditional',
  }

const PrintComponent = (props) => {

    const { match, dispatch } = props;
    const { params } = match;

    const applicationKidsCafeId = params.id;
    const term = params.term;
    const participantAgeRanges = {
        INFANT: 'Infant',
        ONE_THREE: '1-3',
        FOUR_SIX: '4-6',
        SEVEN_NINE: '7-9',
        TEN_TWELVE: '10-12',
        THIRTEEN_FIFTEEN: '13-15',
        SIXTEEN_EIGHTEEN: '16-18',
        EIGHTEEN_PLUS: '18+',
        }

    const daysForService = {
            MONDAYS: 'Mondays',
            TUESDAYS: 'Tuesdays',
            WEDNESDAYS: 'Wednesdays',
            THURSDAYS: 'Thursdays',
            FRIDAYS: 'Fridays',
            SATURDAYS: 'Saturdays',
          }

    const [hideButtons, setHideButtons] = useState(false);

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
  
    return (
        <Query
        query={applicationKidsCafe}
        variables={{ applicationKidsCafeId: applicationKidsCafeId }}
        fetchPolicy="network-only"
      >
        {({ loading, data, error }) => {
          if (loading) return <Loading />;
          if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
          
          const body = data.applicationKidsCafe;
          
  
          return (
            <Grid
            style={{height: '100%', minHeight: '100%', minWidth: '100%'}}
            id='print'
            >
                <div style={modalStyle} className={classes.paper}>
                <div >
                <b>Application Details:</b>
                <div>
                    {term}
                </div>
                <h4 className="purple-headline boxed-in">School Information</h4>
                <div>
                    <b>Did your site participate in this program last year?&nbsp;</b>
                    {body.new == true? 'No': 'Yes'}
                </div>
                <div>
                    <b>School Name:&nbsp;</b>
                    {body.site.name}
                </div>
                <div>
                    <b>Closest School:&nbsp;</b>
                    {body.site.closestSchool}
                </div>
                <div>
                    <b>Address:&nbsp;</b>
                    {body.site.address1}
                </div>
                <div>
                    <b>Address 2:&nbsp;</b>
                    {body.site.address2}
                </div>
                <div>
                    <b>City:&nbsp;</b>
                    {body.site.city}
                </div>
                <div>
                    <b>State:&nbsp;</b>
                    {body.site.state}
                </div>
                <div>
                    <b>Zip:&nbsp;</b>
                    {body.site.zip}
                </div>
                <div>
                    <b>1. Is your site non-profit?&nbsp;</b>
                    {body.forProfit == true? 'Yes': 'No'}
                </div>
                <div>
                    <b>2. Has your site participated in the Texas Department of Agriculture CACFP or SFSP meals program?&nbsp;</b>
                    {body.participatedAgriculture == true? 'Yes': 'No'}
                </div>
                <h4 className="purple-headline boxed-in">Allotment Information</h4>
                <div>
                    <b>Name of Sponsor:&nbsp;</b>
                    {body.agricultureSponsor}
                </div>
                <div>
                    <b>3. Does your site have a childcare license?&nbsp;</b>
                    {body.childcareLicense}
                </div>
                <div>
                    <b>4. Does your site have access to wifi?&nbsp;</b>
                    {body.hasWifi == true? 'Yes': 'No'}
                </div>
                <div>
                    <b>5. Does your site have a device to record meal service (e.g. computer/tablet)?&nbsp;</b>
                    {body.hasRecordingDevice  == true? 'Yes': 'No'}
                </div>
                <div>
                    <b>6. Do you have storage available that meets Food Safety Guidelines?&nbsp;</b>
                    {body.hasStorage  == true? 'Yes': 'No'}
                </div>
                <div>
                    <b>7. Does your site receive any truck deliveries? (e.g. FedEx)&nbsp;</b>
                    {body.receivesDeliveries  == true? 'Yes': 'No'}
                </div>
                <div>
                    <b>8. Is your site a childcare center?&nbsp;</b>
                    {body.isChildcareCenter  == true? 'Yes': 'No'}
                </div>
                {body.isChildcareCenter && <>
                    <div>
                        <b>Do you have a microwave on site?&nbsp;</b>
                        {body.hasMicrowave  == true? 'Yes': 'No'}
                    </div>
                    <div>
                        <b>Are you a Texas Rising Star Facility?&nbsp;</b>
                        {body.isTexasRisingStar == true? 'Yes': 'No'}
                    </div>
                    <div>
                        <b>Do you currently participate in a federally funded program?&nbsp;</b>
                        {body.isFederalParticipant == true? 'Yes': 'No'}
                    </div>
                    <div>
                        <b>Is your site open-enrolled the entire year?&nbsp;</b>
                        {body.isOpenEnrolledYear  == true? 'Yes': 'No'}
                    </div>
                    <div>
                        <b>Do you have infants enrolled?&nbsp;</b>
                        {body.hasInfantsEnrolled == true? 'Yes': 'No'}
                    </div>
                    <div>
                        <b>Does your site have an after-school program available?&nbsp;</b>
                        {body.hasAfterSchool == true? 'Yes': 'No'}
                    </div>
                </>
                }
                <h4 className="purple-headline boxed-in">Primary Point of Contact</h4>
                <div>
                    <b>First Name&nbsp;</b>
                    {body.site.primaryPointOfContact.firstName}
                </div>
                <div>
                    <b>Last Name&nbsp;</b>
                    {body.site.primaryPointOfContact.lastName}
                </div>
                <div>
                    <b>Job Title (optional)&nbsp;</b>
                    {body.site.primaryPointOfContact.title}
                </div>
                <div>
                    <b>Phone&nbsp;</b>
                    {body.site.primaryPointOfContact.phone}
                </div>
                <div>
                    <b>Email&nbsp;</b>
                    {body.site.primaryPointOfContact.email}
                </div>
                <div>
                    <b>Is this the site Supervisor?&nbsp;</b>
                    {body.site.primaryPointOfContact.isSupervisor  == true? 'Yes': 'No'}
                </div>
                <h4 className="purple-headline boxed-in">Secondary Point of Contact</h4>
        
                <div>
                    <b>First Name&nbsp;</b>
                    {body.site.secondaryPointOfContact.firstName}
                </div>
                <div>
                    <b>Last Name&nbsp;</b>
                    {body.site.secondaryPointOfContact.lastName}
                </div>
                <div>
                    <b>Job Title (optional)&nbsp;</b>
                    {body.site.secondaryPointOfContact.title}
                </div>
                <div>
                    <b>Phone&nbsp;</b>
                    {body.site.secondaryPointOfContact.phone}
                </div>
                <div>
                    <b>Email&nbsp;</b>
                    {body.site.secondaryPointOfContact.email}
                </div>
                <div>
                    <b>Is this the site Supervisor?&nbsp;</b>
                    {body.site.secondaryPointOfContact.isSupervisor  == true? 'Yes': 'No'}
                </div>
                <h4 className="purple-headline boxed-in">Site Service Dates and Times</h4>
        
                <div>
                    <b>First Name&nbsp;</b>
                    {body.startDate}
                </div>
                <div>
                    <b>First Name&nbsp;</b>
                    {body.endDate}
                </div>
                <div>
                    <b>Site Operating Hours - Start Time&nbsp;</b>
                    {body.operatingHoursStartTime}
                </div>
                <div>
                    <b>Site Operating Hours - End Time&nbsp;</b>
                    {body.operatingHoursEndTime}
                </div>
                <div>
                    <b>Site Gate Code (if applicable)&nbsp;</b>
                    {body.gateCode}
                </div>
                <div>
                    <b>Total Expected Participants&nbsp;</b>
                    {body.numExpectedParticipants}
                </div>
                <div>
                    <b>Participants Age Ranges (check all that apply):&nbsp;</b>
                    {body.participantAgeRanges.map(el => participantAgeRanges[el]).join(', ')}
                </div>
                <div>
                    <b>Days of the week for meal service (check all that apply):&nbsp;</b>
                    {body.daysForService.map(el => daysForService[el]).join(', ')}
                </div>
                <div>
                    <b>Select the service(s) you are interested in:</b>
                    <div>{kidsCafeServiceInterests.map(el => body[el.name] == true ? 
                    <span key={el.name}>
                        {el.label} <span>&#10003;</span>
                    </span > : <span key={el.name}>
                        {el.label} {
                            <Fragment>
                            <div>
                                <input
                                disabled={true}
                                type="checkbox"
                                className="display-none w-checkbox-input"
                                checked={false}
                                />
                            </div>
                            </Fragment>
                        }
                    </span>)}
                    </div>
                </div>
                {kidsCafeServiceInterests.filter(el => body[el.name]== true).map(el => <>
                    <div>
                        <b>{el.label} - Start Time:&nbsp;</b>
                        {el.name == 'interestBreakfast'? body.breakfastStartTime: 
                        el.name == 'interestLunch'? body.lunchStartTime: 
                        el.name == 'interestSnack'? body.snackStartTime: 
                        el.name == 'interestSupper'? body.supperStartTime: 
                        <></>}
                    </div>
                    <div>
                    <b>{el.label} - End Time:&nbsp;</b>
                        {el.name == 'interestBreakfast'? body.breakfastEndTime: 
                        el.name == 'interestLunch'? body.lunchEndTime: 
                        el.name == 'interestSnack'? body.snackEndTime: 
                        el.name == 'interestSupper'? body.supperEndTime: 
                        <></>}
                    </div>
                    <div>
                    <b>{el.label} - Start Time:&nbsp;</b>
                        {el.name == 'interestBreakfast'? body.numExpectedParticipantsBreakfast: 
                        el.name == 'interestLunch'? body.numExpectedParticipantsLunch: 
                        el.name == 'interestSnack'? body.numExpectedParticipantsSnack: 
                        el.name == 'interestSupper'? body.numExpectedParticipants: 
                        <></>}
                    </div>
                    </>)}

                <div>
                    <b>Special delivery notes (gate code, Door 1, front lobby, etc…)&nbsp;</b>
                    {body.specialDeliveryNotes}
                </div>
                <div>
                    <b>What is your earliest delivery time?&nbsp;</b>
                    {body.earliestDeliveryTime}
                </div>
                <div>
                    <b>What kind of enrichment programs are provided?&nbsp;</b>
                    {body.enrichmentPrograms}
                </div>
                <div>
                    <b>What specific dates are your holiday closures?&nbsp;</b>
                    {body.closureDates}
                </div>
                <div>
                    <b>What specific dates are your field trips?&nbsp;</b>
                    {body.fieldTrips}
                </div>
                <div>
                    <b>Who referred you? (optional)&nbsp;</b>
                    {body.referredBy}
                </div>
                <h4 className="purple-headline boxed-in">The Agreement</h4>
                <div>
                    <b>Agreement Sign:&nbsp;</b>
                    {body.nameSignFirst}
                </div>
                <div>
                    <b>Nondiscrimination Sign:&nbsp;</b>
                    {body.nameSignSecond}
                </div>
                <div>
                    <b>Program Sign:&nbsp;</b>
                    {body.nameSignThird}
                </div>
                </div>
                <DialogTitle className="register-info" style={{ textAlign: 'center' }}>
                
                </DialogTitle>
                <DialogContent className="register-info">
                {!hideButtons && <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                    
                    <Button
                        onClick={() => {
                            setHideButtons(true);
                            setTimeout(() => {
                                window.print();
                                setHideButtons(false);
                            }, 500);
                        }}
                        color="secondary"
                        variant="outlined"
                    >
                        Print Confirmation
                    </Button>
                    </Grid>
                </Grid>}
                {!hideButtons && <DialogActions
                    style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
                >
                    <Button onClick={() => dispatch(goBack())} color="primary" variant="outlined">
                    Close
                    </Button>
                </DialogActions>}
                </DialogContent>
                </div>
            </Grid>
          );
        }}
      </Query>
      
    );
  };
  

export default PrintComponent;