import gql from 'graphql-tag';

export default gql`
  mutation saveTeachersAidApplicationStepThree($input: ApplicationTeachersAidStepThreeInput!) {
    saveTeachersAidApplicationStepThree(input: $input) {
      applicationTeachersAidId
      certifiedProgramRules
      certifiedAgreement
      certifiedNonDiscrimination
      nameSignFirst
      nameSignSecond
      nameSignThird
    }
  }
`;
