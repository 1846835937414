import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import BlankState from '../BlankState';
import handleToastError from '../../helpers/handleToastError';
import ApplicationMetaForm from '../ApplicationMetaForm';

const ActiveTerms = ({ query, mutation }) => {
  const { definitions } = query;
  const [firstDefinition] = definitions;
  const { name: { value } } = firstDefinition;

  return (
    <Fragment>
      <Query query={query}>
        {({ loading, data, error }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) {
            return <BlankState title="Error" subtitle="There was an error loading" />;
          }

          return (
            <Fragment>
              <h2>Active Terms</h2>
              <div className="last-edit">{`Last Edited: ${data[value].lastEdited}`}</div>
              <h4>Application Settings</h4>
              <div className="text-block-14">Activate applications for the School Year and Summer programs.</div>
              <div className="top-border">
                <div className="w-form">
                  <Mutation
                    mutation={mutation}
                    onError={mutationError => handleToastError(mutationError)}
                    onCompleted={() => toast('Update Successful')}
                  >
                    {(adminEditApplicationMeta) => {
                      const update = values => (
                        adminEditApplicationMeta({
                          variables: {
                            input: {
                              ...values,
                            },
                          },
                        })
                      );

                      return (
                        <ApplicationMetaForm
                          {...data[value]}
                          onSubmit={update}
                        />
                      );
                    }}
                  </Mutation>
                </div>
              </div>
            </Fragment>
          );
        }}
      </Query>
    </Fragment>
  );
};

ActiveTerms.propTypes = {
  query: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
};

export default ActiveTerms;
