import gql from 'graphql-tag';

export default gql`
  query {
    userApplicationsBackpackBuddy {
      status
      applicationBackpackBuddyId
      termFormattedShort
      term {
        year
      }
      school {
        name
      }
    }
  }
`;
