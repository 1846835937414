import React from 'react';
import { Link } from 'react-router-dom';
import withAnonymousLayout from '../helpers/withAnonymousLayout';

const ForgotPasswordConfirm = () => (
  <>
    <h2 className="purple-headline half-width">Let&apos;s reset your password</h2>
    <div className="form-block w-form">
      <div className="password-guide">
        <div>
          Check your email for a link to reset your password.
          If it doesn&apos;t appear within a few minutes, check your spam folder.
        </div>
      </div>
      <Link to="/login" className="btn-fill-p top-pad-ten w-button max-content">Return to Login</Link>
    </div>
  </>
);

export default withAnonymousLayout(ForgotPasswordConfirm);
