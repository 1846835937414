import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import SelectProgramPage from '../pages/SelectProgramPage';
import BackpackBuddyEducatorRoutes from './BackpackBuddyEducatorRoutes';
import SchoolMarketEducatorRoutes from './SchoolMarketEducatorRoutes';
// HIDE PROGRAM PER HFB
// import PartnerServicesEducatorRoutes from './PartnerServicesEducatorRoutes';
import KidsCafeEducatorRoutes from './KidsCafeEducatorRoutes';
import TeachersAidEducatorRoutes from './TeachersAidEducatorRoutes';
import BackpackBuddyAdminRoutes from './BackpackBuddyAdminRoutes';
import SchoolMarketAdminRoutes from './SchoolMarketAdminRoutes';
// HIDE PROGRAM PER HFB
// import PartnerServicesAdminRoutes from './PartnerServicesAdminRoutes';
import KidsCafeAdminRoutes from './KidsCafeAdminRoutes';
import TeachersAidAdminRoutes from './TeachersAidAdminRoutes';
import AuthRoute from './AuthRoute';
import AnonymousRoute from './AnonymousRoute';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ForgotPasswordConfirmPage from '../pages/ForgotPasswordConfirmPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import SignaturePage from '../pages/SignaturePage';

const educatorComponents = {
  'Backpack Buddy': BackpackBuddyEducatorRoutes,
  'School Market': SchoolMarketEducatorRoutes,
  'Teachers Aid': TeachersAidEducatorRoutes,
  // HIDE PROGRAM PER HFB
  // 'Partner Services': PartnerServicesEducatorRoutes,
  'Kids Cafe': KidsCafeEducatorRoutes,
};

const adminComponents = {
  'Backpack Buddy': BackpackBuddyAdminRoutes,
  'School Market': SchoolMarketAdminRoutes,
  'Teachers Aid': TeachersAidAdminRoutes,
  // HIDE PROGRAM PER HFB
  // 'Partner Services': PartnerServicesAdminRoutes,
  'Kids Cafe': KidsCafeAdminRoutes,
};

const UserRoutes = ({ user, program }) => {
  const { name, programUrl } = program;

  const educatorRoute = user.type === 'EDUCATOR' && program.name ? <AuthRoute path={programUrl} component={educatorComponents[name]} /> : null;
  const adminRoute = user.type === 'ADMIN' && program.name ? <AuthRoute path={programUrl} component={adminComponents[name]} /> : null;
  const sharedRoute = user.type ? <AuthRoute exact path="/" component={SelectProgramPage} /> : null;

  return (
    <Switch>
      {educatorRoute}
      {adminRoute}
      {sharedRoute}
      <Route component={() => <Redirect to="/login" />} />
    </Switch>
  );
};

const ConnectedUserRoutes = connect(({ user, program }) => ({
  user,
  program,
}))(UserRoutes);

UserRoutes.propTypes = {
  user: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};
const Routes = () => (
  <Switch>
    <AnonymousRoute exact path="/login" component={LoginPage} />
    <AnonymousRoute exact path="/register" component={RegisterPage} />
    <AnonymousRoute exact path="/forgot-password" component={ForgotPasswordPage} />
    <AnonymousRoute exact path="/forgot-password-confirm" component={ForgotPasswordConfirmPage} />
    <AnonymousRoute exact path="/reset-password" component={ResetPasswordPage} />
    <AnonymousRoute exact path="/principal-signature/:id" component={SignaturePage} />
    <ConnectedUserRoutes />
    <AnonymousRoute component={() => <Redirect to="/login" />} />
  </Switch>
);

export default Routes;
