import gql from 'graphql-tag';

export default gql`
  mutation adminDeleteEducatorUser($educatorId: ID!) {
    adminDeleteEducatorUser(educatorId: $educatorId) {
      id
      deletedAt
    }
  } 
`;
