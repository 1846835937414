import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import { awsObjectsQuery } from '../apollo';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import withAdminLayout from '../helpers/withAdminLayout';
import DocumentForm from '../components/DocumentForm';
import DocumentTable from '../components/DocumentTable';
import { AWS_S3_DOCUMENT_BUCKET } from '../config';

const AdminDocumentsPage = () => (
  <Fragment>
    <h2>Documents</h2>
    <Query
      query={awsObjectsQuery}
      variables={{ input: { Bucket: AWS_S3_DOCUMENT_BUCKET } }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, data, error }) => {
        if (loading) return <Loading />;
        if (error) return <BlankState title="Error" subtitle="There was an error loading" />;

        const documents = get(data, 's3.listObjects.Contents', []);

        return (
          <Fragment>
            <div className="text-block">Upload documents to share with all educators.</div>
            <div className="top-border">
              <div className="w-form">
                <DocumentForm />
              </div>
              <div className="table-wrapper-2">
                <DocumentTable documents={documents} />
              </div>
            </div>
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);

export default withAdminLayout(AdminDocumentsPage);
