import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import handleToastError from '../../../../helpers/handleToastError';
import { adminSendPrincipalEmailMutation } from '../../../../apollo';
import mailIcon from '../../../../images/email-filled-closed-envelope.svg';

const SendEmailButton = ({ applicationSchoolMarketId }) => (
  <Mutation
    mutation={adminSendPrincipalEmailMutation}
    onError={mutationError => handleToastError(mutationError)}
    onCompleted={() => toast('Principal Email Sent')}
  >
    {(adminSendPrincipalEmail, { loading }) => {
      const sendPrincipalEmail = () => (
        adminSendPrincipalEmail({
          variables: {
            input: {
              applicationSchoolMarketId,
            },
          },
        })
      );

      return (
        <div className="crud-action-icon-wrapper-2 w-inline-block pointer" onClick={loading ? null : sendPrincipalEmail}>
          <img src={mailIcon} width={20} alt="Resend Signature Email" title="Resend Signature Email" />
        </div>
      );
    }}
  </Mutation>
);

SendEmailButton.propTypes = {
  applicationSchoolMarketId: PropTypes.string.isRequired,
};

export default SendEmailButton;
