import gql from 'graphql-tag';

export default gql`
  mutation adminUpdateDistrict($input: AdminDistrictInput!) {
    adminUpdateDistrict(input: $input) {
      id
      districtId
      name
      deletedAt
    }
  }
`;
