import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import getFormError from '../helpers/getFormError';


import withAnonymousLayout from '../helpers/withAnonymousLayout';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import { sendPasswordResetEmailMutation } from '../apollo';


const ForgotPasswordPage = () => (
  <Mutation mutation={sendPasswordResetEmailMutation}>
    {(sendPasswordResetEmail, { data, error }) => {
      const sendEmail = ({ email }) => (
        sendPasswordResetEmail({
          variables: {
            input: {
              email,
            },
          },
        })
      );

      if (data) return <Redirect to="/forgot-password-confirm" />;

      return (
        <Fragment>
          <h2 className="purple-headline half-width">Let&apos;s reset your password</h2>
          <div className="form-block w-form">
            <ForgotPasswordForm sendEmail={sendEmail} />
            {
              error && (
                <div className="form-error w-form-fail" style={{ display: 'block' }}>
                  <div>{getFormError(error)}</div>
                </div>
              )
            }
          </div>
        </Fragment>
      );
    }}
  </Mutation>
);

export default withAnonymousLayout(ForgotPasswordPage);
