import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import withProgramsLayout from '../helpers/withProgramsLayout';
import selectProgram from '../helpers/selectProgram';
import programs from '../constants/programs';
import programDescriptions from '../constants/programDescriptions';
import { resetProgramAction } from '../redux';

const SelectProgramPage = (props) => {
  const { dispatch, program } = props;

  useEffect(() => {
    if (!isEmpty(program)) {
      dispatch(resetProgramAction());
    }
  }, [dispatch, program]);

  return (
    <Fragment>
      <div className="section-dashboard">
        <div className="_1300-container">
          <h2>Our Programs</h2>
          <div className="spacer-50" />
          {
            programs.map((obj) => {
              const {
                logo,
                name,
              } = obj;

              return (
                <div key={obj.name} className="program-listing">
                  <div onClick={() => selectProgram(dispatch, obj)} className="program-logo w-inline-block">
                    {
                      logo
                        ? <img src={logo} alt="" className="image-8" width={200} />
                        : (
                          <div className="program-logo-text">
                            <span className="logo-bold">{name.split(' ')[0]}</span>
                            {name.split(' ')[1]}
                          </div>
                        )
                    }
                  </div>
                  <div className="program-summary">
                    <div className="summary">
                      <h4>{name}</h4>
                      <p>{programDescriptions[name]}</p>
                    </div>
                    <div onClick={() => selectProgram(dispatch, obj)} className="btn-green w-button">Visit</div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </Fragment>
  );
};

SelectProgramPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired,
};

export default withProgramsLayout(SelectProgramPage);
