import React, { Fragment } from 'react';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { adminEditEmailsMutation, adminEmailsQuery } from '../../apollo';
import BlankState from '../BlankState';
import Loading from '../Loading';
import handleToastError from '../../helpers/handleToastError';
import EmailForm from '../EmailForm';

const Emails = props => (
  <Fragment>
    <h2>Emails</h2>
    <Query query={adminEmailsQuery}>
      {({ loading, data: { adminEmails }, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <BlankState title="Error" subtitle="There was an error loading" />;
        }

        return (
          <Fragment>
            <div className="text-block-14">Manage program emails. These emails are triggered automatically.</div>
            <div className="top-border">
              <div className="table-wrapper-3">
                <div className="applications-table-row-2 w-clearfix">
                  <span className="app-table-col year w-inline-block">
                    <div className="table-data-4 year">Email</div>
                  </span>
                  <span className="app-table-col year w-inline-block">
                    <div className="table-data-4 status">Recipients</div>
                  </span>
                  <span className="app-table-col status w-inline-block">
                    <div className="table-data-4 status">Text</div>
                  </span>
                  <span className="app-table-col status w-inline-block">
                    <div className="table-data-4 status">Status</div>
                  </span>
                </div>
                <Mutation
                  mutation={adminEditEmailsMutation}
                  onError={mutationError => handleToastError(mutationError)}
                  onCompleted={() => toast('Update Successful')}
                >
                  {(adminEditEmails) => {
                    const update = values => (
                      adminEditEmails({
                        variables: {
                          input: {
                            ...values,
                          },
                        },
                      })
                    );

                    return (
                      <EmailForm
                        {...props}
                        adminEmails={adminEmails}
                        onSubmit={update}
                      />
                    );
                  }}
                </Mutation>

              </div>
            </div>
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);

export default Emails;
