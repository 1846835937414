import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

const AuthRoute = ({ component: Component, ...otherProps }) => {
  const AuthComponent = (props) => {
    const { user } = props;

    if (!user.type) {
      return <Redirect to="/login" />;
    }

    return <Component {...props} />;
  };

  const AuthenticatedComponentWithData = connect(({ user, enums, program }) => ({
    user,
    enums,
    program,
  }))(withApollo(AuthComponent));

  return (
    <Route {...otherProps} component={AuthenticatedComponentWithData} />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any.isRequired,
  user: PropTypes.object,
};

AuthRoute.defaultProps = {
  user: null,
};

export default AuthRoute;
