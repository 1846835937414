import React from 'react';
import PropTypes from 'prop-types';
import ProfileForm from './ProfileForm';

const Profile = (props) => {
  const { school } = props;

  return (
    <div className="div-block-19">
      <h2>School Profile</h2>
      <div className="last-edit">{`Last Edited: ${school.lastEdited}`}</div>
      <div className="w-form">
        <div className="field-label">{`First Year Applied: ${school.firstYearAppliedBackpackBuddy}`}</div>
        <ProfileForm {...props} />
      </div>
    </div>
  );
};

Profile.propTypes = {
  school: PropTypes.object.isRequired,
};

export default Profile;
