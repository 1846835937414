import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';

const userReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'AUTHENTICATED':
    case 'SET_USER':
      return {
        ...state,
        ...action.user,
      };
    case 'AUTH_FAILURE':
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

const programReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'SET_PROGRAM':
      return {
        ...state,
        ...action.program,
      };
    case 'AUTH_FAILURE':
    case 'LOGOUT':
    case 'RESET_PROGRAM':
      return {};
    default:
      return state;
  }
};

const enumReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'SET_ENUMS':
      return {
        ...state,
        ...action.enums,
      };
    case 'AUTH_FAILURE':
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

export default history => combineReducers({
  router: connectRouter(history),
  user: userReducer,
  program: programReducer,
  form: reduxFormReducer,
  enums: enumReducer,
});
