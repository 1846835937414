import gql from 'graphql-tag';

export default gql`
  mutation saveKidsCafeApplication($input: ApplicationKidsCafeInput!) {
    saveKidsCafeApplication(input: $input) {
      applicationKidsCafeId
      type
      new
      site {
        siteId
        name
        address1
        address2
        city
        county
        state
        zip
        closestSchool
        crossStreet
        primaryPointOfContact {
          siteMemberId
          title
          type
          firstName
          lastName
          phone
          email
          isSupervisor
        }
        secondaryPointOfContact {
          siteMemberId
          title
          type
          firstName
          lastName
          phone
          email
          isSupervisor
        }
      }
      forProfit
      participatedAgriculture
      agricultureSponsor
      childcareLicense
      hasWifi
      hasRecordingDevice
      hasStorage
      receivesDeliveries
      isChildcareCenter
      hasMicrowave
      isTexasRisingStar
      isFederalParticipant
      isOpenEnrolledYear
      hasInfantsEnrolled
      hasAfterSchool
      specialDeliveryNotes
      earliestDeliveryTime
      fieldTrips
      startDate
      endDate
      operatingHoursStartTime
      operatingHoursEndTime
      gateCode
      numExpectedParticipants
      numExpectedParticipantsBreakfast
      numExpectedParticipantsBrunch
      numExpectedParticipantsLunch
      numExpectedParticipantsBogoLunch
      numExpectedParticipantsSnack
      numExpectedParticipantsSupper
      participantAgeRanges
      daysForService
      interestBreakfast
      interestBrunch
      interestLunch
      interestBogoLunch
      interestSnack
      interestSupper
      breakfastStartTime
      breakfastEndTime
      brunchStartTime
      brunchEndTime
      lunchStartTime
      lunchEndTime
      bogoLunchStartTime
      bogoLunchEndTime
      snackStartTime
      snackEndTime
      supperStartTime
      supperEndTime
      enrichmentPrograms
      closureDates
      referredBy
      agreeToReimbursement
      agreeToDocumentation
      certififedVerification
      nameSign
      siteType
      agreeToProgramPurpose
    }
  }
`;
