import gql from 'graphql-tag';

export default gql`
  mutation adminRestoreDistrict($districtId: ID!) {
    adminRestoreDistrict(districtId: $districtId) {
      id
      districtId
      name
      deletedAt
    }
  }
`;
