import React, { Fragment } from 'react';

const SchoolMarketProgramInfo = () => {
  return (
    <Fragment>
      <h5>
        By completing the application you agree to the following
        important information:
      </h5>
      <div className="rich-text-block w-richtext">
        <ul>
          <li>
            Designate two of your staff members to provide oversight of the
            student market.
          </li>
          <li>
            Provide &quot;core&quot; student volunteers to unload trucks, stock
            shelves, serve clients, and conduct customer service surveys.
            Please inform us if students are not permitted to assist with
            the market due to COVID-19.
          </li>
          <li>
            Tracking and maintenance of client records with our
            electronic client data system, Link2Feed.
          </li>
          <li>
            Food provided cannot be sold, used for other programs,
            used at fundraisers, given to staff (unless registered as a
            client), or used for any other purpose other than to provide food
            to chronically hungry families.
          </li>
          <li>Brick and Mortar Markets must commit for two years.</li>
          <li>Mobile Markets must commit for one year.</li>
          <li>
            Open market twice a month on a set schedule. Once you
            have determined the schedule, it remains the same for the rest
            of the year.
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default SchoolMarketProgramInfo;
