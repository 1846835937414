import React, { Fragment } from 'react';
import '../../styles/agreement.css'
import FALogo from '../../images/Feeding_America_Logo.png';
import HFBLogo from '../../images/Hfb_logo.png';

const GeneralAgreement = (props) => {
  const { data } = props;

  const year = new Date().getFullYear() - 1;
  const isCont = data.applicationBackpackBuddyId && data.school.applicationsBackpackBuddy ?
  data.school.applicationsBackpackBuddy.find(el => el.submittedAt && el.submittedAt.includes(year.toString()) && data.isContinuation && el.status == "APPROVED"):
  data.applicationTeachersAidId && data.school.applicationsTeachersAid ?
  data.school.applicationsTeachersAid.find(el => el.submittedAt && el.submittedAt.includes(year.toString()) && data.isContinuation && el.status == "APPROVED"):
    false

  const replaceDate = data.submittedAt ? new Date(data.submittedAt).toLocaleDateString('en-US') :
  isCont && !data.new ? new Date(isCont.submittedAt).toLocaleDateString('en-US'): false
  return (
    data.school && <Fragment>
      
    <div className="bg-gray-100 text-gray-900 agreement-div scroll">
    <div className="agreement-2024">
        <div className="hfb-logo" style={{display:'flex'}}><div><img src={FALogo} width={150} alt="logo" /></div><div style={{marginLeft:'70%'}}><img style={{}} src={HFBLogo} width={150} alt="logo" /></div></div>
        <div className="hfb-logo"></div>
        <p className="s1" style={{paddingTop: '11pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '110%', textAlign: 'left'}}>
          This School Programs Memorandum of Understanding (“MOU”) dated {replaceDate ? replaceDate:(new Date()).toLocaleDateString('en-US')}, is entered into by and between Houston Food Bank, a 501(c)(3)
          non-profit organization, with its principal place of business at 535 Portwall Street, Houston, Texas 77029 (“HFB”) and {data.school.name}, a {data.organizationType? " a "+data.organizationType+",":""} 
          with its principal place of business at {data.school.address1}, {data.school.city}, Texas {data.school.zip} (“School Programs Partner”) (each a “Party,” and collectively, the “Parties”) in a <u>two year agreement</u>, expiring two years from the
          start date.
        </p>
        <h3 style={{paddingTop: '11pt', paddingLeft: '33pt', textIndent: '0pt', textAlign: 'center'}}>NON-PROFIT ORGANIZATIONS</h3>
        <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p className="s1" style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
          If you are a 501(c)(3) organization, please list the schools and/or school district (including address), you are partnered with below. If you are a school, please skip this section.
        </p>
        <h3 style={{paddingLeft: '26pt', textIndent: '0pt', textAlign: 'center'}}>BACKGROUND</h3>
        <ol id="l1">
          <li data-list-text={1.}>
            <p className="s1" style={{paddingTop: '12pt', paddingLeft: '96pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              HFB's School Programs is a program with a series of pillars developed by the HFB to address the root causes of food insecurity in children in the Greater Houston area.
            </p>
            <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={2.}>
            <p className="s1" style={{paddingLeft: '96pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
              HFB actively seeks out educational institutions that focus their services in the areas of health initiatives and economic initiatives and who want to offer HFB’s School Programs to their students to help them improve their
              overall quality of life.
            </p>
            <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={3.}>
            <p className="s1" style={{paddingLeft: '96pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
              Be able to obtain, maintain, and abide by all Feeding America, USDA/TDA, city and/or county, and Houston Food Bank (HFB) regulations governing the storage and distribution of perishable and non-perishable food and non-food
              products (e.g. Food Dealer’s/Handler’s certificate, health permit(s), and licenses as applicable). Out of date permits will result in the Partner’s account being placed on hold until the permits are obtained. The hold may
              include other HFB programs such as the retail pick-up program.
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={4.}>
            <p className="s1" style={{paddingLeft: '96pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              HFB's School Programs-related programming includes Backpack Buddy; School Market (Brick and Mortar); School Market (Mobile Distribution); Teachers Aid; and/or any other alternative programing, predetermined and approved by
              Houston Food Bank leadership.
            </p>
            <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={5.}>
            <p className="s1" style={{paddingLeft: '96pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              School Programs Partner is an educational institution that wants to establish and operate certain HFBs School Programs. Accordingly, the Parties agree as follows:
            </p>
          </li>
        </ol>
        <h3 style={{paddingTop: '11pt', paddingLeft: '42pt', textIndent: '0pt', textAlign: 'left'}}>Eligibility</h3>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <ol id="l2">
          <li data-list-text={1.}>
            <p style={{paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
              Have 501(c)3 federal tax-exempt status as determined and documented by the Internal Revenue Service <b>(this does not apply to schools and/or school districts). </b>If the agency falls under the governance of another
              organization, their 501(c)3 (for non-school partners) must be provided along with a letter from the organization stating sponsorship of the second organization. A check of the partner’s exempt status will be verified annually as
              part of partnership renewal. Partners must notify their School Specialist immediately if this status has been revoked.
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={2.}>
            <p style={{paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '112%', textAlign: 'left'}}>
              New Partners must complete the onboarding process with the School Programs staff prior to receiving products and services. The new School Partner must complete training within 60 days (about 2 months) of the acceptance letter.
              New partner markets must be able to conduct a minimum of 2 distributions per month.
            </p>
            <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Mission Alignment</h2>
            <p style={{paddingTop: '3pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l3">
              <li data-list-text={1.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Limiting partnership to programs with similar mission</p></li>
              <li data-list-text={2.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Expectations for agency contacts (who and what) and requirements around updates</p></li>
              <li data-list-text={3.}><p style={{paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Minimum agency distribution requirements (frequency and/or volume of distributions) by month/quarter/year</p></li>
              <li data-list-text={4.}>
                <p style={{paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  Participation in Service Insights (Service Insights on Meal Connect, Link2Feed, Oasis etc.), Meal Connect, OrderAhead, online ordering, or other technology platforms adopted by the Food Bank
                </p>
              </li>
              <li data-list-text={5.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Participation in food bank research projects or neighbor information collection</p></li>
              <li data-list-text={6.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Ordering/shopping and delivery minimums</p></li>
              <li data-list-text={7.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Distribution frequency</p></li>
              <li data-list-text={8.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Timely submission of monthly service statistics and other reports as required.</p></li>
              <li data-list-text={9.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Agencies cannot call themselves a "food bank" or use that phrase in their name.</p></li>
              <li data-list-text={10.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', textAlign: 'left'}}>Participation in advocacy outreach or public relations</p></li>
            </ol>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Partner Operations and Service Requirements</h2>
            <p className="s5" style={{paddingTop: '11pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Appropriate Handling and Distribution of Food and Non-Food Product Received from HFB</p>
            <ol id="l4">
              <li data-list-text={1.}>
                <p style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                  Distribute the food received from HFB to low-income individuals, as defined by the TEFAP guidelines. Requirement of a social security card, driver’s license, or any documentation related to citizenship to receive food is
                  prohibited.
                </p>
              </li>
              <li data-list-text={2.}>
                <p style={{paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                  Ensure that food and non-food products received from HFB will not be sold, bartered, exchanged for monetary donations, fundraising, volunteer services, property, or votes for political interest, used for personal use or
                  community events, transferred out of HFB service area, or allowed to re-enter commercial channels.
                </p>
                <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
              </li>
              <li data-list-text={3.}>
                <p style={{paddingLeft: '76pt', textIndent: '-17pt', textAlign: 'left'}}>Ensure that food and non-food products received from HFB will not be sold,</p>
                <p style={{paddingLeft: '77pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
                  bartered, exchanged for monetary donations, fundraising, volunteer services, property, or votes for political interest, used for personal use or community events, transferred out of HFB service area, or allowed to
                  re-enter commercial channels.
                </p>
              </li>
              <li data-list-text={4.}>
                <p style={{paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                  Ensure that all food and non-food received from HFB (School Markets, Backpack Program, and Teachers Aid will not be shared with organizations not affiliated with or approved by HFB.
                </p>
              </li>
              <li data-list-text={5.}>
                <p style={{paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                  Ensure that eligible clients are not refused food assistance. The Partner also agrees that it will comply with the restrictions on the use and transfer of donated property, as described in IRS Tax Code Section 170(e)(3)
                  and any amendments to the Code (See the Federal Register/Vol. 47, No. 21/Monday, February 1982/Rules and Regulations, pp.4509-4512).
                </p>
              </li>
              <li data-list-text={6.}><p style={{paddingLeft: '76pt', textIndent: '-17pt', textAlign: 'left'}}>Notify HFB immediately in case of damage, loss, or theft of product.</p></li>
              <li data-list-text={7.}>
                <p style={{paddingTop: '1pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                  Agree to accept all food and other items received from or through HFB “as is,” and agree to adhere to additional donor stipulations, as requested.
                </p>
              </li>
              <li data-list-text={8.}>
                <p style={{paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Affirm that the original donor, HFB, and Feeding America are:</p>
                <ol id="l5">
                  <li data-list-text="a."><p style={{paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>Released by the School Partner from any liabilities resulting from the donated product.</p></li>
                  <li data-list-text="b."><p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '111%', textAlign: 'left'}}>Held harmless from any claims or obligations regarding the School Partner or the donated product.</p></li>
                  <li data-list-text="c."><p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>Offering no express warranties in relation to the product.</p></li>
                  <li data-list-text="d.">
                    <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                      Distribute food in accordance with the U.S. Department of Agriculture and Texas Health and Human Services Commission non-discrimination statement included at the end of this agreement.
                    </p>
                  </li>
                  <li data-list-text="e.">
                    <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                      As a 501(c) (3) non-profit, and a TDA Contracting Entity, HFB must abide by numerous rules to comply with its tax-exempt status. Partners may not engage in explicitly religious or political activities as part of
                      food distribution.
                    </p>
                    <ol id="l6">
                      <li data-list-text="i.">
                        <p style={{paddingLeft: '194pt', textIndent: '-23pt', textAlign: 'left'}}>
                          Religious activities may not include overt religious content such as worship, religious instruction, or proselytization. Sites may not discriminate against participants based on religion or religious
                          belief. (See the Texas Department of Agriculture’s Written Notice of Beneficiary Rights) An explicitly religious activity at a site must be separated in time or location from food distribution.
                        </p>
                      </li>
                      <li data-list-text="ii.">
                        <p style={{paddingLeft: '194pt', textIndent: '-25pt', textAlign: 'left'}}>
                          Organizations must not require or have any expectation for participants or applicants to participate in explicitly religious activities to receive food. HFB School Programs prohibits distributions from
                          occurring on days when religious services occur, and HFB is closed (e.g. Sundays).
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li data-list-text={9.}>
                <p style={{paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                  Partners may not engage in electioneering by publicly endorsing or appearing to support or oppose elected officials or political parties, even if the former are running unopposed or are not currently engaged in an
                  election.
                </p>
                <ol id="l7">
                  <li data-list-text="a."><p style={{paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>There cannot be any mention of an elected official’s candidacy or the election in connection with an event.</p></li>
                  <li data-list-text="b.">
                    <p style={{paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>
                      All written materials regarding an event with an elected official, political party, or political party organization must include the following disclaimer:
                    </p>
                    <p style={{paddingLeft: '149pt', textIndent: '0pt', textAlign: 'left'}}>“The Houston Food Bank is a 501(c) (3) and does not support or oppose any political party or candidate running for public office.”</p>
                  </li>
                  <li data-list-text="c."><p style={{paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>No pictures or social media material from the event can be used for a political campaign.</p></li>
                  <li data-list-text="d."><p style={{paddingLeft: '148pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>No events can be held with a PAC (Political Action Committee)</p></li>
                  <li data-list-text="e.">
                    <p style={{paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>
                      Ensure that each staff person and volunteer interacting with clients receive Civil Rights training according to the Texas Department of Agriculture and know how to respond to a request to file a civil rights
                      complaint. This training will be completed before they begin work and annually. The signatures of those participating in the training must be maintained on a training log and provided to HFB during monitoring
                      reviews.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '48pt', textIndent: '0pt', textAlign: 'left'}}>Food Safety</h2>
            <p style={{paddingTop: '3pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l8">
              <li data-list-text={1.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Additional food safety and/or training requirements</p></li>
              <li data-list-text={2.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Return of food products due to recall, suspension and/or termination</p></li>
            </ol>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '45pt', textIndent: '0pt', textAlign: 'left'}}>Operational</h2>
            <p style={{paddingTop: '3pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l9">
              <li data-list-text={1.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Agency shopping procedures and deadlines</p></li>
              <li data-list-text={2.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Requirements to order online or similar procedures.</p></li>
              <li data-list-text={3.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Delivery requirements such as door to door policies, winter weather instructions, etc.</p></li>
              <li data-list-text={4.}>
                <p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>Ensure that Agency volunteers or staff members who are eligible for services follow the same process as all others in acquiring food assistance.</p>
              </li>
              <li data-list-text={5.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', textAlign: 'left'}}>Closures and notifications related to inclement weather.</p></li>
              <li data-list-text={6.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', textAlign: 'left'}}>Product return and credit policies</p></li>
            </ol>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '39pt', textIndent: '0pt', textAlign: 'left'}}>Neighbor Experience</h2>
            <p style={{paddingTop: '3pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l10">
              <li data-list-text={1.}>
                <p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>
                  Dignity, equity and inclusion provisions to ensure a welcoming environment for all (for example reflecting food bank values, respecting diversity, inclusive cultures, etc.)
                </p>
              </li>
              <li data-list-text={2.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Centering people experiencing hunger in the work.</p></li>
              <li data-list-text={3.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Choice model requirement</p></li>
              <li data-list-text={4.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Distribution requirements such as hours of operation and frequency</p></li>
              <li data-list-text={5.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Participation in SNAP promotion/outreach, nutrition education, or other programs</p></li>
              <li data-list-text={6.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Nutrition and culturally preferred foods, nudge philosophy or other requirements</p></li>
              <li data-list-text={7.}><p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>Expectations for maintaining neighbor confidentiality and safeguarding personal information.</p></li>
              <li data-list-text={8.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Participation in disaster relief efforts</p></li>
              <li data-list-text={9.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Outreach expectations</p></li>
            </ol>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '42pt', textIndent: '0pt', textAlign: 'left'}}>Continuous Improvement</h2>
            <p style={{paddingTop: '3pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l11">
              <li data-list-text={1.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Fostering a culture of continuous improvement and innovation</p></li>
              <li data-list-text={2.}><p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>Probation, suspension and termination policy including causes, procedures and timeline.</p></li>
              <li data-list-text={3.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Grievance procedure and timeline</p></li>
              <li data-list-text={4.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', textAlign: 'left'}}>Partnership reinstatement policy</p></li>
              <li data-list-text={5.}><p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>Procedures for addressing egregious situations such as 501c3 lapse, selling food, civil rights violations, etc.</p></li>
              <li data-list-text={6.}><p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>Policy for addressing racist, sexist, ableist or other harmful behaviors, comments or actions.</p></li>
              <li data-list-text={7.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Additional requirements around training, orientation, etc.</p></li>
            </ol>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Neighbor Experience</h2>
            <p style={{paddingTop: '3pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l12">
              <li data-list-text={1.}>
                <p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>
                  Dignity, equity and inclusion provisions to ensure a welcoming environment for all (for example reflecting food bank values, respecting diversity, inclusive cultures, etc.)
                </p>
              </li>
              <li data-list-text={2.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Centering people experiencing hunger in the work.</p></li>
              <li data-list-text={3.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Choice model requirement</p></li>
              <li data-list-text={4.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Distribution requirements such as hours of operation and frequency</p></li>
              <li data-list-text={5.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Participation in SNAP promotion/outreach, nutrition education, or other programs</p></li>
              <li data-list-text={6.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Nutrition and culturally preferred foods, nudge philosophy or other requirements</p></li>
              <li data-list-text={7.}><p style={{paddingLeft: '81pt', textIndent: '-18pt', textAlign: 'left'}}>Expectations for maintaining neighbor confidentiality and safeguarding personal information.</p></li>
              <li data-list-text={8.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Participation in disaster relief efforts</p></li>
              <li data-list-text={9.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Outreach expectations</p></li>
            </ol>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Measurements of Service</h2>
            <p style={{paddingTop: '3pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l13">
              <li data-list-text={1.}>
                <p style={{paddingLeft: '81pt', textIndent: '-18pt', lineHeight: '114%', textAlign: 'left'}}>
                  Distributing various measures for evaluating service adequacy (i.e. variety of surveys to neighbors and/or partners, polls, and visual stories).
                </p>
              </li>
              <li data-list-text={2.}><p style={{paddingLeft: '81pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Reporting (Link2Feed or alternate software).</p></li>
              <li data-list-text={3.}>
                <p style={{paddingTop: '1pt', paddingLeft: '81pt', textIndent: '-17pt', textAlign: 'left'}}>Video and audio story telling.</p>
                <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
                <h2 style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Resources</h2>
                <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
                <ol id="l14">
                  <li data-list-text={1.}>
                    <p style={{paddingLeft: '90pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                      <a href="https://squaremeals.org/FandNResources/Training/CivilRights.aspx" className="s18" target="_blank">
                        I authenticate that all persons involved in food distribution, or direct interaction with clients have completed the civil rights course located at
                      </a>
                      <a href="https://squaremeals.org/FandNResources/Training/CivilRights.aspx" target="_blank">https://squaremeals.org/FandNResources/Training/CivilRights.aspx</a>
                    </p>
                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                  </li>
                  <li data-list-text={2.}>
                    <p className="s6" style={{paddingLeft: '90pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                      <a href="http://www.fns.usda.gov/cr/and-justice-all-posters-guidance-and-translations" className="s18" target="_blank">I authenticate that the civil rights poster (“And Justice for All” found here: </a>
                      <span style={{color: '#467885', fontFamily: 'Arial, sans-serif', fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline', fontSize: '11pt'}}>
                        http://www.fns.usda.gov/cr/and-justice-all-posters-guidance-and-translations
                      </span>
                      <span className="p">
                        TEFAP Participant Rights and Responsibilities, TEFAP Written Notice of Beneficiary Rights, TEFAP Client Bill of Rights and TEFAP Pantry Bill of Rights in English and Spanish have been posted in full view of
                        all applicants/clients entering our agency. Please contact School Programs if you require another translation of these forms for your client population.
                      </span>
                    </p>
                    <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
                  </li>
                  <li data-list-text={3.}>
                    <p style={{paddingLeft: '90pt', textIndent: '-18pt', lineHeight: '114%', textAlign: 'left'}}>
                      Grant neighbors the right to contact the partner agency governing board, HFB and the USDA if they have a grievance against your agency. You may reference the Grievance Policy found in the enrolled programs
                      guidebook you will receive in completion of orientation.
                      <span style={{color: '#1b1b1b'}}>To file a program discrimination complaint, a Complainant should complete a Form AD-3027, USDA Program Discrimination Complaint Form which can be obtained online</span>
                    </p>
                    <p className="s9" style={{paddingLeft: '90pt', textIndent: '0pt', lineHeight: '114%', textAlign: 'left'}}>
                      <a href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf" style={{color: '#1b1b1b', fontFamily: 'Arial, sans-serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '11pt'}} target="_blank">
                        at:
                      </a>
                      <span style={{color: '#0a779f', fontFamily: 'Arial, sans-serif', fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline', fontSize: '11pt'}}>
                        https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
                      </span>
                      ,
                      <span className="s7">
                        from any USDA office, by calling (866) 632-9992, or by writing a letter addressed to USDA. The letter must contain the complainant’s name, address, telephone number, and a written description of the alleged
                        discriminatory action in sufficient detail to inform the Assistant Secretary for Civil Rights (ASCR) about the nature and date of an alleged civil rights violation. The completed AD-3027 form or letter must
                        be submitted to USDA by:
                      </span>
                    </p>
                    <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    <ol id="l15">
                      <li data-list-text={1.}>
                        <p className="s10" style={{paddingLeft: '153pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>mail:</p>
                        <p className="s7" style={{paddingLeft: '153pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>U.S. Department of Agriculture</p>
                        <p className="s7" style={{paddingLeft: '153pt', textIndent: '0pt', textAlign: 'left'}}>Office of the Assistant Secretary for Civil Rights 1400 Independence Avenue, SW</p>
                        <p className="s7" style={{paddingLeft: '153pt', textIndent: '0pt', textAlign: 'left'}}>Washington, D.C. 20250-9410; or</p>
                      </li>
                      <li data-list-text={2.}>
                        <p className="s10" style={{paddingLeft: '153pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>fax:</p>
                        <p className="s7" style={{paddingLeft: '153pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>(833) 256-1665 or (202) 690-7442; or</p>
                      </li>
                      <li data-list-text={3.}><p className="s10" style={{paddingLeft: '153pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>email:</p></li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <p style={{paddingLeft: '153pt', textIndent: '0pt', textAlign: 'left'}}><a href="mailto:program.intake@usda.gov" className="s8">Program.Intake@usda.gov</a></p>
            <p style={{paddingTop: '13pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h1 style={{paddingLeft: '18pt', textIndent: '0pt', textAlign: 'center'}}>ARTICLE 1: SCHOOL PROGRAMS</h1>
            <ol id="l16">
              <ol id="l17">
                <li data-list-text="1.1">
                  <h2 style={{paddingTop: '13pt', paddingLeft: '77pt', textIndent: '-34pt', lineHeight: '111%', textAlign: 'left'}}>
                    Selected School Programs. <span className="p">School Programs Partner that is interested in the following HFB School Programs. </span><i>(Initial School Programs in which School Programs Partner will participate.)</i>
                  </h2>
                  <h2 style={{paddingTop: '11pt', paddingLeft: '140pt', textIndent: '-63pt', lineHeight: '111%', textAlign: 'left'}}>
                    <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>Exhibit A. Backpack Buddy.
                    <span className="p">
                      Backpack Buddy is a program that helps alleviate child hunger by closing the weekend gap for chronically hungry children. Better known as children who face food insecurity or a household crisis.
                    </span>
                  </h2>
                  <p style={{paddingLeft: '140pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
                    Every Thursday/Friday, students participating in this program will receive a nutritious, child-friendly food sack to take home and be back to school on Monday, eager and ready to learn. Backpack Buddy welcomes
                    elementary, middle, and high schools to participate.
                  </p>
                  <h2 style={{paddingTop: '12pt', paddingLeft: '140pt', textIndent: '-63pt', lineHeight: '110%', textAlign: 'left'}}>
                    <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>Exhibit B<span className="p">. </span>School Market (Brick and Mortar).
                    <span className="p">
                      The School Market programs help alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily
                      accessible source of food assistance. Brick and mortar pantries are set up like small grocery stores at a consistent location within a schools’ campus, with set distribution schedules. School Market welcomes
                      middle and high schools to house the market in, markets are open to the community.
                    </span>
                  </h2>
                  <h2 style={{paddingTop: '12pt', paddingLeft: '140pt', textIndent: '-63pt', lineHeight: '110%', textAlign: 'left'}}>
                    <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>Exhibit C. School Market (Mobile Distribution).
                    <span className="p">
                      The School Market programs help alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily
                      accessible source of food assistance. Mobile distributions operate through HFB’s large, refrigerated trailers, with a set distribution schedule. School Market welcomes middle and high schools to house the market
                      in, markets are open to the community.
                    </span>
                  </h2>
                  <h2 style={{paddingTop: '12pt', paddingLeft: '140pt', textIndent: '-63pt', lineHeight: '110%', textAlign: 'left'}}>
                    <u> &nbsp;&nbsp; </u>Exhibit D. Teachers Aid.
                    <span className="p">
                      Many children can’t afford the most basic school supplies. Without the proper tools to do the work, children are challenged to achieve academic success. Many compassionate teachers pay for school supplies from
                      their own pockets, so their students have more productive, efficient learning experience. The Teachers Aid program provides the supplies kids need to succeed. The program is open to teachers in Title 1 schools
                      with at least 90% of the student population on the free and reduced- price school lunch program in our urban area and 70% of the student population in our rural area on the free and reduced-price school lunch
                      program.
                    </span>
                  </h2>
                </li>
                <li data-list-text="1.2">
                  <h2 style={{paddingLeft: '77pt', textIndent: '-34pt', lineHeight: '110%', textAlign: 'left'}}>
                    School Program Details.
                    <span className="p">
                      Additional information and details for each program listed in Section 1.1 are presented in Exhibits A, B, C, D, and E. The Exhibit(s) that correspond to the program(s) initiated in Section 1.1 is/are attached
                      hereto and incorporated herein.
                    </span>
                  </h2>
                </li>
              </ol>
            </ol>
            <h2 style={{paddingTop: '12pt', paddingLeft: '43pt', textIndent: '0pt', textAlign: 'center'}}>ARTICLE 2: TERM</h2>
            <ol id="l18">
              <li data-list-text="2.1">
                <h2 style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                  Term.
                  <span className="p">
                    This MOU becomes effective on the date that the final signatory executes this MOU. The agreement will expire 24 months (about 2 years) from the effective date, unless earlier terminated by the Parties in accordance
                    with this MOU (“Term”).
                  </span>
                  <i>School Market (mobile and brick and mortar) will have a 2-year term effective approval date.</i>
                </h2>
              </li>
              <li data-list-text="2.2">
                <h2 style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-36pt', lineHeight: '111%', textAlign: 'left'}}>
                  Renewal.
                  <span className="p">
                    This MOU will terminate after your identified termination date. If either Party provides the other Party with 30 (thirty) days advance written notice of the Party’s intention, do not to continue the program.
                  </span>
                </h2>
              </li>
            </ol>
          </li>
        </ol>
        <h2 style={{paddingTop: '11pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'center'}}>ARTICLE 3: DUTIES OF THE PARTIES</h2>
        <ol id="l19">
          <ol id="l20">
            <li data-list-text="3.1">
              <h2 style={{paddingTop: '12pt', paddingLeft: '59pt', textIndent: '-17pt', textAlign: 'left'}}>Duties of HFB. <span className="p">During the Term of this MOU, HFB shall:</span></h2>
              <p style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                3.1.a
                <i>
                  <b>HFB does not report individual/client level data to the government. Furthermore, HFB will not disclose personal data unless court ordered or compelled by law. Also, we do not ask for nor record immigration status.</b>
                </i>
              </p>
              <ol id="l21">
                <li data-list-text="3.1.1"><p style={{paddingTop: '12pt', paddingLeft: '104pt', textIndent: '-27pt', textAlign: 'left'}}>Conduct yearly School Programs training.</p></li>
                <li data-list-text="3.1.2">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                    Provide nutritious foods to School Programs Partner at no cost to School Programs Partner to operate the HFB School Programs initiated in Section 1:1; OR Provide School Programs Partner with a list of and maps of
                    School Programs sites where neighbors can receive nutritious foods (perishable and non-perishable), if applicable.
                  </p>
                </li>
                <li data-list-text="3.1.3">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                    Provide School Programs Partner with training and access to Program Management Applications site (PMA), Claromentis, Link2Feed, OrderAhead, and NetSuite SCA ordering platform for program food acquisition. Required
                    training will be provided for relevant platform(s) adjacent to the program(s) selected above.
                  </p>
                  <ol id="l22">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', textAlign: 'justify'}}>Assist School Programs Partner with PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA at Partner’s site.</p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingLeft: '131pt', textIndent: '-18pt', textAlign: 'justify'}}>Assign initial logins to School Programs Partner’s staff to use Claromentis, Link2Feed, OrderAhead, and NetSuite SCA.</p>
                    </li>
                    <li data-list-text="c.">
                      <p style={{paddingLeft: '131pt', textIndent: '-18pt', textAlign: 'left'}}>Provide School Programs Partner with on-going support for PMA, Claromentis, Link2Feed,</p>
                      <p style={{paddingLeft: '131pt', textIndent: '0pt', textAlign: 'left'}}>OrderAhead, and NetSuite SCA; and</p>
                    </li>
                    <li data-list-text="d.">
                      <p style={{paddingLeft: '131pt', textIndent: '-18pt', textAlign: 'left'}}>Upon School Programs Partner’s request, conduct Claromentis, Link2Feed, OrderAhead, and NetSuite</p>
                      <p style={{paddingLeft: '131pt', textIndent: '0pt', textAlign: 'left'}}>SCA training for and assign logins to School Programs Partner’s new staff hired during the Term.</p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.1.4">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                    Conduct inspections of perishable and non-perishable food at School Programs sites to ensure the quality of such foods and comply with local health safety codes for food.
                  </p>
                </li>
                <li data-list-text="3.1.5">
                  <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
                    Provide School Programs Partner with a copy of HFB’s Food Quality standards which are attached hereto and incorporated herein as Appendix A.
                  </p>
                </li>
                <li data-list-text="3.1.6">
                  <p style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '109%', textAlign: 'justify'}}>
                    Provide School Programs Partner with a non-exclusive, non-transferable license to use Claromentis, Link2Feed, OrderAhead, and NetSuite SCA ordering platform if applicable, and:
                  </p>
                  <ol id="l23">
                    <li data-list-text="a."><p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-17pt', textAlign: 'left'}}>Conduct assigned software(s) training for School Programs Partner’s staff.</p></li>
                    <li data-list-text="b."><p style={{paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Assist School Programs Partner to implement assigned software(s) at School Programs Partner’s site.</p></li>
                    <li data-list-text="c."><p style={{paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Assign initial logins to School Programs Partner’s staff to use assigned software(s).</p></li>
                    <li data-list-text="d."><p style={{paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Provide School Programs Partner with on-going support for assigned software(s); and</p></li>
                    <li data-list-text="e.">
                      <p style={{paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                        Upon School Programs Partner’s request, conduct assigned software(s) training for and assign logins to School Programs Partner’s new staff hired during the Term.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.1.7">
                  <p style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '204%', textAlign: 'left'}}>
                    Provide School Programs Partner trainings, className materials, equipment and supplies, (Brick and Mortar only) needed to operate the HFB School Programs initialed in section 1:1.
                  </p>
                </li>
                <li data-list-text="3.1.8">
                  <p style={{paddingLeft: '77pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
                    Annually, HFB staff will conduct virtual and/or in-person check-ins with School Programs Partner on a regular basis during the Term (“Regular Check-ins”). Regular Check-ins during the initial Term can occur at least
                    once each quarter through the end of the Term and each subsequent renewal Term.
                  </p>
                </li>
                <li data-list-text="3.1.9">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                    Conduct in-person compliance check-ins (“Compliance Check-ins”) to observe School Programs Partner’s marketing and intake procedures of and compliance with HFB’s School Programs. HFB, alone, will determine whether to
                    conduct a second or third Compliance Check-in at School Programs Partner. HFB and School Programs Partner will mutually agree upon the date and time for each Compliance Check-in. Unless otherwise determined by the
                    Parties, Compliance Check-ins will only be conducted during the School Programs Partner’s regular business hours.
                  </p>
                  <ol id="l24">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '11pt', paddingLeft: '149pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>HFB Liaisons will coordinate and facilitate Compliance Check-ins for shared School Programs Partners.</p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.1.10">
                  <p style={{paddingLeft: '111pt', textIndent: '-33pt', lineHeight: '13pt', textAlign: 'left'}}>Host visits about School Programs (“Visits”).</p>
                  <ol id="l25">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '11pt', paddingLeft: '131pt', textIndent: '-18pt', textAlign: 'justify'}}>
                        Visits are usually requested by media outlets and are attended by media personnel to report on School Programs and School Programs Partners’ successes.
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>HFB will not use observations and information obtained during a visit in lieu of a Compliance Check-in.</p>
                    </li>
                    <li data-list-text="c.">
                      <p style={{paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                        Visits may be unannounced, but if HFB receives advance notice of a visit request, HFB shall promptly notify School Programs Partner about the upcoming visit.
                      </p>
                    </li>
                    <li data-list-text="d.">
                      <p style={{paddingLeft: '130pt', textIndent: '-17pt', lineHeight: '12pt', textAlign: 'left'}}>Whenever possible, HFB liaisons will coordinate and facilitate visits.</p>
                      <p style={{paddingTop: '1pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.1.11">
                  <p style={{paddingLeft: '77pt', textIndent: '-37pt', textAlign: 'left'}}>By logging in to Claromentis, you can request nutrition education classes and support through HFB’s Health Promotion/Nutrition Education Team.</p>
                </li>
                <li data-list-text="3.1.12">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>Provide School Programs Partner annual impact report cards no later than the end of HFB fiscal year.</p>
                </li>
              </ol>
            </li>
            <li data-list-text="3.2">
              <h2 style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-36pt', lineHeight: '111%', textAlign: 'left'}}>
                Duties of School Programs Partner. <span className="p">During the Term of this MOU, School Programs Partner shall:</span>
              </h2>
              <ol id="l26">
                <li data-list-text="3.2.1">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '109%', textAlign: 'justify'}}>
                    At least one School Programs Partner representative will need to receive yearly food safety training. The HFB will provide the training to the School Programs Partner site coordinator via in-person training.
                  </p>
                </li>
                <li data-list-text="3.2.2">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                    Promptly notify School Programs team via Claromentis Help Desk Ticket or email of significant personnel changes that might affect School Programs Partner’s operation of the School Programs and of new staff members
                    who require training on the School Programs software systems.
                  </p>
                </li>
                <li data-list-text="3.2.3">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '110%', textAlign: 'left'}}>
                    Operate the School Programs selected by School Programs Partner and do so in compliance with the School Programs criteria by:
                  </p>
                  <ol id="l27">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '12pt', paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Identifying eligible students and/or sites for School Programs. Please refer to each School Programs external handbook.
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                        Informing eligible students and their parents/guardians of their eligibility to participate in the School Programs by utilizing program description/script provided during onboard training. Please refer to
                        each School Programs external handbook.
                      </p>
                    </li>
                    <li data-list-text="c."><p style={{paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>Enrolling eligible students (“Neighbors”) into the appropriate School Programs.</p></li>
                    <li data-list-text="d.">
                      <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                        We collect data from partners and clients for the following reasons: 1) To improve our programs; 2) to do research; and 3) to connect clients with other programs.
                      </p>
                    </li>
                    <li data-list-text="e.">
                      <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Link2Feed is HFB’s preferred client intake and reporting platform. For information on Link2Feed client and pantry data security, see
                      </p>
                      <p style={{paddingLeft: '149pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}><a href="http://Link2Feed.com/security-features/" className="s13">http://Link2Feed.com/security-features/</a></p>
                    </li>
                    <li data-list-text="f.">
                      <p style={{paddingTop: '1pt', paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Have School Programs Partner available for an on-site monitoring visit at least once a year from the HFB staff or the HFB certified volunteers.
                      </p>
                    </li>
                    <li data-list-text="g.">
                      <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        If the School Programs Partner is contacted by any form of media to discuss School Programs, please get in touch with the School Programs staff before granting any interviews.
                      </p>
                    </li>
                    <li data-list-text="h."><p style={{paddingLeft: '148pt', textIndent: '-17pt', textAlign: 'left'}}>Display the “And Justice for All” poster in the food distribution location.</p></li>
                    <li data-list-text="i."><p style={{paddingTop: '1pt', paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>Display the most recent “TEFAP Eligibility Guidelines” poster.</p></li>
                    <li data-list-text="j.">
                      <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '111%', textAlign: 'left'}}>
                        All food must be stored in a secure, sanitary, and temperature-controlled place away from cleaning materials and toxic chemicals.
                      </p>
                    </li>
                    <li data-list-text="k.">
                      <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>All food must be stored 6 inches off the floor, 6 inches away from the wall, and 6 inches below the ceiling.</p>
                    </li>
                    <li data-list-text="l.">
                      <p style={{paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Actively participating in all Regular Check-ins and Compliance Check-ins that are conducted by HFB during the Term of this MOU for purposes of reviewing the progress of the School Programs at Partner site.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.2.4">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '110%', textAlign: 'left'}}>
                    Schools Program Memorandum of Understanding must be submitted in 24 months (2 years) by the School Programs Partner with updated contact information. This is for School Programs Partners on Backpack Buddy, School
                    Market, and Teachers Aid.
                  </p>
                </li>
                <li data-list-text="3.2.5">
                  <p style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-36pt', textAlign: 'left'}}>
                    As a Houston Food Bank Partner, your school agrees to abide by the HFB co- branding policy outlined in this MOU and will incorporate the HFB logo with your organization’s logo in printed and digital marketing assets
                    where appropriate including social media, signage, and/or media mentions. Include link to Co- branding policy. <span className="s14">Partner Co-branding and Communication Agreement (2).pdf</span>
                  </p>
                  <ol id="l28">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', textAlign: 'left'}}>
                        Agency is responsible for ensuring exterior/interior signage co- branding signage is installed and maintained throughout the duration of the partnership.
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '36pt', textAlign: 'left'}}>
                        Upon termination or end of partner agreement/MOU, all signage, flyers, website, social media, and/or media mentions with HFB logo should be removed or discontinued no later than 15 days (about 2 weeks) from
                        the end of the agreement. All original signage shall be returned to HFB staff.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.2.6">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '110%', textAlign: 'left'}}>School Programs Memorandum of Understanding must be submitted every two years.</p>
                  <ol id="l29">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '11pt', paddingLeft: '140pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                        Inform School Programs of any changes in contact names, addresses, phone numbers, services provided, days and hours of operation/distribution, and provide any other relevant information by using the “Partner
                        Information Change Form (Form F)” in the Partner Agency Guidebook.
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingLeft: '140pt', textIndent: '-18pt', textAlign: 'left'}}>
                        If the Partner is relocating, request prior approval from School Programs for the new distribution site that will be storing and distributing HFB products.
                      </p>
                    </li>
                    <li data-list-text="c.">
                      <p style={{paddingLeft: '139pt', textIndent: '-17pt', lineHeight: '13pt', textAlign: 'left'}}>Agree to only receive and store product at multiple locations if:</p>
                      <ol id="l30">
                        <li data-list-text="i."><p style={{paddingLeft: '184pt', textIndent: '-14pt', lineHeight: '13pt', textAlign: 'left'}}>All locations individually meet the requirements of this agreement.</p></li>
                        <li data-list-text="ii."><p style={{paddingLeft: '184pt', textIndent: '-16pt', lineHeight: '13pt', textAlign: 'left'}}>Have been inspected by a School Programs Specialist.</p></li>
                        <li data-list-text="iii."><p style={{paddingLeft: '184pt', textIndent: '-19pt', lineHeight: '13pt', textAlign: 'left'}}>Approved by HFB prior to receiving food.</p></li>
                      </ol>
                    </li>
                    <li data-list-text="d.">
                      <p style={{paddingLeft: '95pt', textIndent: '-18pt', textAlign: 'left'}}>
                        School Programs will try, when called on, to participate in the following activities: Advocacy, Media Event, Research, Surveys, Focus Groups, School Programs Conference and School Programs Learning Sessions.
                      </p>
                    </li>
                    <li data-list-text="e.">
                      <p style={{paddingLeft: '95pt', textIndent: '-18pt', textAlign: 'left'}}>Recognize and acknowledge that the receipt of TDA commodities must be identified as a subaward, and the partner is the Subrecipient.</p>
                      <p style={{paddingTop: '12pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
                        Name of Federal awarding agency – U.S. Department of Agriculture Federal award project description – Emergency Food Assistance Program – Food Commodities CFDA number –
                      </p>
                      <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
                        10.569 Contract number - 101-0452K9 Contract Year/Federal Award date – 10/1/2020 - 9/30/2021, 10/1/2021 - 9/30/2022 In any fiscal year in which the Partner expends $750,000 or more in federal awards during
                        such fiscal year, including awards received as a subrecipient, subrecipient must comply with the federal audit requirements contained in the Uniform Guidance, [45 CFR Part 75], including the preparation of an
                        audit by an independent Certified Public Accountant in accordance with the Single Audit Act Amendments of 1996, 31 U.S.C. 7501-7507, and with Generally Accepted Accounting Principles, and submit the Single
                        Audit report applicable (including financial statements, schedule of expenditures of federal awards, schedule of findings and questioned costs, summary of prior audit findings, and corrective action plan, if
                        applicable), and management letter within nine (9) months following the end of subrecipient’s fiscal year.
                      </p>
                      <ol id="l31">
                        <li data-list-text="a.">
                          <p style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            Partner accounts will become inactive if an order (School Market, Backpack and Locker Project) is not placed a minimum of every 3 months. If the account becomes inactive, the School Partner is
                            required to reapply for membership and adhere to the guidelines of a new School Partner.
                          </p>
                        </li>
                        <li data-list-text="b.">
                          <p style={{paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>Partner account termination will result in the termination of all School Program privileges.</p>
                        </li>
                        <li data-list-text="c.">
                          <p style={{paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                            The School Programs Team largely works remotely and is rarely on-site at HFB. Therefore, Partners are required to make appointments and pre-schedule meetings with their School Specialist, or other HFB
                            staff. The School Programs Team, or HFB, can’t guarantee a meeting can occur if the partner requires an unplanned meeting or discussion.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.2.7">
                  <p style={{paddingTop: '11pt', paddingLeft: '104pt', textIndent: '-27pt', textAlign: 'left'}}>Communicate problems and requests to the HFB in a timely manner.</p>
                  <p className="s15" style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '115%', textAlign: 'left'}}>
                    EITHER PARTY MAY TERMINATE THIS AGREEMENT, WITH OR WITHOUT CASE, UPON 30 DAYS WRITTEN NOTICE. FAILURE TO COMPLY WITH ANY OF THE PROVISIONS ABOVE OR INCLUDED IN THE PARTNER AGENCY GUIDEBOOK, WHICH IS INCORPORATED
                    HEREIN BY REFERENCE, CAN RESULT IN ACTIONS UP TO AND INCLUDING SUSPENSION OR TERMINATION OF PARTNERSHIP. (SEE ESCALATION PROCESS IN THE GUIDEBOOK.)
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="3.3">
              <h2 style={{paddingTop: '12pt', paddingLeft: '59pt', textIndent: '-18pt', textAlign: 'left'}}>Duties of Both Parties. <span className="p">During the Term, the Parties shall:</span></h2>
              <ol id="l32">
                <li data-list-text="3.3.1">
                  <p style={{paddingTop: '12pt', paddingLeft: '104pt', textIndent: '-27pt', textAlign: 'left'}}>Comply with:</p>
                  <ol id="l33">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '111%', textAlign: 'left'}}>
                        All applicable local, state, and federal statues, ordinances and regulations including but not limited to, the safe and proper handling of food and distribution of food, as amended from time to time without
                        notice; and
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingTop: '11pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Willingness to abide by the policies, procedures, and record keeping requirements of the HFB.</p>
                    </li>
                    <li data-list-text="c.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        School Programs Partner staff and volunteers will not engage in discrimination, in provision of services, against any person because of race, color, citizenship, religion, gender, national origin, ancestry,
                        age, marital status, disability, sexual orientation including gender identity or expression, unfavorable discharge from military, or status as a protected veteran.
                      </p>
                    </li>
                    <li data-list-text="d.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Distribute healthy, nutritious food to participating students/families free of charge.</p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.3.2">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                    Observe and identify strengths and opportunities related to recipient engagement, resources utilization, and overall, School Programs impact.
                  </p>
                </li>
                <li data-list-text="3.3.3">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                    Jointly develop standard School Programs messaging, including any applicable co- branding messaging, which can be used by and prominently displayed at HFB and at School Programs Partner’s designated Recipients’
                    enrollment locations.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </ol>
        <h2 style={{paddingTop: '12pt', paddingLeft: '20pt', textIndent: '0pt', textAlign: 'center'}}>ARTICLE 4: TERMINATION OF THIS MOU</h2>
        <ol id="l34">
          <ol id="l35">
            <li data-list-text="4.1">
              <h2 style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                Termination Without Cause. <span className="p">Either Party may terminate this MOU at any time without cause by providing the other Party with thirty (30) days advance written notice of its intent to terminate.</span>
              </h2>
            </li>
            <li data-list-text="4.2">
              <h2 style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                Termination for Cause.
                <span className="p">
                  HFB may terminate this MOU “for Cause” by providing School Programs Partner with ten (10) days advance written notice of HFB’s intent to terminate this MOU. For purposes of this MOU, “for Cause” means School Programs
                  Partner:
                </span>
              </h2>
              <ol id="l36">
                <li data-list-text="4.2.1"><p style={{paddingTop: '12pt', paddingLeft: '104pt', textIndent: '-27pt', textAlign: 'left'}}>Breaches any material term or condition of this MOU; or</p></li>
                <li data-list-text="4.2.2">
                  <p style={{paddingTop: '11pt', paddingLeft: '104pt', textIndent: '-27pt', textAlign: 'left'}}>Fails to manage, administer, or operate School Programs in accordance with this</p>
                  <p style={{paddingLeft: '113pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>MOU; or</p>
                  <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                </li>
                <li data-list-text="4.2.3">
                  <p style={{paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>Refuses to enroll and/or provide services to an individual who is qualified to participate in School Programs: or</p>
                </li>
                <li data-list-text="4.2.4"><p style={{paddingTop: '12pt', paddingLeft: '104pt', textIndent: '-27pt', textAlign: 'left'}}>Fails to timely enter or submit enrollment and/or outcomes data to HFB; or</p></li>
                <li data-list-text="4.2.5">
                  <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
                    Is rude or inappropriate either with individuals participating in an HFB School Programs through School Programs Partner or with HFB staff; or
                  </p>
                </li>
                <li data-list-text="4.2.6">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>
                    Fails to actively participate in HFB’s Regular Check-ins or in Compliance Check ins at School Programs Partner; or
                  </p>
                </li>
                <li data-list-text="4.2.7">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '109%', textAlign: 'left'}}>Fails to actively participate in HFB’s annual evaluation of School Programs Partner.</p>
                </li>
              </ol>
            </li>
            <li data-list-text="4.3">
              <h2 style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-36pt', lineHeight: '110%', textAlign: 'left'}}>
                Non-Renewal By HFB.
                <span className="p">HFB may elect not to renew this MOU by providing School Programs Partner with advance written notice of HFB’s intent not to renew this MOU on or before May 1 of the then-current Term.</span>
              </h2>
            </li>
            <li data-list-text="4.4">
              <h2 style={{paddingTop: '11pt', paddingLeft: '59pt', textIndent: '-18pt', textAlign: 'left'}}>Effects of Termination or Non-Renewal.</h2>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <ol id="l37">
                <li data-list-text="4.4.1">
                  <h2 style={{paddingLeft: '113pt', textIndent: '-36pt', lineHeight: '110%', textAlign: 'left'}}>
                    Return of Information.
                    <span className="p">
                      Within thirty (30) days after the termination or non-renewal of this MOU, School Programs Partner shall return to HFB all documents, materials, and information about or related to HFB’s School Programs including
                      any copies and derivative materials.
                    </span>
                  </h2>
                </li>
                <li data-list-text="4.4.2">
                  <h2 style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '109%', textAlign: 'left'}}>
                    Return of School Programs Software Systems.
                    <span className="p">
                      Within thirty (30) days after the termination or non-renewal of this MOU, School Programs Partner shall cease and desist all use of PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA and shall return to
                      HFB all full or partial copies PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA in School Programs Partner’s possession or under School Programs Partner’s control.
                    </span>
                  </h2>
                </li>
                <li data-list-text="4.4.3">
                  <h2 style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
                    Return of Property
                    <span className="p">
                      . Within thirty (30) days after the termination or non-renewal of this MOU, School Programs Partner shall return to HFB all equipment related to HFB’s School Programs including but not limited to electronic
                      tablets, laptops, mifi’s, infrastructure equipment, co-branding signage and market supplies.
                    </span>
                  </h2>
                </li>
              </ol>
            </li>
          </ol>
        </ol>
        <h2 style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '0pt', textAlign: 'left'}}>ARTICLE 5: CONFIDENTIALITY AND DATA SHARING</h2>
        <ol id="l38">
          <ol id="l39">
            <li data-list-text="5.1">
              <h2 style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-36pt', lineHeight: '110%', textAlign: 'left'}}>
                Confidentiality.
                <span className="p">
                  During the Term and thereafter, each Party shall hold the other Party's Confidential Information in strictest confidence and (a) shall not use the other Party's Confidential Information for any purpose other than
                  as expressly contemplated by this MOU; and (b) shall not disclose, or cause or permit the other Party's Confidential Information to be disclosed; and (c) shall notify the other Party of any disclosure, misuse,
                </span>
              </h2>
              <p style={{paddingLeft: '77pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>or misappropriation of the other Party's Confidential Information of which the Party becomes aware.</p>
              <ol id="l40">
                <li data-list-text="5.1.1">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '109%', textAlign: 'left'}}>
                    Notwithstanding Section 5.1, a Party may disclose the other Party's Confidential Information in response to a lawful order by a court other government body provided that prior to such disclosure the Party
                    notifies the other Party so that the other Party may seek confidential treatment of the Party's Confidential Information by the court or government body or a protective order (or equivalent) with respect to such
                    disclosure.
                  </p>
                </li>
                <li data-list-text="5.1.2">
                  <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                    Further, a Party may disclose the other Party’s Confidential Information to a third party for purposes of conducting research and/or program evaluation so long as individually identifiable information is
                    de-identified or removed in its entirety from the Confidential Information prior to disclosing such Confidential Information for research and/or program evaluation.
                  </p>
                </li>
                <li data-list-text="5.1.3">
                  <h2 style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                    Confidential Information.
                    <span className="p">
                      "Confidential Information" means non-public information belonging to or in the possession of one Party and includes, but is not limited to the terms of this MOU, personal and health information of
                      individuals enrolled in a HFB School Programs including individuals’ on-going health metrics that are collected by School Programs Partner and shared with HFB (“Participant Personal Information,” or “PPI”),
                      confidential and proprietary data, whether or not designated or marked "confidential," materials, products, technology, manuals, business and marketing plans, financial information, and any other
                      information disclosed or submitted, orally, in writing, or by any other media.
                    </span>
                  </h2>
                </li>
                <li data-list-text="5.1.4">
                  <h2 style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                    What Confidential Information Does Not Include.
                    <span className="p">
                      Confidential Information does not include (a) information that is available in the public domain; (b) is known to the other Party prior to its disclosure to that Party by the Party possessing the information; or
                      (c) is received by the other Party from a third party not bound by a confidentiality agreement with the Party possessing the information.
                    </span>
                  </h2>
                </li>
              </ol>
            </li>
            <li data-list-text="5.2">
              <h2 style={{paddingTop: '12pt', paddingLeft: '59pt', textIndent: '-18pt', textAlign: 'left'}}>Using and Sharing Participant Personal Information (“PPI”).</h2>
              <ol id="l41">
                <li data-list-text="5.2.1">
                  <h2 style={{paddingTop: '12pt', paddingLeft: '104pt', textIndent: '-27pt', textAlign: 'left'}}>HFB’s Use of PPI. <span className="p">During the Term, HFB shall:</span></h2>
                  <ol id="l42">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                        Use PPI received from School Programs Partner solely for the purposes expressly contemplated by this MOU.
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>
                        Store PPI (whether identifiable or de-identified) received from School Programs Partner in a secure, restricted-access, and password-protected data storage system;
                      </p>
                    </li>
                    <li data-list-text="c.">
                      <p style={{paddingTop: '11pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Restrict access to PPI received from School Programs Partner only to those HFB staff who are authorized to access such PPI;
                      </p>
                    </li>
                    <li data-list-text="d.">
                      <p style={{paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Provide School Programs Partner with any relevant data about and specific to individuals participating in an HFB School Programs operated by School Programs Partner;
                      </p>
                    </li>
                    <li data-list-text="e.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-17pt', lineHeight: '110%', textAlign: 'left'}}>
                        At least once each month during the Term, provide FFC Partner with aggregate data about individuals participating in an HFB FFC program operated by FFC Partner;
                      </p>
                    </li>
                    <li data-list-text="f.">
                      <p style={{paddingTop: '11pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '111%', textAlign: 'left'}}>
                        Notify School Programs Partner within two (2) business days of any request and the purpose of that request by a third party for School Programs Partner’s PPI;
                      </p>
                    </li>
                    <li data-list-text="g.">
                      <p style={{paddingTop: '11pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Except as otherwise provided in Section 5.1, share School Programs Partner’s PPI with a third party only after receiving School Programs Partner’s written approval to do so.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="5.2.2">
                  <h2 style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'left'}}>School Programs Partner’s Obligations. <span className="p">During the Term, School Programs Partner shall:</span></h2>
                  <ol id="l43">
                    <li data-list-text="a.">
                      <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
                        Obtain and maintain all consents and permissions from individuals that are necessary to allow School Programs Partner to submit PPI to HFB;
                      </p>
                    </li>
                    <li data-list-text="b.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Obtain and maintain all individual authorization forms necessary to allow School Programs Partner to transmit PPI to HFB for purposes of tracking program outcomes while the individual is participating in the
                        School Programs initialed in Section 1.1;
                      </p>
                    </li>
                    <li data-list-text="c.">
                      <p style={{paddingTop: '12pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Assist HFB to recruit individuals to participate in focus groups, interviews, and School Programs surveys that will help evaluate and improve the school Programs initialed in Section 1.1;
                      </p>
                    </li>
                    <li data-list-text="d.">
                      <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '0pt', lineHeight: '111%', textAlign: 'left'}}>
                        Implement reasonable and appropriate safeguards to protect PPI and maintain and protect PPI in accordance with applicable laws and regulations.
                      </p>
                    </li>
                    <li data-list-text="e."><p style={{paddingTop: '11pt', paddingLeft: '125pt', textIndent: '-12pt', textAlign: 'left'}}>Encrypt all PPI transmitted to HFB;</p></li>
                    <li data-list-text="f.">
                      <p style={{paddingTop: '11pt', paddingLeft: '131pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        Transmit PPI on a monthly or quarterly basis to HFB as described and in accordance with the Data Sharing Requirements of the School Programs initialed in Section 1.1 that are included on the respective
                        Exhibit(s) attached to and incorporated herein.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </ol>
        <h2 style={{paddingTop: '12pt', paddingLeft: '36pt', textIndent: '0pt', textAlign: 'center'}}>ARTICLE 6: MISCELLANEOUS</h2>
        <ol id="l44">
          <ol id="l45">
            <li data-list-text="6.1">
              <h2 style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-35pt', lineHeight: '110%', textAlign: 'left'}}>
                Amendments. <span className="p">This MOU may be amended only by an instrument in writing signed by both Parties. Amendments to this MOU will be effective as of the date stipulated therein.</span>
              </h2>
            </li>
            <li data-list-text="6.2">
              <h2 style={{paddingTop: '12pt', paddingLeft: '59pt', textIndent: '-18pt', textAlign: 'left'}}>Assignment and Delegation. <span className="p">Neither Party shall assign or delegate all or any part of this MOU without the other Party's written consent.</span></h2>
  
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            </li>
            <li data-list-text="6.3">
              <h2 style={{paddingLeft: '77pt', textIndent: '-35pt', lineHeight: '111%', textAlign: 'left'}}>
                Governing Law and Venue.
                <span className="p">This MOU will be interpreted and construed in accordance with the laws of Texas, without application of any principles of law choice. The venue is proper in Harris County, Texas.</span>
              </h2>
            </li>
            <li data-list-text="6.4">
              <h2 style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-36pt', lineHeight: '110%', textAlign: 'left'}}>
                Integration.
                <span className="p">
                  This MOU, together with its Exhibit(s), constitutes the entire agreement between the Parties and supersedes all prior and contemporaneous, oral or written, understandings between the Parties with respect to the subject
                  matter hereof.
                </span>
              </h2>
            </li>
            <li data-list-text="6.5">
              <h2 style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-35pt', lineHeight: '111%', textAlign: 'justify'}}>
                Notice.
                <span className="p">
                  All notices, requests, consents, and other communications hereunder must be in writing, must be addressed to the receiving Party’s address set forth below or to such other address as a Party may designate by notice
                  hereunder, and must be (i) sent via email or (ii) sent by certified mail, return receipt requested, postage prepaid: If to HFB:
                </span>
              </h2>
            </li>
          </ol>
        </ol>
        <p style={{paddingTop: '11pt', paddingLeft: '75pt', textIndent: '0pt', textAlign: 'left'}}>Mail:</p>
        <p style={{paddingTop: '1pt', paddingLeft: '113pt', textIndent: '0pt', lineHeight: '111%', textAlign: 'left'}}>Houston Food Bank 535 Portwall Street</p>
        <p style={{paddingLeft: '113pt', textIndent: '0pt', lineHeight: '11pt', textAlign: 'left'}}>Houston, Texas 77029</p>
        <h2 style={{paddingLeft: '113pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>Attn<span className="p">: </span>Senior Manager of School Partnerships <span className="p">or </span>School Programs</h2>
        <p style={{paddingLeft: '113pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>or</p>
        <p style={{paddingLeft: '113pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>
          <a href="mailto:schoolprograms@houstonfoodbank.org" className="s18" target="_blank">Email: </a><a href="mailto:schoolprograms@houstonfoodbank.org" className="s16" target="_blank">schoolprograms@houstonfoodbank.org</a>
        </p>
        <p style={{paddingTop: '12pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <h2 style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '112%', textAlign: 'left'}}>In Witness Whereof, <span className="p">the Parties have executed this Agreement as of the dates associated with the signatures below.</span></h2>
      </div>

    </div>

    </Fragment>
  );
};

export default GeneralAgreement;
