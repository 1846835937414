import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { push } from 'react-router-redux';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import AddIcon from '../../images/add.svg';
import Loading from '../Loading';
import BlankState from '../BlankState';
import exportExcel from '../../helpers/exportExcel';
import Pagination from '../Pagination';
import { PAGE_SIZE } from '../../config';
import { districtsQuery } from '../../apollo';

class SchoolDistrictTable extends Component {
  state = {
    searchTerm: '',
  }

  exportDistricts = () => {
    const tableData = this.selectTable.getResolvedState().sortedData;
    const filteredData = tableData.map(({ _original }) => _original);

    const columnData = [
      { header: 'District Name', key: 'name' },
      { header: 'Schools', key: 'schoolNamesFormatted' },
      { header: 'Archived', key: 'archivedAtFormatted' },
    ];

    const rowData = [];
    filteredData.forEach((district) => {
      const archivedAtFormatted = district.deletedAt ? 'Yes' : 'No';
      const districtData = {
        archivedAtFormatted,
        ...district,
      };

      if (district.schools.length) {
        const schoolNames = [];
        district.schools.forEach(({ name }) => schoolNames.push(name));
        const schoolNamesFormatted = schoolNames.join(', ');

        rowData.push({
          schoolNamesFormatted,
          ...districtData,
        });
      } else {
        rowData.push({
          ...districtData,
        });
      }
    });
    exportExcel('districts', columnData, rowData);
  };

  searchResults(allDistricts) {
    const { searchTerm } = this.state;
    return filter(allDistricts, ({
      name,
      schools,
    }) => {
      const lowerCase = text => text.toLowerCase();
      const schoolNames = [];
      schools.forEach(school => schoolNames.push(school.name));
      const regexFunc = fieldValueSearch => new RegExp(searchTerm).test(fieldValueSearch);

      return regexFunc(lowerCase(name))
      || regexFunc(lowerCase(schoolNames.join()));
    });
  }

  render() {
    const { dispatch, program: { programUrl } } = this.props;

    return (
      <Fragment>
        <h2>Districts</h2>
        <Query
          query={districtsQuery}
          fetchPolicy="cache-and-network"
        >
          {({ loading, data, error }) => {
            if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
            if (!data.allDistricts && loading) return <Loading />;

            const { allDistricts } = data;
            const filteredDistricts = this.searchResults(allDistricts);
            const columns = [
              {
                Header: 'Name',
                accessor: 'name',
                filterable: false,
              },
              {
                id: 'schools',
                Header: 'Schools',
                accessor: (d) => {
                  const schoolNames = [];
                  d.schools.forEach(({ name }) => schoolNames.push(name));
                  return schoolNames.join(', ');
                },
                filterMethod: (f, row) => {
                  const id = f.pivotId || f.id;
                  return row[id] !== undefined ? String(row[id]).includes(f.value) : true;
                },
              },
              {
                id: 'archived',
                Header: 'Archived',
                accessor: row => (row.deletedAt ? 'Yes' : 'No'),
                Filter: (props) => {
                  const { filter: filterObj, onChange, column } = props;
                  return (
                    <select
                      className="w-select"
                      onChange={event => onChange(event.target.value)}
                      value={filterObj ? filterObj.value : 'all'}
                    >
                      <option value="">{column.Header}</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  );
                },
              },
            ];

            return (
              <Fragment>
                <div className="w-form districts-table">
                  <div className="div-block-22">
                    <Link
                      to={`${programUrl}/districts/new`}
                      className="add-user-link w-inline-block"
                    >
                      <img src={AddIcon} alt="add-user" className="image-6" />
                      <div className="text-block-13">Add District</div>
                    </Link>
                    <div
                      onClick={this.exportDistricts}
                      className="export-btn w-button"
                    >
                      Export
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  className="search-bar w-input districts-table"
                  onChange={evt => (
                    this.setState({ searchTerm: evt.target.value.trim().toLowerCase() })
                  )}
                  placeholder="Search"
                />
                <ReactTable
                  ref={(r) => {
                    this.selectTable = r;
                  }}
                  data={filteredDistricts}
                  columns={columns}
                  loadingText=""
                  NoDataComponent={() => <BlankState title="No Results" />}
                  minRows={0}
                  filterable
                  className="districts-table"
                  defaultPageSize={PAGE_SIZE}
                  PaginationComponent={Pagination}
                  defaultSorted={[{
                    id: 'name',
                    desc: false,
                  }]}
                  getTrGroupProps={(state, rowInfo) => ({
                    className: 'table-row school-row w-clearfix',
                    style: { cursor: 'pointer' },
                    onClick: () => dispatch(push(`${programUrl}/districts/${rowInfo.original.districtId}`)),
                  })}
                  getTdProps={() => ({
                    className: 'table-col fnln-cell',
                  })}
                  getTheadTrProps={() => ({
                    className: 'table-row table-header w-clearfix',
                  })}
                  getTheadFilterTrProps={() => ({
                    className: 'filters',
                  })}
                  getTheadFilterThProps={() => ({
                    className: 'filter-select user-filter',
                  })}
                  column={
                    {
                      ...ReactTableDefaults.column,
                      headerClassName: 'table-col fnln-users w-inline-block pointer noselect',
                      minWidth: 85,
                      width: '10%',
                      Cell: row => row.value || 'N/A',
                    }
                  }
                />
              </Fragment>
            );
          }
          }
        </Query>
      </Fragment>
    );
  }
}

SchoolDistrictTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired,
};

export default SchoolDistrictTable;
