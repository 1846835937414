import React from 'react';
import PropTypes from 'prop-types';
import Reports from '../../Reports';


const AdminReports = (props) => {
  const { school: { schoolId } } = props;

  return (
    <div className="div-block-19 admin-reports">
      <h2>Backpack Distribution Reports</h2>
      <Reports selectedSchool={schoolId} {...props} />
    </div>
  );
};

AdminReports.propTypes = {
  school: PropTypes.object.isRequired,
};

export default AdminReports;
