import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
  Link,
} from 'react-router-dom';
import { Query } from 'react-apollo';
import BackIcon from '../../images/back-icon.svg';
import Loading from '../Loading';
import BlankState from '../BlankState';
import DetailNav from './DetailNav';
import Profile from './Profile';
import Contacts from './Contacts';
import ProgramStatus from './ProgramStatus';
import Allocate from './Allocate';
import FoodSafety from './FoodSafety';
import Applications from './Applications';
import Reports from './Reports';
import DetailFormWrapper from './DetailFormWrapper';
import {
  adminSchoolBackpackBuddyQuery,
  adminEditSchoolProfileMutation,
  adminEditSchoolContactsMutation,
  adminCreateAllocationEntryMutation,
  adminCreateFoodSafetyEntryMutation,
  adminUpdateApplicationsMutation,
  adminUpdateProgramStatusMutation,
} from '../../apollo';

const detailRoutes = [{
  path: '/backpack-buddy/schools/:id',
  mutation: adminEditSchoolProfileMutation,
  component: Profile,
}, {
  path: '/backpack-buddy/schools/:id/contacts',
  mutation: adminEditSchoolContactsMutation,
  component: Contacts,
}, {
  path: '/backpack-buddy/schools/:id/program-status',
  mutation: adminUpdateProgramStatusMutation,
  component: ProgramStatus,
}, {
  path: '/backpack-buddy/schools/:id/allocate',
  mutation: adminCreateAllocationEntryMutation,
  component: Allocate,
}, {
  path: '/backpack-buddy/schools/:id/food-safety',
  mutation: adminCreateFoodSafetyEntryMutation,
  component: FoodSafety,
}, {
  path: '/backpack-buddy/schools/:id/applications',
  mutation: adminUpdateApplicationsMutation,
  component: Applications,
}, {
  path: '/backpack-buddy/schools/:id/reports',
  component: Reports,
}];

const SchoolDetailBackpackBuddy = ({ match, ...otherProps }) => (
  <Fragment>
    <Query
      query={adminSchoolBackpackBuddyQuery}
      variables={{
        schoolId: match.params.id,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        const loadingData = !data.adminSchoolBackpackBuddy && loading;
        if (loadingData || error) {
          return (
            <Fragment>
              <div className="profile-intro">
                <Link
                  to="/backpack-buddy/schools"
                  className="back-btn w-inline-block"
                >
                  <img className="image-14" src={BackIcon} alt="back" />
                  <div className="text-block-11">back to schools</div>
                </Link>
              </div>
              {loading && <Loading />}
              {error && <BlankState title="Error" subtitle="There was an error loading" />}
            </Fragment>
          );
        }

        const { adminSchoolBackpackBuddy: school } = data;
        return school ? (
          <Fragment>
            <div className="profile-intro">
              <Link
                to="/backpack-buddy/schools"
                className="back-btn w-inline-block"
              >
                <img className="image-14" src={BackIcon} alt="back" />
                <div className="text-block-11">back to schools</div>
              </Link>
              <h2 className="purple-headline">{school.name}</h2>
              <div className="divider-2 settings" />
            </div>
            <div className="div-block-13">
              <DetailNav {...otherProps} match={match} />
              <Switch>
                {
                    detailRoutes.map(({ path, mutation, component: Component }) => (
                      <Route
                        key={path}
                        exact
                        path={path}
                        render={() => (
                          <DetailFormWrapper
                            Form={Component}
                            mutation={mutation}
                            school={school}
                            {...otherProps}
                          />
                        )}
                      />
                    ))
                  }
                <Route component={() => <Redirect to="/backpack-buddy/schools" />} />
              </Switch>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="profile-intro">
              <Link
                to="/backpack-buddy/schools"
                className="back-btn w-inline-block"
              >
                <img className="image-14" src={BackIcon} alt="back" />
                <div className="text-block-11">back to schools</div>
              </Link>
            </div>
            <BlankState title="Error" subtitle="There was an error loading" />
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);

SchoolDetailBackpackBuddy.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SchoolDetailBackpackBuddy;
