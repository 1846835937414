import React from 'react';
import { NavLink } from 'react-router-dom';
import HFBLogo from '../images/houstonfood-logo.png';

const withAnonymousLayout = Component => (
  props => (
    <section>
      <div className="registration-flex-wrapper">
        <div className="logo-sidebar">
          <div className="hfb-logo"><img src={HFBLogo} width={150} alt="logo" /></div>
        </div>
        <div className="login-container">
          <div className="login-form-div">
            <div className="program-management">Program Management</div>
            <div data-duration-in={300} data-duration-out={100} className="tabs w-tabs">
              <div className="tabs-menu w-tab-menu">
                <NavLink
                  to="/login"
                  className="login-tab-link w-inline-block w-tab-link"
                  activeClassName="w--current"
                >
                  <div>Log In</div>
                </NavLink>
                <NavLink
                  to="/register"
                  className="login-tab-link w-inline-block w-tab-link"
                  activeClassName="w--current"
                >
                  <div>Sign Up</div>
                </NavLink>
              </div>
              <div className="login-tabs-content w-tab-content">
                <Component {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
);

export default withAnonymousLayout;
