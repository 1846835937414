import gql from 'graphql-tag';

export default gql`
  mutation adminEditConfigKidsCafe($input: ConfigKidsCafeInput!) {
    adminEditConfigKidsCafe(input: $input) {
      id
      configKidsCafeId
      acceptingApplications
    }
  }
`;
