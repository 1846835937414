import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import withProgramsLayout from './withProgramsLayout';

const adminLinks = (Component) => {
  const HOC = (props) => {
    const { program: { programUrl, name } } = props;

    const sharedNavLinks = [
      {
        path: `${programUrl}/users`,
        title: 'Users',
      },
      {
        path: `${programUrl}/program-settings`,
        title: 'Program Settings',
      },
    ];

    const schoolProgramNavLinks = [
      {
        path: `${programUrl}/schools`,
        title: 'Schools',
      },
      {
        path: `${programUrl}/districts`,
        title: 'Districts',
      },
      ...sharedNavLinks,
    ];

    const siteProgramNavLinks = [
      {
        path: `${programUrl}/sites`,
        title: 'Sites',
      },
      ...sharedNavLinks,
    ];

    const organizationProgramNavLinks = [
      {
        path: `${programUrl}/organizations`,
        title: 'Organizations',
      },
      ...sharedNavLinks,
    ];

    const backpackBuddyNavLinks = [
      {
        path: `${programUrl}/documents`,
        title: 'Documents',
      },
      {
        path: `${programUrl}/surveys`,
        title: 'Surveys',
      },
      {
        path: `${programUrl}/resources`,
        title: 'Resources',
      },
    ];

    let navLinks = [];

    switch (name) {
      case 'Backpack Buddy':
        navLinks = [
          ...schoolProgramNavLinks,
          ...backpackBuddyNavLinks,
        ];
        break;
      case 'Teachers Aid':
      case 'School Market':
        navLinks = [
          ...schoolProgramNavLinks,
        ];
        break;
      case 'Kids Cafe':
        navLinks = [
          ...siteProgramNavLinks,
        ];
        break;
      case 'Partner Services':
        navLinks = [
          ...organizationProgramNavLinks,
        ];
        break;
      default:
        break;
    }

    return (
      <Fragment>
        <div className="section-subnav">
          <div className="_1300-container">
            <div className="subnav-flex-2">
              <div className="admin-dash-tabs">
                {navLinks.map(({ path, title, disabled = false }) => (
                  <NavLink
                    key={path}
                    to={path}
                    className={classnames('dashboard-link-3 w-inline-block', { last: path === '/resources' }, { 'disabled-link': disabled })}
                    activeClassName="active"
                  >
                    <div className="text-block-8">{title}</div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="section-page">
          <div className="_1300-container">
            <Component {...props} />
          </div>
        </div>
      </Fragment>
    );
  };

  HOC.propTypes = {
    program: PropTypes.object.isRequired,
  };

  return HOC;
};

const withAdminLayout = Component => (
  withProgramsLayout(adminLinks(props => <Component {...props} />))
);


export default withAdminLayout;
