import React from 'react';
import PropTypes from 'prop-types';
import AllocateForm from './AllocateForm';
import AllocateTable from './AllocateTable';

const Allocate = props => (
  <div className="div-block-19">
    <h2>Allocate</h2>
    <div className="w-form">
      <AllocateForm {...props} />
      <AllocateTable {...props} />
    </div>
  </div>
);

Allocate.propTypes = {
  school: PropTypes.object.isRequired,
};

export default Allocate;
