import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { push } from 'react-router-redux';
import { uniq, filter, flatten } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AddIcon from '../../images/add.svg';
import Loading from '../Loading';
import BlankState from '../BlankState';
import exportExcel from '../../helpers/exportExcel';
import Pagination from '../Pagination';
import { PAGE_SIZE } from '../../config';
import { educatorUsersQuery } from '../../apollo';

class SiteUserTable extends Component {
  state = {
    searchTerm: '',
  }

  exportUsers = () => {
    const tableData = this.selectTable.getResolvedState().sortedData;
    const filteredData = tableData.map(({ _original }) => _original);

    const columnData = [
      { header: 'First Name', key: 'firstName' },
      { header: 'lastName', key: 'lastName' },
      { header: 'Email', key: 'email' },
      { header: 'Phone', key: 'phone' },
      { header: 'Sites', key: 'siteNamesFormatted' },
      { header: 'Date Created', key: 'createdAtFormatted' },
      { header: 'Deleted', key: 'deletedAtFormatted' },
    ];

    const rowData = [];

    filteredData.forEach((user) => {
      const createdAtFormatted = moment.utc(user.createdAt).format('M-D-YY');
      const deletedAtFormatted = user.deletedAt ? 'Yes' : 'No';
      const userData = {
        createdAtFormatted,
        deletedAtFormatted,
        ...user,
      };

      if (user.sites.length) {
        const siteNames = [];
        user.sites.forEach(({ name }) => siteNames.push(name));
        const siteNamesFormatted = siteNames.join(', ');

        rowData.push({
          siteNamesFormatted,
          ...userData,
        });
      } else {
        rowData.push({
          ...userData,
        });
      }
    });

    exportExcel('users', columnData, rowData);
  };

  selectFilter = (props, educatorUsers) => {
    const { filter: filterObj, onChange, column } = props;
    const uniqOptions = educatorUsers.map(({ sites }) => (
      sites.map(site => site[column.id])));
    const filterOptions = uniq(flatten(uniqOptions));
    return (
      <select
        className="w-select"
        onChange={event => onChange(event.target.value)}
        value={filterObj ? filterObj.value : ''}
      >
        <option value="">{column.Header}</option>
        {
          filterOptions.map(value => (
            <option key={`${value}+${column.id}`} value={value}>{value}</option>
          ))
        }
      </select>
    );
  }

  searchResults(educatorUsers) {
    const { searchTerm } = this.state;

    return filter(educatorUsers, ({
      firstName,
      lastName,
      email,
      sites,
    }) => {
      const lowerCase = text => text.toLowerCase();
      const siteNames = [];
      sites.forEach(({ name }) => siteNames.push(name));
      const regexFunc = fieldValueSearch => new RegExp(searchTerm).test(fieldValueSearch);

      return regexFunc(lowerCase(firstName))
        || regexFunc(lowerCase(lastName))
        || regexFunc(lowerCase(email))
        || regexFunc(lowerCase(siteNames.join()));
    });
  }

  render() {
    const { dispatch, program: { programUrl } } = this.props;

    return (
      <Fragment>
        <h2>Users</h2>
        <Query
          query={educatorUsersQuery}
          fetchPolicy="cache-and-network"
        >
          {({ loading, data, error }) => {
            if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
            if (!data.educatorUsers && loading) return <Loading />;

            const { educatorUsers } = data;

            const filteredUsers = this.searchResults(educatorUsers);

            const columns = [{
              Header: 'First Name',
              accessor: 'firstName',
              filterable: false,
            }, {
              Header: 'Last Name',
              accessor: 'lastName',
              filterable: false,
            }, {
              Header: 'Email',
              accessor: 'email',
              filterable: false,
            }, {
              id: 'name',
              Header: 'Site',
              accessor: (d) => {
                const siteNames = [];
                d.sites.forEach(({ name }) => siteNames.push(name));
                return siteNames.join(', ');
              },
              filterMethod: (f, row) => {
                const id = f.pivotId || f.id;
                return row[id] !== undefined ? String(row[id]).includes(f.value) : true;
              },
            },
            {
              id: 'deleted',
              Header: 'Deleted',
              accessor: row => (row.deletedAt ? 'Yes' : 'No'),
              Filter: (props) => {
                const { filter: filterObj, onChange, column } = props;
                return (
                  <select
                    className="w-select"
                    onChange={event => onChange(event.target.value)}
                    value={filterObj ? filterObj.value : 'all'}
                  >
                    <option value="">{column.Header}</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                );
              },
            }];

            return (
              <Fragment>
                <div className="w-form users-table">
                  <div className="div-block-22">
                    <Link
                      to={`${programUrl}/users/new`}
                      className="add-user-link w-inline-block"
                    >
                      <img src={AddIcon} alt="add-user" className="image-6" />
                      <div className="text-block-13">Add a User</div>
                    </Link>
                    <div
                      onClick={this.exportUsers}
                      className="export-btn w-button"
                    >
                      Export
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  className="search-bar w-input users-table"
                  onChange={evt => (
                    this.setState({ searchTerm: evt.target.value.trim().toLowerCase() })
                  )}
                  placeholder="Search"
                />
                <ReactTable
                  ref={(r) => {
                    this.selectTable = r;
                  }}
                  data={filteredUsers}
                  columns={columns}
                  loadingText=""
                  NoDataComponent={() => <BlankState title="No Results" />}
                  minRows={0}
                  filterable
                  className="users-table"
                  defaultPageSize={PAGE_SIZE}
                  PaginationComponent={Pagination}
                  defaultSorted={[{
                    id: 'lastName',
                    desc: false,
                  }]}
                  getTrGroupProps={(state, rowInfo) => ({
                    className: 'table-row school-row w-clearfix',
                    style: { cursor: 'pointer' },
                    onClick: () => dispatch(push(`${programUrl}/users/${rowInfo.original.educatorId}`)),
                  })}
                  getTdProps={() => ({
                    className: 'table-col fnln-cell',
                  })}
                  getTheadTrProps={() => ({
                    className: 'table-row table-header w-clearfix',
                  })}
                  getTheadFilterTrProps={() => ({
                    className: 'filters',
                  })}
                  getTheadFilterThProps={() => ({
                    className: 'filter-select user-filter',
                  })}
                  column={
                    {
                      ...ReactTableDefaults.column,
                      headerClassName: 'table-col fnln-users w-inline-block pointer noselect',
                      minWidth: 85,
                      width: '10%',
                      Cell: row => row.value || 'N/A',
                      Filter: props => this.selectFilter(props, educatorUsers),
                    }
                  }
                />
              </Fragment>
            );
          }
          }
        </Query>
      </Fragment>
    );
  }
}

SiteUserTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired,
};

export default SiteUserTable;
