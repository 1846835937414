import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { push } from 'react-router-redux';
import {
  get,
  uniq,
  filter,
  omit,
  maxBy,
} from 'lodash';
import moment from 'moment';
import exportExcel from '../../helpers/exportExcel';
import getLabel from '../../helpers/getLabel';
import Loading from '../Loading';
import BlankState from '../BlankState';
import { PAGE_SIZE } from '../../config';
import Pagination from '../Pagination';
import { schoolsBackpackBuddyQuery } from '../../apollo';

class SchoolTableBackpackBuddy extends Component {
  state = {
    searchTerm: '',
  }

  exportSchools = () => {
    const tableData = this.selectTable.getResolvedState().sortedData;
    const filteredData = tableData.map(({ _original }) => _original);

    const columnData = [
      { header: 'Backpack Buddy ID Number', key: 'backpackBuddyId' },
      { header: 'School Name', key: 'name' },
      { header: 'District', key: 'districtName' },
      { header: 'Zip', key: 'zip' },
      { header: 'County', key: 'county' },
      { header: 'State', key: 'state' },
      { header: 'Address 1', key: 'address1' },
      { header: 'Address 2', key: 'address2' },
      { header: 'Level', key: 'type' },
      { header: 'Food Safety', key: 'foodSafetyStatus' },
      { header: 'Program Status', key: 'statusBackpackBuddy' },
      { header: 'Term', key: 'termFormattedShort' },
      { header: 'Date Submitted', key: 'submittedAtFormatted' },
      { header: 'Application Status', key: 'applicationStatus' },
      { header: 'Email Secondary Coordinator', key: 'emailSecondaryCoordinator' },
      { header: 'Will Help Media Events', key: 'willHelpMediaEvents' },
      { header: 'Will Distribute Info', key: 'willDistributeInfo' },
      { header: 'Program Interests', key: 'programInterestsFormatted' },
      { header: 'Certified Verification', key: 'certififedVerification' },
      { header: 'Free Lunch', key: 'numFreeLunch' },
      { header: 'Reduced Lunch', key: 'numReducedLunch' },
      { header: 'Total Enrollment', key: 'numTotalEnrollment' },
      { header: 'Participated in program last year', key: 'participatedLastYear' },
      { header: 'Principal Name', key: 'principalName' },
      { header: 'Principal Email', key: 'principalEmail' },
      { header: 'Primary Coordinator Name', key: 'primaryCoordinatorName' },
      { header: 'Primary Coordinator Email', key: 'primaryCoordinatorEmail' },
      { header: 'Secondary Coordinator Name', key: 'secondaryCoordinatorName' },
      { header: 'Secondary Coordinator Email', key: 'secondaryCoordinatorEmail' },
    ];

    const rowData = [];

    filteredData.forEach((school) => {
      const principal = school.teamMembersBackpackBuddy.find(({ type }) => type === 'PRINCIPAL');
      const primaryCoordinator = school.teamMembersBackpackBuddy.find(({ type }) => type === 'PRIMARY');
      const secondaryCoordinator = school.teamMembersBackpackBuddy.find(({ type }) => type === 'SECONDARY');

      const schoolData = {
        ...school,
        principalName: get(principal, 'fullName', 'N/A'),
        principalEmail: get(principal, 'email', 'N/A'),
        primaryCoordinatorName: get(primaryCoordinator, 'fullName', 'N/A'),
        primaryCoordinatorEmail: get(primaryCoordinator, 'email', 'N/A'),
        secondaryCoordinatorName: get(secondaryCoordinator, 'fullName', 'N/A'),
        secondaryCoordinatorEmail: get(secondaryCoordinator, 'email', 'N/A'),
      };

      if (school.applicationsBackpackBuddy.length) {
        school.applicationsBackpackBuddy.forEach((application) => {
          const applicationStatus = application.status;
          const participatedLastYear = application.new ? 'No' : 'Yes';
          const programInterestsFormatted = application.programInterests.join(', ');
          const submittedAtFormatted = application.submittedAt
            ? moment.utc(application.submittedAt).format('M-D-YY')
            : '';

          rowData.push({
            submittedAtFormatted,
            programInterestsFormatted,
            applicationStatus,
            participatedLastYear,
            ...schoolData,
            ...omit(application, 'status'),
          });
        });
      } else {
        rowData.push({
          ...schoolData,
        });
      }
    });

    exportExcel('schools', columnData, rowData);
  };

  selectFilter = (props, termSchools, options = null) => {
    const { filter: filterObj, onChange, column } = props;

    const uniqOptions = uniq(termSchools.map(school => (
      get(school, `[${column.id}]`)
    )));

    return (
      <select
        className="filter-select w-select"
        onChange={event => onChange(event.target.value)}
        value={filterObj ? filterObj.value : ''}
      >
        <option value="">{column.Header}</option>
        {
          uniqOptions.map(value => (
            <option key={`${value}+${column.id}`} value={value}>{(options && value !== 'N/A') ? getLabel(options, value) : value}</option>
          ))
        }
      </select>
    );
  }

  startOfMonth = () => moment.utc(new Date()).startOf('month').format();

  startOfNextMonth = () => moment.utc(new Date()).add(1, 'month').startOf('month').format();

  searchResults(termSchools) {
    const { searchTerm } = this.state;

    return filter(termSchools, ({
      backpackBuddyId,
      name,
      districtName,
      zip,
      county,
      type,
      foodSafetyStatus,
      statusBackpackBuddy,
      new: newSchool,
    }) => {
      const lowerCase = text => text.toLowerCase();
      const isNew = newSchool ? 'new' : '';
      const regexFunc = fieldValueSearch => new RegExp(searchTerm).test(fieldValueSearch);

      return regexFunc(lowerCase(name))
        || regexFunc(lowerCase(districtName))
        || regexFunc(lowerCase(type))
        || regexFunc(lowerCase(county))
        || regexFunc(backpackBuddyId)
        || regexFunc(zip)
        || regexFunc(isNew)
        || regexFunc(lowerCase(statusBackpackBuddy))
        || regexFunc(lowerCase(foodSafetyStatus));
    });
  }

  render() {
    const { selectedTermId, enums, dispatch } = this.props;

    const {
      schoolTypes,
      programStatuses,
      foodSafetyStatuses,
    } = enums;

    return (
      <Query
        query={schoolsBackpackBuddyQuery}
        variables={{ termId: selectedTermId }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, data, error }) => {
          if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
          if (!data.schoolsBackpackBuddy && loading) return <Loading />;

          const { schoolsBackpackBuddy: termSchools } = data;
          const filteredTermSchools = this.searchResults(termSchools);

          const columns = [{
            Header: 'Backpack ID',
            accessor: 'backpackBuddyId',
            filterable: false,
          }, {
            Header: 'School',
            accessor: 'name',
            filterable: false,
          }, {
            Header: 'District',
            accessor: 'districtName',
          }, {
            Header: 'Zip',
            accessor: 'zip',
          }, {
            Header: 'County',
            accessor: 'county',
          }, {
            Header: 'Level',
            accessor: 'type',
            Cell: row => getLabel(schoolTypes.options, row.value),
            Filter: props => this.selectFilter(props, termSchools, schoolTypes.options),
          }, {
            Header: 'Program Status',
            accessor: 'statusBackpackBuddy',
            Cell: row => getLabel(programStatuses.options, row.value),
            Filter: props => this.selectFilter(props, termSchools, programStatuses.options),
          }, {
            Header: 'Food Safety',
            accessor: 'foodSafetyStatus',
            Cell: row => (row.value !== 'N/A' ? getLabel(foodSafetyStatuses.options, row.value) : row.value),
            Filter: props => (
              this.selectFilter(props, termSchools, foodSafetyStatuses.options)
            ),
          }, {
            id: 'allocation',
            Header: 'Monthly Allocation',
            filterable: false,
            accessor: d => d.termMonths,
            Cell: (row) => {
              if (row.value.length) {
                const activeMonth = row.value.find(({ startDate }) => (
                  new Date(startDate).getTime() === new Date(this.startOfMonth()).getTime()
                ));

                const activeNextMonth = row.value.find(({ startDate }) => (
                  new Date(startDate).getTime() === new Date(this.startOfNextMonth()).getTime()
                ));

                if (activeMonth) {
                  const { monthlyAllocation: thisMonthAllocation } = activeMonth;
                  const nextMonthAllocation = get(activeNextMonth, 'monthlyAllocation', thisMonthAllocation);

                  return (
                    <div
                      style={{
                        width: row.width,
                        height: '100%',
                        fontWeight: 'bold',
                        color: thisMonthAllocation === nextMonthAllocation ? '#6fb700' : '#aa1438',
                      }}
                    >
                      {thisMonthAllocation}
                    </div>
                  );
                }
              }

              return 0;
            },
          }, {
            id: 'new',
            Header: 'Returning',
            accessor: (d) => {
              const activeApplications = d.applicationsBackpackBuddy.filter(({ status }) => status === 'APPROVED');
              const mostRecent = maxBy(activeApplications, o => o.createdAt);
              if (mostRecent) {
                return mostRecent.new ? 'No' : 'Yes';
              }
              return 'N/A';
            },
            Filter: (props) => {
              const { filter: filterObj, onChange, column } = props;
              return (
                <select
                  className="filter-select w-select"
                  onChange={event => onChange(event.target.value)}
                  value={filterObj ? filterObj.value : 'all'}
                >
                  <option value="">{column.Header}</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              );
            },
          }, {
            id: 'archived',
            Header: 'Archived',
            accessor: row => (row.deletedAt ? 'Yes' : 'No'),
            Filter: (props) => {
              const { onChange, column } = props;
              return (
                <select
                  className="filter-select w-select"
                  onChange={event => onChange(event.target.value)}
                >
                  <option value="No">{column.Header}</option>
                  <option value="">Show All</option>
                </select>
              );
            },
          }];

          return (
            <Fragment>
              <div className="date-export admin-schools backpack-buddy">
                <div
                  onClick={this.exportSchools}
                  className="export-btn w-button admin-schools"
                >
                  Export
                </div>
              </div>
              <input
                type="text"
                className="search-bar w-input"
                onChange={evt => (
                  this.setState({ searchTerm: evt.target.value.trim().toLowerCase() })
                )}
                placeholder="Search"
              />
              <ReactTable
                className="schools-table backpack-buddy"
                ref={(r) => {
                  this.selectTable = r;
                }}
                data={filteredTermSchools}
                columns={columns}
                loadingText=""
                NoDataComponent={() => <BlankState title="No Results" />}
                minRows={0}
                filterable
                defaultPageSize={PAGE_SIZE}
                PaginationComponent={Pagination}
                defaultFiltered={[{
                  id: 'archived',
                  value: 'No',
                }]}
                defaultSorted={[{
                  id: 'name',
                  desc: false,
                }]}
                getTrGroupProps={(state, rowInfo) => ({
                  className: 'table-row school-row w-clearfix',
                  style: { cursor: 'pointer' },
                  onClick: () => dispatch(push(`/backpack-buddy/schools/${rowInfo.original.schoolId}`)),
                })}
                getTdProps={() => ({
                  className: 'table-col schools-cells',
                })}
                getTheadTrProps={() => ({
                  className: 'table-row table-header w-clearfix',
                })}
                getTheadFilterTrProps={() => ({
                  className: 'filters',
                })}
                column={
                  {
                    ...ReactTableDefaults.column,
                    headerClassName: 'table-col schools-header w-inline-block pointer noselect',
                    minWidth: 85,
                    width: '10%',
                    Cell: row => row.value || 'N/A',
                    Filter: props => this.selectFilter(props, termSchools),
                  }
                }
              />
            </Fragment>
          );
        }
        }
      </Query>
    );
  }
}

SchoolTableBackpackBuddy.propTypes = {
  selectedTermId: PropTypes.string.isRequired,
  enums: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SchoolTableBackpackBuddy;
