import React from 'react';
import PropTypes from 'prop-types';
import FoodSafetyForm from './FoodSafetyForm';
import FoodSafetyTable from './FoodSafetyTable';

const FoodSafety = props => (
  <div className="div-block-19">
    <h2>Food Safety</h2>
    <div className="w-form">
      <FoodSafetyForm {...props} />
      <FoodSafetyTable {...props} />
    </div>
  </div>
);

FoodSafety.propTypes = {
  school: PropTypes.object.isRequired,
};

export default FoodSafety;
