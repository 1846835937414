import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({
  input,
  label,
  value,
  placeholder,
  wrapperStyles,
  meta: { touched, error },
  ...otherProps
}) => (
  <div style={wrapperStyles}>
    {label}
    <textarea {...input} {...otherProps} placeholder={placeholder}>{value}</textarea>
    {touched && error
    && (
    <span style={{
      position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
    }}
    >
      {error}
    </span>
    )}
  </div>
);

TextArea.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  wrapperStyles: PropTypes.object,
};

TextArea.defaultProps = {
  placeholder: null,
  maxLength: 255,
  label: '',
  value: '',
  wrapperStyles: { position: 'relative' },
};

export default TextArea;
