import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import {
  required,
  parseNumber,
  validateEnrollmentNumbers,
} from '../../helpers';
import ApplicationActions from '../ApplicationActions';
import TextField from '../TextField';
import RadioButtonGroup from '../RadioButtonGroup';
import CheckboxGroup from '../CheckboxGroup';
import BackgroundVerification from '../BackgroundVerification';

const StepTwoForm = (props) => {
  const {
    handleSubmit,
    save,
    readOnly,
    termsAvailable,
    invalid,
    submitFailed,
    enums: {
      programInterests,
    },
  } = props;
  return (
    <Form onSubmit={handleSubmit(save)} id="email-form" className="backbackbuddy-form">
      <h4 className="purple-headline boxed-in">Allotment Information</h4>
      <div className="field-combo-wrapper">
        <div className="field-combo thirds">
          <Field
            label={<label htmlFor="Last-3" className="field-label">Total Enrollment</label>}
            component={TextField}
            validate={[required]}
            name="numTotalEnrollment"
            className="text-field number w-input"
            parse={parseNumber}
            maxLength={4}
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo thirds">
          <Field
            label={<label htmlFor="Last-3" className="field-label">Number of Students Receiving Reduced Lunch</label>}
            component={TextField}
            validate={[required, validateEnrollmentNumbers]}
            name="numReducedLunch"
            className="text-field number w-input"
            parse={parseNumber}
            maxLength={4}
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo thirds">
          <Field
            label={<label htmlFor="First-3" className="field-label">Number of Students Receiving Free Lunch</label>}
            component={TextField}
            validate={[required, validateEnrollmentNumbers]}
            name="numFreeLunch"
            className="text-field number w-input"
            parse={parseNumber}
            maxLength={4}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper radio-wrapper">
        <label htmlFor="First" className="field-label">Would you be willing to help Houston Food Bank with media events?</label>
        <div>
          <Field
            name="willHelpMediaEvents"
            validate={required}
            component={RadioButtonGroup}
            className="radio-inline w-radio"
            parse={val => val === 'true'}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper radio-wrapper">
        <label htmlFor="First-3" className="field-label">Would you be willing to pass out info about other Houston Food Bank&#39;s programs if provided?</label>
        <div>
          <Field
            name="willDistributeInfo"
            validate={required}
            component={RadioButtonGroup}
            className="radio-inline w-radio"
            parse={val => val === 'true'}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper radio-wrapper">
        <label htmlFor="Last-3" className="field-label">Please check the programs that you are interested in receiving more information about (optional)</label>
        <Field
          name="programInterests"
          options={programInterests.options}
          component={CheckboxGroup}
          className="checkbox-inline w-checkbox"
          disabled={readOnly || !termsAvailable}
        />
      </div>
      <BackgroundVerification {...props} />
      <ApplicationActions {...props} />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

StepTwoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  enums: PropTypes.object.isRequired,
  termsAvailable: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

StepTwoForm.defaultProps = {
  readOnly: false,
};

export default StepTwoForm;
