import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from '../routes';
import { apolloClient } from '../apollo';
import { reduxStore, history } from '../redux';
import '../styles/normalize.css';
import '../styles/webflow.css';
import '../styles/hfb-programs.webflow.css';
import '../styles/custom.css';
import InitUser from './InitUser';

export const store = reduxStore();

const App = () => (
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <InitUser>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </InitUser>
      <ToastContainer
        autoClose={2000}
        position={toast.POSITION.TOP_CENTER}
        transition={Slide}
        hideProgressBar
        closeButton={false}
        draggable={false}
        className="toast-container"
        toastClassName="toast"
      />
    </Provider>
  </ApolloProvider>
);

export default App;
