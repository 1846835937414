import gql from 'graphql-tag';

export default gql`
  query($districtId: ID!) {
    adminDistrictDetails(districtId: $districtId) {
      id
      districtId
      name
      deletedAt
      lastEdited
    }
  }
`;
