import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import ReactTable, { ReactTableDefaults } from 'react-table';
import moment from 'moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BlankState from '../../BlankState';
import handleToastError from '../../../helpers/handleToastError';
import Pagination from '../../Pagination';
import { PAGE_SIZE } from '../../../config';
import deleteIcon from '../../../images/delete.svg';
import { adminSchoolBackpackBuddyQuery, adminDeleteAllocationEntryMutation } from '../../../apollo';

const AllocateTable = ({ school }) => {
  const { allocationEntries } = school;

  const columns = [{
    Header: 'Updated By',
    accessor: 'updatedBy',
  }, {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: row => moment.utc(row.value).format('M-D-YY'),
  }, {
    Header: 'Backpacks Allocated',
    accessor: 'weeklyAllocation',
  }, {
    Header: '',
    Cell: row => (
      <Mutation
        mutation={adminDeleteAllocationEntryMutation}
        variables={{
          allocationEntryId: row.original.allocationEntryId,
        }}
        refetchQueries={[
          {
            query: adminSchoolBackpackBuddyQuery,
            variables: { schoolId: row.original.schoolId },
          },
        ]}
        onError={mutationError => handleToastError(mutationError)}
        onCompleted={() => toast('Delete Successful')}
        awaitRefetchQueries
      >
        {(adminDeleteAllocationEntry) => {
          const confirmDelete = () => {
            confirmAlert({
              message: 'Are you sure?',
              buttons: [
                {
                  label: 'Cancel',
                },
                {
                  label: 'Delete',
                  onClick: adminDeleteAllocationEntry,
                },
              ],
            });
          };

          return (
            <div className="crud-action-icon-wrapper w-inline-block" onClick={confirmDelete}>
              <img className="pointer" src={deleteIcon} width={14} alt="delete" />
            </div>
          );
        }}
      </Mutation>
    ),
  }];

  return (
    <ReactTable
      className="table-wrapper-3"
      data={allocationEntries}
      columns={columns}
      loadingText=""
      NoDataComponent={() => <BlankState title="No Results" />}
      minRows={0}
      filterable={false}
      PaginationComponent={Pagination}
      defaultPageSize={PAGE_SIZE}
      defaultSorted={[{
        id: 'createdAt',
        desc: true,
      }]}
      getTrProps={() => ({
        className: 'table-row-3 allocate-row w-clearfix',
      })}
      getTdProps={() => ({
        className: 'table-col-2 allocate-cell',
      })}
      getTheadTrProps={() => ({
        className: 'table-row-3 table-header w-clearfix',
      })}
      column={
        {
          ...ReactTableDefaults.column,
          headerClassName: 'table-col-2 allocate-table w-inline-block pointer noselect',
          minWidth: 85,
          width: '10%',
        }
      }
    />
  );
};

AllocateTable.propTypes = {
  school: PropTypes.object.isRequired,
};

export default AllocateTable;
