import React, { useState } from 'react';
import ApplicationForm from './ApplicationForm';

const Applications = (props) => {
  return <div className="table-wrapper-3 applications">
    <h2>Applications</h2>
    <ApplicationForm {...props} />
  </div>
};

export default Applications;
