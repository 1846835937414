import gql from 'graphql-tag';

export default gql`
  {
    allOrganizations {
      id
      organizationId
      name
      address1
      address2
      city
      state
      zip
      county
      einNumber
      organizationMembers {
        organizationMemberId
        firstName
        lastName
        title
        phone
        email
        type
      }
      primaryPointOfContact {
        organizationMemberId
        firstName
        lastName
        title
        phone
        email
        type
      }
      secondaryPointOfContact {
        organizationMemberId
        firstName
        lastName
        title
        phone
        email
        type
      }
    }
  }
`;
