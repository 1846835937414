import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import TextField from '../TextField';
import { setUserAction } from '../../redux';
import {
  required,
  email,
  phone,
  normalizePhone,
} from '../../helpers';
import handleToastError from '../../helpers/handleToastError';
import { editProfileMutation } from '../../apollo';

const ProfileTab = reduxForm({
  form: 'educatorProfile',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  dispatch,
}) => (
  <div className="div-block-19">
    <h2>Profile Information</h2>
    <Mutation
      mutation={editProfileMutation}
      onError={mutationError => handleToastError(mutationError)}
      onCompleted={({ editProfile }) => {
        dispatch(setUserAction({ ...editProfile }));
        toast('Update Successful');
      }}
    >
      {(editProfile) => {
        const save = async input => (
          editProfile({
            variables: {
              input,
            },
          })
        );

        return (
          <Form
            className="form-3"
            onSubmit={handleSubmit(save)}
          >
            <Field
              className="account-field w-input"
              name="firstName"
              data-name="Account First Name"
              component={TextField}
              label={<label htmlFor="Account-First-Name" className="account-field-label">First Name</label>}
              validate={required}
            />
            <Field
              className="account-field w-input"
              name="lastName"
              data-name="Account Last Name"
              component={TextField}
              label={<label htmlFor="Account-Last-Name" className="account-field-label">Last Name</label>}
              validate={required}
            />
            <Field
              className="account-field w-input"
              name="email"
              data-name="Account Email"
              component={TextField}
              label={<label htmlFor="Account-Email" className="account-field-label">Email</label>}
              validate={[required, email]}
            />
            <Field
              className="account-field w-input"
              name="phone"
              data-name="Account Phone"
              component={TextField}
              label={<label htmlFor="Account-Phone" className="account-field-label">Phone</label>}
              validate={phone}
              normalize={normalizePhone}
            />
            <input
              type="submit"
              value={submitting ? 'Saving...' : 'Save'}
              className="form-button account w-button"
              disabled={submitting}
            />
          </Form>
        );
      }}
    </Mutation>
  </div>
));

const mapStateToProps = ({ user }) => {
  const {
    firstName,
    lastName,
    email: educatorEmail,
    phone: educatorPhone,
  } = user;


  const initialValues = {
    firstName,
    lastName,
    email: educatorEmail,
    phone: educatorPhone,
  };

  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(ProfileTab);
