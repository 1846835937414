import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { educatorUserQuery, enumsQuery, adminUserQuery } from '../../apollo';
import {
  setEnumsAction, authenticatedAction, authFailureAction, setProgramAction,
} from '../../redux';
import removeTypename from '../../helpers/removeTypename';
import { endSession } from '../../helpers/session';
import Loading from '../Loading';
import programs from '../../constants/programs';

class InitUser extends React.Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    const { client, dispatch } = this.props;

    let results;
    let enumResults;

    try {
      const { data } = await client.query({
        query: educatorUserQuery,
        fetchPolicy: 'network-only',
      });

      results = data;

      dispatch(authenticatedAction(data.educatorUser));
    } catch (error) {
      console.log('educator init false');
    }

    if (!results) {
      try {
        const { data } = await client.query({
          query: adminUserQuery,
          fetchPolicy: 'network-only',
        });

        results = data;

        dispatch(authenticatedAction(data.adminUser));
      } catch (error) {
        console.log('admin init false');
      }
    }

    try {
      const { data: { enums } } = await client.query({
        query: enumsQuery,
      });

      enumResults = enums;

      const formattedEnums = {};
      enums.forEach(({ name, options }) => {
        formattedEnums[name] = {
          options,
        };
      });

      const foundProgram = programs.find(({ programUrl }) => (
        window.location.href.indexOf(programUrl) > -1));

      dispatch(setProgramAction(foundProgram));
      dispatch(setEnumsAction(removeTypename(formattedEnums)));
    } catch (error) {
      console.log('init enums failure');
    }

    if (!results || !enumResults) {
      endSession();
      dispatch(authFailureAction());
    }

    // Prevent race condition from resetting redux store
    await new Promise(resolve => setTimeout(resolve, 100));

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { children } = this.props;

    if (loading) {
      return <Loading />;
    }

    return children;
  }
}

InitUser.propTypes = {
  children: PropTypes.any.isRequired,
  client: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(withApollo(InitUser));
