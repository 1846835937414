import { saveAs } from 'file-saver';
import template from '../files/teachersaid-teacher-template.xlsx';

const downloadTeachersTemplate = () => {
  try {
    saveAs(template, 'teachersaid-teacher-template.xlsx');
  } catch (err) {
    window.navigator.msSaveBlob(template, 'teachersaid-teacher-template.xlsx');
  }
};

export default downloadTeachersTemplate;
