import gql from 'graphql-tag';

export default gql`
  query adminSchoolSchoolMarket ($schoolId: ID!) {
    adminSchoolSchoolMarket(schoolId: $schoolId) {
      id
      schoolId
      name
      schoolMarketAgencyNumber
      address1
      address2
      city
      state
      zip
      county
      districtId
      type
      gradesServed
      lastEdited
      lastEditedTeamMembers
      firstYearAppliedSchoolMarket
      statusSchoolMarket
      statusSchoolMarketUpdatedAt
      deletedAt
      applicationsSchoolMarket {
        id
        applicationSchoolMarketId
        new
        createdAt
        submittedAt
        submittedBy
        status
        termFormattedShort
        numFreeLunch
        numReducedLunch
        numTotalEnrollment
        isContinuation
      }
      teamMembersSchoolMarket {
        id
        teamMemberId
        firstName
        lastName
        email
        phone
        type
        title
      }
    }
  }
`;
