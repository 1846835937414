import gql from 'graphql-tag';

export default gql`
  {
    allSites {
      id
      siteId
      name
      address1
      address2
      city
      state
      zip
      county
      closestSchool
      crossStreet
      siteMembers {
        siteMemberId
        firstName
        lastName
        title
        phone
        email
        type
        isSupervisor
      }
      primaryPointOfContact {
        siteMemberId
        firstName
        lastName
        title
        phone
        email
        type
        isSupervisor
      }
      secondaryPointOfContact {
        siteMemberId
        firstName
        lastName
        title
        phone
        email
        type
        isSupervisor
      }
    }
  }
`;
