/* eslint-disable no-console */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { toast } from 'react-toastify';
import { UserAgentApplication } from 'msal';
import getFormError from '../helpers/getFormError';
import { loginMutation, educatorUserQuery, adminUserQuery } from '../apollo';
import { AZURE_AUTHORITY_URL, AZURE_CLIENT_ID } from '../config';
import LoginForm from '../components/LoginForm';
import withAnonymousLayout from '../helpers/withAnonymousLayout';
import setUserEnv from '../helpers/setUserEnv';

const userAgentApplication = new UserAgentApplication(
  AZURE_CLIENT_ID,
  AZURE_AUTHORITY_URL,
  null,
);
const LoginPage = (props) => {
  console.log(AZURE_AUTHORITY_URL);
  const { client } = props;

  const loginAdmin = (event) => {
    event.preventDefault();

    const graphScopes = ['user.read', 'profile'];

    userAgentApplication.loginPopup(graphScopes).then(() => {
      userAgentApplication.acquireTokenSilent(graphScopes).then((accessToken) => {
        setUserEnv(accessToken, adminUserQuery);
      }, (error) => {
        console.log('acquireTokenSilent error: ', error);
        userAgentApplication.acquireTokenPopup(graphScopes).then((accessToken) => {
          setUserEnv(accessToken, adminUserQuery);
        }, (e) => {
          console.log('acquireTokenPopup error: ', e);
        });
      });
    }, (error) => {
      if (error.includes('AADSTS50105')) {
        toast('You don\'t have access to this application, contact your administrator.')
      }
      console.log('Login Admin Error: ', error);
    });
  };

  const loginEducator = async ({ email, password }) => {
    try {
      const { data } = await client.mutate({
        mutation: loginMutation,
        variables: { input: { email, password } },
      });

      if (data) {
        setUserEnv(data.login, educatorUserQuery);
      }
    } catch (error) {
      toast(getFormError(error));
    }
  };
  return (
    <>
      <Fragment>
        <h2 className="purple-headline half-width">
          Welcome back,
          <br />
          Log in to your account.
        </h2>
        <div className="form-block w-form">
          <LoginForm login={loginEducator} />
        </div>
      </Fragment>
      <button type="button" onClick={loginAdmin} className="admin-login w-button">Administrator Login</button>
    </>
  );
};

LoginPage.propTypes = {
  client: PropTypes.object.isRequired,
};

export default withAnonymousLayout(withApollo(LoginPage));
