import gql from 'graphql-tag';

export default gql`
  query adminEmails {
    adminEmails {
      id
      emailId
      name
      recipients
      subjectLine
      messageBody
      mergeTags,
      status
    }
  }
`;
