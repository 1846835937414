import gql from 'graphql-tag';

export default gql`
  query adminConfigKidsCafe {
    adminConfigKidsCafe {
      id
      configKidsCafeId
      acceptingApplications
      lastEdited
    }
  }
`;
