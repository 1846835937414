import React, { Fragment } from 'react';

const SchoolMarketAgreement = () => {
  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">Program Agreement</h4>
      <div className="agreement-div scroll">
      <h1 style={{paddingLeft: '41pt', textIndent: '71pt', lineHeight: '187%', textAlign: 'left'}}>Exhibit B: School Market (Brick and Mortar) <u>Program Description</u></h1>
        <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
          The School Market programs help alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily accessible source of food
          assistance. Brick and mortar pantries are set up like small grocery stores at a consistent location within a school’s campus with set distribution schedules. School Market welcomes middle and high schools to house the market are open to
          the community.
        </p>
        <h1 style={{paddingTop: '11pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}><u>Program Details </u>– School Programs Partner’s Responsibilities</h1>
        <ol id="l49">
          <li data-list-text={1.}>
            <p className="s23" style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>
              The School Partner <b>MUST </b>have a minimum of 2 distributions per month. If a distribution is canceled School Partner <b>MUST </b>reschedule the missing distribution within that same month, no exceptions.
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={2.}>
            <p className="s23" style={{paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
              Intake must be completed on our Link2Feed platform at the point of service. Paper forms are only used for emergencies and must be approved by your School Market Specialist.
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p className="s23" style={{paddingLeft: '59pt', textIndent: '0pt', textAlign: 'left'}}>Please review the School Market workbook page</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={3.}>
            <p className="s23" style={{paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>Food from other sources may not be mixed in with the food you receive from HFB for your Brick-and-Mortar Market.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={4.}>
            <p className="s23" style={{paddingLeft: '59pt', textIndent: '0pt', textAlign: 'left'}}>
              Distribute food to program participants in accordance with the pre-determined schedule. 5. Follow specific guidelines laid out by school and Houston Food Bank personnel.
            </p>
          </li>
        </ol>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <ol id="l50">
          <li data-list-text={6.}>
            <p className="s23" style={{paddingLeft: '70pt', textIndent: '-11pt', textAlign: 'left'}}>Identify a School Market Coordinator to be the primary contact for the School Market Program.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={7.}>
            <p className="s23" style={{paddingLeft: '70pt', textIndent: '-11pt', textAlign: 'left'}}>Receive deliveries on the designated day and time agreed upon by both parties.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={8.}>
            <p className="s23" style={{paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Provide student volunteers to conduct intake/record visits of School Market clients in Link2Feed platform.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={9.}>
            <p className="s23" style={{paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'justify'}}>
              Once the MOU has been established, HFB will provide access to the reporting portal that provides School Programs data, using the School Programs unique ID, which can be checked at any time for data information, utilization, and
              other report data points.
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={10.}>
            <p className="s23" style={{paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
              I understand that the items received cannot be sold, used for other school programs, used for fundraisers, or used for any other purpose other than to provide for the neighbors you are serving. Failure to comply with the program
              requirements will result in the loss of the School Market Program.
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={11.}>
            <p style={{paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>
              <a href="mailto:schoolmarket@houstonfoodbank.org" style={{color: '#221f1f', fontFamily: 'Arial, sans-serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '10pt'}} target="_blank">
                For any questions that you may have about the program, please contact the School Market staff at
              </a>
              <a href="mailto:schoolmarket@houstonfoodbank.org" className="s25" target="_blank">schoolmarket@houstonfoodbank.org</a><a href="mailto:schoolmarket@houstonfoodbank.org" className="s26" target="_blank">.</a>
            </p>
          </li>
        </ol>
        <h1 style={{paddingLeft: '41pt', textIndent: '107pt', lineHeight: '187%', textAlign: 'left'}}>Exhibit C: School Market (Mobile Distribution) <u>Program Description</u></h1>
        <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
          The School Market programs help alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily accessible source of food
          assistance. Mobile distributions operate through HFB’s large, refrigerated trailers, with a set distribution schedule. School Market welcomes middle and high schools to house the market in. Markets are open to the community.
        </p>
        <h1 style={{paddingTop: '11pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}><u>Program Details </u>– School Programs Partner’s Responsibilities</h1>
        <ol id="l51">
          <li data-list-text={1.}>
            <p style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>
              HFB <b>requires a partner to </b>have a minimum of 2 distributions per month. If a distribution is canceled (within 48 hours of the distribution), the school partner <b>is responsible for </b>rescheduling the canceled
              distribution within that same month of the cancelled distribution, no exceptions.
            </p>
            <ol id="l52">
              <li data-list-text="a.">
                <p style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-17pt', lineHeight: '109%', textAlign: 'left'}}>
                  Changes to the Distribution Schedule must be made in writing and sent via email to their liaison at least one (1) week in advance of the distribution.
                </p>
              </li>
              <li data-list-text="b.">
                <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '110%', textAlign: 'left'}}>Cancellations due to extreme weather, equipment failure, or natural disasters may be made as needed.</p>
              </li>
              <li data-list-text="c.">
                <p style={{paddingTop: '12pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                  Request cancellations of School Market (Brick and Mortar) distributions by notifying HFB via email at least <u>48 hours prior to the distribution</u>.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text={2.}>
            <p style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'justify'}}>
              Intake must be completed on our Link2Feed platform at the point of service. Paper forms are only used for emergencies and must be approved by your School Market Specialist. Please review the School Market guidebook page
            </p>
          </li>
          <li data-list-text={3.}>
            <p style={{paddingTop: '12pt', paddingLeft: '71pt', textIndent: '-12pt', textAlign: 'left'}}>Receive the mobile truck on the designated day and time agreed upon by both parties.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={4.}>
            <p style={{paddingLeft: '71pt', textIndent: '-12pt', textAlign: 'left'}}>Mobile distributions will adhere to 3 hours per distribution.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={5.}>
            <p style={{paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
              Conduct temperature monitoring and safe keeping of perishable foods (i.e., mobile doors kept closed, removing a limited amount of perishable product from the mobile so it doesn’t sit out too long).
            </p>
          </li>
        </ol>
        <ol id="l53">
          <li data-list-text={7.}><p style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Identify a School Market Coordinator to be the primary contact for the School Market Program.</p></li>
          <li data-list-text={8.}><p style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Provide student volunteers to conduct intake/record visit of School Market clients in Link2Feed platform.</p></li>
          <li data-list-text={9.}>
            <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-17pt', lineHeight: '109%', textAlign: 'left'}}>
              Provide access to at least 8 to 10 parking spots for parking in the mobile market and to allow for set-up and preparation for the distribution.
            </p>
          </li>
          <li data-list-text={10.}>
            <p style={{paddingLeft: '78pt', textIndent: '-18pt', textAlign: 'left'}}>Provide a safe space for eligible individuals without a vehicle to access the market.</p>
            <p style={{paddingTop: '2pt', textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={11.}>
            <p style={{paddingLeft: '78pt', textIndent: '-18pt', textAlign: 'left'}}>Provide access to sufficient on-site trash receptacles and coordinate all site cleanup.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text={12.}>
            <p style={{paddingLeft: '77pt', textIndent: '-17pt', lineHeight: '110%', textAlign: 'left'}}>
              Once the MOU has been established, HFB will provide access to the reporting portal that provides School Programs data, using the School Programs unique ID, which can be checked at any time for data information, utilization, and
              other report data points.
            </p>
          </li>
          <li data-list-text={13.}>
            <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-17pt', lineHeight: '110%', textAlign: 'left'}}>
              I understand that the items received cannot be sold, used for other school programs, used for fundraisers, or used for any other purpose other than to provide for the
            </p>
            <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '0pt', lineHeight: '146%', textAlign: 'left'}}>
              neighbors you are serving. Failure to comply with the program requirements will result in the loss of the School Market Program at School Partner.
            </p>
          </li>
          <li data-list-text={14.}>
            <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>
              <a href="mailto:schoolmarket@houstonfoodbank.org" style={{color: '#221f1f', fontFamily: 'Arial, sans-serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '11pt'}} target="_blank">
                For any questions that you may have about the program, please contact the School Market staff at
              </a>
              <a href="mailto:schoolmarket@houstonfoodbank.org" className="s13" target="_blank">schoolmarket@houstonfoodbank.org</a><a href="mailto:schoolmarket@houstonfoodbank.org" className="s18" target="_blank">.</a>
            </p>
          </li>
        </ol>
      </div>
    </Fragment>
  );
};

export default SchoolMarketAgreement;
