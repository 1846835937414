import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import withSchoolLinks from '../helpers/withSchoolLinks';
import { surveysQuery } from '../apollo';
import SurveyTable from '../components/SurveyTable';

const EducatorSurveysPage = ({ selectedSchool }) => {
  if (!selectedSchool) return <Redirect to="/dashboard" />;

  return (
    <div className="wrapper document">
      <h2>Surveys</h2>
      <Query
        query={surveysQuery}
        variables={{ schoolId: selectedSchool }}
      >
        {({ loading, error, data: { surveys } }) => {
          if (loading) return <Loading />;
          if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
          if (!surveys.length) return <BlankState title="No Results" />;

          return (
            <div className="table-wrapper-2">
              <SurveyTable surveys={surveys} />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

EducatorSurveysPage.propTypes = {
  selectedSchool: PropTypes.string.isRequired,
};

export default withSchoolLinks(EducatorSurveysPage);
