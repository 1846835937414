import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import ApplicationKidsCafe from '../components/ApplicationKidsCafe';
import EducatorApplicationStartPage from '../pages/EducatorApplicationStartPage';
import EducatorAccountPage from '../pages/EducatorAccountPage';

const KidsCafeEducatorRoutes = ({ match, user }) => {
  const { applicationKidsCafeRequired } = user;
  if (applicationKidsCafeRequired) {
    return (
      <Switch>
        <AuthRoute path={`${match.path}/start`} component={EducatorApplicationStartPage} />
        <AuthRoute path={`${match.path}/application/:step?/:id?`} component={ApplicationKidsCafe} />
        <Route component={() => <Redirect to={`${match.path}/application`} />} />
      </Switch>
    );
  }

  return (
    <Fragment>
      <Switch>
        <AuthRoute path={`${match.path}/account`} component={EducatorAccountPage} />
        <AuthRoute path={`${match.path}/application/:step?/:id?`} component={ApplicationKidsCafe} />
        <Route component={() => <Redirect to={`${match.path}/account/applications`} />} />
      </Switch>
    </Fragment>
  );
};

KidsCafeEducatorRoutes.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default KidsCafeEducatorRoutes;
