import React, { Fragment } from 'react';

const GeneralAgreement = (props) => {
  const { data } = props;

  const year = new Date().getFullYear() - 1;
  const isCont = data.applicationBackpackBuddyId && data.school.applicationsBackpackBuddy ?
  data.school.applicationsBackpackBuddy.find(el => el.submittedAt && el.submittedAt.includes(year.toString()) && data.isContinuation && el.status == "APPROVED"):
  data.applicationTeachersAidId && data.school.applicationsTeachersAid ?
  data.school.applicationsTeachersAid.find(el => el.submittedAt && el.submittedAt.includes(year.toString()) && data.isContinuation && el.status == "APPROVED"):
  data.applicationSchoolMarketId && data.school.applicationsSchoolMarket ?
  data.school.applicationsSchoolMarket.find(el => el.submittedAt && el.submittedAt.includes(year.toString()) && data.isContinuation && el.status == "APPROVED"):
    false

  const replaceDate = data.submittedAt ? new Date(data.submittedAt).toLocaleDateString('en-US') :
  isCont ? new Date(isCont.submittedAt).toLocaleDateString('en-US'): false
  return (
    data.school && <Fragment>
      <h4 className="purple-headline boxed-in">The Agreement</h4>
      <p>
        This document is an agreement made between Houston Food Bank’s Teachers Aid and your school,
        that by participating in the school supply program, the school agrees to the following:
      </p>
      <div className="agreement-div scroll">
        <h5 className="black-headline center">SCHOOL PROGRAMS MEMORANDUM OF UNDERSTANDING</h5>
        <p>
        This School Programs Memorandum of Understanding (“MOU”) dated {replaceDate ? replaceDate:(new Date()).toLocaleDateString('en-US')}, 
        is entered into by and between Houston Food Bank, a 501(c)(3) non-profit organization, with its principal place
         of business at 535 Portwall Street, Houston, Texas 77029 (“HFB”) and {data.school.name}, 
         {data.organizationType? " a "+data.organizationType+",":""} with its principal place of business at {data.school.address1}, {data.school.city},
        Texas {data.school.zip} (“School Programs Partner”) (each a “Party,” and collectively, the “Parties”).
        </p>
        <h5 className="black-headline center">NON-PROFIT ORGANIZATIONS</h5>
        <p>
          If you are a 501(c)(3) organization, please list the schools and/or school district (including address), 
          you are partnered with below. If you are a school, please skip this section.
        </p>
        <p>
          {data.nonProfitOrganizations}
        </p>
        <h5 className="black-headline center">BACKGROUND</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">1.</span>
              HFB's School Programs is a program with a series of pillars developed by the HFB to address the root causes of food insecurity in children in the Greater Houston area.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">2.</span>
              HFB actively seeks out educational institutions that focus their services in the areas of health initiatives and economic initiatives and who want to offer HFB’s School Programs to their students to help them improve their overall quality of life.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">3.</span>
              HFB's School Programs-related programming includes Backpack Buddy; Backpack Buddy at Home; School Market (Brick and Mortar); School Market (Mobile Distribution); Teachers Aid; and The Locker Project.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">4.</span>
              School Programs Partner is an educational institution that wants to establish and operate certain HFBs School Programs.
            </p>
            <p>Accordingly, the Parties agree as follows:</p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">d.</span>
                Inform staff that only Principals, Assistant Principals, Counselors,
                CIS representatives, and Teachers can participate (Substitute Teachers,
                Aids, Paraprofessionals, Cafeteria and Janitorial Staff are not
                able to participate)
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">f.</span>
                Consent to Client Data Privacy Statement
            </p>
          </li>
        </ul>
        <h5 className="black-headline center">Eligibility</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">1.</span>
              Have 501(c)3 federal tax-exempt status as determined and documented by the Internal Revenue Service (this does not apply to schools and/or school districts). If the agency falls under the governance of another organization, their 501(c)3 (for non-school partners) must be provided along with a letter from the organization stating sponsorship of the second organization. A check of the partner’s exempt status will be verified annually as part of partnership renewal. Partners must notify their School Specialist immediately if this status has been revoked.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">2.</span>
              Have 501(c)3 federal tax-exempt status as determined and documented by the Internal Revenue Service (this does not apply to schools and/or school districts). If the agency falls under the governance of another organization, their 501(c)3 (for non-school partners) must be provided along with a letter from the organization stating sponsorship of the second organization. A check of the partner’s exempt status will be verified annually as part of partnership renewal. Partners must notify their School Specialist immediately if this status has been revoked.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">3.</span>
              Have 501(c)3 federal tax-exempt status as determined and documented by the Internal Revenue Service (this does not apply to schools and/or school districts). If the agency falls under the governance of another organization, their 501(c)3 (for non-school partners) must be provided along with a letter from the organization stating sponsorship of the second organization. A check of the partner’s exempt status will be verified annually as part of partnership renewal. Partners must notify their School Specialist immediately if this status has been revoked.
            </p>
          </li>
        </ul>
        <h5 className="black-headline center">Partner Operations and Service Requirements</h5>
        <p><i>Appropriate Handling and Distribution of Food and Non-Food Product Received from HFB</i></p>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">4.</span>
              Distribute the food received from HFB to low-income individuals, as defined by the TEFAP guidelines. Requirement of a social security card, driver’s license, or any documentation related to citizenship to receive food is prohibited.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">5.</span>
              Ensure that food and non-food products received from HFB will not be: sold, bartered, exchanged for monetary donations, fundraising, volunteer services, property, or votes for political interest, used for personal use or community events, transferred out of HFB service area, or allowed to re-enter commercial channels.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">6.</span>
              Ensure that all food and non-food received from HFB (School Markets, Backpack Program, Locker Project and Teachers Aid will not be shared with organizations not affiliated with or approved by HFB.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">7.</span>
              Ensure that eligible clients are not refused food assistance. The Partner also agrees that it will comply with the restrictions on the use and transfer of donated property, as described in IRS Tax Code Section 170(e)(3) and any amendments to the Code (See the Federal Register/Vol. 47, No. 21/Monday, February 1982/Rules and Regulations, pp.4509-4512).
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">8.</span>
              Notify HFB immediately in case of damage, loss, or theft of product.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">9.</span>
              Agree to accept all food and other items received from or through HFB “as is,” and agree to adhere to additional donor stipulations, as requested.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">10.</span>
              Affirm that the original donor, HFB, and Feeding America are:
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">a.</span>
              Released by the School Partner from any liabilities resulting from the donated product.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">b.</span>
              Held harmless from any claims or obligations regarding the School Partner or the donated product;
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">c.</span>
              Offering no express warranties in relation to the product.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">d.</span>
              Distribute food in accordance with the U.S. Department of Agriculture and Texas Health and Human Services Commission non-discrimination statement included at the end of this agreement.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <span className="line-item">e.</span>
              As a 501(c) (3) non-profit, and a TDA Contracting Entity, HFB must abide by numerous rules to comply with its tax-exempt status. Partners may not engage in explicitly religious or political activities as part of food distribution.
            </p>
          </li>
          <li className="agreement-list-item subsub">
            <p>
              <span className="line-item">1.</span>
              Religious activities may not include overt religious content such as worship, religious instruction, or proselytization. Sites may not discriminate against participants based on religion or religious belief. (See the Texas Department of Agriculture’s Written Notice of Beneficiary Rights) An explicitly religious activity at a site must be separated in time or location from food distribution.
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">a.</span>
              Organizations must not require or have any expectation for participants or applicants to participate in explicitly religious activities to receive food.
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">b.</span>
              HFB School Programs prohibits distributions from occurring on days when religious services occur, and HFB is closed (e.g. Sundays).
            </p>
          </li>
          <li className="agreement-list-item subsub">
            <p>
              <span className="line-item">2.</span>
              Partners may not engage in electioneering by publicly endorsing or appear to support or oppose elected officials or political parties, even if the former are running unopposed or are not currently engaged in an election.
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">a.</span>
              There cannot be any mention of an elected official’s candidacy or the election in connection with an event.
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">b.</span>
              All written materials regarding an event with an elected official, political party, or political party organization must include the following disclaimer: “The Houston Food Bank is a 501(c) (3) and does not support or oppose any political party or candidate running for public office.”
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">c.</span>
              No pictures or social media material from the event can be used for a political campaign.
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">d.</span>
              No events can be held with a PAC (Political Action Committee)
            </p>
          </li>
          <li className="agreement-list-item subsub">
            <p>
              <span className="line-item">f.</span>
              Ensure that each staff person and volunteer interacting with clients receive Civil Rights training according to the Texas Department of Agriculture and know how to respond to a request to file a civil rights complaint. This training will be completed before they begin to work and on an annual basis. The signatures of those participating in the training must be maintained on a training log and provided to HFB during monitoring reviews.
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">1.</span>
              I authenticate that all persons involved in food distribution, or direct interaction with clients have completed the civil rights course located at https://squaremeals.org/FandNResources/Training/CivilRights.aspx .
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">2.</span>
              I authenticate that the civil rights poster (“And Justice for All” found here: https://www.fns.usda.gov/cr/and-justice-all-posters-guidance-and-translations , TEFAP Participant Rights and Responsibilities, TEFAP Written Notice of Beneficiary Rights, TEFAP Client Bill of Rights and TEFAP Pantry Bill of Rights in English and Spanish have been posted in full view of all applicants/clients entering our agency. Please contact School Programs if you require another translation of these forms for your client population.
            </p>
          </li>
          <li className="agreement-list-item subsubsub">
            <p>
              <span className="line-item">3.</span>
              Grant clients the right to contact the partner agency governing board, HFB and the USDA if they have a grievance against your agency. You may reference the Grievance Policy that is found in the enrolled programs guidebook.
            </p>
          </li>
        </ul>
        <h5 className="black-headline center">ARTICLE 1: SCHOOL PROGRAMS</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">1.1</span>
              <b>Selected School Programs.</b> School Programs Partner wants to participate in the following HFB School Programs. (Initial School Programs in which School Programs Partner will participate.)
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <b>_______Exhibit A. Backpack Buddy.</b> Backpack Buddy is a program that helps alleviate child hunger by closing the weekend gap for chronically hungry children. Better known as children who face food insecurity or a household crisis. Every Friday, students participating in this program will receive a nutritious, children-friendly food sack to take home and be back to school on Monday, eager and ready to learn. Backpack Buddy welcomes elementary, middle, and high schools to participate.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <b>_______Exhibit B. School Market (Brick and Mortar).</b> The School Market programs helps alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily accessible source of food assistance. Brick and mortar pantries are set up like small grocery stores at a consistent location within a schools’ campus, with set distribution schedules. School Market welcome middle and high schools to house the market in, markets are open to the community.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <b>_______Exhibit C. School Market (Mobile Distribution).</b> The School Market programs helps alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily accessible source of food assistance. Mobile distributions operate through HFB’s large, refrigerated trailers, with a set distribution schedule. School Market welcome middle and high schools to house the market in, markets are open to the community.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <b>_______Exhibit D. Teachers Aid.</b> Many children can’t afford the most basic school supplies. Without the proper tools to do the work, children are challenged to achieve academic success. Many compassionate teaches pay for school supplies from their own pockets so their students have more productive, efficient learning experience. The Teachers Aid program provides the supplies kids need to succeed. The program is open to teachers in Title 1 schools with at least 60% of the student population on the free and reduced-price school lunch program.
            </p>
          </li>
          <li className="agreement-list-item sub">
            <p>
              <b>_______Exhibit E. The Locker Project.</b> This project was created to target families that need food assistance, but their child might not want a Backpack Buddy bag, or the parents cannot attend a pantry to distribution due to working late. The Locker system has 24-hour refrigerated access, allowing families to pick up food conveniently. The program will enable families to shop bi-weekly on the Order Ahead platform for items they would like delivered. The Locker Project welcomes middle and high schools to participate.
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">1.2</span>
              <b>Selected School Programs.</b> School Programs Partner wants to participate in the following HFB School Programs. (Initial School Programs in which School Programs Partner will participate.)
            </p>
          </li>
        </ul>
        <h5 className="black-headline center">ARTICLE 2: TERM</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">2.1</span>
              <b>Term.</b> This MOU becomes effective on the date that the final signatory executes this MOU and ends on June 30, 2024, unless earlier terminated by the Parties in accordance with this MOU (“Term”). <b>School Market (mobile and brick and mortar) and the Locker Project will have a 2-year term.</b>
            </p>
          </li>
          <li className="agreement-list-item">
            <p>
              <span className="line-item">2.2</span>
              <b>Renewal.</b> This MOU will terminate after your identified termination date. If either Party provides the other Party with 30 (thirty) days advance written notice of the Party’s intention, not to continue the program.
            </p>
          </li>
        </ul>
        <h5 className="black-headline center">ARTICLE 3: DUTIES OF THE PARTIES</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">3.1</span>
              <b>Duties of HFB.</b> During the Term of this MOU, HFB shall:
            </p>
            <ul className="agreement-text w-list-unstyled">
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.1</span> Conduct yearly School Programs training.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.2</span> Provide nutritious foods to School Programs Partner at no cost to School Programs Partner to operate the HFB School Programs initialed in Section 1.1; OR Provide School Programs Partner with a list of and maps of School Programs sites where recipients can receive nutritious foods (perishable and non-perishable); If applicable.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.3</span> Provide School Programs Partner with training and access to Program Management Applications site (PMA), Claromentis, Link2Feed, OrderAhead, and NetSuite SCA ordering platform for program food acquisition. Required training will be provided for relevant platform(s) adjacent to the program(s) selected above.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.4</span> Conduct regular inspections of perishable and non-perishable food at School Programs sites to ensure the quality of such foods and comply with local health- safety codes for food;</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.5</span> Provide School Programs Partner with a copy of HFB’s Food Quality Standards which are attached hereto and incorporated herein as Appendix A;</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.6</span> Provide School Programs Partner with a non-exclusive, non-transferable license to use PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA ordering platform if applicable, and:</p></li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">A.</span>
                  Assist School Programs Partner with PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA at Partner’s site;
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">B.</span>
                  Assign initial logins to School Programs Partner’s staff to use Claromentis, Link2Feed, OrderAhead, and NetSuite SCA;
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">C.</span>
                  Provide School Programs Partner with on-going support for PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA; and
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">D.</span>
                  Upon School Programs Partner’s request, conduct PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA training for and assign logins to School Programs Partner’s new staff hired during the Term.
                </p>
              </li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.7</span> Provide School Programs Partner trainings, className materials, equipment and supplies, needed to operate the HFB School Programs initialed in Section 1.1;</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.8</span> Conduct virtual and/or in-person check-ins with School Programs Partner on a regular basis during the Term (“Regular Check-ins”). Regular Check-ins during the initial Term will occur at least once each quarter through the end of the Term and each subsequent renewal team.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.9</span> Conduct in-person compliance check-ins (“Compliance Check-ins”) to observe School Programs Partner’s marketing and intake procedures of and compliance with HFB’s School Programs. HFB, alone, will determine whether to conduct a second or third Compliance Check-in at School Programs Partner. HFB and School Programs Partner will mutually agree upon the date and time for each Compliance Check-in. Unless otherwise determined by the Parties, Compliance Check-ins will only be conducted during School Programs Partner’s regular business hours.</p></li>
              <li className="agreement-list-item subsub"><p><span className="line-item">a.</span> HFB Liaisons will coordinate and facilitate Compliance Check-ins for shared School Programs Partners.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.10</span> Host visits about School Programs (“Visits”).</p></li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item subsub">a.</span>
                  Visits are usually requested by media outlets and are attended by media personnel to report about School Programs and School Programs Partners’ successes.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">b.</span>
                  Visits may be unannounced, but HFB will not use observations and information obtained during a visit in lieu of a Compliance Check-in.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">c.</span>
                  If HFB receives advance notice of a visit request, HFB shall promptly notify School Programs Partner about the upcoming visit.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">d.</span>
                  Whenever possible, HFB liaisons will coordinate and facilitate visits.
                </p>
              </li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.11</span> Upon request, provide nutrition education and support through HFB’s Health Promotion/Nutrition Education Team.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.1.12</span> Provide School Programs Partner an annual impact report card no later than the end of HFB’s fiscal year.</p></li>
        
            </ul>
          </li>
        </ul>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item">
            <p>
              <span className="line-item">3.2</span>
              <b>Duties of School Programs Partner.</b> During the Term of this MOU, School Programs Partner shall:
            </p>
            <ul className="agreement-text w-list-unstyled">
              <li className="agreement-list-item sub"><p><span className="line-item">3.2.1</span> At least one School Programs Partner representative will need to receive yearly food safety training. The HFB will provide the training to the School Programs Partner site coordinator via in-person training.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.2.2</span> Promptly notify School Programs team via Claromentis Help Desk Ticket or email of significant personnel changes that might affect School Programs Partner’s operation of the School Programs and of new staff members who require training on the School Programs software systems.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.2.3</span> Operate the School Programs selected by School Programs Partner and do so in compliance with the School Programs criteria by:</p></li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">a.</span>
                  Identifying eligible students and/or sites for School Programs. Please refer to each School Programs external handbook;
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">b.</span>
                  Informing eligible students and their parents/guardians of their eligibility to participate in the School Programs by utilizing program description/script provided during onboarding training. Please refer to each School Programs external handbook;
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">c.</span>
                  Enrolling eligible students (“Neighbors”) into the appropriate School Programs;
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">d.</span>
                  We collect data from partners and clients for the following reasons: 1) To improve our programs; 2) to do research; and 3) to connect clients with other programs.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">e.</span>
                  HFB does not report individual/client level data to the government. Furthermore, HFB will not disclose personal data unless court ordered or compelled by law. Also, we do not ask for nor record immigration status.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">f.</span>
                  Link2Feed and Claromentis is HFB’s preferred client intake and reporting platform. For information on Link2Feed client and pantry data security, see http://Link2Feed.com/security-features/
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">g.</span>
                  Have School Programs Partner available for an on-site monitoring visit at least once a year from the HFB staff or the HFB certified volunteers;
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">h.</span>
                  If School Programs Partner is contacted by any form of media to discuss School Programs, please get in touch with the School Programs staff before granting any interviews.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">i.</span>
                  Educating Neighbors about ways to measure and assure the quality of perishable and non-perishable food based on guidelines included in Appendix A;
                </p>
              </li>  
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">j.</span> 
                  Display the “And Justice for All” poster in the food distribution location;
                </p>    
              </li>  
              <li className="agreement-list-item subsub">
                <p> 
                  <span className="line-item">k.</span> 
                  Display the most recent “TEFAP Eligibility Guidelines” poster;
                </p>    
              </li>  
              <li className="agreement-list-item subsub">
                <p> 
                  <span className="line-item">l.</span> 
                  All food must be stored in a secure, sanitary, and temperature-controlled place away from cleaning materials and toxic chemicals;
                </p>    
              </li>  
              <li className="agreement-list-item subsub">
                <p> 
                  <span className="line-item">m.</span> 
                  All food must be stored 6 inches off the floor, 6 inches away from the wall, and 6 inches below the ceiling.
                </p>    
              </li>  
              <li className="agreement-list-item subsub">
                <p> 
                  <span className="line-item">n.</span> 
                  Actively participating in all Regular Check-ins and Compliance Check-ins that are conducted by HFB during the Term of this MOU for purposes of reviewing the progress of the School Programs at Partner site.
                </p>    
              </li>  
              <li className="agreement-list-item sub"><p><span className="line-item">3.2.4</span> Schools Program Memorandum of Understanding must be submitted yearly by the School Programs Partner with updated contact information. This is for School Programs Partners on Backpack Buddy, Backpack Buddy at Home, School Market (mobile Distribution), Teachers Aid, and The Locker Project.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.2.5</span> As a Houston Food Bank Partner, your school agrees to abide by the HFB co- branding policy outlined in this MOU and will incorporate the HFB logo with your organization’s logo in printed and digital marketing assets where appropriate including social media, signage, and/or media mentions. Include link to Co- branding policy.</p></li>            
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">a.</span>
                  Agency is responsible for ensuring exterior/interior signage co-branding signage is installed and maintained throughout duration of partnership.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">b.</span>
                  Upon termination or end of partner agreement/MOU, all signage, flyers, website, social media, and/or media mentions with HFB logo should be removed or discontinued no later than 15 days from the end of agreement. All original signage shall be returned to HFB staff.
                </p>
              </li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.2.6</span> School Programs Memorandum of Understanding must be submitted every year (Backpack program and School Market - Mobiles) and every two years (School Market - Brick and Mortar and The Locker Project) by the School Programs Partner.</p></li>            
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">a.</span>
                  Inform School Programs of any changes in contact names, addresses, phone numbers, services provided, days and hours of operation/distribution, and provide any other relevant information by using the “Partner Information Change Form (Form F)” in the Partner Agency Guidebook.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">b.</span>
                  If the Partner is relocating, request prior approval from School Programs for the new distribution site that will be storing and distributing HFB products.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">c.</span>
                  Agree to only receive and store product at multiple locations if:
                </p>
              </li>
              <li className="agreement-list-item subsubsub">
                <p>
                  <span className="line-item">i.</span>
                  All locations individually meet the requirements of this agreement.
                </p>
              </li>
              <li className="agreement-list-item subsubsub">
                <p>
                  <span className="line-item">ii.</span>
                  Have been inspected by School Programs Specialist.
                </p>
              </li>
              <li className="agreement-list-item subsubsub">
                <p>
                  <span className="line-item">iii.</span>
                  Approved by HFB prior to receiving food.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">d.</span>
                  School Programs will make an effort, when called on, to participate in the following activities: Advocacy, Media Event, Research, Surveys, Focus Groups, School Programs Conference and School Programs Learning Sessions.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">e.</span>
                  Recognize and acknowledge that the receipt of TDA commodities must be identified as a subaward, and the partner is the Subrecipient.
                </p>
              </li>   
              <li className="agreement-list-item"><p>Name of Federal awarding agency – U.S. Department of Agriculture Federal award project description – Emergency Food Assistance Program – Food Commodities CFDA number – 10.569 Contract number - 101-0452K9 Contract Year/Federal Award date – 10/1/2020 - 9/30/2021, 10/1/2021 - 9/30/2022 In any fiscal year in which the Partner expends $750,000 or more in federal awards during such fiscal year, including awards received as a subrecipient, subrecipient must comply with the federal audit requirements contained in the Uniform Guidance, [45 CFR Part 75], including the preparation of an audit by an independent Certified Public Accountant in accordance with the Single Audit Act Amendments of 1996, 31 U.S.C. 7501-7507, and with Generally Accepted Accounting Principles, and submit the Single Audit report applicable (including financial statements, schedule of expenditures of federal awards, schedule of findings and questioned costs, summary of prior audit findings, and corrective action plan, if applicable), and management letter within nine (9) months following the end of subrecipient’s fiscal year.</p></li>                     
              <li className="agreement-list-item sub">
                <p>
                  <span className="line-item">a.</span>
                  Partner accounts will become inactive if an order (School Market, Backpack and Locker Project) is not placed a minimum of every 3 months. If the account becomes inactive, the School Partner is required to reapply for membership and adhere to the guidelines of a new School Partner.
                </p>
              </li>
              <li className="agreement-list-item sub">
                <p>
                  <span className="line-item">b.</span>
                  Partner account termination will result in the termination of all School Program privileges.
                </p>
              </li>
              <li className="agreement-list-item sub">
                <p>
                  <span className="line-item">c.</span>
                  The School Programs Team largely works remotely and are rarely on-site at HFB. Therefore, Partners are required to make appointments and pre-schedule meetings with their School Specialist, or other HFB staff. The School Programs Team, or HFB, can’t guarantee a meeting can occur if the partner requires an unplanned meeting or discussion.
                </p>
              </li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.2.7</span> Communicate problems and requests to the HFB in a timely manner.</p></li>
              <li className="agreement-list-item"><p><b>EITHER PARTY MAY TERMINATE THIS AGREEMENT, WITH OR WITHOUT CASE, UPON 30 DAYS WRITTEN NOTICE. FAILURE TO COMPLY WITH ANY OF THE PROVISIONS ABOVE OR INCLUDED IN THE PARTNER AGENCY GUIDEBOOK, WHICH IS INCORPORATED HEREIN BY REFERENCE, CAN RESULT IN ACTIONS UP TO AND INCLUDING SUSPENSION OR TERMINATION OF PARTNERSHIP. (SEE ESCALATION PROCESS IN THE GUIDEBOOK.)</b></p></li>                     
              <li className="agreement-list-item"><p><span className="line-item">3.3</span><b>Duties of Both Parties.</b> During the Term, the Parties shall</p></li>                     
              <li className="agreement-list-item sub"><p><span className="line-item">3.3.1</span>Comply with:</p></li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">a.</span>
                  All applicable local, state, and federal statues, ordinances and regulations including but not limited to, the safe and proper handling of food and distribution of food, as amended from time to time without notice; and
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">b.</span>
                  Willingness to abide by the policies, procedures, and record keeping requirements of the HFB;
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">c.</span>
                  School Programs Partner staff and volunteers will not engage in discrimination, in provision of services, against any person because of race, color, citizenship,religion, gender, national origin, ancestry, age, marital status, disability, sexual orientation including gender identity or expression, unfavorable discharge from military, or status as a protected veteran.
                </p>
              </li>
              <li className="agreement-list-item subsub">
                <p>
                  <span className="line-item">d.</span>
                  Distribute healthy, nutritious food to participating students/families free of charge.
                </p>
              </li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.3.2</span>Observe and identify strengths and opportunities related to recipient engagement, resources utilization, and overall, School Programs impact.</p></li>
              <li className="agreement-list-item sub"><p><span className="line-item">3.3.3</span>Jointly develop standard School Programs messaging, including any applicable co-branding messaging, that can be used by and prominently displayed at HFB and at School Programs Partner’s designated Recipients’ enrollment locations.</p></li>
            </ul>
          </li>
        </ul>
        <h5 className="black-headline center">ARTICLE 4: TERMINATION OF THIS MOU</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item"><p><span className="line-item">4.1</span><b>Termination Without Cause.</b> Either Party may terminate this MOU at any time without cause by providing the other Party with thirty (30) days advance written notice of its intent to terminate.</p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">4.2</span><b>Termination For Cause.</b> HFB may terminate this MOU “for Cause” by providing School Programs Partner with ten (10) days advance written notice of HFB’s intent to terminate this MOU. For purposes of this MOU, “for Cause” means School Programs Partner:</p></li>                     
          <li className="agreement-list-item sub"><p><span className="line-item">4.2.1</span>Breaches any material term or condition of this MOU; or</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.2.2</span>Fails to manage, administer, or operate School Programs in accordance with this MOU; or</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.2.3</span>Refuses to enroll and/or provide services to an individual who is qualified to participate in School Programs; or</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.2.4</span>Fails to timely enter or submit enrollment and/or outcomes data to HFB; or</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.2.5</span>Is rude or inappropriate either with individuals participating in an HFB School Programs through School Programs Partner or with HFB staff; or</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.2.6</span>Fails to actively participate in HFB’s Regular Check-ins or in Compliance Check- ins at School Programs Partner; or</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.2.7</span>Fails to actively participate in HFB’s annual evaluation of School Programs Partner.</p></li>
          <li className="agreement-list-item"><p><span className="line-item">4.3</span><b>Non-Renewal By HFB.</b> HFB may elect not to renew this MOU by providing School Programs Partner with advance written notice of HFB’s intent not to renew this MOU on or before May 1 of the then-current Term.</p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">4.4</span><b>Effects of Termination or Non-Renewal.</b></p></li>                     
          <li className="agreement-list-item sub"><p><span className="line-item">4.4.1</span>Return of Information. Within thirty (30) days after the termination or non-renewal of this MOU, School Programs Partner shall return to HFB all documents, materials, and information about or related to HFB’s School Programs including any copies and derivative materials.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.4.2</span>Return of School Programs Software Systems. Within thirty (30) days after the termination or non-renewal of this MOU, School Programs Partner shall cease and desist all use of PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA and shall return to HFB all full or partial copies PMA, Claromentis, Link2Feed, OrderAhead, and NetSuite SCA in School Programs Partner’s possession or under School Programs Partner’s control.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.4.3</span>Return of Property. Within thirty (30) days after the termination or non-renewal of this MOU, School Programs Partner shall return to HFB all equipment related to HFB’s School Programs including but not limited to electronic tablets, laptops, mi- fi’s, infrastructure equipment, co-branding signage and market supplies.</p></li>
        </ul>
        <h5 className="black-headline center">ARTICLE 5: CONFIDENTIALITY AND DATA SHARING</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item"><p><span className="line-item">5.1</span><b>Confidentiality.</b> During the Term and thereafter, each Party shall hold the other Party's Confidential Information in strictest confidence and (a) shall not use the other Party's Confidential Information for any purpose other than as expressly contemplated by this MOU; and (b) shall not disclose, or cause or permit the other Party's Confidential Information to be disclosed; and (c) shall notify the other Party of any disclosure, misuse, or misappropriation of the other Party's Confidential Information of which the Party becomes aware.</p></li>                     
          <li className="agreement-list-item sub"><p><span className="line-item">5.1.1</span>Notwithstanding Section 5.1, a Party may disclose the other Party's Confidential Information in response to a lawful order by a court or other government body provided that prior to such disclosure the Party notifies the other Party so that the other Party may seek confidential treatment of the Party's Confidential Information by the court or government body or a protective order (or equivalent) with respect to such disclosure.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.1.2</span>Further, a Party may disclose the other Party’s Confidential Information to a third party for purposes of conducting research and/or program evaluation so long as individually identifiable information is de-identified or removed in its entirety from the Confidential Information prior to disclosing such Confidential Information for research and/or program evaluation</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.1.3</span><b>Confidential Information.</b> "Confidential Information" means non-public information belonging to or in the possession of one Party and includes, but is not limited to the terms of this MOU, personal and health information of individuals enrolled in a HFB School Programs including individuals’ on-going health metrics that are collected by School Programs Partner and shared with HFB (“Participant Personal Information,” or “PPI”), confidential and proprietary data, whether or not designated or marked "confidential," materials, products, technology, manuals, business and marketing plans, financial information, and any other information disclosed or submitted, orally, in writing, or by any other media.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.1.4</span><b>What Confidential Information Does Not Include.</b> Confidential Information does not include (a) information that is available in the public domain; (b) is known to the other Party prior to its disclosure to that Party by the Party possessing the information; or (c) is received by the other Party from a third party not bound by a confidentiality agreement with the Party possessing the information.</p></li>
          <li className="agreement-list-item"><p><span className="line-item">5.2</span><b>Using and Sharing Participant Personal Information (“PPI”).</b> </p></li>                     
          <li className="agreement-list-item sub"><p><span className="line-item">5.2.1</span><b>HFB’s Use of PPI.</b> During the Term, HFB shall:</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">a.</span> Use PPI received from School Programs Partner solely for the purposes expressly contemplated by this MOU;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">b.</span> Store PPI (whether identifiable or de-identified) received from School Programs Partner in a secure, restricted-access, and password-protected data storage system;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">c.</span> Restrict access to PPI received from School Programs Partner only to those HFB staff who are authorized to access such PPI;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">d.</span> Provide School Programs Partner with any relevant data about and specific to individuals participating in an HFB School Programs operated by School Programs Partner;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">e.</span> At least once each month during the Term, provide FFC Partner with aggregate data about individuals participating in an HFB FFC program operated by FFC Partner;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">f.</span> Notify School Programs Partner within two (2) business days of any request and the purpose of that request by a third party for School Programs Partner’s PPI;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">g.</span> Except as otherwise provided in Section 5.1, share School Programs Partner’s PPI with a third party only after receiving School Programs Partner’s written approval to do so.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.2.2</span><b>School Programs Partner’s Obligations.</b> During the Term, School Programs Partner shall:</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">a.</span> Obtain and maintain all consents and permissions from individuals that are necessary to allow School Programs Partner to submit PPI to HFB;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">b.</span> Obtain and maintain all individual authorization forms necessary to allow School Programs Partner to transmit PPI to HFB for purposes of tracking program outcomes while the individual is participating in the School Programs initialed in Section 1.1;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">c.</span> Assist HFB to recruit individuals to participate in focus groups, interviews, and School Programs surveys that will help evaluate and improve the School Programs initialed in Section 1.1;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">d.</span> Implement reasonable and appropriate safeguards to protect PPI and maintain and protect PPI in accordance with applicable laws and regulations.</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">e.</span> Encrypt all PPI transmitted to HFB;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">f.</span> Transmit PPI on a monthly or quarterly basis to HFB as described and in accordance with the Data Sharing Requirements of the School Programs initialed in Section 1.1 that are included on the respective Exhibit(s) attached to and incorporated herein.</p></li>
        </ul>
        <h5 className="black-headline center">ARTICLE 6: MISCELLANEOUS</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item"><p><span className="line-item">6.1</span><b>Amendments.</b> This MOU may be amended only by an instrument in writing that is signed by both Parties. Amendments to this MOU will be effective as of the date stipulated therein. </p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">6.2</span><b>Assignment and Delegation.</b> Neither Party shall assign or delegate all or any part of this MOU without the prior written consent of the other Party. </p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">6.3</span><b>Governing Law and Venue.</b> This MOU will be interpreted and construed in accordance with the laws of the state of Texas, without application of any principles of choice of laws. Venue is proper in Harris County, Texas.</p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">6.4</span><b>Integration.</b> This MOU, together with its Exhibit(s), constitutes the entire agreement between the Parties and supersedes all prior and contemporaneous, oral or written, understandings between the Parties with respect to the subject matter hereof.</p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">6.5</span><b>Notice.</b> All notices, requests, consents, and other communications hereunder must be in writing, must be addressed to the receiving Party’s address set forth below or to such other address as a Party may designate by notice hereunder, and must be (i) sent via email or (ii) sent by certified mail, return receipt requested, postage prepaid:</p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">If to HFB:</span>Houston Food Bank 535 Portwall Street Houston, Texas 77029 Attn: School Programs Outreach Logistics Senior Manager And/or Attn: School Programs Coordinators Email: schoolprograms@houstonfoodbank.org</p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">If to School Programs Partner:</span>{data.school.address1}, {data.school.city}, Texas {data.school.zip}. Attn: {data.school.name} Email: {data.school.principal?data.school.principal.email:''}</p></li>                     
          <li className="agreement-list-item"><p><span className="line-item">In Witness Whereof,</span>the Parties have executed this Agreement as of the dates associated with the signatures below.</p></li>                     
          
        </ul>
      </div>
    </Fragment>
  );
};

export default GeneralAgreement;
