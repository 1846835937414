import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/lib/Creatable';
import { get, find } from 'lodash';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 55,
    borderRadius: 0,
    borderWidth: 2,
    borderColor: (state.isFocused || state.menuIsOpen) ? '#76b900 !important' : 'hsla(0, 0%, 77.3%, 0.3) !important',
    boxShadow: 'none',
  }),
};

class SiteSelectInput extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      loading: prevLoading,
      input: { value: preValue },
      meta: { error: prevError, touched: prevTouched },
    } = this.props;
    const {
      loading: nextLoading,
      input: { value: nextValue },
      meta: { error: nextError, touched: nextTouched },
    } = nextProps;

    if (prevLoading !== nextLoading
      || preValue !== nextValue
      || prevTouched !== nextTouched
      || prevError !== nextError) {
      return true;
    }

    return false;
  }

  render() {
    const {
      input,
      label,
      options,
      change,
      loading,
      placeholder,
      disabled,
      meta: { touched, error },
    } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        {label}
        <CreatableSelect
          styles={customStyles}
          getOptionLabel={o => (o.site ? `${o.site.name}` : o.label)}
          isDisabled={disabled}
          value={find(options, { label: input.value })}
          placeholder={placeholder}
          onChange={value => change('site', get(value, 'site', { name: value.value }))}
          options={options}
          isLoading={loading}
          getOptionValue={option => get(option, 'site.siteId')}
        />
        {touched && error
          && (
            <span style={{
              position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
            }}
            >
              {error}
            </span>
          )}
      </div>
    );
  }
}

SiteSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

SiteSelectInput.defaultProps = {
  disabled: false,
};

export default SiteSelectInput;
