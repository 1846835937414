import gql from 'graphql-tag';

export default gql`
  query($schoolId: ID!) {
    schoolContacts(schoolId: $schoolId) {
      id
      teamMemberId
      firstName
      lastName
      title
      email
      phone
      type
    }
  }
`;
