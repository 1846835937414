import gql from 'graphql-tag';

export default gql`
  {
    educatorUser {
      educatorId
      firstName
      lastName
      fullName
      email
      phone
      type
      applicationBackpackBuddyRequired
      applicationBackpackBuddyRequiredId
      applicationSchoolMarketRequired
      applicationSchoolMarketRequiredId
      applicationPartnerServicesRequired
      applicationPartnerServicesRequiredId
      applicationKidsCafeRequired
      applicationKidsCafeRequiredId
      applicationTeachersAidRequired
      applicationTeachersAidRequiredId
    }
  }
`;
