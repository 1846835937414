import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const stripParagraphs = html => (
  html ? html.replace(/<\/?p[^>]*>/g, '').trim() : null
);

const richTextRequired = (html) => {
  const rawText = stripParagraphs(html);

  return rawText ? undefined : 'Required';
};

class RichTextField extends Component {
  constructor(props) {
    super(props);

    const contentBlock = htmlToDraft(props.input.value);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      this.state = {
        editorState,
      };
    }
  }

  convertToHtml = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawContentState);

    return stripParagraphs(html) ? html : null;
  };

  handleEditorStateChange = (editorState) => {
    const { input: { onBlur, onChange } } = this.props;
    const html = this.convertToHtml(editorState);

    this.setState({ editorState });
    onBlur();
    onChange(html);
  };

  render() {
    const { editorState } = this.state;
    const { meta: { touched, error }, label } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        {label}
        <Editor
          toolbar={{
            options: [
              'list',
              'textAlign',
              'link',
              'inline',
            ],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
          }}
          editorState={editorState}
          onEditorStateChange={this.handleEditorStateChange}
          wrapperClassName="rich-editor-wrapper"
          editorClassName="textarea w-input"
        />
        {
          touched && error && (
            <span
              style={{
                position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
              }}
            >
              {error}
            </span>
          )
        }
      </div>
    );
  }
}


RichTextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
};


export { richTextRequired };

export default RichTextField;
