import React from 'react';
import Reports from '../components/Reports';
import withSchoolLinks from '../helpers/withSchoolLinks';

const EducatorDashboardPage = props => (
  <div className="section-info">
    <div className="_1300-container">
      <h1 className="main-header">Backpack Distribution Reports</h1>
      <Reports {...props} />
    </div>
  </div>
);

export default withSchoolLinks(EducatorDashboardPage);
