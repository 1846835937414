import React from 'react';
import PropTypes from 'prop-types';

const TextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  ...otherProps
}) => (
  <div style={{ position: 'relative' }}>
    {label}
    <input {...input} {...otherProps} placeholder={placeholder} />
    {touched && error
    && (
      <span style={{
        position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
      }}
      >
        {error}
      </span>
    )
    }
  </div>
);

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

TextField.defaultProps = {
  placeholder: null,
  maxLength: 255,
};

export default TextField;
