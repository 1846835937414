/* eslint-disable no-console */
import { storeAccessToken, endSession } from './session';
import { apolloClient, enumsQuery } from '../apollo';
import {
  setEnumsAction, authenticatedAction, authFailureAction,
} from '../redux';
import removeTypename from './removeTypename';
import { store } from '../components/App';

const setUserEnv = async (token, userQuery) => {
  storeAccessToken(token);

  const userType = userQuery.definitions[0].selectionSet.selections[0].name.value;

  try {
    const { data } = await apolloClient.query({
      query: userQuery,
      fetchPolicy: 'network-only',
    });

    const { data: { enums } } = await apolloClient.query({
      query: enumsQuery,
    });

    const formattedEnums = {};
    enums.forEach(({ name, options }) => {
      formattedEnums[name] = {
        options,
      };
    });

    store.dispatch(setEnumsAction(removeTypename(formattedEnums)));
    store.dispatch(authenticatedAction(data[userType]));
  } catch (error) {
    console.log('setUserEnv error ', error);
    endSession();
    store.dispatch(authFailureAction());
  }
};

export default setUserEnv;
