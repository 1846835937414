import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { get, omit } from 'lodash';
import {
  Field,
  reduxForm,
  submit,
  formValueSelector,
} from 'redux-form';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import TextField from '../TextField';
import {
  email,
  phone,
  uniqueTeamMemberModal,
  normalizePhone,
  required,
} from '../../helpers';
import DropDownMenu from '../DropDownMenu';
import DismissGreenIcon from '../../images/dismiss-green.svg';

const EditSchoolContactModal = (props) => {
  const {
    isActive,
    activeTeamMemberType,
    teamMemberTypes,
    submitting,
    onClose,
    dispatch,
  } = props;

  const hideFields = activeTeamMemberType === 'STAFF' || activeTeamMemberType === 'VOLUNTEER';
  return (
    <Modal
      isOpen={Boolean(isActive)}
      onAfterOpen={() => null}
      contentLabel="Example Modal"
      className="edit-school-contact-modal"
      overlayClassName="modal-black-overlay"
      ariaHideApp={false}
    >
      <form>
        <div className="addstudent-popup">
          <div className="addstudent-header">
            <h5 className="heading-5">Edit Participant</h5>
            <button type="button" onClick={() => onClose()} className="close w-inline-block">
              <img src={DismissGreenIcon} alt="close" />
            </button>
          </div>
          <div className="w-form">
            <div className="field-combo-wrapper">
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">First Name</label>}
                  component={TextField}
                  validate={required}
                  name="firstName"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">Last Name</label>}
                  component={TextField}
                  validate={required}
                  name="lastName"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
            </div>
            <div className="field-combo-wrapper dropdown-select">
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">Participant Type</label>}
                  name="type"
                  component={DropDownMenu}
                  className="select-field w-select"
                  validate={(value, allValues) => uniqueTeamMemberModal(value, allValues, isActive)}
                  options={teamMemberTypes.options.map((teamMemberOption) => {
                    const { value, label } = teamMemberOption;
                    if (value === 'PRIMARY' || value === 'PRINCIPAL') {
                      return {
                        value,
                        label: `${label} (required)`,
                      };
                    }
                    return teamMemberOption;
                  })}
                />
              </div>
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">Job Title (optional)</label>}
                  component={TextField}
                  name="title"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
            </div>
            {
              !hideFields && (
                <div className="field-combo-wrapper">
                  <div className="field-combo">
                    <Field
                      label={<label htmlFor="First-3" className="field-label">Phone Number</label>}
                      component={TextField}
                      validate={[required, phone]}
                      normalize={normalizePhone}
                      name="phone"
                      className="text-field w-input"
                      autoComplete="input-unsupported"
                    />
                  </div>
                  <div className="field-combo">
                    <Field
                      label={<label htmlFor="First-3" className="field-label">Email</label>}
                      component={TextField}
                      validate={[required, email]}
                      name="email"
                      className="text-field w-input"
                      autoComplete="input-unsupported"
                    />
                  </div>
                </div>
              )
            }
            <div className="form-buttons">
              <button type="button" onClick={() => onClose()} className="form-button cancel w-button">
                Cancel
              </button>
              <button
                type="button"
                onClick={() => dispatch(submit('editSchoolContactForm'))}
                className="form-button w-button"
                disabled={submitting}
              >
                {submitting ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

EditSchoolContactModal.propTypes = {
  isActive: PropTypes.object,
  teamMemberTypes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  activeTeamMemberType: PropTypes.string,
};

EditSchoolContactModal.defaultProps = {
  isActive: null,
  activeTeamMemberType: null,
};

function mapStateToProps(state, props) {
  const selector = formValueSelector('editSchoolContactForm');
  const { teamMembersBackpackBuddy } = state.form.applicationForm.values.school;
  return {
    activeTeamMemberType: selector(state, 'type'),
    initialValues: {
      ...props.isActive,
      teamMembersBackpackBuddy,
    },
  };
}

const withData = ComponentToWrap => (
  (otherProps) => {
    const { onClose, change } = otherProps;

    const submitModal = (data) => {
      const oldTeamMembers = get(data, 'teamMembersBackpackBuddy');
      let updatedTeamMember = omit(data, ['index', 'teamMembersBackpackBuddy']);

      if (updatedTeamMember.type === 'STAFF' || updatedTeamMember.type === 'VOLUNTEER') {
        updatedTeamMember = omit(updatedTeamMember, ['phone', 'email']);
      }

      const updatedTeamMembers = oldTeamMembers.map((teamMember, i) => {
        if (i !== data.index) {
          return teamMember;
        }
        return updatedTeamMember;
      });

      change('school.teamMembersBackpackBuddy', updatedTeamMembers);
      toast('Participant Updated');
      onClose();
    };

    const WithData = compose(
      connect(mapStateToProps),
      reduxForm({
        form: 'editSchoolContactForm',
        onSubmit: submitModal,
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
      }),
    )(ComponentToWrap);

    return <WithData {...otherProps} />;
  }
);

export default withData(EditSchoolContactModal);
