import { onError } from 'apollo-link-error';
import { toast } from 'react-toastify';
import { endSession } from '../../helpers/session';
import { authFailureAction } from '../../redux';
import { store } from '../../components/App';

const onErrorLink = onError(
  ({
    graphQLErrors, networkError,
  }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const error of graphQLErrors) {
        const { message } = error;
        if (message && message === 'You must be logged in to do this') {
          endSession();
          store.dispatch(authFailureAction());
        }
        console.log(`[GraphQL error]: Message: ${message}`);
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);

      toast('Network error, please try again.', {
        toastId: 'network-error',
        type: 'warning',
      });
    }
  },
);

export default onErrorLink;
