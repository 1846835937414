import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const RadioButtonGroup = ({
  options,
  input,
  disabled,
  meta: { touched, error },
  ...otherProps
}) => (
  <Fragment>
    {
      options.map(({ label, value }) => (
        <div {...otherProps} key={value}>
          <input
            {...input}
            disabled={Boolean(disabled)}
            id={`${value}-${input.name}-radio`}
            type="radio"
            className="display-none w-radio-input"
            value={value}
            checked={value === input.value}
          />
          <label htmlFor={`${value}-${input.name}-radio`} className="radio-label w-form-label">
            {label}
          </label>
        </div>
      ))
    }
    {touched && error && <span style={{ color: 'red' }}>{error}</span>}
  </Fragment>
);

RadioButtonGroup.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

RadioButtonGroup.defaultProps = {
  disabled: false,
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
};

export default RadioButtonGroup;
