import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ChevronDropIcon from '../../images/chevron-drop.svg';
import SchoolTableBackpackBuddy from '../SchoolTableBackpackBuddy';
import SchoolTableSchoolMarket from '../SchoolTableSchoolMarket';
import SchoolTableTeachersAid from '../SchoolTableTeachersAid';
import Loading from '../Loading';
import BlankState from '../BlankState';
import { programTermsQuery } from '../../apollo';

class SchoolTerms extends Component {
  state = {
    selectedTermId: '',
  }

  render() {
    const { selectedTermId } = this.state;
    const { program: { name } } = this.props;

    let SchoolTable;
    switch (name) {
      case 'Backpack Buddy':
        SchoolTable = SchoolTableBackpackBuddy;
        break;
      case 'Teachers Aid':
        SchoolTable = SchoolTableTeachersAid;
        break;
      case 'School Market':
        SchoolTable = SchoolTableSchoolMarket;
        break;
      default:
        break;
    }

    return (
      <Fragment>
        <h2>Schools</h2>
        <Query
          query={programTermsQuery}
          variables={{
            programName: name,
          }}
        >
          {({ loading, data, error }) => {
            if (loading) return <Loading />;
            if (error) return <BlankState title="Error" subtitle="There was an error loading" />;

            return (
              <Fragment>
                <div className="date-export admin-schools backpack-buddy">
                  <div className="date-range">
                    <div className="date-from">
                      <span className="link-block-8 w-inline-block admin-school-select-wrapper">
                        <select
                          className="text-block-7 admin-school-select"
                          value={selectedTermId}
                          onChange={e => this.setState({
                            selectedTermId: e.target.value,
                          })}
                        >
                          <option value="">Select Term:</option>
                          {
                            data.programTerms.map((term) => {
                              const { termId, termFormattedShort } = term;
                              return (
                                <option
                                  key={termId}
                                  value={termId}
                                >
                                  {termFormattedShort}
                                </option>
                              );
                            })
                          }
                        </select>
                        <img src={ChevronDropIcon} width={18} alt="school-year" className="image-8" />
                      </span>
                    </div>
                  </div>
                </div>
                <SchoolTable selectedTermId={selectedTermId} {...this.props} />
              </Fragment>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

SchoolTerms.propTypes = {
  enums: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default SchoolTerms;
