import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ program }) => {
  if (program.logo) {
    return (
      <div className="page-logo-link w-inline-block">
        <img src={program.logo} width={150} alt="" className="image-13" />
      </div>
    );
  }

  if (program.name) {
    return (
      <div className="program-logo-text">
        <span className="logo-bold">{program.name.split(' ')[0]}</span>
        {program.name.split(' ')[1]}
      </div>
    );
  }

  return (
    <div className="program-management-page">Program Management</div>
  );
};

Logo.propTypes = {
  program: PropTypes.object.isRequired,
};

export default Logo;
