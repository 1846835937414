import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAdminLayout from '../helpers/withAdminLayout';
import SchoolDistrictTable from '../components/SchoolDistrictTable';
import SchoolDistrictDetail from '../components/SchoolDistrictDetail';

const AdminSchoolProgramDistrictsPage = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <SchoolDistrictTable {...props} />}
      />
      <Route
        path={`${match.path}/:id`}
        render={routerProps => <SchoolDistrictDetail {...props} {...routerProps} />}
      />
      <Route component={() => <Redirect to={`${match.path}`} />} />
    </Switch>
  );
};

AdminSchoolProgramDistrictsPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withAdminLayout(AdminSchoolProgramDistrictsPage);
