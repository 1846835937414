import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { createStore, useStore } from 'react-hookstore';
import { Query } from 'react-apollo';
import moment from 'moment';
import ReportsDatePicker from './ReportsDatePicker';
import ReportAccordion from './ReportAccordion';
import SchoolHeader from './SchoolHeader';
import BlankState from '../BlankState';
import Loading from '../Loading';
import { schoolReportsQuery } from '../../apollo';
import exportExcel from '../../helpers/exportExcel';

export const ReportsDateContext = React.createContext({});

createStore('reportDates', {
  startDate: moment().startOf('year').local().toDate(),
  endDate: moment().endOf('year').local().toDate(),
});

const Reports = ({ selectedSchool, user }) => {
  const isAdmin = user.type === 'ADMIN';
  const [filterDates, setFilterDates] = useStore('reportDates');
  const [selectedReports, setSelectedReports] = useState({});
  const [isOpenSchools, setOpenSchools] = useState({});

  const handleReportSelectionChange = (reportId, isSelected) => {
    const newSelected = { ...selectedReports };
    newSelected[reportId] = isSelected;
    setSelectedReports(newSelected);
  };

  const isAllSelected = (schools = null) => {
    if (schools === null) {
      return false;
    }
    const checkSchools = (Array.isArray(schools)) ? schools : [schools];
    if (checkSchools.length === 0) {
      return false;
    }

    let selected = true;
    checkSchools.forEach(({ termMonths }) => {
      termMonths.forEach((termMonth) => {
        if (!selectedReports[termMonth.termMonthId]) {
          selected = false;
        }
      });
    });

    return selected;
  };

  const onHeaderSelect = (schools = null) => {
    if (!schools) {
      return false;
    }
    const checkSchools = (Array.isArray(schools)) ? schools : [schools];
    const newSelected = { ...selectedReports };

    const changeAll = (termMonths, isSelected) => {
      termMonths.forEach((termMonth) => {
        newSelected[termMonth.termMonthId] = isSelected;
      });
    };

    if (isAllSelected(schools)) {
      checkSchools.forEach((checkSchool) => {
        changeAll(checkSchool.termMonths, false);
      });
    } else {
      checkSchools.forEach((checkSchool) => {
        changeAll(checkSchool.termMonths, true);
      });
    }
    setSelectedReports(newSelected);
    return true;
  };

  const renderEmptyState = () => <BlankState title="No Results" />;

  const renderAllSchools = (schoolReports) => {
    const reports = schoolReports.map((school) => {
      const isOpen = isOpenSchools[school.schoolId] !== undefined
        ? isOpenSchools[school.schoolId]
        : true;

      return (
        <Fragment key={school.schoolId}>
          <SchoolHeader
            title={school.name}
            isSelected={isAllSelected(schoolReports)}
            onSelectChange={() => onHeaderSelect(school)}
            isOpen={isOpen}
            onOpenChange={
              isOpenChange => setOpenSchools(
                { ...isOpenSchools, [school.schoolId]: isOpenChange },
              )
            }
          />
          {
            school.termMonths.map(termMonth => (
              <Fragment key={termMonth.termMonthId}>
                {isOpen && (
                  <div className="school-reports-wrapper">
                    <ReportAccordion
                      key={termMonth.termMonthId}
                      schoolId={school.schoolId}
                      selectedSchool={selectedSchool}
                      termMonth={termMonth}
                      onSelectChange={
                        isSelected => handleReportSelectionChange(termMonth.termMonthId, isSelected)
                      }
                      isSelected={(selectedReports[termMonth.termMonthId] === true)}
                    />
                  </div>
                )}
              </Fragment>
            ))
          }
        </Fragment>
      );
    });

    if (!reports.length) {
      return renderEmptyState();
    }

    return reports;
  };

  const renderOneSchool = (schoolReport) => {
    if (!schoolReport) {
      return renderEmptyState();
    }

    const reports = schoolReport.termMonths.map(termMonth => (
      <ReportAccordion
        key={termMonth.termMonthId}
        schoolId={schoolReport.schoolId}
        selectedSchool={selectedSchool}
        termMonth={termMonth}
        onSelectChange={
          isSelected => handleReportSelectionChange(termMonth.termMonthId, isSelected)
        }
        isSelected={(selectedReports[termMonth.termMonthId] === true)}
        isAdmin={isAdmin}
      />
    ));

    if (!reports.length) {
      return renderEmptyState();
    }

    return (
      <div className="school-reports-wrapper">
        {reports}
      </div>
    );
  };

  const exportReports = (schoolReports) => {
    const columnData = [
      { header: 'First name', key: 'studentName' },
      { header: 'Student ID', key: 'studentIdNumber' },
      { header: 'Week 1', key: 'week1' },
      { header: 'Week 2', key: 'week2' },
      { header: 'Week 3', key: 'week3' },
      { header: 'Week 4', key: 'week4' },
      { header: 'Week 5', key: 'week5' },
      { header: 'Testimonial', key: 'testimonial' },
      { header: 'Month', key: 'month' },
      { header: 'Year', key: 'year' },
      { header: 'Allocated', key: 'allocated' },
      { header: 'Distributed', key: 'distributed' },
      { header: 'Discarded', key: 'discarded' },
      { header: 'School Name', key: 'schoolName' },
      { header: 'Backpack Buddy ID Number', key: 'backpackBuddyId' },
    ];

    const rowData = [];

    const getDistributionNumberByDateId = (entries, dateId) => {
      const entry = entries.find(
        de => de.termMonthDistributionDateId === dateId,
      );

      return (entry) ? entry.distributionNumber : 0;
    };

    schoolReports.forEach((school) => {
      const { name: schoolName, backpackBuddyId } = school;

      school.termMonths.forEach((termMonth) => {
        const month = moment.utc(termMonth.startDate).format('MMMM');
        const year = moment.utc(termMonth.startDate).year();
        const { distributionDates } = termMonth;

        if (selectedReports[termMonth.termMonthId]) {
          termMonth.distributions.forEach((distribution) => {
            const distributed = termMonth.distributions.reduce(
              (result, { distributionDateEntries }) => result + distributionDateEntries.reduce(
                (prevResult, { distributionNumber }) => prevResult + distributionNumber, 0,
              ), 0,
            );
            const allocated = termMonth.monthlyAllocation;
            const discarded = allocated - distributed;

            const { testimonial, studentName, studentIdNumber } = distribution;
            const row = {
              schoolName,
              backpackBuddyId,
              year,
              month,
              testimonial,
              studentName,
              studentIdNumber,
              distributed,
              allocated,
              discarded,
            };

            distributionDates.forEach((date, index) => {
              const label = `week${index + 1}`;
              row[label] = getDistributionNumberByDateId(
                distribution.distributionDateEntries,
                date.termMonthDistributionDateId,
              );
            });

            rowData.push(row);
          });
        }
      });
    });

    exportExcel('distribution-reports', columnData, rowData);
  };

  return (
    <Query
      query={schoolReportsQuery}
      variables={{
        startDate: filterDates.startDate,
        endDate: filterDates.endDate,
        schoolId: selectedSchool,
      }}
      fetchPolicy="cache-and-network"
    >
      {(
        {
          loading,
          error,
          data,
          refetch,
        },
      ) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <BlankState title="Error" subtitle="There was an error loading" />;
        }

        const { schoolReports } = data;

        return (
          <ReportsDateContext.Provider value={{ filterDates, refetch }}>
            <ReportsDatePicker
              filterDates={filterDates}
              onFilterDateChange={newFilterDates => setFilterDates(newFilterDates)}
              selectedReports={Object.keys(selectedReports).filter(k => selectedReports[k])}
              setSelectedReports={setSelectedReports}
              isAllSelected={isAllSelected(schoolReports)}
              onSelectAllChange={() => onHeaderSelect(schoolReports)}
              onExport={() => exportReports(schoolReports)}
              schoolId={selectedSchool}
              isAdmin={isAdmin}
            />
            <div className="site-reports">
              {(!selectedSchool && schoolReports)
                && renderAllSchools(schoolReports)
              }

              {(selectedSchool && schoolReports)
                && renderOneSchool(schoolReports.find(school => school.schoolId === selectedSchool))
              }
            </div>
          </ReportsDateContext.Provider>
        );
      }}
    </Query>
  );
};

Reports.propTypes = {
  selectedSchool: PropTypes.string,
  user: PropTypes.object.isRequired,
};

Reports.defaultProps = {
  selectedSchool: null,
};

export default Reports;
