import gql from 'graphql-tag';

export default gql`
  {
    notification {
      notificationId
      text
    }
  }
`;
