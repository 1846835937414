import gql from 'graphql-tag';

export default gql`
  query adminConfigSchoolMarket {
    adminConfigSchoolMarket {
      id
      configSchoolMarketId
      summerEnabled
      schoolEnabled
      waitlistEnabled
      lastEdited
    }
  }
`;
