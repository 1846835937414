import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';

const PrgramStatusForm = reduxForm({
  form: 'schoolProgramStatus',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  update,
  reset,
  dispatch,
  enums,
}) => {
  const submitAndReset = data => (
    update(data).then(() => (
      dispatch(reset('schoolProgramStatus'))
    ))
  );

  return (
    <Form onSubmit={handleSubmit(submitAndReset.bind(this))}>
      <div className="field-combo-2">
        <label htmlFor="First-3" className="field-label">Update Status</label>
        <Field
          component="select"
          className="select-field w-select"
          name="statusTeachersAid"
        >
          {
            enums.programStatuses.options.map(({ value, label }) => (
              <option key={value} value={value}>{label}</option>
            ))
          }
        </Field>
      </div>
      <div className="div-block-20" />
      <div className="btn-padding">
        <input
          type="submit"
          value={submitting ? 'Please wait...' : 'Save Changes'}
          disabled={submitting}
          className="btn-solid-green w-button"
        />
      </div>
    </Form>
  );
});

const mapStateToProps = (reduxState, props) => {
  const { school } = props;
  const { schoolId, statusTeachersAid } = school;

  return {
    initialValues: {
      schoolId,
      statusTeachersAid,
    },
  };
};

export default connect(mapStateToProps)(PrgramStatusForm);
