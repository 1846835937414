import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import withProgramsLayout from '../helpers/withProgramsLayout';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import {
  configSchoolMarketQuery,
  configTeachersAidQuery,
} from '../apollo';
import programContactEmails from '../constants/programContactEmails';
import SchoolMarketProgramInfo from '../components/SchoolMarketProgramInfo';

function EducatorApplicationStartPage({ program }) {
  const {
    name,
    programUrl,
  } = program;
  const programText = `Please complete this agreement to be considered for the ${name} program. After your application has been submitted, check your approval status by logging in to your account.`;
  const shouldCheckConfig = name === 'School Market' || name === 'Teachers Aid';
  const waitlistText = `The ${name} program has reached capacity for the school year. We are currently accepting schools on the waiting list. If you are interested, please fill out the following form. We will contact you at a later time.`;
  const configQuery = (name === 'School Market') ? configSchoolMarketQuery : configTeachersAidQuery;
  const queryDataPath = (name === 'School Market') ? 'configSchoolMarket.waitlistEnabled' : 'configTeachersAid.waitlistEnabled';
  const emailWSubject = name === 'School Market' ? `mailto:${programContactEmails[name]}?subject=School%20Market%20Waitlist` : `mailto:${programContactEmails[name]}?subject=Teachers%20Aid%20Waitlist`;
  const startLink = <Link to={`${programUrl}/application/step-1`} className="btn-green w-button">Start Application</Link>;

  return (
    <Fragment>
      <div className="section-begin-application">
        <div className="_1300-container">
          <div className="start-flex">
            <Query query={configQuery} fetchPolicy="network-only" skip={!shouldCheckConfig}>
              {({ loading, error, data }) => {
                if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
                if (loading) return <Loading />;

                const waitlistEnabled = get(data, queryDataPath);

                if (waitlistEnabled) {
                  return (
                    <Fragment>
                      <div className="half-width">
                        <h2>{`${name} Waitlist`}</h2>
                        <p>{waitlistText}</p>
                        <br />
                        {name === 'School Market' && <SchoolMarketProgramInfo />}
                        <p>
                          If you have any questions, and/or concerns, email &nbsp;
                          <a href={`mailto:${emailWSubject}`} className="paragraph-link">{programContactEmails[name]}</a>
                        </p>
                      </div>
                      {startLink}
                    </Fragment>
                  );
                }

                if (name === 'School Market') {
                  return (
                    <Fragment>
                      <div className="width-70 schoolmarket-start">
                        <h2>{`${name} Program Application`}</h2>
                        <p>
                          The Houston Food Bank is looking to partner with
                          schools/organizations that will support students and their families
                          as they work towards healthy, stable lives. HFB School Program
                          representatives work with partners to monitor, collect, and track
                          the progress and outcome of school pantry clients as they journey
                          towards self-sufficiency.
                        </p>
                        <br />
                        <p>{programText}</p>
                        <br />
                        <SchoolMarketProgramInfo />
                      </div>
                      {startLink}
                    </Fragment>
                  );
                }

                return (
                  <Fragment>
                    <div className="half-width">
                      <h2>{`${name} Program Application`}</h2>
                      <p>{programText}</p>
                    </div>
                    {startLink}
                  </Fragment>

                );
              }}
            </Query>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

EducatorApplicationStartPage.propTypes = {
  program: PropTypes.object.isRequired,
};

export default withProgramsLayout(EducatorApplicationStartPage);
