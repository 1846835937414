import gql from 'graphql-tag';

export default gql`
  query configPartnerServices {
    configPartnerServices {
      acceptingApplications
      applicationYear
    }
  }
`;
