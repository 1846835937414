export const authenticatedAction = user => ({
  user,
  type: 'AUTHENTICATED',
});

export const setUserAction = user => ({
  user,
  type: 'SET_USER',
});

export const setProgramAction = program => ({
  program,
  type: 'SET_PROGRAM',
});

export const resetProgramAction = () => ({
  type: 'RESET_PROGRAM',
});

export const setEnumsAction = enums => ({
  enums,
  type: 'SET_ENUMS',
});

export const authFailureAction = () => ({
  type: 'AUTH_FAILURE',
});

export const logoutAction = () => ({
  type: 'LOGOUT',
});
