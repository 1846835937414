import gql from 'graphql-tag';

export default gql`
  query configSchoolMarket {
    configSchoolMarket {
      waitlistEnabled
      summerEnabled
      schoolEnabled
    }
  }
`;
