import gql from 'graphql-tag';

export default gql`
  query adminConfigPartnerServices {
    adminConfigPartnerServices {
      id
      configPartnerServicesId
      acceptingApplications
      lastEdited
    }
  }
`;
