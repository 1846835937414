import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import AuthRoute from './AuthRoute';
import ApplicationBackpackBuddy from '../components/ApplicationBackpackBuddy';
import EducatorDashboardPage from '../pages/EducatorDashboardPage';
import EducatorContactsPage from '../pages/EducatorContactsPage';
import EducatorSurveysPage from '../pages/EducatorSurveysPage';
import EducatorDocumentsPage from '../pages/EducatorDocumentsPage';
import EducatorAccountPage from '../pages/EducatorAccountPage';
import EducatorApplicationStartPage from '../pages/EducatorApplicationStartPage';
import { notificationQuery } from '../apollo';

const BackpackBuddyEducatorRoutes = ({ match, user }) => {
  const { applicationBackpackBuddyRequired } = user;

  if (applicationBackpackBuddyRequired) {
    return (
      <Switch>
        <AuthRoute path={`${match.path}/start`} component={EducatorApplicationStartPage} />
        <AuthRoute path={`${match.path}/application/:step?/:id?`} component={ApplicationBackpackBuddy} />
        <Route component={() => <Redirect to={`${match.path}/application`} />} />
      </Switch>
    );
  }

  return (
    <Fragment>
      <Query
        query={notificationQuery}
      >
        {({ loading, data, error }) => {
          if (loading) return null;
          if (error) return null;
          return (
            <>
              {
                data && data.notification.text && (
                  <div className="section notification">
                    <div className="reminder">
                      <div className="reminder-text">
                        <h5 className="heading-3">Reminder</h5>
                        <p className="paragraph">{data.notification.text}</p>
                      </div>
                    </div>
                  </div>
                )
              }
            </>
          );
        }}
      </Query>
      <Switch>
        <AuthRoute exact path={`${match.path}/dashboard`} component={EducatorDashboardPage} />
        <AuthRoute exact path={`${match.path}/contacts`} component={EducatorContactsPage} />
        <AuthRoute exact path={`${match.path}/surveys`} component={EducatorSurveysPage} />
        <AuthRoute exact path={`${match.path}/documents`} component={EducatorDocumentsPage} />
        <AuthRoute path={`${match.path}/account`} component={EducatorAccountPage} />
        <AuthRoute path={`${match.path}/application/:step?/:id?`} component={ApplicationBackpackBuddy} />
        <Route component={() => <Redirect to={`${match.path}/dashboard`} />} />
      </Switch>
    </Fragment>
  );
};

BackpackBuddyEducatorRoutes.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default BackpackBuddyEducatorRoutes;
