import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Mutation } from 'react-apollo';
import { push } from 'react-router-redux';
import {
  Form,
  Field,
  FieldArray,
  reduxForm,
} from 'redux-form';
import moment from 'moment';
import LaunchIcon from '../../../../images/launch.svg';
import deleteIcon from '../../../../images/delete.svg';
import dotsMenu from '../../../../images/pdf-svgrepo-com.svg';
import BlankState from '../../../BlankState';
import handleToastError from '../../../../helpers/handleToastError';
import { adminDeleteApplicationMutation, adminSchoolTeachersAidQuery } from '../../../../apollo';
import SendEmailButton from './SendEmailButton';

const renderApplications = ({
  fields,
  schoolId,
  dispatch,
  enums,
}) => (
  <Fragment>
    {
      fields.map((application, index) => {
        const {
          termFormattedShort,
          submittedAt,
          submittedBy,
          isContinuation,
          status,
          applicationTeachersAidId,
        } = fields.get(index);

        return (
          <Fragment key={applicationTeachersAidId}>
            <div className="table-row-3 applications w-clearfix">
              <div
                onClick={() => dispatch(push(`/teachers-aid/application/step-1/${applicationTeachersAidId}`))}
                className="app-table-col year view-application"
              >
                {console.log(fields.get(index))}
                <div>{termFormattedShort}</div>
                {isContinuation && <div>Continuation of the previous year</div>}
              </div>
              <div className="app-table-col sub-date">
                <div>{submittedAt ? moment.utc(submittedAt).format('M-D-YY') : 'N/A'}</div>
              </div>
              <div className="app-table-col sub-by">
                <div className="text-force-wrap">{submittedBy}</div>
              </div>
              <div className="app-table-col school-stat">
                <div className="form-block-6 w-form">
                  <Field
                    component="select"
                    className="select-dropdown w-select"
                    name={`${application}.status`}
                    disabled={status === 'IN_PROGRESS'}
                  >
                    {
                      enums.applicationSchoolStatuses.options.map(({ value, label }) => (
                        <option
                          disabled={value === 'IN_PROGRESS' || value === 'PENDING_PRINCIPAL'}
                          key={value}
                          value={value}
                        >
                          {label}
                        </option>
                      ))
                    }
                  </Field>
                </div>
              </div>
              <div className="app-table-col actions tablet-wrap">
                {status === 'PENDING_PRINCIPAL' && <SendEmailButton applicationTeachersAidId={applicationTeachersAidId} />}
                {
                  (status === 'PENDING_PRINCIPAL' || status === 'APPROVED') && (
                    <div
                      onClick={() => dispatch(push(`/teachers-aid/principal-signature/${applicationTeachersAidId}`))}
                      className="crud-action-icon-wrapper-2 w-inline-block pointer"
                    >
                      <img src={LaunchIcon} width={20} alt="View Principal Agreement" title="View Principal Agreement" />
                    </div>
                  )
                }

                <div className="crud-action-icon-wrapper-2 w-inline-block" onClick={() => dispatch(push(`/teachers-aid/print/${applicationTeachersAidId}`))}>
                  <img className="pointer" src={dotsMenu} alt="Print Application" title="Print Application" />
                </div>
                <Mutation
                  mutation={adminDeleteApplicationMutation}
                  variables={{ input: { applicationTeachersAidId } }}
                  refetchQueries={[
                    { query: adminSchoolTeachersAidQuery, variables: { schoolId } },
                  ]}
                  onError={mutationError => handleToastError(mutationError)}
                  onCompleted={() => toast('Delete Successful')}
                >
                  {(adminDeleteApplication) => {
                    const confirmDelete = () => {
                      confirmAlert({
                        message: 'Are you sure?',
                        buttons: [
                          {
                            label: 'Cancel',
                          },
                          {
                            label: 'Delete',
                            onClick: adminDeleteApplication,
                          },
                        ],
                      });
                    };

                    return (
                      <div className="crud-action-icon-wrapper-2 w-inline-block" onClick={confirmDelete}>
                        <img className="pointer" src={deleteIcon} alt="Delete Application" title="Delete Application" />
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            </div>
          </Fragment>
        );
      })
    }
  </Fragment>
);

renderApplications.propTypes = {
  fields: PropTypes.object.isRequired,
  schoolId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  enums: PropTypes.object.isRequired,
};

const ApplicationForm = reduxForm({
  form: 'schoolApplications',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  dirty,
  update,
  reset,
  dispatch,
  enums,
  initialValues: {
    schoolId,
    applicationsTeachersAid,
  },
}) => {
  const submitAndReset = (data) => {
    const applicationsNormalized = data.applicationsTeachersAid.map(({
      applicationTeachersAidId,
      status,
    }) => ({
      applicationTeachersAidId,
      status,
    }));

    const normalizedData = {
      schoolId,
      applicationsTeachersAid: applicationsNormalized,
    };

    return (
      update(normalizedData).then(() => (
        dispatch(reset('schoolApplications'))
      ))
    );
  };

  return (
    <Form onSubmit={handleSubmit(submitAndReset.bind(this))}>
      <div className="applications-table-row-2 w-clearfix">
        <span className="app-table-col year w-inline-block">
          <div className="table-data-4 year">Application Year</div>
        </span>
        <span className="app-table-col sub-date w-inline-block">
          <div className="table-data-4 status">Submission</div>
        </span>
        <span className="app-table-col sub-by w-inline-block">
          <div className="table-data-4 status">Submitted By</div>
        </span>
        <span className="app-table-col school-stat w-inline-block">
          <div className="table-data-4 status">Status</div>
        </span>
      </div>
      {
        applicationsTeachersAid.length ? (
          <Fragment>
            <FieldArray
              name="applicationsTeachersAid"
              component={renderApplications}
              props={{ enums, schoolId, dispatch }}
            />
            <div className="btn-padding">
              <input
                type="submit"
                value={submitting ? 'Please wait...' : 'Save Changes'}
                disabled={submitting || !dirty}
                className="btn-solid-green w-button"
              />
            </div>
          </Fragment>
        ) : <BlankState title="No Results" />
      }
    </Form>
  );
});

const mapStateToProps = (reduxState, props) => {
  const { school } = props;
  const { schoolId, applicationsTeachersAid } = school;

  const applicationsData = applicationsTeachersAid.map(({
    applicationTeachersAidId,
    termFormattedShort,
    submittedAt,
    submittedBy,
    isContinuation,
    status,
  }) => ({
    applicationTeachersAidId,
    termFormattedShort,
    submittedAt,
    submittedBy,
    isContinuation,
    status,
  }));

  return {
    initialValues: {
      schoolId,
      applicationsTeachersAid: applicationsData,
    },
  };
};

ApplicationForm.propTypes = {
  enums: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ApplicationForm);
