import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import BackIcon from '../../images/back-icon.svg';
import SchoolDistrictDetailForm from '../SchoolDistrictDetailForm';
import handleToastError from '../../helpers/handleToastError';
import Loading from '../Loading';
import BlankState from '../BlankState';
import {
  adminDistrictDetailsQuery,
  adminUpdateDistrictMutation,
} from '../../apollo';

const SchoolDistrictDetail = (props) => {
  const { location, match, program: { programUrl } } = props;

  return (
    <Fragment>
      <Query
        query={adminDistrictDetailsQuery}
        skip={match.params.id === 'new'}
        variables={{
          districtId: match.params.id,
        }}
      >
        {({ loading, data, error }) => {
          if (loading) {
            return (
              <Fragment>
                <div className="profile-intro">
                  <Link
                    to={`${programUrl}/districts`}
                    className="back-btn w-inline-block"
                  >
                    <img src={BackIcon} alt="back" className="image-20" />
                    <div>back to districts</div>
                  </Link>
                </div>
                <Loading />
              </Fragment>
            );
          }

          if (error) {
            return (
              <Fragment>
                <div className="profile-intro">
                  <Link
                    to={`${programUrl}/districts`}
                    className="back-btn w-inline-block"
                  >
                    <img src={BackIcon} alt="back" className="image-20" />
                    <div>back to districts</div>
                  </Link>
                </div>
                <BlankState title="Error" subtitle="There was an error loading" />
              </Fragment>
            );
          }

          const districtDetails = get(data, 'adminDistrictDetails', {});
          return (
            <Fragment>
              <div className="profile-intro">
                <Link
                  to={`${programUrl}/districts`}
                  className="back-btn w-inline-block"
                >
                  <img src={BackIcon} alt="back" className="image-20" />
                  <div>back to districts</div>
                </Link>
                <h2 className="purple-headline">{get(districtDetails, 'name', '')}</h2>
              </div>
              <Mutation
                mutation={adminUpdateDistrictMutation}
                onError={mutationError => handleToastError(mutationError)}
                onCompleted={() => toast('Update Successful')}
              >
                {(adminUpdateDistrict, { data: updateData }) => {
                  const update = (values) => {
                    const {
                      id,
                      districtId,
                      name,
                      deletedAt,
                    } = values;
                    const newDistrictValue = {
                      id,
                      districtId,
                      name,
                      deletedAt,
                    };
                    return (
                      adminUpdateDistrict({
                        variables: {
                          input: {
                            ...newDistrictValue,
                          },
                        },
                      })
                    );
                  };

                  if (updateData && location.pathname.includes('new')) {
                    return <Redirect to={`${updateData.adminUpdateDistrict.districtId}`} />;
                  }
                  return (
                    <SchoolDistrictDetailForm
                      {...props}
                      onSubmit={update}
                      districtDetails={districtDetails}
                    />
                  );
                }}
              </Mutation>
            </Fragment>
          );
        }}
      </Query>
    </Fragment>
  );
};

SchoolDistrictDetail.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default SchoolDistrictDetail;
