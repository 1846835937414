import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import ProfileTab from '../components/ProfileTab';
import SideMenu from '../components/SideMenu';
import ApplicationsTab from '../components/ApplicationsTab';
import withProgramsLayout from '../helpers/withProgramsLayout';

const EducatorAccountPage = (props) => {
  const { match, program } = props;
  const { path } = match;

  return (
    <div className="section-content">
      <div className="_1300-container">
        <h2>My Account</h2>
        <div className="divider settings" />
        <div className="account-info">
          <SideMenu program={program} />
          <Switch>
            <Route path={`${path}/profile`} component={ProfileTab} />
            <Route render={() => <ApplicationsTab {...props} />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

EducatorAccountPage.propTypes = {
  program: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withProgramsLayout(EducatorAccountPage);
