import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import moment from 'moment';
import BlankState from '../BlankState';
import handleToastError from '../../helpers/handleToastError';
import { PAGE_SIZE } from '../../config';
import Pagination from '../Pagination';
import deleteIcon from '../../images/delete.svg';
import LaunchesImg from '../../images/launch.svg';
import { surveysQuery, adminDeleteSurveyMutation } from '../../apollo';

const SurveyTable = (props) => {
  const { surveys, isAdmin } = props;

  const columns = [{
    className: 'file-name',
    headerClassName: 'doc-table-col w-inline-block name pointer noselect',
    Header: 'Name',
    accessor: 'title',
    Cell: row => (
      <a
        href={row.original.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {row.value}
      </a>
    ),
  }, {
    className: 'id',
    headerClassName: 'doc-table-col w-inline-block date-added pointer noselect',
    Header: 'Date Added',
    accessor: 'createdAt',
    Cell: row => moment.utc(row.value).format('M-D-YY'),
  }, {
    Header: '',
    className: 'actions',
    show: isAdmin,
    Cell: row => (
      <Mutation
        mutation={adminDeleteSurveyMutation}
        variables={{
          surveyId: row.original.surveyId,
        }}
        refetchQueries={[{ query: surveysQuery }]}
        onError={mutationError => handleToastError(mutationError)}
        onCompleted={() => toast('Delete Successful')}
        awaitRefetchQueries
      >
        {(deleteSurvey) => {
          const confirmDelete = () => {
            confirmAlert({
              message: 'Are you sure?',
              buttons: [
                {
                  label: 'Cancel',
                },
                {
                  label: 'Delete',
                  onClick: deleteSurvey,
                },
              ],
            });
          };

          return (
            <div className="crud-action-icon-wrapper w-inline-block" onClick={confirmDelete}>
              <img className="pointer" src={deleteIcon} width={14} alt="delete" />
            </div>
          );
        }}
      </Mutation>
    ),
  }, {
    Header: '',
    className: 'actions',
    show: !isAdmin,
    Cell: row => (
      <a
        href={row.original.link}
        target="_blank"
        rel="noopener noreferrer"
        title="View"
        className="crud-action-icon-wrapper-2 w-inline-block"
      >
        <img
          src={LaunchesImg}
          width={20}
          alt="view"
        />
      </a>
    ),
  }];

  return (
    <ReactTable
      data={surveys}
      columns={columns}
      loadingText=""
      NoDataComponent={() => <BlankState title="No Results" />}
      minRows={0}
      filterable={false}
      PaginationComponent={Pagination}
      defaultPageSize={PAGE_SIZE}
      defaultSorted={[{
        id: 'createdAt',
        desc: true,
      }]}
      getTrGroupProps={() => ({
        className: 'table-row surveys w-clearfix',
      })}
      getTdProps={() => ({
        className: 'doc-table-col',
      })}
      getTheadTrProps={() => ({
        className: 'document-table-row w-clearfix',
      })}
      column={
        {
          ...ReactTableDefaults.column,
          minWidth: 85,
          width: '10%',
        }
      }
    />
  );
};

const mapStateToProps = ({ user }) => {
  const isAdmin = user.type === 'ADMIN';

  return {
    isAdmin,
  };
};

SurveyTable.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  surveys: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(SurveyTable);
