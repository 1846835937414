import React from 'react';
import { Field, reduxForm } from 'redux-form';

import {
  required,
  passwordsMatch,
  minLength6,
  oneUppercase,
} from '../../helpers';
import TextField from '../TextField';

const ResetPasswordForm = reduxForm({ form: 'resetPassword' })(({
  handleSubmit,
  submitting,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)} className="login-form" id="email-form">
    <div className="field-combo stack">
      <Field
        label={<label htmlFor="Password-Create-2" className="field-label">New Password</label>}
        component={TextField}
        validate={[required, minLength6, oneUppercase]}
        name="password"
        type="password"
        placeholder="Create Password"
        autoComplete="new-password"
        className="text-field w-input"
      />
    </div>
    <div className="field-combo stack">
      <Field
        label={<label htmlFor="Password-Confirm-2" className="field-label">Confirm Password</label>}
        component={TextField}
        validate={[required, passwordsMatch]}
        name="confirm-password"
        type="password"
        placeholder="Confirm Password"
        autoComplete="new-password"
        className="text-field w-input"
      />
    </div>
    <br />
    <div className="password-guide">
      <div>
        Choose a password that is at least 6 characters
        long and includes at least 1 uppercase character.
      </div>
    </div>
    <input
      type="submit"
      value={submitting ? 'Please wait...' : 'Login'}
      disabled={submitting}
      className="btn-fill-p top-pad-ten w-button"
    />
  </form>
));

export default ResetPasswordForm;
