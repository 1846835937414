import gql from 'graphql-tag';

export default gql`
  query configKidsCafe {
    configKidsCafe {
      acceptingApplications
      applicationYear
    }
  }
`;
