import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Field,
  FieldArray,
  reduxForm,
  isDirty,
} from 'redux-form';
import classnames from 'classnames';
import { validateEmails } from '../../helpers';
import getLabels from '../../helpers/getLabels';
import EmailModal from '../EmailModal';

const renderEmails = ({
  fields,
  enums,
  state,
  meta: {
    valid,
  },
}) => {
  const [activeEmail, setActiveEmail] = useState(null);

  return (
    <Fragment>
      {
        fields.map((email, index) => {
          const {
            emailId,
            name,
            recipients,
            mergeTags,
          } = fields.get(index);

          return (
            <Fragment key={emailId}>
              <div className="table-row applications w-clearfix">
                <div className="app-table-col year">
                  <div>{name}</div>
                </div>
                <div className="app-table-col year">
                  <div>{getLabels(enums.backpackBuddyTeamMemberTypes.options, recipients)}</div>
                </div>
                <div className="app-table-col status">
                  <div>
                    <span
                      className={
                        classnames('edit-link pointer email-settings-edit-link', {
                          warning: isDirty('emailForm')(state, email),
                        })
                      }
                      onClick={() => setActiveEmail(emailId)}
                    >
                      Edit
                    </span>
                  </div>
                </div>
                <div className="app-table-col" />
                <div className="app-table-col">
                  <div className="form-block-3 w-form">
                    <Field
                      component="select"
                      className="email-status w-select"
                      name={`${email}.status`}
                    >
                      {enums.emailStatuses.options.map(
                        o => <option key={o.value} value={o.value}>{o.label}</option>,
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <EmailModal
                valid={valid}
                email={email}
                emailId={emailId}
                activeEmail={activeEmail}
                setActiveEmail={setActiveEmail}
                mergeTags={mergeTags}
              />
            </Fragment>
          );
        })
      }
    </Fragment>
  );
};

renderEmails.propTypes = {
  fields: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

const EmailForm = reduxForm({
  form: 'emailForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  onSubmit,
  enums,
  state,
  dirty,
}) => {
  const submit = ({ adminEmails }) => {
    const data = adminEmails.map(({
      emailId,
      status,
      subjectLine,
      messageBody,
    }) => ({
      emailId,
      status,
      subjectLine,
      messageBody,
    }));

    return onSubmit({
      adminEmails: data,
    });
  };

  return (
    <Form onSubmit={handleSubmit(submit.bind(this))}>
      <FieldArray
        name="adminEmails"
        component={renderEmails}
        props={{ enums, state }}
        validate={validateEmails}
      />
      <div className="btn-padding">
        <input
          type="submit"
          value={submitting ? 'Please wait...' : 'Save Changes'}
          disabled={submitting}
          className="btn-solid-green w-button"
        />
        {dirty && <span className="error-label emails-unsaved-warning">You have unsaved changes.</span>}
      </div>
    </Form>
  );
});

const mapStateToProps = (state, props) => {
  const { adminEmails } = props;

  return {
    state,
    initialValues: {
      adminEmails,
    },
  };
};

EmailForm.propTypes = {
  enums: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(EmailForm);
