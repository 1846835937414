import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Checkbox from '../Checkbox';

const ApplicationMetaForm = reduxForm({
  form: 'applicationMeta',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="w-checkbox">
      <Field
        label="School Year"
        component={Checkbox}
        name="schoolEnabled"
        id="schoolEnabled"
        className="w-checkbox-input"
      />
    </div>
    <div className="w-checkbox">
      <Field
        label="Summer"
        component={Checkbox}
        name="summerEnabled"
        id="summerEnabled"
        className="w-checkbox-input"
      />
    </div>
    <div className="btn-padding">
      <input
        type="submit"
        value={submitting ? 'Please wait...' : 'Save Changes'}
        disabled={submitting}
        className="btn-solid-green w-button"
      />
    </div>
  </form>
));

function mapStateToProps(state, props) {
  const { summerEnabled, schoolEnabled } = props;

  return {
    initialValues: {
      summerEnabled,
      schoolEnabled,
    },
  };
}

export default connect(mapStateToProps)(ApplicationMetaForm);
