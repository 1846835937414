import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import DropdownIcon from '../../images/dropdown.svg';

const SchoolDropDownMenu = ({
  options,
  selected,
  onSelect,
}) => {
  const [isOpen, setOpen] = useState(false);

  const getOptionById = id => options.find(option => option.schoolId === id);
  const selectedOption = getOptionById(selected);

  return (
    <div className="_1300-container">
      <div className="subnav-flex">
        <div>
          <div data-delay={0} className="dropdown w-dropdown">
            <div
              className={classnames('school-sites-dropdown w-dropdown-toggle', { 'w--open is-open': isOpen })}
              onClick={() => setOpen(!isOpen)}
            >
              <h5>{selectedOption.name}</h5>
              <img
                src={DropdownIcon}
                alt="show-hide"
                className="image-3 show-hide-icon"
              />
            </div>
            <nav className={classnames('dropdown-list w-dropdown-list', { 'w--open': isOpen })}>
              {options.map(option => (
                <span
                  key={option.schoolId}
                  className="sites-dropdown w-dropdown-link"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(false);
                    onSelect(option.schoolId);
                  }}
                >
                  {option.name}
                </span>
              ))}
            </nav>
          </div>
        </div>
        {
          selected && (
            <div className="dashboard-links">
              <NavLink
                to="/backpack-buddy/dashboard"
                activeClassName="active"
                className="dashboard-link w-inline-block"
              >
                <div>Dashboard</div>
              </NavLink>
              <NavLink
                to="/backpack-buddy/contacts"
                activeClassName="active"
                className="dashboard-link w-inline-block"
              >
                <div>Contacts</div>
              </NavLink>
              <NavLink
                to="/backpack-buddy/documents"
                activeClassName="active"
                className="dashboard-link w-inline-block"
              >
                <div>Document Library</div>
              </NavLink>
              <NavLink
                to="/backpack-buddy/surveys"
                activeClassName="active"
                className="dashboard-link last w-inline-block"
              >
                <div>Surveys</div>
              </NavLink>
            </div>
          )
        }
      </div>
    </div>
  );
};

SchoolDropDownMenu.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SchoolDropDownMenu;
