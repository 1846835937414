import gql from 'graphql-tag';

export default gql`
  mutation adminRestoreEducatorUser($educatorId: ID!) {
    adminRestoreEducatorUser(educatorId: $educatorId) {
      id
      deletedAt
    }
  }
`;
