import React from 'react';
import PropTypes from 'prop-types';
import ContactForm from './ContactForm';

const Contacts = (props) => {
  const { site } = props;

  return (
    <div className="div-block-19">
      <h2>Contacts</h2>
      <div className="last-edit">{`Last Edited: ${site.lastEditedSiteMembers}`}</div>
      <ContactForm {...props} />
    </div>
  );
};

Contacts.propTypes = {
  site: PropTypes.object.isRequired,
};

export default Contacts;
