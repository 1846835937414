import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrevIcon from '../../images/left-page.svg';
import NextIcon from '../../images/right-page.svg';

const DefaultButton = (props) => {
  const { children } = props;

  return (
    <button type="button" className="page-link w-inline-block" {...props}>{children}</button>
  );
};

DefaultButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

class Pagination extends Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { pages } = this.props;

    if (pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => (
    visiblePages.filter(page => page <= totalPages)
  );

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  };

  changePage(newPage = null) {
    const { page, pages, onPageChange } = this.props;
    const activePage = page + 1;

    if (newPage === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(newPage, pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, pages),
    });

    onPageChange(newPage - 1);
  }

  render() {
    const { PageButtonComponent, page, pages } = this.props;
    const { visiblePages } = this.state;
    const activePage = page + 1;

    return (Boolean(pages) && pages > 1) && (
      <div className="pagination-div">
        <PageButtonComponent
          onClick={() => {
            if (activePage === 1) return;
            this.changePage(activePage - 1);
          }}
          disabled={activePage === 1}
        >
          <img src={PrevIcon} alt="previous" />
        </PageButtonComponent>
        {visiblePages.map((pageNum, index, array) => (
          <PageButtonComponent
            key={pageNum}
            className={
              activePage === pageNum
                ? 'page-link w-inline-block active-page'
                : 'page-link w-inline-block'
            }
            onClick={() => this.changePage(pageNum)}
          >
            {array[index - 1] + 2 < pageNum ? `...${pageNum}` : pageNum}
          </PageButtonComponent>
        ))}
        <PageButtonComponent
          onClick={() => {
            if (activePage === pages) return;
            this.changePage(activePage + 1);
          }}
          disabled={activePage === pages}
        >
          <img src={NextIcon} alt="next" />
        </PageButtonComponent>
      </div>
    );
  }
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  PageButtonComponent: PropTypes.func,
};

Pagination.defaultProps = {
  PageButtonComponent: DefaultButton,
};

export default Pagination;
