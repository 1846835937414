import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HFBLogo from '../images/HFB-logo.png';
import Logo from '../components/Logo';
import NavDropdown from '../components/NavDropdown';

const withProgramsLayout = (Component) => {
  const HOC = (props) => {
    const { program } = props;
    const programUrl = program && program.programUrl ? program.programUrl : '/';

    return (
      <Fragment>
        <div className="section-topnav">
          <div className="topnav-container">
            <div className="topnav-logos-flex">
              <Link to="/" className="hfb-logo-link w-inline-block">
                <img src={HFBLogo} width={125} alt="" className="hfb" />
              </Link>
              <div className="topnav-divider" />
              <Link to={programUrl} aria-current="page" className="page-logo-link w-inline-block w--current">
                <Logo program={program} />
              </Link>
            </div>
            <NavDropdown {...props} />
          </div>
        </div>
        <Component {...props} />
      </Fragment>
    );
  };

  HOC.propTypes = {
    dispatch: PropTypes.func.isRequired,
    program: PropTypes.object.isRequired,
  };

  return HOC;
};

export default withProgramsLayout;
