import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GRAPHQL_URI } from '../config';
import links from './links';

const client = new ApolloClient({
  uri: GRAPHQL_URI,
  link: links,
  cache: new InMemoryCache(),
});

export default client;
