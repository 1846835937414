import React, { Fragment } from 'react';

const TeachersAidProgramAgreement = (props = null) => {
  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">Program Agreement</h4>
      <div className="agreement-div scroll">
        <h1 style={{paddingLeft: '18pt', textIndent: '0pt', textAlign: 'center'}}>Exhibit D: Teachers Aid</h1>
        <p className="s19" style={{paddingTop: '12pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Program Description</p>
        <p style={{paddingTop: '12pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
          Many children can’t afford the most basic school supplies. Without the proper tools to do the work, children are challenged to achieve academic success. Many compassionate teachers pay for school supplies from their own pockets, so
          their students have more productive, efficient learning experience. The Teachers Aid program provides the supplies kids need to succeed.
        </p>
        <p className="s19" style={{paddingTop: '11pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Program Details</p>
        <ol id="l54">
          <li data-list-text={1.}>
            <p style={{paddingTop: '12pt', paddingLeft: '78pt', textIndent: '-18pt', textAlign: 'justify'}}>
              Schools participating must be a Title 1 school, 90% or higher (urban schools) and 70% or higher rural schools of the student population on the free and reduced-price school lunch program.
            </p>
          </li>
          <li data-list-text={2.}>
            <p style={{paddingTop: '12pt', paddingLeft: '78pt', textIndent: '-18pt', textAlign: 'left'}}>
              <b>Teachers Aid product is intended for education purposes only!</b> This product is to assist the students and educators with bridging the gap of the lack of educational materials and ensure a successful school year.
            </p>
          </li>
        </ol>
        <ol id="l55">
          <li data-list-text={2.}>
            <p style={{paddingTop: '12pt', paddingLeft: '71pt', textIndent: '-12pt', textAlign: 'left'}}>Site Coordinators has been assigned and agrees too:</p>
            <ol id="l56">
              <li data-list-text="i."><p style={{paddingTop: '11pt', paddingLeft: '113pt', textIndent: '-14pt', textAlign: 'left'}}>Assist the teachers with scheduling an in-person shopping day and time in an appropriate system.</p></li>
              <li data-list-text="ii."><p style={{paddingLeft: '113pt', textIndent: '-16pt', textAlign: 'left'}}>Responsible for submitting and keeping the teacher roster updated after its initial application submission.</p></li>
              <li data-list-text="iii.">
                <p style={{paddingLeft: '113pt', textIndent: '-19pt', textAlign: 'left'}}>
                  Inform staff that only teachers, counselors, and approved personnel (by HFB staff) can participate in the program. All other staff members are not eligible to participate.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text={3.}><p style={{paddingTop: '11pt', paddingLeft: '71pt', textIndent: '-12pt', textAlign: 'left'}}>In-Store is only once a month.</p></li>
          <li data-list-text={4.}><p style={{paddingTop: '11pt', paddingLeft: '71pt', textIndent: '-12pt', textAlign: 'left'}}>All in-store appointments must be made using our scheduling platform.</p></li>
          <li data-list-text={5.}><p style={{paddingTop: '11pt', paddingLeft: '71pt', textIndent: '-12pt', textAlign: 'left'}}>ENTIRE roster must be completed and submitted along with the application.</p></li>
          <li data-list-text={6.}>
            <p style={{paddingTop: '11pt', paddingLeft: '59pt', textIndent: '0pt', textAlign: 'left'}}>Consent to Client Data Privacy Statement. <span style={{color: '#221f1f'}}>For any questions that you may have about the program.</span></p>
          </li>
          <li data-list-text={7.}>
            <p style={{paddingTop: '11pt', paddingLeft: '76pt', textIndent: '-17pt', lineHeight: '12pt', textAlign: 'left'}}>For any additional information, please contact the Teachers Aid staff <span className="s27">at</span></p>
          </li>
        </ol>
        <p style={{paddingLeft: '59pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left'}}><a href="mailto:teachersaid@houstonfoodbank.org" className="s13">teachersaid@houstonfoodbank.org</a></p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
      </div>
    </Fragment>
  );
};

export default TeachersAidProgramAgreement;
