import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  required,
  passwordsMatch,
  email,
  minLength6,
  oneUppercase,
  phone,
  normalizePhone,
} from '../../helpers';
import TextField from '../TextField';

const RegisterForm = reduxForm({ form: 'register' })((props) => {
  const { handleSubmit, submitting, register } = props;

  return (
    <form onSubmit={handleSubmit(register)} id="email-form" name="email-form" className="login-form">
      <div className="field-combo-wrapper sign-up">
        <div className="field-combo">
          <Field
            component={TextField}
            label={<label htmlFor="First-2" className="field-label">First Name</label>}
            validate={[required]}
            name="firstName"
            autoComplete="given-name"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<label htmlFor="Last-2" className="field-label">Last Name</label>}
            component={TextField}
            validate={[required]}
            name="lastName"
            autoComplete="family-name"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper sign-up">
        <div className="field-combo">
          <Field
            label={<label htmlFor="Phone-2" className="field-label">Phone Number</label>}
            component={TextField}
            validate={[required, phone]}
            normalize={normalizePhone}
            name="phone"
            autoComplete="tel"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<label htmlFor="Email-Signup-2" className="field-label">Email</label>}
            component={TextField}
            validate={[required, email]}
            name="email"
            type="email"
            placeholder="you@example.com"
            autoComplete="email"
            className="text-field w-input"
          />

        </div>
      </div>
      <div className="field-combo-wrapper sign-up">
        <div className="field-combo">
          <Field
            label={<label htmlFor="Password-Create-2" className="field-label">Password</label>}
            component={TextField}
            validate={[required, minLength6, oneUppercase]}
            name="password"
            type="password"
            placeholder="Create Password"
            autoComplete="new-password"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<label htmlFor="Password-Confirm-2" className="field-label">Confirm Password</label>}
            component={TextField}
            validate={[required, passwordsMatch]}
            name="confirm-password"
            type="password"
            placeholder="Confirm Password"
            autoComplete="new-password"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="password-guide">
        <div>
          Choose a password that is at least 6 characters long and
          includes at least 1 uppercase character.
        </div>
      </div>
      <input
        type="submit"
        value={submitting ? 'Please wait...' : 'Create Account'}
        disabled={submitting}
        className="btn-fill-p top-pad-ten w-button"
      />
    </form>
  );
});

export default RegisterForm;
