import gql from 'graphql-tag';

export default gql`
  query {
    userApplicationsSchoolMarket {
      status
      applicationSchoolMarketId
      termFormattedShort
      term {
        year
      }
      school {
        name
      }
    }
  }
`;
