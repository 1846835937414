import React from 'react';
import PropTypes from 'prop-types';

const DropDownMenu = ({
  input,
  label,
  options,
  placeholder,
  meta: { touched, error },
  ...otherProps
}) => (
  <div style={{ position: 'relative' }}>
    {label}
    <select {...input} {...otherProps}>
      <option value="">{placeholder}</option>
      {
        options.map(({ value, label: optionLabel }) => (
          <option key={value} value={value}>{optionLabel}</option>
        ))
      }
    </select>
    {touched && error
      && (
        <span style={{
          position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
        }}
        >
          {error}
        </span>
      )
    }
  </div>
);

DropDownMenu.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

DropDownMenu.defaultProps = {
  placeholder: 'Select one...',
};

export default DropDownMenu;
