import React, { Fragment } from 'react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import getFormError from '../helpers/getFormError';
import withAnonymousLayout from '../helpers/withAnonymousLayout';
import { registerMutation, educatorUserQuery } from '../apollo';
import RegisterForm from '../components/RegisterForm';
import setUserEnv from '../helpers/setUserEnv';

const RegisterPage = () => (
  <Mutation mutation={registerMutation}>
    {(register, { data }) => {
      const registerEducator = async ({
        firstName,
        lastName,
        phone,
        email,
        password,
      }) => {
        try {
          await register({
            variables: {
              input: {
                firstName,
                lastName,
                phone,
                email,
                password,
              },
            },
          });
        } catch (error) {
          toast(getFormError(error));
        }
      };

      if (data) {
        setUserEnv(data.register, educatorUserQuery);
      }

      return (
        <Fragment>
          <h2 className="purple-headline half-width">Create a new account.</h2>
          <div className="form-block w-form">
            <RegisterForm register={registerEducator} />
          </div>
        </Fragment>
      );
    }}
  </Mutation>
);

export default withAnonymousLayout(RegisterPage);
