import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Switch, Redirect, NavLink,
} from 'react-router-dom';
import withAdminLayout from '../helpers/withAdminLayout';
import SiteApplicationSettings from '../components/SiteApplicationSettings';

const AdminSiteProgramSettingsPage = (props) => {
  const { match } = props;

  return (
    <Fragment>
      <div className="profile-intro">
        <h2 className="purple-headline">Program Settings</h2>
        <div className="divider-2 settings" />
      </div>
      <div className="div-block-13">
        <div className="detail-tabs">
          <NavLink
            key="Application"
            exact
            to={match.path}
            activeClassName="active"
            className="account-links w-inline-block"
          >
            <div>Application</div>
          </NavLink>
        </div>
        <div className="div-block-19">
          <Switch>
            <Route
              key="Application"
              exact
              path={match.path}
              render={() => <SiteApplicationSettings {...props} />}
            />
            <Route component={() => <Redirect to={match.path} />} />
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

AdminSiteProgramSettingsPage.propTypes = {
  match: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default withAdminLayout(AdminSiteProgramSettingsPage);
