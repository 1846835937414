import gql from 'graphql-tag';

export default gql`
  mutation adminEditNotification($input: AdminNotificationInput!) {
    adminEditNotification(input: $input) {
      notificationId
      text
    }
  }
`;
