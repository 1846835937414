import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import handleToastError from '../../helpers/handleToastError';
import TextArea from '../TextArea';
import { ReportsDateContext } from './index';
import { schoolReportsQuery, submitReportMutation } from '../../apollo';

class SubmitReportFormClass extends Component {
  componentDidMount() {
    const { initialize, termMonth } = this.props;

    const initialValues = {
      comment: (termMonth.distributionReport) ? termMonth.distributionReport.comments : '',
    };

    initialize(initialValues);
  }

  save = async (data, mutation) => {
    const { valid, termMonth } = this.props;
    if (valid) {
      await mutation({
        variables: {
          input: {
            termMonthId: termMonth.termMonthId,
            comments: data.comment,
          },
        },
      });
      return true;
    }
    return false;
  };

  render = () => {
    const {
      termMonth,
      submitting,
      handleSubmit,
      isAdmin,
      selectedSchool,
    } = this.props;

    return (
      <div className="w-form">
        <ReportsDateContext.Consumer>
          {context => (
            <Mutation
              mutation={submitReportMutation}
              refetchQueries={[
                {
                  query: schoolReportsQuery,
                  variables: { ...context.filterDates, schoolId: selectedSchool },
                },
              ]}
              onError={mutationError => handleToastError(mutationError)}
              onCompleted={() => toast('Report Submitted')}
            >
              {submitReport => (
                <form className="form-2">
                  <Field
                    name="comment"
                    maxLength={5000}
                    className="comment w-input field-textarea"
                    placeholder={termMonth.distributionReport ? 'No comments.' : 'Add comments about this report.'}
                    component={TextArea}
                    disabled={termMonth.distributionReport}
                    wrapperStyles={{ width: `${isAdmin ? '100%' : '50%'}`, position: 'relative' }}
                  />
                  {!termMonth.distributionReport && (
                    <button
                      type="button"
                      disabled={submitting}
                      className="form-button w-button"
                      onClick={handleSubmit(
                        formData => this.save(formData, submitReport),
                      )}
                    >
                      {(submitting ? 'Please wait...' : 'Submit')}
                    </button>
                  )}

                  {termMonth.distributionReport && !isAdmin && (
                    <p className="paragraph-3">
                      {`Submitted by ${termMonth.distributionReport.submittedBy}`}
                      <br />
                      {`${moment(termMonth.distributionReport.createdAt).format('MMMM Do, YYYY h:mm a')}`}
                    </p>
                  )}
                </form>
              )}
            </Mutation>
          )}
        </ReportsDateContext.Consumer>
      </div>
    );
  }
}

const SubmitReportForm = reduxForm({
  enableReinitialize: true,
})(SubmitReportFormClass);

SubmitReportFormClass.propTypes = {
  valid: PropTypes.bool.isRequired,
  termMonth: PropTypes.object.isRequired,
  selectedSchool: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

SubmitReportFormClass.defaultProps = {
  isAdmin: false,
};

const mapStateToProps = (state, props) => {
  const valuesInitial = {
    comment: (props.termMonth.distributionReport) ? props.termMonth.distributionReport.comments : '',
  };

  return {
    initialValues: valuesInitial,
  };
};

export default connect(mapStateToProps)(SubmitReportForm);
