import gql from 'graphql-tag';

export default gql`
  {
    surveys {
      surveyId
      link
      title
      createdAt
    }
  }
`;
