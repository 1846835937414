import gql from 'graphql-tag';

export default gql`
  query adminConfigBackpackBuddy {
    adminConfigBackpackBuddy {
      id
      configBackpackBuddyId
      summerEnabled
      schoolEnabled
      lastEdited
    }
  }
`;
