import { saveAs } from 'file-saver';
import template from '../files/backpackbuddy-student-template.xlsx';

const downloadTemplate = () => {
  try {
    saveAs(template, 'backpackbuddy-student-template.xlsx');
  } catch (err) {
    window.navigator.msSaveBlob(template, 'backpackbuddy-student-template.xlsx');
  }
};

export default downloadTemplate;
