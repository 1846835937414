import gql from 'graphql-tag';

export default gql`
  query schoolsBackpackBuddy($termId: ID) {
    schoolsBackpackBuddy(termId: $termId) {
      schoolId
      backpackBuddyId
      name
      address1
      address2
      city
      state
      zip
      county
      districtId
      districtName
      type
      gradesServed
      foodSafetyStatus
      statusBackpackBuddy
      deletedAt
      teamMembersBackpackBuddy {
        teamMemberId
        fullName
        email
        type
      }
      applicationsBackpackBuddy {
        applicationBackpackBuddyId
        new
        termFormattedShort
        submittedAt
        status
        certififedVerification
        emailSecondaryCoordinator
        willHelpMediaEvents
        willDistributeInfo
        programInterests
        agreeToProgramPurpose
        agreeToProgramResponsibilities
        numFreeLunch
        numReducedLunch
        numTotalEnrollment
        createdAt
      }
      termMonths {
        startDate
        monthlyAllocation
      }
    }
  }
`;
