import gql from 'graphql-tag';

export default gql`
  mutation uploadStudents($input: UploadStudentInput!) {
    uploadStudents (input: $input) {
      termMonthId
      startDate
      endDate
      weeklyAllocation
      monthlyAllocation
      distributionReport {
        distributionReportId
        comments
        createdAt
        submittedBy
        educatorId
      }
      distributions {
        distributionId
        studentName
        studentIdNumber
        testimonial
        distributionDateEntries {
          distributionId
          distributionNumber
          termMonthDistributionDateId
        }
      }
      distributionDates {
        date
        termMonthDistributionDateId
      }
    }
  }
`;
