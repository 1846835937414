import gql from 'graphql-tag';

export default gql`
  mutation adminCreateSurvey($input: AdminSurveyInput!) {
    adminCreateSurvey(input: $input) {
      surveyId
      link
      title
      createdAt
    }
  }
`;
