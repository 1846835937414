/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import {
  required,
  teamRequired,
  applicationTerm,
} from '../../helpers';
import getLabel from '../../helpers/getLabel';
import ApplicationActions from '../ApplicationActions';
import TextField from '../TextField';
import RadioButtonGroup from '../RadioButtonGroup';
import CheckboxGroup from '../CheckboxGroup';
import Checkbox from '../Checkbox';
import SchoolSelectInput from '../SchoolSelectInput';
import TeamMembers from '../TeamMembers';
import { allSchoolsQuery } from '../../apollo';
import sortGradesServed from '../../helpers/sortGradesServed';
import DistrictDropDownMenu from '../DistrictDropDownMenu';
import TextArea from '../TextArea';

const StepOneForm = (props) => {
  const {
    data,
    handleSubmit,
    save,
    change,
    formSyncErrors,
    newTeamMember,
    readOnly,
    termsAvailable,
    configBackpackBuddy,
    termsForYear,
    invalid,
    submitFailed,
    enums: {
      gradesServed,
      schoolTypes,
      termTypes,
      backpackBuddyTeamMemberTypes,
    },
  } = props;

  const {
    schoolEnabled,
    summerEnabled,
  } = configBackpackBuddy.configBackpackBuddy;

  const termOptions = termsForYear.filter(el => ((summerEnabled && el.type == 'SUMMER') || (schoolEnabled && el.type == 'SCHOOL')))
  .map(({ termId, type, termFormatted }) => {
    return ({
      value: termId,
      label: (
        <span>
          {getLabel(termTypes.options, type)}
          <span className="term-text">{termFormatted}</span>
        </span>
      ),
    });
  });

  return (
    <Form onSubmit={handleSubmit(save)} id="email-form" className="backbackbuddy-form">
      <h4 className="purple-headline boxed-in">Application Type</h4>
      {
        !termsForYear.length ? (
          <span style={{ color: 'red' }}>
            We are not currently accepting applications for this year.
          </span>
        ) : (
          <Field
            name="termId"
            options={termOptions}
            component={RadioButtonGroup}
            className="radio-field w-radio"
            disabled={readOnly || !termsAvailable}
            validate={value => applicationTerm(value, termsForYear, summerEnabled, schoolEnabled)}
          />
        )
      }
      {(!(data && data.applicationBackpackBuddy && (data.applicationBackpackBuddy.status === 'APPROVED')) && !termsAvailable && Boolean(termsForYear.length)) && (
        <span style={{ color: 'red', display: 'block' }}>
          We are not currently accepting new applications.
        </span>
      )}
      <h4 className="purple-headline boxed-in">School Information</h4>
      <div className="radio-question">
        <label htmlFor="First-3" className="field-label">Did your site participate in this program last year?</label>
        <Field
          name="new"
          options={[
            {
              value: false,
              label: 'Yes',
            },
            {
              value: true,
              label: 'No',
            },
          ]}
          component={RadioButtonGroup}
          className="radio-field inline-radio w-radio"
          parse={val => val === 'true'}
          disabled={readOnly || !termsAvailable}
          validate={required}
        />
      </div>
      <label htmlFor="First" className="field-label">School Type</label>
      <Field
        name="school.type"
        validate={required}
        options={schoolTypes.options}
        component={RadioButtonGroup}
        className="radio-field inline-radio w-radio"
        disabled={readOnly || !termsAvailable}
      />
      <div className="field-combo-wrapper dropdown-select">
        <div className="field-combo">
          <Query query={allSchoolsQuery} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              const schoolOptions = get(data, 'allSchools', []).map(school => ({
                school,
                label: school.name,
              }));

              return (
                <Field
                  label={<label htmlFor="First-3" className="field-label">School Name</label>}
                  component={SchoolSelectInput}
                  options={schoolOptions}
                  includeTeamMembersBackpackBuddy
                  validate={[required]}
                  change={change}
                  loading={loading}
                  name="school.name"
                  placeholder={error ? 'Error loading schools, please refresh.' : 'Enter School Name'}
                  className="text-field w-input"
                  disabled={readOnly || !termsAvailable}
                />
              );
            }}
          </Query>
        </div>
        <div className="field-combo">
          <Field
            name="school.districtId"
            className="text-field w-select"
            component={DistrictDropDownMenu}
            label={<label htmlFor="First-3" className="field-label">School District</label>}
            validate={required}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="textarea-combo">
        <div className="field-combo">
          <Field
            label={<label htmlFor="First-3" className="field-label">Organization Type</label>}
            component={TextField}
            name="organizationType"
            className="text-field w-input"
            placeholder=""
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-textarea half">
          <Field
            name="nonProfitOrganizations"
            component={TextArea}
            maxLength={255}
            className="field-textarea w-input"
            label={<label htmlFor="First-3" className="field-label">If you are a 501(c)(3) organization, please list the schools and/or school district (including address), you are partnered with below. If you are a school, please skip this section.</label>}
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={<label htmlFor="First-3" className="field-label">Address Line 1</label>}
            component={TextField}
            validate={[required]}
            name="school.address1"
            className="text-field w-input"
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo">
          <Field
            label={<label htmlFor="Last-3" className="field-label">Address Line 2 (optional)</label>}
            component={TextField}
            name="school.address2"
            className="text-field w-input"
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={<label htmlFor="First-3" className="field-label">City</label>}
            component={TextField}
            validate={[required]}
            name="school.city"
            className="text-field w-input"
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo">
          <Field
            label={<label htmlFor="Last-3" className="field-label">Country</label>}
            component={TextField}
            validate={[required]}
            name="school.county"
            className="text-field w-input"
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={<label htmlFor="First-3" className="field-label">State</label>}
            component={TextField}
            validate={[required]}
            name="school.state"
            className="text-field w-input"
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo">
          <Field
            label={<label htmlFor="Last-3" className="field-label">Zip</label>}
            component={TextField}
            validate={[required]}
            name="school.zip"
            className="text-field w-input"
            disabled={readOnly || !termsAvailable}
          />
        </div>
      </div>
      <label htmlFor="Last-3" className="field-label">Grades Served (check all that apply)</label>
      <Field
        name="school.gradesServed"
        validate={required}
        options={sortGradesServed(gradesServed)}
        component={CheckboxGroup}
        className="checkbox-inline w-checkbox _7"
        disabled={readOnly || !termsAvailable}
      />
      <h4 className="purple-headline boxed-in">Team Information</h4>
      <p>
        Enter all participants including the principal, coordinators,
        and any staff members or volunteers that will help with the program
        and have access to children on a regular basis.
      </p>
      <br />
      <Field
        name="emailSecondaryCoordinator"
        component={Checkbox}
        className="checkbox-inline w-checkbox"
        label="Include Secondary Coordinator on program communication emails for reports, newsletters and updates."
        disabled={readOnly || !termsAvailable}
      />
      <div className="team-block">
        <h5 className="grey-headline">Manage Program Participants</h5>
        <Field
          name="school.teamMembersBackpackBuddy"
          validate={teamRequired}
          component={TeamMembers}
          change={change}
          newTeamMember={newTeamMember}
          formSyncErrors={formSyncErrors}
          disabled={readOnly || !termsAvailable}
          teamMemberTypes={backpackBuddyTeamMemberTypes}
        />
      </div>
      <ApplicationActions {...props} />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

StepOneForm.propTypes = {
  adminView: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  termsAvailable: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  formSyncErrors: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  newTeamMember: PropTypes.object,
  user: PropTypes.object.isRequired,
  configBackpackBuddy: PropTypes.object.isRequired,
  termsForYear: PropTypes.array.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

StepOneForm.defaultProps = {
  readOnly: false,
  newTeamMember: null,
  data: null,
};

export default StepOneForm;
