import React from 'react';
import PropTypes from 'prop-types';
import ProfileForm from './ProfileForm';

const Profile = (props) => {
  const { site } = props;

  return (
    <div className="div-block-19">
      <h2>Site Profile</h2>
      <div className="last-edit">{`Last Edited: ${site.lastEdited}`}</div>
      <div className="w-form">
        <div className="field-label">{`First Year Applied: ${site.firstYearApplied}`}</div>
        <ProfileForm {...props} />
      </div>
    </div>
  );
};

Profile.propTypes = {
  site: PropTypes.object.isRequired,
};

export default Profile;
