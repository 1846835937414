import gql from 'graphql-tag';

export default gql`
  mutation adminEditConfigPartnerServices($input: ConfigPartnerServicesInput!) {
    adminEditConfigPartnerServices(input: $input) {
      id
      configPartnerServicesId
      acceptingApplications
    }
  }
`;
