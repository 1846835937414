import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import handleToastError from '../helpers/handleToastError';
import ContactForm from '../components/ContactForm';
import withSchoolLinks from '../helpers/withSchoolLinks';
import { schoolContactsQuery, editSchoolContactsMutation } from '../apollo';

const EducatorContactsPage = (props) => {
  const { selectedSchool } = props;

  if (!selectedSchool) return <Redirect to="/backpack-buddy/dashboard" />;

  return (
    <div className="section-info">
      <div className="wrapper">
        <h2>School Contacts</h2>
        <Mutation
          mutation={editSchoolContactsMutation}
          refetchQueries={[{ query: schoolContactsQuery, variables: { schoolId: selectedSchool } }]}
          onError={mutationError => handleToastError(mutationError)}
          onCompleted={() => toast('Update Successful')}
        >
          {(editSchoolContacts) => {
            const update = values => (
              editSchoolContacts({
                variables: {
                  input: {
                    ...values,
                  },
                },
              })
            );

            return (
              <Query query={schoolContactsQuery} variables={{ schoolId: selectedSchool }}>
                {({ loading, error: queryError, data: { schoolContacts } }) => {
                  if (loading) {
                    return <Loading />;
                  }

                  if (queryError) {
                    return <BlankState title="Error" subtitle="There was an error loading" />;
                  }

                  if (!schoolContacts.length) {
                    return <BlankState title="No Results" />;
                  }

                  return (
                    <ContactForm
                      schoolContacts={schoolContacts}
                      onSubmit={update}
                      {...props}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Mutation>

      </div>

    </div>
  );
};

EducatorContactsPage.propTypes = {
  selectedSchool: PropTypes.string.isRequired,
};

export default withSchoolLinks(EducatorContactsPage);
