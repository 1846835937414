import gql from 'graphql-tag';

export default gql`
  mutation adminArchiveDistrict($districtId: ID!) {
    adminArchiveDistrict(districtId: $districtId) {
      id
      districtId
      name
      deletedAt
    }
  }
`;
