import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { required } from '../../../helpers';
import TextField from '../../TextField';
import DatePicker from '../../DatePicker';

const FoodSafetyForm = reduxForm({
  form: 'schoolFoodSafety',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  update,
  reset,
  dispatch,
}) => {
  const submitAndReset = data => (
    update(data).then(() => (
      dispatch(reset('schoolFoodSafety'))
    ))
  );

  return (
    <Form onSubmit={handleSubmit(submitAndReset.bind(this))}>
      <div className="field-combo-wrapper-2 w-clearfix">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Completed By</label>}
            component={TextField}
            validate={required}
            name="completedBy"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="date-range date-pad">
        <div className="date-from date-stack">
          <Field
            label={<div htmlFor="First-3" className="field-label-2 inline-date">Completion Date</div>}
            component={DatePicker}
            validate={required}
            name="completionDate"
            className="w-input"
          />
        </div>
        <div className="date-from date-stack">
          <Field
            label={<div htmlFor="First-3" className="field-label-2 inline-date">Expiration Date</div>}
            component={DatePicker}
            validate={required}
            name="expirationDate"
            className="w-input"
          />
        </div>
      </div>
      <input
        type="submit"
        value={submitting ? 'Please wait...' : 'Save Changes'}
        disabled={submitting}
        className="btn-solid-green w-button"
      />
    </Form>
  );
});

const mapStateToProps = (reduxState, props) => {
  const { school } = props;
  const { schoolId } = school;

  return {
    initialValues: {
      schoolId,
    },
  };
};

export default connect(mapStateToProps)(FoodSafetyForm);
