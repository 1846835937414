import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { maxBy, get } from 'lodash';
import { parseNumber, required } from '../../../helpers';
import TextField from '../../TextField';

const AllocateForm = reduxForm({
  form: 'schoolAllocations',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  update,
}) => (
  <Form onSubmit={handleSubmit(update)}>
    <div className="field-combo-wrapper-2 w-clearfix">
      <div className="field-combo-2">
        <Field
          label={<label htmlFor="First-3" className="field-label">Number of Weekly Backpacks</label>}
          component={TextField}
          validate={required}
          name="weeklyAllocation"
          className="text-field w-input"
          parse={parseNumber}
          maxLength={4}
        />
      </div>
    </div>
    <input
      type="submit"
      value={submitting ? 'Please wait...' : 'Save Changes'}
      disabled={submitting}
      className="btn-solid-green w-button"
    />
  </Form>
));

const mapStateToProps = (reduxState, props) => {
  const { school } = props;
  const { schoolId } = school;
  const allocationEntries = get(school, 'allocationEntries');
  const mostRecent = maxBy(allocationEntries, 'createdAt');
  const weeklyAllocation = get(mostRecent, 'weeklyAllocation', 0);

  return {
    initialValues: {
      schoolId,
      weeklyAllocation,
    },
  };
};

export default connect(mapStateToProps)(AllocateForm);
