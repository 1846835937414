import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Switch, Redirect, NavLink,
} from 'react-router-dom';
import withAdminLayout from '../helpers/withAdminLayout';
import ActiveTerms from '../components/ActiveTerms';
import Emails from '../components/Emails';
import Notifications from '../components/Notifications';
import SchoolApplicationSettings from '../components/SchoolApplicationSettings';
import {
  adminConfigSchoolMarketQuery,
  adminEditConfigSchoolMarketMutation,
  adminConfigTeachersAidQuery,
  adminEditConfigTeachersAidMutation,
  adminConfigBackpackBuddyQuery,
  adminEditConfigBackpackBuddyMutation,
} from '../apollo';

const AdminSchoolProgramSettingsPage = (props) => {
  const { match, program: { name } } = props;

  const backpackBuddy = [
    {
      title: 'Active Terms',
      Component: ActiveTerms,
      query: adminConfigBackpackBuddyQuery,
      mutation: adminEditConfigBackpackBuddyMutation,
      path: match.path,
    },
    {
      title: 'Emails',
      Component: Emails,
      path: `${match.path}/emails`,
    },
    {
      title: 'Banner Notifications',
      Component: Notifications,
      path: `${match.path}/notifications`,
    },
  ];

  const schoolMarket = [
    {
      title: 'Active Terms',
      Component: ActiveTerms,
      query: adminConfigSchoolMarketQuery,
      mutation: adminEditConfigSchoolMarketMutation,
      path: match.path,
    },
    {
      title: 'Application',
      Component: SchoolApplicationSettings,
      query: adminConfigSchoolMarketQuery,
      mutation: adminEditConfigSchoolMarketMutation,
      path: `${match.path}/application`,
    },
  ];

  const teachersAid = [
    {
      title: 'Active Terms',
      Component: ActiveTerms,
      query: adminConfigTeachersAidQuery,
      mutation: adminEditConfigTeachersAidMutation,
      path: match.path,
    },
    {
      title: 'Application',
      Component: SchoolApplicationSettings,
      query: adminConfigTeachersAidQuery,
      mutation: adminEditConfigTeachersAidMutation,
      path: `${match.path}/application`,
    },
  ];

  let settingsLinks = [];

  switch (name) {
    case 'Backpack Buddy':
      settingsLinks = backpackBuddy;
      break;
    case 'School Market':
      settingsLinks = schoolMarket;
      break;
    case 'Teachers Aid':
      settingsLinks = teachersAid;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <div className="profile-intro">
        <h2 className="purple-headline">Program Settings</h2>
        <div className="divider-2 settings" />
      </div>
      <div className="div-block-13">
        <div className="detail-tabs">
          {
            settingsLinks.map(({ title, path }) => (
              <NavLink
                key={title}
                exact
                to={path}
                activeClassName="active"
                className="account-links w-inline-block"
              >
                <div>{title}</div>
              </NavLink>
            ))
          }
        </div>
        <div className="div-block-19">
          <Switch>
            {
              settingsLinks.map(({ title, Component, path, query, mutation }) => (
                <Route
                  key={title}
                  exact
                  path={path}
                  render={() => <Component {...props} query={query} mutation={mutation} />}
                />
              ))
            }
            <Route component={() => <Redirect to={match.path} />} />
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

AdminSchoolProgramSettingsPage.propTypes = {
  match: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default withAdminLayout(AdminSchoolProgramSettingsPage);
