import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAdminLayout from '../helpers/withAdminLayout';
import SchoolUserTable from '../components/SchoolUserTable';
import SchoolUserDetail from '../components/SchoolUserDetail';

const AdminSchoolProgramUsersPage = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <SchoolUserTable {...props} />}
      />
      <Route
        path={`${match.path}/:id`}
        render={routerProps => <SchoolUserDetail {...props} {...routerProps} />}
      />
      <Route component={() => <Redirect to={`${match.path}`} />} />
    </Switch>
  );
};

AdminSchoolProgramUsersPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withAdminLayout(AdminSchoolProgramUsersPage);
