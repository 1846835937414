import gql from 'graphql-tag';

export default gql`
  query($educatorId: ID!) {
    adminEducatorUserDetails(educatorId: $educatorId) {
      id
      educatorId
      updatedAt
      fullName
      firstName
      lastName
      email
      deletedAt
      schools {
        id
        schoolId
        name
        districtId
        districtName
      }
      sites {
        id
        siteId
        name
      }
      organizations {
        id
        organizationId
        name
      }
    }
  }
`;
