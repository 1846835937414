import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import getFormError from '../helpers/getFormError';
import withAnonymousLayout from '../helpers/withAnonymousLayout';
import ResetPasswordForm from '../components/ResetPasswordForm';
import { resetPasswordMutation, educatorUserQuery } from '../apollo';
import setUserEnv from '../helpers/setUserEnv';

const ResetPasswordPage = ({ location }) => (
  <Mutation mutation={resetPasswordMutation}>
    {(resetPassword, { data, error }) => {
      const { search } = location;
      const params = new URLSearchParams(search);
      const passwordResetToken = params.get('token') || '';

      const onSubmit = ({ password }) => (
        resetPassword({
          variables: {
            input: {
              password,
              passwordResetToken,
            },
          },
        })
      );

      if (data) {
        setUserEnv(data.resetPassword, educatorUserQuery);
      }

      return (
        <Fragment>
          <h2 className="purple-headline half-width">Let&apos;s reset your password</h2>
          <div className="form-block w-form">
            <ResetPasswordForm onSubmit={onSubmit} />
            {
              error && (
                <div className="form-error w-form-fail" style={{ display: 'block' }}>
                  <div>{getFormError(error)}</div>
                </div>
              )
            }
          </div>
        </Fragment>
      );
    }}
  </Mutation>
);

ResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withAnonymousLayout(ResetPasswordPage);
