import gql from 'graphql-tag';

export default gql`
  query applicationPartnerServices($applicationPartnerServicesId: ID!) {
    applicationPartnerServices(applicationPartnerServicesId: $applicationPartnerServicesId) {
      applicationPartnerServicesId
      applicationYear
      status
      new
      operatingHoursStartTime
      operatingHoursEndTime
      gateCode
      isNonProfit
      isReligious
      servicesInterest
      organization {
        organizationId
        name
        einNumber
        address1
        address2
        city
        county
        state
        zip
        primaryPointOfContact {
          organizationMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        secondaryPointOfContact {
          organizationMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
      }
    }
  }
`;
