import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Field,
  FieldArray,
  reduxForm,
} from 'redux-form';
import {
  phone,
  email,
  normalizePhone,
  validateSchoolContacts,
} from '../../../helpers';
import getLabel from '../../../helpers/getLabel';
import TextField from '../../TextField';

const renderMembers = ({
  fields,
  enums,
}) => (
  <Fragment>
    {
      fields.map((member, index) => {
        const memberType = fields.get(index).type;

        return (
          <div className="contact-block" key={fields.get(index).type}>
            <h4>{getLabel(enums.schoolMarketTeamMemberTypes.options, memberType)}</h4>
            <div className="w-form">
              <div className="field-combo-wrapper-2 w-clearfix">
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">First Name</label>}
                    component={TextField}
                    name={`${member}.firstName`}
                    className="text-field w-input"
                  />
                </div>
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">Last Name</label>}
                    component={TextField}
                    name={`${member}.lastName`}
                    className="text-field w-input"
                  />
                </div>
              </div>
              <div className="field-combo-wrapper-2 w-clearfix">
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">Phone</label>}
                    component={TextField}
                    validate={phone}
                    normalize={normalizePhone}
                    name={`${member}.phone`}
                    className="text-field w-input"
                  />
                </div>
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">Email</label>}
                    component={TextField}
                    validate={email}
                    name={`${member}.email`}
                    className="text-field w-input"
                  />
                </div>
              </div>
              {
                memberType !== 'PRINCIPAL' && (
                <div className="field-combo-wrapper-2 w-clearfix">
                  <div className="field-combo-2">
                    <Field
                      label={<label htmlFor="First-3" className="field-label">Job Title</label>}
                      component={TextField}
                      name={`${member}.title`}
                      className="text-field w-input"
                    />
                  </div>
                </div>
                )
              }
            </div>
          </div>
        );
      })
    }
  </Fragment>
);

renderMembers.propTypes = {
  enums: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

const ContactForm = reduxForm({
  form: 'schoolContactsSchoolMarket',
  validate: validateSchoolContacts,
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  update,
  enums,
}) => (
  <Form onSubmit={handleSubmit(update)}>
    <FieldArray
      name="teamMembers"
      component={renderMembers}
      props={{ enums }}
    />
    <div>
      <input
        type="submit"
        value={submitting ? 'Please wait...' : 'Save Changes'}
        disabled={submitting}
        className="btn-solid-green w-button"
      />
    </div>
    <br />
    <br />
  </Form>
));

const mapStateToProps = (reduxState, props) => {
  const { school } = props;
  const { teamMembersSchoolMarket, schoolId } = school;
  const teamMemberTypes = ['PRIMARY_POINT_OF_CONTACT', 'PRINCIPAL'];
  const teamMembers = [];

  teamMemberTypes.forEach((memberType) => {
    const existingMember = teamMembersSchoolMarket.find(({ type }) => type === memberType);

    if (existingMember) {
      const {
        teamMemberId,
        email: existingMemberEmail,
        firstName,
        lastName,
        phone: existingMemberPhone,
        title,
        type,
      } = existingMember;

      teamMembers.push({
        teamMemberId,
        email: existingMemberEmail,
        firstName,
        lastName,
        phone: existingMemberPhone,
        title,
        type,
      });
    } else {
      teamMembers.push({ type: memberType });
    }
  });

  return {
    initialValues: {
      schoolId,
      teamMembers,
    },
  };
};

ContactForm.propTypes = {
  enums: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ContactForm);
