import React from 'react';
import PropTypes from 'prop-types';

const TeamMemberTextField = ({
  input,
  label,
  placeholder,
  meta: { error },
  ...otherProps
}) => (
  <div style={{ position: 'relative' }}>
    {label}
    <input
      {...input}
      {...otherProps}
      placeholder={placeholder}
      onKeyDown={e => e.keyCode === 13 && e.preventDefault()}
    />
    {error
      && (
        <span style={{
          position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
        }}
        >
          {error}
        </span>
      )
    }
  </div>
);

TeamMemberTextField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
};

TeamMemberTextField.defaultProps = {
  placeholder: null,
};

export default TeamMemberTextField;
