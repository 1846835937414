/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import moment from 'moment';
import Sum from 'lodash/sum';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import handleToastError from '../../helpers/handleToastError';
import AddIcon from '../../images/add.svg';
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/revise.svg';
import 'react-confirm-alert/src/react-confirm-alert.css';
import StudentDetailsModal from './StudentDetailsModal';
import { ReportsDateContext } from './index';
import { schoolReportsQuery, deleteDistributionsMutation } from '../../apollo';

const ReportDetailsSubmitted = ({ termMonth, isAdmin, ...otherProps }) => {
  const [selectedDistributions, setSelectedDistributions] = useState({});

  const handleChange = (event) => {
    const distributionId = event.target.id;
    setSelectedDistributions({ ...selectedDistributions, [distributionId]: event.target.checked });
  };
  const keys = Object.keys(selectedDistributions);
  const distributionIds = keys.filter(key => selectedDistributions[key]);

  const modalsControl = {};

  const [isModalOpen, setModalOpen] = useState(modalsControl);

  const openModal = (studentId) => {
    setModalOpen({ ...modalsControl, [studentId]: true });
  };

  const closeModal = (studentId) => {
    setModalOpen({ ...modalsControl, [studentId]: false });
  };

  const handleDeleteStudent = (deleteDistribution) => {
    const num = distributionIds.length;
    confirmAlert({
      message: (num > 1) ? `Are you sure you want to delete ${num} distributions?` : 'Are you sure you want to delete this distribution?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => deleteDistribution(),
        },
      ],
    });
  };

  const renderStudents = (distributions, distributionDates) => {
    const orderedDistributions = distributions.sort((a, b) => (
      a.studentName.localeCompare(b.studentName)
    ));

    return orderedDistributions.map((distributionEntry) => {
      const findEntryByDateId = dateId => distributionEntry.distributionDateEntries.find(
        entry => entry.termMonthDistributionDateId === dateId,
      );

      const formName = `addStudentForm_${distributionEntry.distributionId.trim()}`;

      return (
        <div className="report-table-row w-clearfix" key={distributionEntry.distributionId}>
          <StudentDetailsModal
            onClose={() => closeModal(formName)}
            isOpen={isModalOpen[formName] || false}
            termMonthId={termMonth.termMonthId}
            distribution={distributionEntry}
            distributionDates={distributionDates}
            form={formName}
            {...otherProps}
          />
          <div className="report-table-col cell-2">
            <div>{distributionEntry.studentName}</div>
          </div>
          <div className="report-table-col cell-1">
            <div>{distributionEntry.studentIdNumber}</div>
          </div>
          {distributionDates.map((date) => {
            const distribution = findEntryByDateId(date.termMonthDistributionDateId);
            const distributed = (distribution) ? distribution.distributionNumber : 0;
            return (
              <div
                className="report-table-col cell-1"
                key={`${distributionEntry.distributionId}-${date.termMonthDistributionDateId}`}
              >
                <div>{distributed}</div>
              </div>
            );
          })}
          <div className="report-table-col testimonial">
            <div>{distributionEntry.testimonial}</div>
          </div>
          {
            (isAdmin || !termMonth.distributionReport) && (
              <div className="report-table-col actions distribution">
                <button
                  type="button"
                  title="Edit"
                  className="crud-action-icon-wrapper w-inline-block"
                  onClick={() => openModal(formName)}
                >
                  <img
                    src={EditIcon}
                    width={14}
                    alt="edit"
                  />
                </button>
                <div className="checkbox-field w-checkbox">
                  <input
                    type="checkbox"
                    id={distributionEntry.distributionId}
                    name={distributionEntry.distributionId}
                    className="w-checkbox-input"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )
          }
        </div>
      );
    });
  };

  const renderDistributionDate = (distributionDate) => {
    const date = moment.utc(distributionDate.date);

    return (
      <span
        key={distributionDate.termMonthDistributionDateId}
        className="report-table-col cell-1 w-inline-block"
      >
        <div className="report-table-data date">
          {date.format('MMM DD')}
        </div>
      </span>
    );
  };

  const renderDistributionTotal = (distributionDate) => {
    const total = Sum(termMonth.distributions.map(
      distribution => Sum(distribution.distributionDateEntries.map(
        entry => (
          (distributionDate.termMonthDistributionDateId === entry.termMonthDistributionDateId)
            ? entry.distributionNumber
            : 0),
      )),
    ));

    return (
      <span
        key={distributionDate.termMonthDistributionDateId}
        className="report-table-col cell-1 w-inline-block"
      >
        <div className="report-table-data total">{total}</div>
      </span>
    );
  };

  return (
    <Fragment>
      {
        isAdmin && (
          <div className="div-block-18">
            <div className="report-text">
              <em>
                {`Submitted by ${termMonth.distributionReport.submittedBy}`}
                &nbsp;
                {`${moment.utc(termMonth.distributionReport.createdAt).local().format('MMMM Do, YYYY h:mm a')}`}
              </em>
            </div>
            <a
              onClick={(e) => {
                if (e && e.preventDefault) {
                  e.preventDefault();
                }
                openModal(`add_${termMonth.termMonthId}`);
              }}
              className="add-student-link w-inline-block pointer"
            >
              <img
                src={AddIcon}
                alt="add"
                className="image-6"
              />
              <span>
                Add a Student
              </span>
            </a>
          </div>
        )
      }
      <div id="report-details-wrapper" className="student-distribution-report">
        <StudentDetailsModal
          onClose={() => closeModal(`add_${termMonth.termMonthId}`)}
          isOpen={isModalOpen[`add_${termMonth.termMonthId}`] || false}
          termMonthId={termMonth.termMonthId}
          distribution={null}
          distributionDates={termMonth.distributionDates}
          form={`NewStudentForm_${termMonth.termMonthId}`}
          {...otherProps}
        />
        <h5 className="heading-4">Student Distribution Report</h5>
        <div>
          <div className="report-table">
            <div className="report-table-row table-header w-clearfix">
              <span className="report-table-col cell-2 w-inline-block">
                <div className="report-table-data">Name</div>
              </span>
              <span className="report-table-col cell-1 w-inline-block">
                <div className="report-table-data id">ID</div>
              </span>
              {termMonth.distributionDates.map(renderDistributionDate)}
              <span className="report-table-col cell-1 w-inline-block">
                <div className="report-table-data testimonial">Testimonial</div>
              </span>
              {
                (isAdmin || !termMonth.distributionReport) && (
                <ReportsDateContext.Consumer>
                  {context => (
                    <Mutation
                      mutation={deleteDistributionsMutation}
                      variables={{
                        distributionIds,
                      }}
                      refetchQueries={[
                        {
                          query: schoolReportsQuery,
                          variables: {
                            ...context.filterDates,
                            schoolId: otherProps.selectedSchool,
                          },
                        },
                      ]}
                      onError={mutationError => handleToastError(mutationError)}
                      onCompleted={() => {
                        const num = distributionIds.length;
                        if (num > 1) {
                          toast(`${num} Distributions Deleted`);
                        } else {
                          toast('Distribution Deleted');
                        }
                        setSelectedDistributions({});
                      }}
                    >
                      {deleteDistributions => (
                        <>
                          <button
                            type="button"
                            title="Delete distribution"
                            className="crud-action-icon-wrapper w-inline-block delete-students"
                            disabled={!distributionIds.length}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteStudent(deleteDistributions);
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              width={14}
                              alt="delete"
                            />
                          </button>
                        </>
                      )}
                    </Mutation>
                  )}
                </ReportsDateContext.Consumer>
                )
              }
            </div>
            {
              renderStudents(
                termMonth.distributions,
                termMonth.distributionDates,
              )
            }
          </div>
          <div className="totals-divider" />
          <div className="report-table-row table-header w-clearfix">
            <span className="report-table-col total-label w-inline-block">
              <div className="report-table-data">Weekly Totals</div>
            </span>
            {termMonth.distributionDates.map(renderDistributionTotal)}
            {!termMonth.distributionReport && (
              <a
                onClick={(e) => {
                  if (e && e.preventDefault) {
                    e.preventDefault();
                  }
                  openModal(`add_${termMonth.termMonthId}`);
                }}
                className="link-block-7 w-inline-block pointer"
              >
                <img
                  src={AddIcon}
                  alt="add"
                  className="image-6"
                />
                <span>
                  Add a Student
                </span>
              </a>
            )}
            <button
              type="button"
              className="report-table-col table-header w-inline-block"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ReportDetailsSubmitted.propTypes = {
  termMonth: PropTypes.object.isRequired,
  schoolId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
};

ReportDetailsSubmitted.defaultProps = {
  isAdmin: false,
};


export default ReportDetailsSubmitted;
