import React from 'react';
import PropTypes from 'prop-types';
import ProgramStatusForm from './ProgramStatusForm';

const ProgramStatus = (props) => {
  const { school } = props;
  return (
    <div className="div-block-19">
      <h2>Program Status</h2>
      <div className="last-edit">{`Last Edited: ${school.statusBackpackBuddyUpdatedAt}`}</div>
      <div className="w-form">
        <ProgramStatusForm {...props} />
      </div>
    </div>
  );
};

ProgramStatus.propTypes = {
  school: PropTypes.object.isRequired,
};

export default ProgramStatus;
