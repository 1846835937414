const getFormError = (errors = null) => {
  let errMsg = 'An unknown error occured.';
  if (!errors) return null;
  const { graphQLErrors, networkError } = errors;

  if (graphQLErrors && graphQLErrors.length) {
    errMsg = graphQLErrors[0].message;
  }

  if (networkError) {
    // @TODO handle network errors
  }

  return errMsg;
};

export default getFormError;
