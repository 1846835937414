/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import '../../styles/agreement.css'

const ProgramAgreement = () => {

  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">The Agreement</h4>
      <div className="agreement-div scroll">
      <ol id="l46">
          <li data-list-text="(3)">
            <h1 style={{paddingLeft: '41pt', textIndent: '71pt', lineHeight: '187%', textAlign: 'left'}}>Exhibit A: Backpack Buddy <u>Program Description</u></h1>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left'}}>
              Backpack Buddy is a program that helps alleviate child hunger by closing the weekend gap for chronically hungry children. Better known as children who face food insecurity or a household crisis. Every Friday, students
              participating in this program will receive a nutritious, child-friendly food sack to take home and be back to school on Monday, eager and ready to learn. Backpack Buddy welcomes elementary, middle, and high schools to
              participate.
            </p>
            <h1 style={{paddingTop: '11pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}><u>Program Details </u>– School Programs Partner’s Responsibilities</h1>
            <ol id="l47">
              <li data-list-text={1.}>
                <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>
                  Identify chronically hungry children in your school(s) using the guidelines specified in the Student Referral Forms, as indicated in the Food Insecurity training and Backpack Buddy Information Packet.
                </p>
              </li>
              <li data-list-text={2.}>
                <p style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  Ensure that all students participants have a signed permission slip on Claromentis. All Backpack Buddy records should be stored for a minimum of three years. *Scanning permission slips to the Backpack Buddy staff will
                  not be accepted.
                </p>
              </li>
              <li data-list-text={3.}>
                <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  Participating students must meet income eligibility with either receiving free and/or reduced lunch at their school or have a letter of extreme circumstances or economic hardship(s) written by a parent/guardian or School
                  Coordinator.
                </p>
              </li>
              <li data-list-text={4.}>
                <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>
                  Identify any food allergies that the enrolled student may have (peanuts, milk, etc.). Flag their name so that those items will not be distributed to them once sent to the school. Contact your assigned Backpack Buddy
                  Specialist for specific food allergy.
                </p>
              </li>
              <li data-list-text={5.}>
                <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  Distribute food sacks each week (on the last instructional day of the week). unless otherwise specified due to holidays or school closures) to children with completed permission forms on file.
                </p>
              </li>
              <li data-list-text={6.}><p style={{paddingTop: '11pt', paddingLeft: '71pt', textIndent: '-12pt', textAlign: 'left'}}>Provide food sacks at least three times a month while the program operates.</p></li>
              <li data-list-text={7.}>
                <p style={{paddingTop: '12pt', paddingLeft: '59pt', textIndent: '0pt', textAlign: 'left'}}>Keep accurate records. Prepare, submit, and adhere to the electronic weekly reporting system Link2Feed.</p>
              </li>
              <li data-list-text={8.}>
                <p style={{paddingTop: '12pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  Ensure School Partner staff and volunteers with direct repetitive contact with children pass a national background check, as indicated in the Backpack Buddy Informational Packet.
                </p>
              </li>
              <li data-list-text={9.}>
                <p style={{paddingTop: '11pt', paddingLeft: '59pt', textIndent: '0pt', textAlign: 'left'}}>
                  After initial APPROVED distribution allotments, all future <span style={{color: '#221f1f'}}>allotment adjustments will be scheduled at designated time, once a quarter.</span>
                </p>
                <ol id="l48">
                  <li data-list-text="i.">
                    <p style={{paddingTop: '12pt', paddingLeft: '185pt', textIndent: '-14pt', textAlign: 'left'}}>1<span className="s21">st </span>QTR: July – September</p>
                  </li>
                  <li data-list-text="ii.">
                    <p style={{paddingLeft: '185pt', textIndent: '-16pt', textAlign: 'left'}}>2<span className="s22">nd </span>QTR: October – December</p>
                  </li>
                  <li data-list-text="iii.">
                    <p style={{paddingLeft: '185pt', textIndent: '-19pt', textAlign: 'left'}}>3<span className="s22">rd </span>QTR: January – March</p>
                  </li>
                  <li data-list-text="iv.">
                    <p style={{paddingLeft: '185pt', textIndent: '-19pt', textAlign: 'left'}}>4<span className="s22">th </span>QTR: April – June</p>
                  </li>
                </ol>
              </li>
              <li data-list-text={10.}>
                <p  style={{paddingLeft: '77pt', textIndent: '-26pt', textAlign: 'left'}}>
                  Receive food from the HFB to help provide food to chronically hungry children over the weekend. I understand that the food we receive for the Backpack Buddy program can only be used for that purpose. I understand that
                  the food and backpacks cannot be sold, used for other school programs, used for fundraisers, given to staff, or used for any other purpose other than to provide food to chronically hungry children. Food sack items may
                  not be removed, replaced, or added unless approved by the HFB. Failure to comply with the program requirements will result in the loss of the Backpack Buddy program at School Partner.
                </p>
              </li>
              <li data-list-text={11.}>
                <p style={{paddingTop: '11pt', paddingLeft: '77pt', textIndent: '-17pt', textAlign: 'left'}}>
                  <a href="mailto:backpackbuddy@houstonfoodbank.org" className="s18" target="_blank"> For any questions that you may have about the program please contact the Backpack Buddy staff at </a>
                  <a href="mailto:backpackbuddy@houstonfoodbank.org" className="s13" target="_blank">backpackbuddy@houstonfoodbank.org</a><a href="mailto:backpackbuddy@houstonfoodbank.org" className="s18" target="_blank">.</a>
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </Fragment>
  );
};

export default ProgramAgreement;
