import React, { Fragment } from 'react';
// eslint-disable-next-line import/no-unresolved
import { createStore, useStore } from 'react-hookstore';
import { Query } from 'react-apollo';
import SchoolDropDownMenu from '../components/SchoolDropDownMenu';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import { educatorSchoolsQuery } from '../apollo';
import withProgramsLayout from './withProgramsLayout';

createStore('selectedSchoolStore', '');

function schoolLinks(WrappedComponent) {
  const Links = (props) => {
    const [selectedSchool, setSelectedSchool] = useStore('selectedSchoolStore');

    const handleOnSelect = (selectedId = '') => setSelectedSchool(selectedId);

    return (
      <Query query={educatorSchoolsQuery}>
        {({ loading, error, data: { educatorSchools } }) => {
          if (loading) return <Loading />;
          if (error) return null;
          if (educatorSchools.length) {
            const options = [{ schoolId: '', name: 'All School Sites' }, ...educatorSchools];
            return (
              <Fragment>
                <div className="section-sites">
                  <SchoolDropDownMenu
                    options={options}
                    selected={selectedSchool}
                    onSelect={handleOnSelect}
                  />
                </div>
                <WrappedComponent selectedSchool={selectedSchool} {...props} />
              </Fragment>
            );
          }
          return <BlankState title="No Results" subtitle="You have no school associations" />;
        }}
      </Query>
    );
  };

  return Links;
}

const withSchoolLinks = Component => (
  withProgramsLayout(schoolLinks(props => <Component {...props} />))
);

export default withSchoolLinks;
