import gql from 'graphql-tag';

export default gql`
  mutation saveApplicationStepTwo($input: ApplicationStepTwoInput!) {
    saveApplicationStepTwo(input: $input) {
      applicationBackpackBuddyId
      numFreeLunch
      numReducedLunch
      numTotalEnrollment
      willHelpMediaEvents
      willDistributeInfo
      programInterests
      certififedVerification
    }
  }
`;
