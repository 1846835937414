import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import readXlsxFile from 'read-excel-file';
import { Form, Field } from 'redux-form';
import { toast } from 'react-toastify';
import { nth, toString } from 'lodash';
import 'react-widgets/dist/css/react-widgets.css';
import ApplicationActions from '../ApplicationActions';
import Teachers from '../Teachers';
import {
  teachersRequired,
  uniqueTeachersAidTeachers,
  phone,
  email,
} from '../../helpers';
import downloadTeachersTemplate from '../../helpers/downloadTeachersTemplate';
import BackgroundVerification from '../BackgroundVerification';

const ApplicationTeachersAidStepTwo = (props) => {
  const {
    save,
    handleSubmit,
    change,
    formSyncErrors,
    readOnly,
    termsAvailable,
    newTeacher,
    invalid,
    submitFailed,
    dispatch,
    existingTeachers,
    enums: {
      gradesServed,
      subjects,
    },
  } = props;
  const resetInput = (e) => {
    e.target.value = null;
  };

  const parseTemplate = async (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.substr(file.name.length - 4);
    if (fileExtension !== 'xlsx') {
      toast('Upload must be a .xlsx file');
      return null;
    }

    const rows = await readXlsxFile(file);
    rows.shift();

    const errorPhone = rows.some(row => phone(toString(nth(row, 3)).trim()));
    const errorEmail = rows.some(row => email(toString(nth(row, 4)).trim())) || rows.some(row => toString(nth(row, 4)).trim() === '');
    const errorfirstName = rows.some(row => toString(nth(row, 0)).trim() === '');
    const errorlastName = rows.some(row => toString(nth(row, 1)).trim() === '');

    if (errorPhone) {
      toast('At least one teacher has an improper phone number');
      return null;
    }

    if (errorEmail) {
      toast('At least one teacher has a missing or invalid email');
      return null;
    }

    if (errorfirstName) {
      toast('At least one teacher has a missing first name');
      return null;
    }

    if (errorlastName) {
      toast('At least one teacher has a missing last name');
      return null;
    }

    const uploadedTeachers = [];
    rows.map((row) => {
      const subjectsTaught = [];
      const gradesTaught = [];

      if (row[7]) {
        subjectsTaught.push('ART');
      }
      if (row[8]) {
        subjectsTaught.push('ENGLISH');
      }
      if (row[9]) {
        subjectsTaught.push('FOREIGN_LANGUAGE');
      }
      if (row[10]) {
        subjectsTaught.push('GYM_HEALTH');
      }
      if (row[11]) {
        subjectsTaught.push('HISTORY');
      }
      if (row[12]) {
        subjectsTaught.push('MATH');
      }
      if (row[13]) {
        subjectsTaught.push('MUSIC_LIBRARIAN');
      }
      if (row[14]) {
        subjectsTaught.push('OTHER');
      }
      if (row[15]) {
        subjectsTaught.push('PRINCIPAL_COUNSELOR_CIS');
      }
      if (row[16]) {
        subjectsTaught.push('SPEECH_DYSLEXIA_SPECIALIST_THERAPY');
      }
      if (row[17]) {
        subjectsTaught.push('SPECIAL_EDUCATION');
      }
      if (row[18]) {
        subjectsTaught.push('SCIENCE');
      }
      if (row[19]) {
        gradesTaught.push('PK');
      }
      if (row[20]) {
        gradesTaught.push('K');
      }
      if (row[21]) {
        gradesTaught.push('1');
      }
      if (row[22]) {
        gradesTaught.push('2');
      }
      if (row[23]) {
        gradesTaught.push('3');
      }
      if (row[24]) {
        gradesTaught.push('4');
      }
      if (row[25]) {
        gradesTaught.push('5');
      }
      if (row[26]) {
        gradesTaught.push('6');
      }
      if (row[27]) {
        gradesTaught.push('7');
      }
      if (row[28]) {
        gradesTaught.push('8');
      }
      if (row[29]) {
        gradesTaught.push('9');
      }
      if (row[30]) {
        gradesTaught.push('10');
      }
      if (row[31]) {
        gradesTaught.push('11');
      }
      if (row[32]) {
        gradesTaught.push('12');
      }

      return uploadedTeachers.push({
        firstName: toString(nth(row, 0)).trim(),
        lastName: toString(nth(row, 1)).trim(),
        title: toString(nth(row, 2)).trim(),
        phone: toString(nth(row, 3)).trim(),
        email: toString(nth(row, 4)).trim(),
        proxyTeacher: toString(nth(row, 5)).trim(),
        numStudentsTaughtPerYear: Number(nth(row, 6)),
        subjectsTaught,
        gradesTaught,
      });
    });

    return uploadedTeachers;
  };

  const uploadTeachers = async (e) => {
    const data = await parseTemplate(e);
    if (data) {
      const updatedTeachers = [];
      const newTeachers = existingTeachers.length ? existingTeachers.concat(data) : updatedTeachers.concat(data);
      dispatch(change('school.teachers', newTeachers));
      toast('Teachers Added');
    }
  };

  return (
    <Form onSubmit={handleSubmit(save)} id="email-form" className="teachersaid-form">
      <div className="roster">
        <h4 className="purple-headline boxed-in">Teacher Roster</h4>
        {
          !readOnly && termsAvailable ? (
            <Fragment>
              <p>Upload your school’s teacher roster or manually enter the following information for each teacher below.</p>
              <div className="upload-roster">
                <p style={{ marginRight: 'auto' }}><em className="italic-note">Click to Upload Teacher Roster</em></p>
                <Fragment>
                  <label htmlFor="template-upload" className="btn-fill-p w-button btn-green">Upload Template</label>
                  <input
                    style={{ display: 'none' }}
                    disabled={readOnly || !termsAvailable}
                    id="template-upload"
                    type="file"
                    onClick={resetInput}
                    onChange={uploadTeachers}
                  />
                </Fragment>
                &nbsp;
                <button className="btn-fill-p w-button" onClick={downloadTeachersTemplate} type="button">
                Download Template
                </button>
              </div>
            </Fragment>
          ) : <p>Teachers on this school teacher roster are listed below</p>
        }
        <div className="team-block">
          <Field
            name="school.teachers"
            validate={[teachersRequired, uniqueTeachersAidTeachers]}
            component={Teachers}
            change={change}
            newTeacher={newTeacher}
            formSyncErrors={formSyncErrors}
            disabled={readOnly || !termsAvailable}
            gradesServed={gradesServed}
            subjects={subjects}
          />
        </div>
      </div>
      <BackgroundVerification {...props} />
      <ApplicationActions {...props} />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

ApplicationTeachersAidStepTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  change: PropTypes.func.isRequired,
  formSyncErrors: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  newTeacher: PropTypes.object,
  existingTeachers: PropTypes.array,
  termsAvailable: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

ApplicationTeachersAidStepTwo.defaultProps = {
  readOnly: false,
  newTeacher: null,
  existingTeachers: [],
};

export default ApplicationTeachersAidStepTwo;
