import gql from 'graphql-tag';

export default gql`
  query organizations {
    organizations {
      organizationId
      einNumber
      name
      address1
      address2
      city
      state
      zip
      county
      statusPartnerServices
      deletedAt
      organizationMembers {
        organizationMemberId
        fullName
        email
        type
      }
      applicationsPartnerServices {
        applicationPartnerServicesId
        new
        submittedAt
        status
        operatingHoursStartTime
        operatingHoursEndTime
        gateCode
        isNonProfit
        isReligious
        servicesInterest
        createdAt
      }
    }
  }
`;
