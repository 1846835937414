import gql from 'graphql-tag';

export default gql`
  mutation adminEditEmails($input: AdminEmailsInput!) {
    adminEditEmails(input: $input) {
      id
      emailId
      name
      recipients
      subjectLine
      messageBody
      mergeTags
      status
    }
  }
`;
