import gql from 'graphql-tag';

export default gql`
  query programTerms($termYear: String, $programName: String!) {
    programTerms(termYear: $termYear, programName: $programName) {
      id
      termId
      type
      year
      startDate
      endDate
      termFormatted
      termFormattedShort
    }
  }
`;
