import gql from 'graphql-tag';

export default gql`
  mutation editProfile($input: EducatorUserInput!) {
    editProfile(input: $input) {
      educatorId
      firstName
      lastName
      fullName
      email
      phone
      type
      applicationBackpackBuddyRequired
      applicationBackpackBuddyRequiredId
    }
  }
`;
