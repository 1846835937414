import gql from 'graphql-tag';

export default gql`
  query applicationSignatureDetails($applicationId: ID!) {
    applicationSignatureDetails(applicationId: $applicationId) {
      id
      programName
      status
      signedAt
      marketType
      organizationType
      nonProfitOrganizations
      submittedAt
      nameSignFirst
      nameSignSecond
      nameSignThird
      certifiedAgreement
      certifiedNonDiscrimination
      certifiedProgramRules
      agreeToProgramPurpose
      agreeNondiscrimination
      agreeToProgramResponsibilities
      educator {
        firstName
        lastName
        phone
        email
      }
      school {
        schoolId
        schoolMarketAgencyNumber
        name
        districtId
        districtName
        address1
        address2
        city
        county
        state
        zip
        principal {
          fullName
          phone
          email
          type
        }
        primaryPointOfContact {
          fullName
          phone
          email
          type
        }
      }
    }
  }
`;
