import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '../TextField';
import { required, email } from '../../helpers';

const ForgotPasswordForm = reduxForm({ form: 'forgotPassword' })(({
  handleSubmit,
  submitting,
  sendEmail,
}) => (
  <form onSubmit={handleSubmit(sendEmail)} className="login-form" id="email-form">
    <div className="field-combo stack">
      <Field
        label={<label htmlFor="Email-Log-In" className="field-label">Email</label>}
        component={TextField}
        validate={[required, email]}
        name="email"
        type="email"
        placeholder="you@example.com"
        autoComplete="email"
        className="text-field w-input"
      />
    </div>
    <input
      type="submit"
      value={submitting ? 'Please wait...' : 'Next'}
      disabled={submitting}
      className="btn-fill-p top-pad-ten w-button"
    />
  </form>
));

export default ForgotPasswordForm;
