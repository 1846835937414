import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAdminLayout from '../helpers/withAdminLayout';
import SiteTable from '../components/SiteTable';
import SiteDetail from '../components/SiteDetail';

const AdminSitesPage = props => (
  <Switch>
    <Route
      exact
      path={props.match.path}
      render={() => <SiteTable {...props} />}
    />
    <Route
      path={`${props.match.path}/:id`}
      render={routerProps => <SiteDetail {...props} {...routerProps} />}
    />
    <Route component={() => <Redirect to={`${props.match.path}`} />} />
  </Switch>
);

AdminSitesPage.propTypes = {
  program: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAdminLayout(AdminSitesPage);
