import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  FieldArray,
  reduxForm,
} from 'redux-form';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { Mutation, Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import moment from 'moment';
import { get, has } from 'lodash';
import handleToastError from '../../helpers/handleToastError';
import { required, email } from '../../helpers';
import removeTypename from '../../helpers/removeTypename';
import {
  allSitesQuery,
  adminDeleteEducatorUserMutation,
  adminRestoreEducatorUserMutation,
  sendPasswordResetEmailMutation,
} from '../../apollo';
import AdminSiteSelectInput from '../AdminSiteSelectInput';
import TextField from '../TextField';
import AddIcon from '../../images/add.svg';
import generateUniqueKey from '../../helpers/generateUniqueKey';

const renderSites = ({
  fields,
  change,
}) => (
  <div className="contact-block">
    <h4>Assigned Sites</h4>
    <div className="w-form">
      {
        fields.map((site, index) => (
          <div key={generateUniqueKey(index)} className="field-combo-2-wrapper-2 w-clearfix">
            <div className="field-combo-2">
              <Query query={allSitesQuery} fetchPolicy="cache-and-network">
                {({ loading, error, data }) => {
                  const siteOptions = get(data, 'allSites', []).map((siteOpt) => {
                    const {
                      id,
                      siteId,
                      name,
                    } = siteOpt;

                    return {
                      site: {
                        id,
                        siteId,
                        name,
                      },
                      label: siteOpt.name,
                    };
                  });

                  return (
                    <Field
                      label={<label htmlFor="First-3" className="field-label">{`Site ${index + 1}`}</label>}
                      component={AdminSiteSelectInput}
                      options={siteOptions}
                      validate={required}
                      change={change}
                      index={index}
                      loading={loading}
                      name={`${site}.name`}
                      placeholder={error ? 'Error loading sites, please refresh.' : 'Enter Site Name'}
                      className="text-field w-input"
                    />
                  );
                }}
              </Query>
              <span onClick={() => fields.remove(index)} className="remove-school pointer">Remove Site</span>
            </div>
            
          </div>
        ))
      }
    </div>
    <span className="add-user-link w-inline-block">
      <img src={AddIcon} alt="add-school" className="image-6" />
      <div onClick={() => fields.push({})} className="text-block-13 pointer">Add a Site</div>
    </span>
  </div>
);

renderSites.propTypes = {
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const SiteUserDetailForm = reduxForm({
  form: 'educatorDetailForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  onSubmit,
  initialValues,
  change,
  program: {
    programUrl,
  },
}) => {
  const existingUser = has(initialValues, 'id');

  const isDeleted = initialValues.deletedAt;

  const adminDeleteRestoreMutations = isDeleted
    ? adminRestoreEducatorUserMutation
    : adminDeleteEducatorUserMutation;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="div-block-13">
        <div className="div-block-19">
          {
            existingUser ? (
              <Fragment>
                <h2>User Profile</h2>
                <div className="last-edit">{`Last Edited: ${moment.utc(initialValues.updatedAt).format('M/D/YY')}`}</div>
              </Fragment>
            ) : <h2>Create New User</h2>
          }
          <div className="contact-block">
            <div className="w-form">
              <div className="field-combo-2-wrapper-2 w-clearfix">
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">First Name</label>}
                    component={TextField}
                    validate={required}
                    name="firstName"
                    className="text-field w-input"
                  />
                </div>
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">Last Name</label>}
                    component={TextField}
                    validate={required}
                    name="lastName"
                    className="text-field w-input"
                  />
                </div>
              </div>
              <div className="field-combo-2-wrapper-2 w-clearfix">
                <div className="field-combo-2">
                  <Field
                    label={<label htmlFor="First-3" className="field-label">Email</label>}
                    component={TextField}
                    validate={[required, email]}
                    name="email"
                    className="text-field w-input"
                  />
                </div>
              </div>
            </div>
          </div>
          <FieldArray
            name="sites"
            component={renderSites}
            props={{ change }}
          />
          <div className="contact-block last-contact">
            <div className="w-form">
              {
                existingUser && (
                  <Mutation
                    mutation={sendPasswordResetEmailMutation}
                    onError={mutationError => handleToastError(mutationError)}
                    onCompleted={() => toast('Email Sent')}
                  >
                    {(sendPasswordResetEmail, { loading }) => {
                      const sendEmail = () => (
                        sendPasswordResetEmail({
                          variables: {
                            input: {
                              email: initialValues.email,
                            },
                          },
                        })
                      );

                      return (
                        <Fragment>
                          <h5>Reset Password</h5>
                          <span
                            className={classnames('admin-reset-password-link', { 'disabled-link': loading })}
                            onClick={sendEmail}
                          >
                            Click here
                          </span>
                          <span>&nbsp;to send this user a password reset email.</span>
                          <div>
                            An email will be sent to the user to start the password reset process.
                          </div>
                        </Fragment>
                      );
                    }}
                  </Mutation>
                )
              }
              <br />
              <input
                type="submit"
                value={submitting ? 'Please wait...' : 'Save Changes'}
                disabled={submitting}
                className="btn-solid-green w-button"
              />

              {
                existingUser && (
                  <Mutation
                    mutation={adminDeleteRestoreMutations}
                    variables={{
                      educatorId: initialValues.educatorId,
                    }}
                    onError={mutationError => handleToastError(mutationError)}
                    onCompleted={() => (
                      isDeleted ? toast('User Deleted') : toast('User Restored')
                    )}
                  >
                    {(mutation, { data, loading }) => {
                      const confirmDeleteRestore = () => {
                        confirmAlert({
                          message: 'Are you sure?',
                          buttons: [
                            {
                              label: 'Cancel',
                            },
                            {
                              label: isDeleted ? 'Restore' : 'Delete',
                              onClick: mutation,
                            },
                          ],
                        });
                      };

                      if (data) {
                        return <Redirect to={`${programUrl}/users`} />;
                      }

                      return (
                        <span
                          onClick={confirmDeleteRestore}
                          className={classnames('delete-link w-button', { 'disabled-link': loading })}
                        >
                          { isDeleted ? 'Restore ' : 'Delete ' }
                          User
                        </span>
                      );
                    }}
                  </Mutation>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
});

const mapStateToProps = (state, props) => {
  const { adminEducatorUserDetails } = props;

  return {
    initialValues: {
      ...removeTypename(adminEducatorUserDetails),
    },
  };
};

export default connect(mapStateToProps)(SiteUserDetailForm);
