import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const SideMenu = (props) => {
  const { program: { programUrl } } = props;

  return (
    <Fragment>
      <div className="div-block-15">
        <NavLink
          to={`${programUrl}/account/applications`}
          className="account-links  w-inline-block"
          activeClassName="w--current active"
        >
          <div>Applications</div>
        </NavLink>
        <NavLink
          to={`${programUrl}/account/profile`}
          className="account-links  w-inline-block"
          activeClassName="w--current active"
        >
          <div>Profile</div>
        </NavLink>
      </div>
    </Fragment>
  );
};

SideMenu.propTypes = {
  program: PropTypes.object.isRequired,
};

export default SideMenu;
