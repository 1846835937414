import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { pull } from 'lodash';

const CheckboxGroup = ({
  options,
  input,
  disabled,
  meta: { touched, error },
  ...otherProps
}) => (
  <Fragment>
    {
      options.map(({ label, value }) => (
        <div {...otherProps} key={value}>
          <input
            disabled={disabled}
            {...input}
            id={`${value}-checkbox`}
            type="checkbox"
            className="display-none w-checkbox-input"
            value={value}
            checked={input.value.includes(value)}
            onChange={(e) => {
              const newValue = [...input.value];

              if (e.target.checked) {
                newValue.push(value);
              } else {
                pull(newValue, value);
              }

              return input.onChange(newValue);
            }}
          />
          <label htmlFor={`${value}-checkbox`} className="checkbox-label w-form-label">
            {label}
          </label>
        </div>
      ))
    }
    {touched && error && <span style={{ color: 'red' }}>{error}</span>}
  </Fragment>
);

CheckboxGroup.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
  disabled: false,
};

export default CheckboxGroup;
