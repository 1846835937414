import { toast } from 'react-toastify';
import getErrorMessage from './getErrorMessage';

const handleToastError = (error) => {
  const errorMessage = getErrorMessage(error);
  if (errorMessage) {
    toast(errorMessage);
  }
};

export default handleToastError;
