import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PurpleChevronIcon from '../../images/purple-chevron-down.svg';

const SchoolHeader = (
  {
    title, isSelected, onSelectChange, isOpen, onOpenChange,
  },
) => (
  <div className="site-div">
    <h5 className="heading">{title}</h5>
    <div className="div-block-7">
      <button
        type="button"
        onClick={() => onOpenChange(!isOpen)}
        className="link-block-5 w-inline-block"
        style={{ background: 'transparent' }}
      >
        <div>
          {(isOpen ? 'Hide ' : 'Show ')}
          Reports
        </div>
        <img src={PurpleChevronIcon} width={10} alt="is-open" className={classnames('image-4', { rotate: isOpen })} />
      </button>
      <div className="form-block-2 w-form">
        <form id="email-form" name="email-form" data-name="Email Form">
          <div className="checkbox-field w-checkbox">
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              data-name="Checkbox"
              className="w-checkbox-input"
              checked={isSelected}
              onChange={onSelectChange}
            />
            <label htmlFor="checkbox" className="checkbox-label-2 w-form-label">Export</label>
          </div>
        </form>
      </div>
    </div>
  </div>
);

SchoolHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
};

SchoolHeader.defaultProps = {
  isOpen: true,
  onOpenChange: null,
};

export default SchoolHeader;
