import React, { Fragment } from 'react';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import BlankState from '../BlankState';
import handleToastError from '../../helpers/handleToastError';
import { adminConfigKidsCafeQuery, adminEditConfigKidsCafeMutation } from '../../apollo';
import SiteApplicationSettingsForm from '../SiteApplicationSettingsForm';

const SiteApplicationSettings = () => (
  <Fragment>
    <Query query={adminConfigKidsCafeQuery}>
      {({ loading, data, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <BlankState title="Error" subtitle="There was an error loading" />;
        }

        return (
          <Fragment>
            <h2>Application Settings</h2>
            <div className="last-edit">{`Last Edited: ${data.adminConfigKidsCafe.lastEdited}`}</div>
            <div className="text-block-14">Manage application submissions.</div>
            <div className="top-border">
              <div className="w-form">
                <Mutation
                  mutation={adminEditConfigKidsCafeMutation}
                  onError={mutationError => handleToastError(mutationError)}
                  onCompleted={() => toast('Update Successful')}
                >
                  {(mutationFunc) => {
                    const update = values => (
                      mutationFunc({
                        variables: {
                          input: {
                            ...values,
                          },
                        },
                      })
                    );

                    return (
                      <SiteApplicationSettingsForm
                        {...data.adminConfigKidsCafe}
                        onSubmit={update}
                      />
                    );
                  }}
                </Mutation>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);


export default SiteApplicationSettings;
