import gql from 'graphql-tag';

export default gql`
  mutation submitReport($input: DistributionReportInput!) {
    submitReport(input: $input){
      distributionReportId
      comments
      createdAt
      submittedBy
      educatorId
    }
  }
`;
