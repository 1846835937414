import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Mutation } from 'react-apollo';
import Modal from 'react-modal';
import moment from 'moment';
import { toast } from 'react-toastify';
import handleToastError from '../../helpers/handleToastError';
import DismissGreenIcon from '../../images/dismiss-green.svg';
import TextArea from '../TextArea';
import TextField from '../TextField';
import { required, normalizeNumber } from '../../helpers';
import { ReportsDateContext } from './index';
import { schoolReportsQuery, editDistributionMutation } from '../../apollo';

const getInitialValues = (props) => {
  const { distribution, distributionDates } = props;

  const initialValues = {
    studentName: (distribution) ? distribution.studentName : '',
    idNumber: (distribution) ? distribution.studentIdNumber : '',
    testimonial: (distribution) ? distribution.testimonial : '',
  };

  if (distribution) {
    distributionDates.forEach((date) => {
      const distributionDateEntry = distribution.distributionDateEntries.find(
        entry => entry.termMonthDistributionDateId === date.termMonthDistributionDateId,
      );
      initialValues[`dateInput${date.termMonthDistributionDateId.trim()}`] = (distributionDateEntry) ? distributionDateEntry.distributionNumber : '';
    });
  }

  return initialValues;
};

class StudentDetailsModal extends Component {
  componentDidMount() {
    const { initialize } = this.props;

    initialize(getInitialValues(this.props));
  }

  closeModal = () => {
    const { onClose, initialize } = this.props;

    initialize(getInitialValues(this.props));
    onClose();
  };

  save = async (data, mutation) => {
    const {
      valid,
      distributionDates,
      distribution,
      termMonthId,
    } = this.props;
    if (valid) {
      const dateEntries = [];

      distributionDates.forEach((date) => {
        dateEntries.push({
          termMonthDistributionDateId: date.termMonthDistributionDateId,
          distributionNumber: Number(data[`dateInput${date.termMonthDistributionDateId.trim()}`]),
        });
      });

      const { studentName, idNumber, testimonial } = data;
      const variables = {
        termMonthId,
        dateEntries,
        studentName,
        testimonial,
        studentIdNumber: idNumber.trim(),
      };

      await mutation({
        variables: {
          input: variables,
          distributionId: distribution ? distribution.distributionId : undefined,
        },
      });
    }
  };

  renderDateEntryInput = (date) => {
    const day = moment.utc(date.date);
    return (
      <div className="addstudent-dates" key={date.termMonthDistributionDateId}>
        <Field
          name={`dateInput${date.termMonthDistributionDateId.trim()}` /* Do not remove */}
          validate={required}
          normalize={normalizeNumber}
          type="number"
          min="0"
          className=" w-radio addstudent-field date w-input"
          label={<label htmlFor="Testimonial-2" className="addstudent-field-label">{day.format('MMM DD')}</label>}
          component={TextField}
        />
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      submitting,
      isOpen,
      distributionDates,
      selectedSchool,
    } = this.props;

    return (
      <ReportsDateContext.Consumer>
        {context => (
          <Mutation
            mutation={editDistributionMutation}
            onError={mutationError => handleToastError(mutationError)}
            onCompleted={() => {
              toast('Distribution Saved');
              this.closeModal();
            }}
            refetchQueries={[
              {
                query: schoolReportsQuery,
                variables: { ...context.filterDates, schoolId: selectedSchool },
              },
            ]}
            awaitRefetchQueries
          >
            {editDistribution => (
              <Modal
                isOpen={isOpen}
                onAfterOpen={() => null}
                onRequestClose={() => this.closeModal()}
                contentLabel="Example Modal"
                className="students-modal"
                overlayClassName="modal-black-overlay"
                ariaHideApp={false}
              >
                <form name="students-modal">
                  <div className="addstudent-popup">
                    <div className="addstudent-header">
                      <h5 className="heading-5">Add a Student</h5>
                      <button type="button" onClick={this.closeModal} className="close w-inline-block">
                        <img src={DismissGreenIcon} alt="close" />
                      </button>
                    </div>
                    <div className="w-form">
                      <div className="div-block-12">
                        <div className="student-name">
                          <Field
                            name="studentName"
                            label={<label htmlFor="Name-2" className="addstudent-field-label">Name</label>}
                            validate={required}
                            className=" w-radio addstudent-field name w-input"
                            component={TextField}
                          />
                        </div>
                        <div className="id-number">
                          <Field
                            name="idNumber"
                            label={<label htmlFor="ID-Number" className="addstudent-field-label">ID Number</label>}
                            validate={required}
                            className=" w-radio addstudent-field name w-input"
                            component={TextField}
                          />
                        </div>
                      </div>
                      <div className="div-block-13">
                        {distributionDates.map(this.renderDateEntryInput)}
                      </div>
                      <Field
                        name="testimonial"
                        type="text"
                        maxLength={5000}
                        className=" w-radio textarea w-input"
                        label={<label htmlFor="Testimonial" className="addstudent-field-label">Testimonial</label>}
                        placeholder="Add comments about this student."
                        component={TextArea}
                      />
                      <div className="form-buttons">
                        <button type="button" onClick={() => this.closeModal()} className="form-button cancel w-button">
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="form-button w-button"
                          disabled={submitting}
                          onClick={handleSubmit(
                            formData => this.save(formData, editDistribution),
                          )}
                        >
                          {submitting ? 'Saving...' : 'Save'}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal>
            )}
          </Mutation>
        )}
      </ReportsDateContext.Consumer>
    );
  }
}

const StudentDetailsModalWrapper = reduxForm({
  enableReinitialize: true,
})(StudentDetailsModal);

StudentDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  termMonthId: PropTypes.string.isRequired,
  distribution: PropTypes.object,
  distributionDates: PropTypes.array.isRequired,
  valid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedSchool: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

StudentDetailsModal.defaultProps = {
  distribution: null,
};

function mapStateToProps(state, props) {
  return {
    initialValues: getInitialValues(props),
  };
}

export default connect(mapStateToProps)(StudentDetailsModalWrapper);
