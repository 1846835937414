import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { reset } from 'redux-form';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { applicationSignatureDetailsQuery, principalSignatureMutation } from '../apollo';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import handleToastError from '../helpers/handleToastError';
import HFBLogo from '../images/HFB-logo.png';
import SignatureForm from '../components/SignatureForm';


const SignaturePage = (props) => {
  const { match, dispatch } = props;
  const { params } = match;

  return (
    <Query
      query={applicationSignatureDetailsQuery}
      variables={{ applicationId: params.id }}
      fetchPolicy="network-only"
    >
      {({ loading, data, error }) => {
        if (loading) return <Loading />;
        if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
        if (!data.applicationSignatureDetails) return <Redirect to="/login" />;

        const { applicationSignatureDetails: application } = data;

        return (
          <Mutation
            mutation={principalSignatureMutation}
            onError={async (mutationError) => {
              handleToastError(mutationError);
              await new Promise(resolve => setTimeout(resolve, 2000));
              dispatch(reset('signatureForm'));
            }}
            onCompleted={async () => {
              toast('Signature Submitted Successfully');
              await new Promise(resolve => setTimeout(resolve, 2000));
              dispatch(push('/login'));
            }}
          >
            {(principalSignature) => {
              const update = values => (
                principalSignature({
                  variables: {
                    input: {
                      id: values.id,
                    },
                  },
                })
              );

              return (
                <Fragment>
                  <div className="section-topnav">
                    <div className="topnav-container">
                      <div className="topnav-logos-flex">
                        <div className="hfb-logo-link w-inline-block">
                          <img src={HFBLogo} alt="logo" className="hfb" />
                        </div>
                      </div>
                      <div className="topnav-dropdown" />
                    </div>
                  </div>
                  <section className="section-content">
                    <div className="_1300-container">
                      <div className="backbackbuddy-form w-form">
                        <SignatureForm update={update} application={application} {...props} />
                      </div>
                    </div>
                  </section>
                </Fragment>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

SignaturePage.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(SignaturePage);
