import gql from 'graphql-tag';

export default gql`
query awsObjects($input: AwsS3ListObjectsInput!){
  s3(config: {region: "us-east-1"}) {
    listObjects(input: $input) {
      Name
      Contents {
        Key
        LastModified
        Size
      }
    }
  }
}
`;
