import gql from 'graphql-tag';

export default gql`
  {
    educatorSchools {
      schoolId
      backpackBuddyId
      name
    }
  }
`;
