import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
} from 'redux-form';
import Modal from 'react-modal';
import RichTextField from '../RichTextField';
import TextField from '../TextField';
import { required } from '../../helpers';

const EmailModal = ({
  email,
  emailId,
  activeEmail,
  setActiveEmail,
  valid,
  mergeTags,
}) => (
  <Modal
    isOpen={activeEmail === emailId}
    shouldCloseOnOverlayClick={false}
    className="students-modal"
    overlayClassName="modal-black-overlay"
    ariaHideApp={false}
  >
    <div className="email-edit-modal">
      <div className="addstudent-header">
        <h5 className="heading-5">Edit Email</h5>
      </div>
      <div className="w-form">
        <Field
          label={<label htmlFor="Name" className="addstudent-field-label">Subject Line</label>}
          component={TextField}
          validate={required}
          name={`${email}.subjectLine`}
          className="w-input"
        />
        <Field
          label={<label htmlFor="Testimonial" className="addstudent-field-label">Message</label>}
          component={RichTextField}
          validate={required}
          name={`${email}.messageBody`}
          maxLength={5000}
          className="textarea w-input"
        />
        <div className="merge-tags">{`Merge Tags: ${mergeTags || 'N/A'}`}</div>
        <div className="form-buttons">
          <button
            type="button"
            className="form-button w-button"
            onClick={() => {
              if (valid) setActiveEmail();
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

EmailModal.propTypes = {
  email: PropTypes.string.isRequired,
  emailId: PropTypes.string.isRequired,
  activeEmail: PropTypes.string,
  setActiveEmail: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  mergeTags: PropTypes.string.isRequired,
};

EmailModal.defaultProps = {
  activeEmail: null,
};

export default EmailModal;
