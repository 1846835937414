import gql from 'graphql-tag';

export default gql`
  mutation editDistribution($input: EditDistributionInput!, $distributionId: ID) {
    editDistribution(input: $input, distributionId: $distributionId) {
      distributionId
      studentName
      studentIdNumber
      testimonial
      termMonthId
      distributionDateEntries {
        termMonthDistributionDateId
        distributionNumber
        distributionId
      }
    }
  }
`;
