import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sum from 'lodash/sum';
import PurpleChevronIcon from '../../images/purple-chevron-down.svg';
import ReportDetails from './ReportDetails';

const ReportAccordion = (
  {
    termMonth, isSelected, onSelectChange, ...otherProps
  },
) => {
  const [isOpen, setOpen] = useState(false);

  const { monthlyAllocation } = termMonth;
  const montlyDistributed = Sum(
    termMonth.distributions.map(
      distribution => Sum(distribution.distributionDateEntries.map(
        entry => entry.distributionNumber,
      )),
    ),
  );

  const hasReport = (termMonth.distributionReport);

  return (
    <div
      className={classnames('date-div', 'accordion', { 'is-open active-details': isOpen }, { 'submitted-details': hasReport })}
    >
      <div className="div-block-6">
        <h5 className="month-header">{termMonth.termMonthFormatted}</h5>
        <div className="div-block-7">
          <button type="button" className="link-block-5 w-inline-block" onClick={() => setOpen(!isOpen)}>
            <div>
              {!isOpen && 'Show Details'}
              {isOpen && 'Hide Details'}
            </div>
            <img src={PurpleChevronIcon} width={10} alt="show-hide" className="image-4 show-hide-icon" />
          </button>
          <div className="form-block-2 w-form">
            <form id="email-form" name="email-form" data-name="Email Form">
              <div className="checkbox-field w-checkbox">
                <input
                  type="checkbox"
                  id="checkbox-2"
                  name="checkbox-2"
                  data-name="Checkbox 2"
                  className="w-checkbox-input"
                  checked={isSelected}
                  onChange={event => onSelectChange(event.target.checked)}
                />
                <label htmlFor="checkbox-5" className="checkbox-label-2 w-form-label">Export</label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="distribution-stats">
        <div className="text-block">
          <span>Allocated&nbsp;</span>
          <span className="text-span">
            {monthlyAllocation}
          </span>
        </div>
        <div className="vertical-divider" />
        <div className="text-block">
          <span>Distributed&nbsp;</span>
          <span className="text-span">
            {montlyDistributed}
          </span>
        </div>
        <div className="vertical-divider" />
        <div className="text-block">
          <span>Discarded&nbsp;</span>
          <span className="text-span">
            {monthlyAllocation - montlyDistributed}
          </span>
        </div>
      </div>
      {isOpen && (
        <>
          <ReportDetails termMonth={termMonth} {...otherProps} />
        </>
      )}
    </div>
  );
};

ReportAccordion.propTypes = {
  termMonth: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  schoolId: PropTypes.string.isRequired,
};

export default ReportAccordion;
