import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Checkbox from '../Checkbox';

const SiteApplicationSettingsForm = reduxForm({
  form: 'siteApplicationSettings',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="w-checkbox">
      <Field
        label="Accepting Applications"
        component={Checkbox}
        name="acceptingApplications"
        id="acceptingApplications"
        className="w-checkbox-input"
      />
    </div>
    <div className="btn-padding">
      <input
        type="submit"
        value={submitting ? 'Please wait...' : 'Save Changes'}
        disabled={submitting}
        className="btn-solid-green w-button"
      />
    </div>
  </form>
));

function mapStateToProps(state, props) {
  const { acceptingApplications } = props;

  return {
    initialValues: {
      acceptingApplications,
    },
  };
}

export default connect(mapStateToProps)(SiteApplicationSettingsForm);
