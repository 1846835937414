import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import SchoolDetailBackpackBuddy from '../components/SchoolDetailBackpackBuddy';
import SchoolDetailSchoolMarket from '../components/SchoolDetailSchoolMarket';
import SchoolTerms from '../components/SchoolTerms';
import withAdminLayout from '../helpers/withAdminLayout';
import SchoolDetailTeachersAid from '../components/SchoolDetailTeachersAid';

const AdminSchoolsPage = (props) => {
  const { program, match } = props;

  const adminSchoolDetailComponents = {
    'Backpack Buddy': SchoolDetailBackpackBuddy,
    'School Market': SchoolDetailSchoolMarket,
    'Teachers Aid': SchoolDetailTeachersAid,
  };

  const DetailComponent = adminSchoolDetailComponents[program.name];

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => <SchoolTerms {...props} />}
      />
      <Route
        path={`${match.path}/:id`}
        render={routerProps => <DetailComponent {...props} {...routerProps} />}
      />
      <Route component={() => <Redirect to={`${props.match.path}`} />} />
    </Switch>
  );
};

AdminSchoolsPage.propTypes = {
  program: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withAdminLayout(AdminSchoolsPage);
