import gql from 'graphql-tag';

export default gql`
  query adminSchoolBackpackBuddy ($schoolId: ID!) {
    adminSchoolBackpackBuddy(schoolId: $schoolId) {
      id
      schoolId
      name
      backpackBuddyId
      address1
      address2
      city
      state
      zip
      county
      districtId
      type
      gradesServed
      lastEdited
      lastEditedTeamMembers
      firstYearAppliedBackpackBuddy
      statusBackpackBuddy
      statusBackpackBuddyUpdatedAt
      deletedAt
      applicationsBackpackBuddy {
        id
        applicationBackpackBuddyId
        new
        createdAt
        submittedAt
        submittedBy
        status
        termFormattedShort
        numFreeLunch
        numReducedLunch
        numTotalEnrollment
        isContinuation
      }
      teamMembersBackpackBuddy {
        id
        teamMemberId
        firstName
        lastName
        email
        phone
        type
        title
      }
      allocationEntries {
        id
        allocationEntryId
        adminId
        schoolId
        updatedBy
        weeklyAllocation
        createdAt
      }
      foodSafetyEntries {
        id
        foodSafetyEntryId
        adminId
        completedBy
        completionDate
        expirationDate
        createdAt
      }
    }
  }
`;
