import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import TextArea from '../TextArea';

const NotificationForm = reduxForm({
  form: 'notificationForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  update,
}) => (
  <Form className="banner-text-form" onSubmit={handleSubmit(update)}>
    <Field
      name="text"
      type="text"
      maxLength={5000}
      className="banner-textarea w-input"
      label={<label className="field-label" htmlFor="text">Banner Notification Text</label>}
      placeholder="Add text to enable a notification."
      component={TextArea}
    />
    <br />
    <input
      type="submit"
      value={submitting ? 'Please wait...' : 'Save Changes'}
      disabled={submitting}
      className="btn-solid-green w-button"
    />
  </Form>
));

const mapStateToProps = (reduxState, { notification }) => {
  const { notificationId, text } = notification;

  return {
    initialValues: {
      notificationId,
      text,
    },
  };
};

export default connect(mapStateToProps)(NotificationForm);
