import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { getStoreByName } from 'react-hookstore';
import moment from 'moment';
import chevronDrop from '../../images/chevron-drop.svg';
import programs from '../../constants/programs';
import selectProgram from '../../helpers/selectProgram';
import { logoutAction } from '../../redux';
import { endSession } from '../../helpers/session';

class NavDropdown extends Component {
  state = {
    programsNavOpen: false,
    accountNavOpen: false,
  };

  toggleProgramsNav = () => {
    this.setState(prevState => ({
      programsNavOpen: !prevState.programsNavOpen,
    }));
  };

  toggleAccountNav = () => {
    this.setState(prevState => ({
      accountNavOpen: !prevState.accountNavOpen,
    }));
  };

  logout = (e) => {
    const { dispatch } = this.props;
    e.preventDefault();

    const reportFilterStore = getStoreByName('reportDates');
    reportFilterStore.setState({
      startDate: moment().startOf('year').local().toDate(),
      endDate: moment().endOf('year').local().toDate(),
    });

    endSession();
    dispatch(logoutAction());
  };

  render() {
    const { programsNavOpen, accountNavOpen } = this.state;
    const { user, dispatch, program: { name, programUrl } } = this.props;
    const { type, fullName } = user;
    const isEducator = type === 'EDUCATOR';
    const programApplicationRequired = name && `application${name.replace(/\s/g, '')}Required`;

    return (
      <div className="topnav-dropdowns-flex">
        <div className="w-dropdown" onMouseOver={this.toggleProgramsNav} onFocus={this.toggleProgramsNav} onMouseOut={this.toggleProgramsNav} onBlur={this.toggleProgramsNav}>
          <div onClick={this.toggle} className="topnav-dropdown w-dropdown-toggle">
            <div className="dropdown-label">Programs</div>
            <img src={chevronDrop} alt="" className="dropdown-chevron" />
          </div>
          <nav className={classnames('topnav-dropdown-list w-dropdown-list', { 'w--open': programsNavOpen })}>
            {
              programs.map(p => <div key={p.name} onClick={() => selectProgram(dispatch, p)} className="topnav-dropdown-link w-dropdown-link">{p.name}</div>)
            }
          </nav>
        </div>
        <div className="topnav-divider" />
        <div className="w-dropdown" onMouseOver={this.toggleAccountNav} onFocus={this.toggleAccountNav} onMouseOut={this.toggleAccountNav} onBlur={this.toggleAccountNav}>
          <div onClick={this.toggle} className="topnav-dropdown w-dropdown-toggle">
            <div className="dropdown-label">{fullName}</div>
            <img src={chevronDrop} alt="" className="dropdown-chevron" />
          </div>
          <nav className={classnames('topnav-dropdown-list w-dropdown-list', { 'w--open': accountNavOpen })}>
            {
              isEducator && name && (!user[programApplicationRequired]) && (
                <Link to={`${programUrl}/account/applications`} className="topnav-dropdown-link w-dropdown-link">
                  My Account
                </Link>
              )
            }
            <div onClick={this.logout} className="topnav-dropdown-link last w-dropdown-link">
            Log Out
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

NavDropdown.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  program: PropTypes.object,
};

NavDropdown.defaultProps = {
  program: {},
};

export default connect()(NavDropdown);
