import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  FieldArray,
  reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import {
  email,
  phone,
  normalizePhone,
  validateSchoolContactsBackpackBuddy,
} from '../../helpers';
import getLabel from '../../helpers/getLabel';
import TextField from '../TextField';

const renderMembers = ({
  fields,
  enums,
}) => (
  <div className="w-form">
    {
      fields.map((member, index) => {
        const memberType = fields.get(index).type;

        return (
          <Fragment key={fields.get(index).type}>
            <h4>{getLabel(enums.allTeamMemberTypes.options, memberType)}</h4>
            <div className="contact-group">
              <div className="contact-form-wrapper">
                <div className="form-item">
                  <Field
                    label={<label htmlFor="First-3" className="contact-field-label">First Name</label>}
                    component={TextField}
                    name={`${member}.firstName`}
                    className="text-field w-input"
                  />
                </div>
                <div className="form-item">
                  <Field
                    label={<label htmlFor="First-3" className="contact-field-label">Last Name</label>}
                    component={TextField}
                    name={`${member}.lastName`}
                    className="text-field w-input"
                  />
                </div>
              </div>
              <div className="contact-form-wrapper">
                <div className="form-item">
                  <Field
                    label={<label htmlFor="First-3" className="contact-field-label">Phone</label>}
                    component={TextField}
                    validate={phone}
                    normalize={normalizePhone}
                    name={`${member}.phone`}
                    className="text-field w-input"
                  />
                </div>
                <div className="form-item">
                  <Field
                    label={<label htmlFor="First-3" className="contact-field-label">Email</label>}
                    component={TextField}
                    validate={email}
                    name={`${member}.email`}
                    className="text-field w-input"
                  />
                </div>
              </div>
              {
                memberType !== 'PRINCIPAL' && (
                  <div className="contact-form-wrapper">
                    <div className="form-item">
                      <Field
                        label={<label htmlFor="First-3" className="contact-field-label">Job Title</label>}
                        component={TextField}
                        name={`${member}.title`}
                        className="text-field w-input"
                      />
                    </div>
                  </div>
                )
              }
            </div>
            {memberType !== 'PRINCIPAL' && (<div className="contact-divider" />)}
          </Fragment>
        );
      })
    }
  </div>
);

renderMembers.propTypes = {
  enums: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
};

const ContactForm = reduxForm({
  form: 'schoolContacts',
  validate: validateSchoolContactsBackpackBuddy,
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  onSubmit,
  enums,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <FieldArray
      name="teamMembers"
      component={renderMembers}
      props={{ enums }}
    />
    <div className="btn-padding">
      <input
        type="submit"
        value={submitting ? 'Please wait...' : 'Save Changes'}
        disabled={submitting}
        className="btn-solid-green w-button"
      />
    </div>
  </Form>
));

const mapStateToProps = (reduxState, props) => {
  const { schoolContacts: schoolTeamMembers, selectedSchool: schoolId } = props;
  const teamMemberTypes = ['PRIMARY', 'SECONDARY', 'PRINCIPAL'];
  const teamMembers = [];

  teamMemberTypes.forEach((memberType) => {
    const existingMember = schoolTeamMembers.find(({ type }) => type === memberType);

    if (existingMember) {
      const {
        teamMemberId,
        email: existingMemberEmail,
        firstName,
        lastName,
        phone: existingMemberPhone,
        title,
        type,
      } = existingMember;

      teamMembers.push({
        teamMemberId,
        email: existingMemberEmail,
        firstName,
        lastName,
        phone: existingMemberPhone,
        title,
        type,
      });
    } else {
      teamMembers.push({ type: memberType });
    }
  });

  return {
    initialValues: {
      schoolId,
      teamMembers,
    },
  };
};

ContactForm.propTypes = {
  enums: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ContactForm);
