import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAdminLayout from '../helpers/withAdminLayout';
import SiteUserTable from '../components/SiteUserTable';
import SiteUserDetail from '../components/SiteUserDetail ';

const AdminSiteProgramUsersPage = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <SiteUserTable {...props} />}
      />
      <Route
        path={`${match.path}/:id`}
        render={routerProps => <SiteUserDetail {...props} {...routerProps} />}
      />
      <Route component={() => <Redirect to={`${match.path}`} />} />
    </Switch>
  );
};

AdminSiteProgramUsersPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withAdminLayout(AdminSiteProgramUsersPage);
