import gql from 'graphql-tag';

export default gql`
  mutation saveSchoolMarketApplication($input: ApplicationSchoolMarketInput!) {
    saveSchoolMarketApplication(input: $input) {
      applicationSchoolMarketId
      new
      organizationType
      nonProfitOrganizations
      isContinuation
      school {
        name
        districtId
        address1
        address2
        city
        county
        state
        zip
        gradesServed
        teamMembers {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
      }
      numFreeLunch
      numReducedLunch
      numTotalEnrollment
      marketType
      operatingFrequency
      preferredDistributionDay
      preferredDistributionTime
      secondaryPreferredDistributionDay
      secondaryPreferredDistributionTime
      agreeToProgramPurpose
      agreeToProgramResponsibilities
      agreeNondiscrimination
      nameSignFirst
      nameSignSecond
      nameSignThird
      certififedVerification
    }
  }
`;