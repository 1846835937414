import gql from 'graphql-tag';

export default gql`
  mutation editSchoolContacts($input: EditSchoolContactsInput!) {
    editSchoolContacts(input: $input) {
      id
      teamMemberId
      firstName
      lastName
      title
      email
      phone
      type
    }
  }
`;
