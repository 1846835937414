import {
  get,
  has,
  omit,
  isNull,
  isEmpty,
  isArray,
  isString,
  isBoolean,
} from 'lodash';
import moment from 'moment';

export const required = (value) => {
  if (isString(value) && !value.trim()) return 'Required';
  if (isArray(value) && isEmpty(value)) return 'Required';
  if (!value && typeof value !== 'number' && !isBoolean(value)) return 'Required';
  return undefined;
};

export const teacherInputRequired = (value, allValues) => {
  if (has(allValues, 'newTeacher') && !isNull(allValues.newTeacher)) {
    if (isString(value) && !value.trim()) return 'Required';
    if (!value && typeof value !== 'number' && !isBoolean(value)) return 'Required';
    return undefined;
  }
  return undefined;
};

export const teachersRequired = (value) => {
  if (!value.length) return 'Teachers Required';
  return undefined;
};

export const requiredTrue = (value) => {
  if (isBoolean(value) && !value) return 'Required';
  if (!isBoolean(value) && !value) return 'Required';
  return undefined;
};

export const teamRequired = (value) => {
  const hasPrincipal = value.length && value.find(({ type }) => type === 'PRINCIPAL');
  if (!hasPrincipal) return 'Principal Required';
  const hasPrimary = value.length && value.find(({ type }) => type === 'PRIMARY');
  if (!hasPrimary) return 'Primary Coordinator Required';
  return undefined;
};

export const uniqueTeamMemberModal = (value, allValues, isActive) => {
  const exists = get(allValues, 'teamMembersBackpackBuddy', []).find(({ type }) => (
    type === value
  ));

  if (!value) {
    return 'Required';
  }

  if (exists && isActive.type !== value) {
    if (value === 'PRINCIPAL') {
      return 'Principal Already Exists';
    }
    if (value === 'PRIMARY') {
      return 'Primary Coordinator Already Exists';
    }
    if (value === 'SECONDARY') {
      return 'Secondary Coordinator Already Exists';
    }
  }

  return undefined;
};

export const uniqueTeamMember = (value, allValues) => {
  if (value === 'PRINCIPAL') {
    const hasValue = get(allValues, 'school.teamMembersBackpackBuddy', []).find(({ type }) => type === 'PRINCIPAL');

    if (hasValue) {
      return 'Principal Already Exists';
    }
  }
  if (value === 'PRIMARY') {
    const hasValue = get(allValues, 'school.teamMembersBackpackBuddy', []).find(({ type }) => type === 'PRIMARY');

    if (hasValue) {
      return 'Primary Coordinator Already Exists';
    }
  }
  if (value === 'SECONDARY') {
    const hasValue = get(allValues, 'school.teamMembersBackpackBuddy', []).find(({ type }) => type === 'SECONDARY');

    if (hasValue) {
      return 'Secondary Coordinator Already Exists';
    }
  }
  return undefined;
};

export const uniqueTeachersAidTeamMember = (value, allValues) => {
  const coordinatorEmail = get(allValues, 'school.siteCoordinator.email', '');
  const principalEmail = get(allValues, 'school.principal.email', '');

  if (coordinatorEmail.toLowerCase() === principalEmail.toLowerCase()) {
    return 'Email must be unique';
  }

  return undefined;
};

export const uniqueTeachersAidTeachers = (value) => {
  const withEmails = value.filter(({ email }) => email);
  const emails = [];
  withEmails.map(teacher => emails.push(teacher.email.toLowerCase().trim()));
  if ((new Set(emails)).size !== emails.length) {
    return 'Teacher emails must be unique';
  }
  return undefined;
};

export const uniqueSchoolMarketTeamMember = (value, allValues) => {
  const primaryPointOfContactEmail = get(allValues, 'school.primaryPointOfContact.email', '');
  const principalEmail = get(allValues, 'school.principal.email', '');

  if (primaryPointOfContactEmail.toLowerCase() === principalEmail.toLowerCase()) {
    return 'Email must be unique';
  }

  return undefined;
};

export const uniqueKidsCafeTeamMember = (value, allValues) => {
  const primaryPointOfContactEmail = get(allValues, 'site.primaryPointOfContact.email', '');
  const secondaryPointOfContactEmail = get(allValues, 'site.secondaryPointOfContact.email', '');

  if (primaryPointOfContactEmail.toLowerCase() === secondaryPointOfContactEmail.toLowerCase()) {
    return 'Email must be unique';
  }

  return undefined;
};

export const teamPrincipalmatch = (value, allValues) => {
  let principal;

  principal = get(allValues, 'school.teamMembersBackpackBuddy', []).find(({ type }) => type === 'PRINCIPAL');
  if (!principal) {
    principal = get(allValues, 'school.principal');
  }

  if (principal && principal.email !== value) {
    return 'Principal Email Doesn\'t Match';
  }

  return undefined;
};

export const educatorNameMatch = (value, allValues) => {

  if ((allValues.educator.firstName+ " " +allValues.educator.lastName).trim() !== value.trim()) {
    return 'Name Doesn\'t Match';
  }

  return undefined;
};

export const teamInputRequired = (value, allValues) => {
  if (has(allValues, 'newTeamMember') && !isNull(allValues.newTeamMember)) {
    if (isString(value) && !value.trim()) return 'Required';
    if (!value && typeof value !== 'number' && !isBoolean(value)) return 'Required';
    return undefined;
  }
  return undefined;
};

export const teamInputRequiredOptional = (value, allValues) => {
  if (has(allValues, 'newTeamMember') && !isNull(allValues.newTeamMember)) {
    const type = get(allValues, 'newTeamMember.type');
    if (type !== 'STAFF' && type !== 'VOLUNTEER') {
      if (isString(value) && !value.trim()) return 'Required';
      if (!value && typeof value !== 'number' && !isBoolean(value)) return 'Required';
      return undefined;
    }
  }
  return undefined;
};

export const validateSchoolContactsBackpackBuddy = (values) => {
  const errors = {};

  const teamMembersArrayErrors = [];
  get(values, 'teamMembers', []).forEach((member, memberIndex) => {
    const teamMemberErrors = {};
    const allFieldsEmpty = Object.values(omit(member, ['type', 'teamMemberId'])).every(x => (x === null || x === ''));
    const firstNameRequired = required(member.firstName);
    const lastNameRequired = required(member.lastName);
    const phoneRequired = required(member.phone);
    const emailRequired = required(member.email);

    if (!allFieldsEmpty) {
      if (firstNameRequired) {
        teamMemberErrors.firstName = firstNameRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
      if (lastNameRequired) {
        teamMemberErrors.lastName = lastNameRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
      if (phoneRequired) {
        teamMemberErrors.phone = phoneRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
      if (emailRequired) {
        teamMemberErrors.email = emailRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
      teamMembersArrayErrors[memberIndex] = teamMemberErrors;
    } else if (member.type === 'PRINCIPAL' || member.type === 'PRIMARY') {
      if (firstNameRequired) {
        teamMemberErrors.firstName = firstNameRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
      if (lastNameRequired) {
        teamMemberErrors.lastName = lastNameRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
      if (phoneRequired) {
        teamMemberErrors.phone = phoneRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
      if (emailRequired) {
        teamMemberErrors.email = emailRequired;
        teamMembersArrayErrors[memberIndex] = teamMemberErrors;
      }
    }
    if (teamMembersArrayErrors.length) {
      errors.teamMembers = teamMembersArrayErrors;
    }
  });
  return errors;
};

export const validateSchoolContacts = (values) => {
  const errors = {};

  const teamMembersArrayErrors = [];
  get(values, 'teamMembers', []).forEach((member, memberIndex) => {
    const teamMemberErrors = {};
    const firstNameRequired = required(member.firstName);
    const lastNameRequired = required(member.lastName);
    const phoneRequired = required(member.phone);
    const emailRequired = required(member.email);

    if (firstNameRequired) {
      teamMemberErrors.firstName = firstNameRequired;
      teamMembersArrayErrors[memberIndex] = teamMemberErrors;
    }
    if (lastNameRequired) {
      teamMemberErrors.lastName = lastNameRequired;
      teamMembersArrayErrors[memberIndex] = teamMemberErrors;
    }
    if (phoneRequired) {
      teamMemberErrors.phone = phoneRequired;
      teamMembersArrayErrors[memberIndex] = teamMemberErrors;
    }
    if (emailRequired) {
      teamMemberErrors.email = emailRequired;
      teamMembersArrayErrors[memberIndex] = teamMemberErrors;
    }
    teamMembersArrayErrors[memberIndex] = teamMemberErrors;

    if (teamMembersArrayErrors.length) {
      errors.teamMembers = teamMembersArrayErrors;
    }
  });
  return errors;
};

export const validateSiteContacts = (values) => {
  const errors = {};

  const siteMembersArrayErrors = [];
  get(values, 'siteMembers', []).forEach((member, memberIndex) => {
    const siteMembersErrors = {};
    const firstNameRequired = required(member.firstName);
    const lastNameRequired = required(member.lastName);
    const phoneRequired = required(member.phone);
    const emailRequired = required(member.email);
    const supervisorRequired = required(member.isSupervisor);

    if (firstNameRequired) {
      siteMembersErrors.firstName = firstNameRequired;
      siteMembersArrayErrors[memberIndex] = siteMembersErrors;
    }
    if (lastNameRequired) {
      siteMembersErrors.lastName = lastNameRequired;
      siteMembersArrayErrors[memberIndex] = siteMembersErrors;
    }
    if (phoneRequired) {
      siteMembersErrors.phone = phoneRequired;
      siteMembersArrayErrors[memberIndex] = siteMembersErrors;
    }
    if (emailRequired) {
      siteMembersErrors.email = emailRequired;
      siteMembersArrayErrors[memberIndex] = siteMembersErrors;
    }
    if (supervisorRequired) {
      siteMembersErrors.isSupervisor = supervisorRequired;
      siteMembersArrayErrors[memberIndex] = siteMembersErrors;
    }
    siteMembersArrayErrors[memberIndex] = siteMembersErrors;

    if (siteMembersArrayErrors.length) {
      errors.siteMembers = siteMembersArrayErrors;
    }
  });
  return errors;
};

export const validateOrganizationContacts = (values) => {
  const errors = {};

  const organizationMembersArrayErrors = [];
  get(values, 'organizationMembers', []).forEach((member, memberIndex) => {
    const organizationMemberErrors = {};
    const allFieldsEmpty = Object.values(omit(member, ['type', 'organizationMemberId'])).every(x => (x === null || x === ''));
    const firstNameRequired = required(member.firstName);
    const lastNameRequired = required(member.lastName);
    const phoneRequired = required(member.phone);
    const emailRequired = required(member.email);

    if (!allFieldsEmpty) {
      if (firstNameRequired) {
        organizationMemberErrors.firstName = firstNameRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
      if (lastNameRequired) {
        organizationMemberErrors.lastName = lastNameRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
      if (phoneRequired) {
        organizationMemberErrors.phone = phoneRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
      if (emailRequired) {
        organizationMemberErrors.email = emailRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
      organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
    } else if (member.type === 'PRIMARY_POINT_CONTACT') {
      if (firstNameRequired) {
        organizationMemberErrors.firstName = firstNameRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
      if (lastNameRequired) {
        organizationMemberErrors.lastName = lastNameRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
      if (phoneRequired) {
        organizationMemberErrors.phone = phoneRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
      if (emailRequired) {
        organizationMemberErrors.email = emailRequired;
        organizationMembersArrayErrors[memberIndex] = organizationMemberErrors;
      }
    }
    if (organizationMembersArrayErrors.length) {
      errors.organizationMembers = organizationMembersArrayErrors;
    }
  });
  return errors;
};

export const validateOptionalContact = (value, allValues, store, field) => {
  const contact = get(allValues, 'organization.secondaryPointOfContact');
  const allFieldsEmpty = Object.values(omit(contact, ['__typename', 'type', 'organizationMemberId'])).every(x => (x === null || x === ''));

  if (allFieldsEmpty) {
    return undefined;
  }

  if (field) {
    const toValidate = get(allValues, field);
    return required(toValidate);
  }

  return undefined;
};

export const validateEmails = (value = []) => {
  let errors = {};

  const adminEmailArrayErrors = [];
  value.forEach((email, emailIndex) => {
    const adminEmailErrors = {};
    const messageRequired = required(email.messageBody);
    const subjectRequired = required(email.subjectLine);

    if (messageRequired) {
      adminEmailErrors.messageBody = messageRequired;
      adminEmailArrayErrors[emailIndex] = adminEmailErrors;
    }
    if (subjectRequired) {
      adminEmailErrors.subjectLine = subjectRequired;
      adminEmailArrayErrors[emailIndex] = adminEmailErrors;
    }

    if (adminEmailArrayErrors.length) {
      errors = adminEmailArrayErrors;
    }
  });

  if (!isEmpty(errors)) return adminEmailArrayErrors;

  return undefined;
};

export const signeeEmailmatch = (value, allValues) => {
  if (allValues.signeeEmailToValidate !== value) {
    return 'Email Doesn\'t Match';
  }

  return undefined;
};

export const applicationTerm = (value, termsForYear, summerEnabled, schoolEnabled) => {
  const selectedValue = termsForYear.find(({ termId }) => termId === value);
  const selectedType = get(selectedValue, 'type');

  if (!selectedType) return 'Required';
  if (selectedType === 'SUMMER' && !summerEnabled) return 'We are not currently accepting new summer applications';
  if (selectedType === 'SCHOOL' && !schoolEnabled) return 'We are not currently accepting new school year applications';

  return undefined;
};

export const passwordsMatch = (value, allValues) => (value !== allValues.password ? "Passwords don't match" : undefined);

export const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Invalid email address'
  : undefined);

export const phone = value => (
  value && !/^(0|[1-9][0-9]{9})$/i.test(value.replace(/-/g, ''))
    ? 'Invalid phone number, must be 10 digits'
    : undefined
);

export const number = (value) => {
  if (isString(value) && !value.trim()) return 'Required';
  return value && isNaN(value) ? 'Not a number' : undefined;
};

export const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);

export const maxLenth10 = maxLength(10);

export const minLength = min => value => (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const minLength6 = minLength(6);

export const oneUppercase = value => (value && !/^(?=.*[A-Z]).*$/.test(value)
  ? 'Must contain one uppercase letter'
  : undefined);

export const url = value => (
  value && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)
    ? 'Invalid URL, must start with http:// or https://'
    : undefined
);

export const validTime = (value) => {
  if (value) {
    const isValid = moment(value, 'LT', true).isValid();
    const hasTimeSpecification = value.includes('AM') || value.includes('PM');

    if (!isValid || !hasTimeSpecification) {
      return 'Invalid time, must format like 11:00 AM';
    }
  }

  return undefined;
};

export const validateDate = (value) => {
  const dateformat = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
  return (value && value.match(dateformat)) ? undefined : 'Invalid date, must format like MM/DD/YYYY';
};

export const validateEnrollmentNumbers = (value, allValues) => {
  const totalNum = get(allValues, 'numTotalEnrollment');
  const totalNumAlt = get(allValues, 'application.numTotalEnrollment');
  if (totalNum && totalNum < value) {
    return 'Must be less than total enrollment';
  }
  if (totalNumAlt && totalNumAlt < value) {
    return 'Must be less than total enrollment';
  }
  return undefined;
};

export const validateSchoolMarketSchoolType = (value) => {
  if (value === 'ELEMENTARY') {
    return 'Program not available for elementary schools';
  }
  return undefined;
};
