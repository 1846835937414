import React from 'react';
import { connect } from 'react-redux';
import { Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { Form, Field, reduxForm } from 'redux-form';
import { confirmAlert } from 'react-confirm-alert';
import maxBy from 'lodash/maxBy';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { required, parseNumber, validateEnrollmentNumbers } from '../../../helpers';
import handleToastError from '../../../helpers/handleToastError';
import {
  adminDeleteSchoolMutation,
  adminArchiveSchoolMutation,
  adminRestoreSchoolMutation,
} from '../../../apollo';
import TextField from '../../TextField';
import CheckboxGroup from '../../CheckboxGroup';
import RadioButtonGroup from '../../RadioButtonGroup';
import sortGradesServed from '../../../helpers/sortGradesServed';
import DistrictDropDownMenu from '../../DistrictDropDownMenu';

const ProfileForm = reduxForm({
  form: 'schoolProfile',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  update,
  enums,
  initialValues,
}) => {
  const isDeleted = initialValues.school.deletedAt;
  const adminArchiveRestoreMutations = isDeleted
    ? adminRestoreSchoolMutation
    : adminArchiveSchoolMutation;

  return (
    <Form onSubmit={handleSubmit(update)}>
      <div className="field-combo-wrapper-2 radio-wrapper">
        <label htmlFor="First" className="field-label">School Level</label>
        <div>
          <Field
            name="school.type"
            validate={required}
            options={enums.schoolTypes.options}
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
          />
        </div>
      </div>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Name</label>}
            component={TextField}
            validate={required}
            name="school.name"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Teachers Aid School #</label>}
            component={TextField}
            name="school.teachersAidSchoolNumber"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">City</label>}
            component={TextField}
            validate={required}
            name="school.city"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            name="school.districtId"
            className="text-field w-select"
            component={DistrictDropDownMenu}
            label={<label htmlFor="First-3" className="field-label">School District</label>}
            validate={required}
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">County</label>}
            component={TextField}
            validate={required}
            name="school.county"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Address Line 1</label>}
            component={TextField}
            validate={required}
            name="school.address1"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Address Line 2</label>}
            component={TextField}
            name="school.address2"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">State</label>}
            component={TextField}
            validate={required}
            name="school.state"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Zip</label>}
            component={TextField}
            validate={required}
            name="school.zip"
            className="text-field w-input"
          />
        </div>
      </div>
      <div>
        <label htmlFor="Last-3" className="field-label">Grades Served (check all that apply)</label>
        <Field
          name="school.gradesServed"
          validate={required}
          options={sortGradesServed(enums.gradesServed)}
          component={CheckboxGroup}
          className="checkbox-inline w-checkbox"
        />
      </div>
      <div className="field-combo-wrapper">
        <div className="field-combo thirds">
          <Field
            label={<label htmlFor="First-3" className="field-label">Total Enrollment</label>}
            component={TextField}
            validate={initialValues.application ? required : null}
            name="application.numTotalEnrollment"
            className="text-field number w-input"
            parse={parseNumber}
            maxLength={4}
            disabled={!initialValues.application}
          />
        </div>
        <div className="field-combo thirds">
          <Field
            label={<label htmlFor="First-3" className="field-label">Number of Students Receiving Reduced Lunch</label>}
            component={TextField}
            validate={initialValues.application ? [required, validateEnrollmentNumbers] : null}
            name="application.numReducedLunch"
            className="text-field number w-input"
            parse={parseNumber}
            maxLength={4}
            disabled={!initialValues.application}
          />
        </div>
        <div className="field-combo thirds">
          <Field
            label={<label htmlFor="First-3" className="field-label">Number of Students Receiving Free Lunch</label>}
            component={TextField}
            validate={initialValues.application ? [required, validateEnrollmentNumbers] : null}
            name="application.numFreeLunch"
            className="text-field number w-input"
            parse={parseNumber}
            maxLength={4}
            disabled={!initialValues.application}
          />
        </div>
      </div>
      <div className="radio-question">
        <label htmlFor="First-3" className="field-label">Participated in program last year</label>
        <Field
          name="application.new"
          options={[
            {
              value: false,
              label: 'Yes',
            },
            {
              value: true,
              label: 'No',
            },
          ]}
          component={RadioButtonGroup}
          className="radio-field inline-radio w-radio"
          parse={val => val === 'true'}
          disabled={!initialValues.application}
          validate={initialValues.application ? required : null}
        />
      </div>
      <div className="spacer-15" />
      <div className="values-text">These values are based on the most recent approved application.</div>
      <div className="btn-flex-container">
        <div className="btn-padding">
          <input
            type="submit"
            value={submitting ? 'Please wait...' : 'Save Changes'}
            disabled={submitting}
            className="btn-solid-green w-button"
          />
        </div>
        &nbsp;
        &nbsp;
        &nbsp;
        <Mutation
          mutation={adminArchiveRestoreMutations}
          variables={{
            schoolId: initialValues.school.schoolId,
          }}
          refetchQueries={['adminSchoolTeachersAid', 'schoolsTeachersAid']}
          onError={mutationError => handleToastError(mutationError)}
          onCompleted={() => (
            isDeleted ? toast('School Restored') : toast('School Archived')
          )}
        >
          {(mutation, { data, loading }) => {
            const confirmArchiveRestore = () => {
              confirmAlert({
                message: 'Are you sure?',
                buttons: [
                  {
                    label: 'Cancel',
                  },
                  {
                    label: isDeleted ? 'Restore' : 'Archive',
                    onClick: mutation,
                  },
                ],
              });
            };

            if (data) {
              return <Redirect to="/teachers-aid/schools" />;
            }

            return (
              <div className="btn-padding">
                <input
                  type="button"
                  value={isDeleted ? 'Restore School' : 'Archive School'}
                  disabled={submitting}
                  className={classnames('form-save w-button', { 'disabled-link': loading })}
                  onClick={confirmArchiveRestore}
                />
              </div>
            );
          }}
        </Mutation>
        <Mutation
          mutation={adminDeleteSchoolMutation}
          variables={{
            schoolId: initialValues.school.schoolId,
          }}
          refetchQueries={['adminSchoolTeachersAid', 'schoolsTeachersAid']}
          onError={mutationError => handleToastError(mutationError)}
          onCompleted={() => (
            toast('School Deleted')
          )}
        >
          {(mutation, { data, loading }) => {
            const confirmDelete = () => {
              confirmAlert({
                message: 'Are you sure? This is a destructive action that will delete all related program(s) data.',
                buttons: [
                  {
                    label: 'Cancel',
                  },
                  {
                    label: 'Delete',
                    onClick: mutation,
                  },
                ],
              });
            };

            if (data) {
              return <Redirect to="/teachers-aid/schools" />;
            }

            return (
              <div className="btn-padding">
                <input
                  type="button"
                  value="Delete School"
                  disabled={submitting}
                  className={classnames('form-save w-button', { 'disabled-link': loading })}
                  onClick={confirmDelete}
                />
              </div>
            );
          }}
        </Mutation>
      </div>
    </Form>
  );
});

const mapStateToProps = (reduxState, props) => {
  const { school } = props;

  const activeApplications = school.applicationsTeachersAid.filter(({ status }) => status === 'APPROVED');
  const mostRecent = maxBy(activeApplications, o => o.createdAt);

  const {
    schoolId,
    name,
    teachersAidSchoolNumber,
    type,
    city,
    districtId,
    county,
    address1,
    address2,
    state,
    zip,
    gradesServed,
    deletedAt,
  } = school;

  const initialValues = {
    schoolId,
    school: {
      schoolId,
      name,
      type,
      teachersAidSchoolNumber,
      city,
      districtId,
      county,
      address1,
      address2,
      state,
      zip,
      gradesServed,
      deletedAt,
    },
  };

  if (mostRecent) {
    const isNew = mostRecent.new;
    const {
      applicationTeachersAidId,
      numFreeLunch,
      numReducedLunch,
      numTotalEnrollment,
    } = mostRecent;

    initialValues.application = {
      applicationTeachersAidId,
      numFreeLunch,
      numReducedLunch,
      numTotalEnrollment,
      new: isNew,
    };
  }

  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(ProfileForm);
