import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ProgressBar = ({ step, programName, termYear }) => (
  step !== 4 && (
  <Fragment>
    <div className="bb-title">
      <h2 className="purple-headline">
        {
          step === 1 && `${programName || ''} Program Application ${termYear}`
        }
        {
          step === 2 && `${programName || ''} Program Application Continued`
        }
        {
          step === 3 && `${programName || ''} Program Application Agreement`
        }
      </h2>
      <div className="progress-bar">
        <div className="text-block-6">Your Progress</div>
        <div className="bubble-div">
          <a href="/application/step-1/" className={classnames('progress-bubble w-inline-block')}>
            <div>1</div>
          </a>
          <div className={classnames('progress-divider', { green: step !== 1 })} />
          <a href="/application/step-2/" className={classnames('progress-bubble w-inline-block', { inactive: step === 1 })}>
            <div>2</div>
          </a>
          <div className={classnames('progress-divider', { green: step === 3 })} />
          <a href="/application/step-3/" className={classnames('progress-bubble w-inline-block', { inactive: step !== 3 })}>
            <div>3</div>
          </a>
        </div>
      </div>
    </div>
  </Fragment>
  )
);

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  programName: PropTypes.string.isRequired,
  termYear: PropTypes.string,
};

ProgressBar.defaultProps = {
  termYear: '',
};

export default ProgressBar;
