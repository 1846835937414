import gql from 'graphql-tag';

export default gql`
  query configBackpackBuddy {
    configBackpackBuddy {
      summerEnabled
      schoolEnabled
    }
  }
`;
