import { apolloClient } from '../apollo';

export const getAccessToken = () => (
  localStorage.getItem('accessToken')
);

export const storeAccessToken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
};

export const endSession = () => {
  apolloClient.resetStore();
  localStorage.removeItem('accessToken');
};
