import gql from 'graphql-tag';

export default gql`
  {
    allDistricts {
      id
      districtId
      name
      deletedAt
      schools {
        schoolId
        name
      }
    }
  }
`;
