import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withProgramsLayout from '../../helpers/withProgramsLayout';
import programContactEmails from '../../constants/programContactEmails';

function ApplicationStatusPending({ program }) {
  const { name } = program;
  return (
    <Fragment>
      <div className="half-width">
        <h2>{`Your ${name} Application is Pending`}</h2>
        <p>
          {
            name === 'School Market' && (
              <>
                Your Principal or Primary Point of Contact is required to provide
                an e-signature for this application to be considered. An email
                message will be sent to the email that you provided. The recipient
                must click the link and follow the instructions to provide their signature.
              </>
            )
          }
          {
            ((name === 'Teachers Aid') || (name === 'Backpack Buddy')) && (
              <>
                Your Principal is required to provide an e-signature for this
                application to be considered. An email message will be sent to
                the Principal email that you provided. Your Principal must click
                the link and follow the instructions to provide their signature.
              </>
            )
          }
          {
            (name === 'Kids Cafe' || name === 'Partner Services') && (
              <>
                {`Your application is being evaluated by the ${name} team.
                A ${name} team member will follow up with further instructions`}
              </>
            )
          }
          <br />
          <br />
          {`For questions, please contact the ${name} Program Manager at`}
          &nbsp;
          <a href={`mailto:${programContactEmails[name]}`} className="paragraph-link">{programContactEmails[name]}</a>
        </p>
      </div>
      <div className="btn-solid-grey w-button no-cursor">Application Pending</div>
    </Fragment>
  );
}

ApplicationStatusPending.propTypes = {
  program: PropTypes.object.isRequired,
};

export default withProgramsLayout(ApplicationStatusPending);
