import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { get } from 'lodash';
import {
  requiredTrue,
  required,
  email,
  teamPrincipalmatch,
} from '../../helpers';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import ApplicationActions from '../ApplicationActions';
import TeachersAidProgramAgreement from '../TeachersAidProgramAgreement';
import GeneralAgreement from '../GeneralAgreement';
import TeachersAidProgramAgreement2024 from '../TeachersAidProgramAgreement2024';
import GeneralAgreement2024 from '../GeneralAgreement2024';
import NonDiscriminationStament from '../NonDiscriminationStament';
import moment from 'moment';

const ApplicationTeachersAidStepThree = (props) => {
  const {
    save,
    handleSubmit,
    readOnly,
    termsAvailable,
    initialValues,
    invalid,
    submitFailed,
    data
  } = props;
  const principal = get(initialValues, 'school.principal');
  const principalEmail = get(principal, 'email', 'N/A');

  const isPrev2024 = () => {
    const date = moment(data.applicationTeachersAid.submittedAt);
    const data2 = data.applicationTeachersAid;
    const year = new Date().getFullYear() - 1;
    console.log(data2)
    const isCont = data2.school.applicationsTeachersAid ?
    data2.school.applicationsTeachersAid.find(el => el.submittedAt && el.submittedAt.includes(year.toString()) && !el.isContinuation && el.status == "APPROVED"):
      false
  
    return (!!data.applicationTeachersAid.submittedAt && ((date.year() < 2024 || date.month() < 7) && date.year() <= 2024)) || isCont;
  };

  return <Form onSubmit={handleSubmit(save)} id="email-form" className="teachersaid-form">
      <div className="agreement">
        {isPrev2024() ? <GeneralAgreement data={data.applicationTeachersAid} />:<GeneralAgreement2024 data={data.applicationTeachersAid} />}
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={
                  (
                    <Fragment>
                      <label htmlFor="Last" className="field-label">
                        Agreement Sign
                      </label>
                    </Fragment>
                  )
                }
              component={TextField}
              placeholder="Enter Your Name"
              validate={[required]}
              name="nameSignFirst"
              disabled={readOnly || !termsAvailable}
              className="text-field w-input"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<strong>I Agree</strong>}
              name="certifiedAgreement"
              validate={requiredTrue}
              component={Checkbox}
              className="checkbox-inline"
              disabled={readOnly || !termsAvailable}
              {...{
                style: {paddingBottom: 35}
              }}
            />
          </div>
        </div>
        <NonDiscriminationStament data={data.applicationTeachersAid} />
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={
                  (
                    <Fragment>
                      <label htmlFor="Last" className="field-label">
                        Nondiscrimination Sign
                      </label>
                    </Fragment>
                  )
                }
              component={TextField}
              placeholder="Enter Your Name"
              validate={[required]}
              name="nameSignSecond"
              disabled={readOnly || !termsAvailable}
              className="text-field w-input"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<strong>I Agree</strong>}
              name="certifiedNonDiscrimination"
              validate={requiredTrue}
              component={Checkbox}
              className="checkbox-inline"
              disabled={readOnly || !termsAvailable}
              {...{
                style: {paddingBottom: 35}
              }}
            />
          </div>
        </div>
        {isPrev2024() ? <TeachersAidProgramAgreement data={data.applicationTeachersAid} />:<TeachersAidProgramAgreement2024 data={data.applicationTeachersAid} />}
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={
                  (
                    <Fragment>
                      <label htmlFor="Last" className="field-label">
                        Program Sign
                      </label>
                    </Fragment>
                  )
                }
              component={TextField}
              placeholder="Enter Your Name"
              validate={[required]}
              name="nameSignThird"
              disabled={readOnly || !termsAvailable}
              className="text-field w-input"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<strong>I Agree</strong>}
              name="certifiedProgramRules"
              validate={requiredTrue}
              component={Checkbox}
              className="checkbox-inline"
              disabled={readOnly || !termsAvailable}
              {...{
                style: {paddingBottom: 35}
              }}
            />
          </div>
        </div>
        {
          !readOnly && termsAvailable && (
            <Fragment>
              <h4 className="purple-headline boxed-in">Principal Signature</h4>
              <p>
                  Your Principal is required to provide an e-signature for this application to be
                  considered. An email message will be sent to the Principal email that you provided.
                  Your Principal must click the link and follow the instructions to provide
                  their signature.
              </p>
              <div className="field-combo-wrapper sign-up">
                <div className="field-combo">
                  <Field
                    label={
                        (
                          <Fragment>
                            <label htmlFor="Last" className="field-label">
                              Verify Principal Email
                            </label>
                            <i>{principalEmail}</i>
                          </Fragment>
                        )
                      }
                    component={TextField}
                    placeholder="Enter Principal Email"
                    validate={[required, email, teamPrincipalmatch]}
                    name="verifyPrincipalEmail"
                    className="text-field w-input"
                  />
                </div>
              </div>
            </Fragment>
          )
        }
      </div>
      <ApplicationActions {...props} />
      <br />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>;
};

ApplicationTeachersAidStepThree.propTypes = {
  adminView: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  termsAvailable: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
};

ApplicationTeachersAidStepThree.defaultProps = {
  readOnly: false,
};

export default ApplicationTeachersAidStepThree;
