/* eslint-disable import/prefer-default-export */
// https://redux-form.com/6.0.0-rc.3/examples/normalizing/
export const normalizePhone = (value) => {
  if (!value) {
    return null;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const normalizePercent = (value) => {
  const numString = value.replace(/\D/g, '');

  const parsedNumber = numString ? Number(numString) : null;

  if (typeof parsedNumber === 'number') {
    if (parsedNumber > 100) {
      return 1;
    }
    return parsedNumber / 100;
  }
  return parsedNumber;
};

export const normalizeNumber = (value) => {
  const type = Math.sign(value);

  if (type === 0 || type === 1) {
    return value;
  }
  return undefined;
};

export const normalizeDate = (value, prevValue) => {
  if (!value) return value;

  const valueOnlyNumbers = value.replace(/[^\d]/g, '');
  const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');
  if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

  const month = valueOnlyNumbers.slice(0, 2);
  const day = valueOnlyNumbers.slice(2, 4);
  const year = valueOnlyNumbers.slice(4, 8);

  if (valueOnlyNumbers.length < 2) return `${month}`;
  if (valueOnlyNumbers.length === 2) return `${month}/`;
  if (valueOnlyNumbers.length < 4) return `${month}/${day}`;
  if (valueOnlyNumbers.length === 4) return `${month}/${day}/`;
  if (valueOnlyNumbers.length > 4) return `${month}/${day}/${year}`;
};
