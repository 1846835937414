import backpackBuddyLogo from '../images/backpack-logo.svg';
import schoolMarketLogo from '../images/schoolmarket-logo.png';
import teachersAidLogo from '../images/teachersaid-logo.png';

const programs = [
  {
    name: 'Backpack Buddy',
    programUrl: '/backpack-buddy',
    logo: backpackBuddyLogo,
  },
  {
    name: 'Kids Cafe',
    programUrl: '/kids-cafe',
    logo: '',
  },
  // HIDE PROGRAM PER HFB
  // {
  //   name: 'Partner Services',
  //   programUrl: '/partner-services',
  //   logo: '',
  // },
  {
    name: 'School Market',
    programUrl: '/school-market',
    logo: schoolMarketLogo,
  },
  {
    name: 'Teachers Aid',
    programUrl: '/teachers-aid',
    logo: teachersAidLogo,
  },
];

export default programs;
