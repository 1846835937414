import gql from 'graphql-tag';

export default gql`
  query schoolsSchoolMarket($termId: ID) {
    schoolsSchoolMarket(termId: $termId) {
      schoolId
      schoolMarketAgencyNumber
      name
      address1
      address2
      city
      state
      zip
      county
      districtId
      districtName
      type
      gradesServed
      statusSchoolMarket
      deletedAt
      teamMembersSchoolMarket {
        teamMemberId
        fullName
        email
        phone
        type
      }
      applicationsSchoolMarket {
        applicationSchoolMarketId
        new
        termFormattedShort
        submittedAt
        status
        certififedVerification
        numFreeLunch
        numReducedLunch
        numTotalEnrollment
        marketType
        operatingFrequency
        preferredDistributionDay
        preferredDistributionTime
        secondaryPreferredDistributionDay
        secondaryPreferredDistributionTime
        waitlisted
        waitlistedAt
        createdAt
      }
    }
  }
`;
