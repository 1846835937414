import React from 'react';
import PropTypes from 'prop-types';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Mutation } from 'react-apollo';
import BlankState from '../BlankState';
import handleToastError from '../../helpers/handleToastError';
import Pagination from '../Pagination';

import { AWS_S3_DOCUMENT_BUCKET, PAGE_SIZE } from '../../config';
import deleteIcon from '../../images/delete.svg';
import { deleteS3ObjectMutation, awsObjectsQuery } from '../../apollo';

const DocumentTable = (props) => {
  const { documents, isAdmin } = props;

  const columns = [{
    className: 'file-name',
    headerClassName: 'doc-table-col w-inline-block name pointer noselect',
    Header: 'Name',
    accessor: 'Key',
    Cell: (row) => {
      const url = `https://s3.amazonaws.com/${AWS_S3_DOCUMENT_BUCKET}/${row.value}`;
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.value}
        </a>
      );
    },
  }, {
    className: 'id',
    headerClassName: 'doc-table-col w-inline-block date-added pointer noselect',
    Header: 'Date Added',
    accessor: 'LastModified',
    Cell: row => moment.utc(row.value).format('M-D-YY'),
  }, {
    className: 'date',
    headerClassName: 'doc-table-col w-inline-block date-added pointer noselect',
    Header: 'File Size',
    accessor: 'Size',
    Cell: row => filesize(row.value),
  }, {
    Header: '',
    className: 'actions',
    show: isAdmin,
    Cell: row => (
      <Mutation
        mutation={deleteS3ObjectMutation}
        variables={{
          key: row.original.Key,
          bucket: AWS_S3_DOCUMENT_BUCKET,
        }}
        refetchQueries={[
          { query: awsObjectsQuery, variables: { input: { Bucket: AWS_S3_DOCUMENT_BUCKET } } },
        ]}
        onError={mutationError => handleToastError(mutationError)}
        onCompleted={() => toast('Delete Successful')}
        awaitRefetchQueries
      >
        {(deleteS3Object) => {
          const confirmDelete = () => {
            confirmAlert({
              message: 'Are you sure?',
              buttons: [
                {
                  label: 'Cancel',
                },
                {
                  label: 'Delete',
                  onClick: deleteS3Object,
                },
              ],
            });
          };

          return (
            <div className="crud-action-icon-wrapper w-inline-block" onClick={confirmDelete}>
              <img className="pointer" src={deleteIcon} width={14} alt="delete" />
            </div>
          );
        }}
      </Mutation>
    ),
  }];

  return (
    <ReactTable
      data={documents}
      columns={columns}
      loadingText=""
      NoDataComponent={() => <BlankState title="No Results" />}
      minRows={0}
      filterable={false}
      PaginationComponent={Pagination}
      defaultPageSize={PAGE_SIZE}
      defaultSorted={[{
        id: 'LastModified',
        desc: true,
      }]}
      getTrGroupProps={() => ({
        className: 'table-row surveys w-clearfix',
      })}
      getTdProps={() => ({
        className: 'doc-table-col',
      })}
      getTheadTrProps={() => ({
        className: 'document-table-row w-clearfix',
      })}
      column={
        {
          ...ReactTableDefaults.column,
          minWidth: 85,
          width: '10%',
        }
      }
    />
  );
};

const mapStateToProps = ({ user }) => {
  const isAdmin = user.type === 'ADMIN';

  return {
    isAdmin,
  };
};

DocumentTable.propTypes = {
  documents: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(DocumentTable);
