import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Modal,
    makeStyles
  } from '@material-ui/core';
import BlankState from '../BlankState';
import Loading from '../Loading';
import React, { Fragment, useState } from 'react'
import { Query } from 'react-apollo';
import { applicationBackpackBuddyQuery, districtsQuery } from '../../apollo';
import { sortBy } from 'lodash';
import { get } from 'lodash';
import { goBack } from 'connected-react-router';

function getModalStyle() {
      
    return {
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
    },
  }));

 const schoolType = {
    ELEMENTARY: 'Elementary',
    MIDDLE: 'Middle',
    HIGH: 'High',
    NON_TRADITIONAL: 'Non-traditional',
  }

const PrintComponent = (props) => {

    const { match, dispatch } = props;
    const { params } = match;

    const applicationBackpackBuddyId = params.id

    const [hideButtons, setHideButtons] = useState(false);
    const backpackBuddyTeamMemberTypes = {
        PRINCIPAL: 'Principal',
        PRIMARY: 'Primary Coordinator',
        SECONDARY: 'Secondary Coordinator',
        STAFF: 'Additional Staff',
        VOLUNTEER: 'Volunteer',
      };

    const hs = {
        NUTRITION_EDUCATION: 'Nutrition Education',
        SNAP: 'SNAP',
        KIDS_CAFE: 'Kids Cafe',
        PARTNER_SERVICES: 'Partner Services',
        TEACHERS_AID: 'Teachers Aid',
      }

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
  
    return (
        <Query
        query={applicationBackpackBuddyQuery}
        variables={{ applicationBackpackBuddyId: applicationBackpackBuddyId }}
        fetchPolicy="network-only"
      >
        {({ loading, data, error }) => {
            console.log(data)
          if (loading) return <Loading />;
          if (error) return <BlankState title="Error" subtitle="There was an error loading" />;
          
          const body = data.applicationBackpackBuddy;
  
          return (
            <Grid
            style={{height: '100%', minHeight: '100%', minWidth: '100%'}}
            id='print'
            >
                <div style={modalStyle} className={classes.paper}>
                <div >
                <b>Application Details:</b>
                <div>
                    {body.term.termFormattedShort}
                </div>
                <h4 className="purple-headline boxed-in">School Information</h4>
                <div>
                    <b>Did your site participate in this program last year?&nbsp;</b>
                    {body.new == true? 'No': 'Yes'}
                </div>
                <div>
                    <b>School Type:&nbsp;</b>
                    {schoolType[body.school.type]}
                </div>
                <div>
                    <b>School Name:&nbsp;</b>
                    {body.school.name}
                </div>
                <div>
                    <Query query={districtsQuery}>
                        {({ loading: queryLoading, error: queryError, data }) => {
                        const districtOptions = sortBy(get(data, 'allDistricts', []).map(district => ({
                            label: district.name,
                            value: district.districtId,
                        })), 'label');

                        let placeholder = '';
                        if (queryLoading) {
                            placeholder = 'Loading...';
                        }
                        if (queryError) {
                            placeholder = 'Error loading districts, please refresh.';
                        }

                        return (
                            <div>
                                <b>School District:&nbsp;</b>
                                {placeholder == ''? districtOptions.find(el => el.value == body.school.districtId).label: placeholder}
                            </div>
                        );
                        }}
                    </Query>
                </div>
                <div>
                    <b>Organization Type:&nbsp;</b>
                    {body.organizationType}
                </div>
                <div>
                    <b>If you are a 501(c)(3) organization, please list the schools and/or school district (including address), you are partnered with below. If you are a school, please skip this section.&nbsp;</b>
                    {body.nonProfitOrganizations}
                </div>
                <div>
                    <b>Address:&nbsp;</b>
                    {body.school.address1}
                </div>
                <div>
                    <b>Address 2:&nbsp;</b>
                    {body.school.address2}
                </div>
                <div>
                    <b>City:&nbsp;</b>
                    {body.school.city}
                </div>
                <div>
                    <b>State:&nbsp;</b>
                    {body.school.state}
                </div>
                <div>
                    <b>Zip:&nbsp;</b>
                    {body.school.zip}
                </div>
                <div>
                    <b>Grades Served:&nbsp;</b>
                    {body.school.gradesServed.join(', ')}
                </div>
                <div>
                    <div className="roster-table team-members">
                        <b>Program Participants</b>
                        <div className="roster-table-headers">
                        <div className="column teachers">
                            <h4 className="roster-table-header">First Name</h4>
                        </div>
                        <div className="column teachers">
                            <h4 className="roster-table-header">Last Name</h4>
                        </div>
                        <div className="column teachers">
                            <h4 className="roster-table-header">Type</h4>
                        </div>
                        <div className="column teachers">
                            <h4 className="roster-table-header">Title</h4>
                        </div>
                        <div className="column teachers">
                            <h4 className="roster-table-header">Phone</h4>
                        </div>
                        <div className="column teachers">
                            <h4 className="roster-table-header">Email</h4>
                        </div>
                        </div>
                        <div className="roster-content">
                        {
                            body.school.teamMembersBackpackBuddy.map((teamMember, i) => {
                            const {
                                firstName,
                                lastName,
                                type,
                                title,
                                phone: teamMemberPhone,
                                email: teamMemberEmail,
                            } = teamMember;

                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className="row" key={`${i}-${type}+${firstName}+${lastName}`}>
                                <div className="column teachers">
                                    <div className="padding-left-ten">{firstName}</div>
                                </div>
                                <div className="column teachers">
                                    <div className="padding-left-ten">{lastName}</div>
                                </div>
                                <div className="column teachers">
                                    <div className="padding-left-ten">{backpackBuddyTeamMemberTypes[type]}</div>
                                </div>
                                <div className="column teachers">
                                    <div className="padding-left-ten">{title || 'N/A'}</div>
                                </div>
                                <div className="column teachers">
                                    <div className="padding-left-ten">{teamMemberPhone || 'N/A'}</div>
                                </div>
                                <div className="column teachers email">
                                    <div className="padding-left-ten">{teamMemberEmail || 'N/A'}</div>
                                </div>
                                
                                </div>
                            );
                            })
                        }
                        </div>
                    </div>
                </div>
                <h4 className="purple-headline boxed-in">Allotment Information</h4>
                <div>
                    <b>Total Enrollment:&nbsp;</b>
                    {body.numTotalEnrollment}
                </div>
                <div>
                    <b>Number of Students Receiving Reduced Lunch:&nbsp;</b>
                    {body.numReducedLunch}
                </div>
                <div>
                    <b>Number of Students Receiving Free Lunch:&nbsp;</b>
                    {body.numFreeLunch}
                </div>
                <div>
                    <b>Would you be willing to help Houston Food Bank with media events?&nbsp;</b>
                    {body.willHelpMediaEvents  == true? 'Yes': 'No'}
                </div>
                <div>
                    <b>Would you be willing to pass out info about other Houston Food Bank&#39;s programs if provided?&nbsp;</b>
                    {body.willDistributeInfo  == true? 'Yes': 'No'}
                </div>
                <div>
                    <b>Programs interested:&nbsp;</b>
                    {body.programInterests.map(el => hs[el]).join(', ')}
                </div>
                <h4 className="purple-headline boxed-in">The Agreement</h4>
                <div>
                    <b>Agreement Sign:&nbsp;</b>
                    {body.nameSignFirst}
                </div>
                <div>
                    <b>Nondiscrimination Sign:&nbsp;</b>
                    {body.nameSignSecond}
                </div>
                <div>
                    <b>Program Sign:&nbsp;</b>
                    {body.nameSignThird}
                </div>
                </div>
                <DialogTitle className="register-info" style={{ textAlign: 'center' }}>
                
                </DialogTitle>
                <DialogContent className="register-info">
                {!hideButtons && <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                    
                    <Button
                        onClick={() => {
                            setHideButtons(true);
                            setTimeout(() => {
                                window.print();
                                setHideButtons(false);
                            }, 500);
                        }}
                        color="secondary"
                        variant="outlined"
                    >
                        Print Confirmation
                    </Button>
                    </Grid>
                </Grid>}
                {!hideButtons && <DialogActions
                    style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
                >
                    <Button onClick={() => dispatch(goBack())} color="primary" variant="outlined">
                    Close
                    </Button>
                </DialogActions>}
                </DialogContent>
                </div>
            </Grid>
          );
        }}
      </Query>
      
    );
  };
  

export default PrintComponent;