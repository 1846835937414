import gql from 'graphql-tag';

export default gql`
  {
    educatorUsers {
      educatorId
      fullName
      firstName
      lastName
      email
      phone
      createdAt
      deletedAt
      schools {
        schoolId
        backpackBuddyId
        name
        districtId
        districtName
      }
      sites {
        siteId
        name
      }
      organizations {
        organizationId
        name
      }
    }
  }
`;
