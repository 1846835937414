import gql from 'graphql-tag';

export default gql`
  query applicationSchoolMarket($applicationSchoolMarketId: ID!) {
    applicationSchoolMarket(applicationSchoolMarketId: $applicationSchoolMarketId) {
      applicationSchoolMarketId
      status
      new
      termId
      organizationType
      nonProfitOrganizations
      submittedAt
      isContinuation
      educator {
        firstName
        lastName
        phone
        email
      }
      term {
        id
        termId
        type
        year
        startDate
        endDate
        termFormatted
        termFormattedShort
      }
      numFreeLunch
      numReducedLunch
      numTotalEnrollment
      marketType
      operatingFrequency
      preferredDistributionDay
      preferredDistributionTime
      secondaryPreferredDistributionDay
      secondaryPreferredDistributionTime
      agreeToProgramPurpose
      agreeToProgramResponsibilities
      agreeNondiscrimination
      nameSignFirst
      nameSignSecond
      nameSignThird
      certififedVerification
      school {
        schoolId
        name
        type
        districtId
        address1
        address2
        city
        county
        state
        zip
        gradesServed
        applicationsSchoolMarket {
          applicationSchoolMarketId
          submittedAt
          signedAt
          status
          isContinuation
        }
        primaryPointOfContact {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        principal {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
      }
    }
  }
`;