import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const AnonymousRoute = ({
  component,
  userType,
  ...otherProps
}) => {
  if (userType) {
    return <Redirect to="/" />;
  }

  return (
    <Route component={component} {...otherProps} />
  );
};

AnonymousRoute.propTypes = {
  component: PropTypes.any.isRequired,
  userType: PropTypes.string,
};

AnonymousRoute.defaultProps = {
  userType: null,
};

const AnonymousRouteWithData = connect(({ user }) => ({
  userType: user.type,
}))(AnonymousRoute);

export default AnonymousRouteWithData;
