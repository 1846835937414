import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  omit,
  get,
  pullAt,
  has,
} from 'lodash';
import { Field } from 'redux-form';
import { confirmAlert } from 'react-confirm-alert';
import EditSchoolContactModal from '../EditSchoolContactModal';
import TextField from '../TeamMemberTextField';
import deleteIcon from '../../images/delete.svg';
import editIcon from '../../images/edit.svg';
import {
  email,
  phone,
  teamInputRequired,
  teamInputRequiredOptional,
  uniqueTeamMember,
  normalizePhone,
} from '../../helpers';
import getLabel from '../../helpers/getLabel';
import DropDownMenu from '../DropDownMenu';

const TeamMembers = ({
  input,
  newTeamMember,
  change,
  meta: { touched, error },
  formSyncErrors,
  disabled,
  teamMemberTypes,
}) => {
  const hideFields = get(newTeamMember, 'type') && (newTeamMember.type === 'STAFF' || newTeamMember.type === 'VOLUNTEER');
  const [isModalOpen, setModalOpen] = useState(null);

  const openModal = (teamMember) => {
    setModalOpen(teamMember);
  };

  return (
    <Fragment>
      <EditSchoolContactModal
        change={change}
        onClose={() => setModalOpen(null)}
        isActive={isModalOpen}
        teamMemberTypes={teamMemberTypes}
      />

      {
        !disabled && (
          <Fragment>
            <div className="field-combo-wrapper">
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">First Name</label>}
                  component={TextField}
                  validate={teamInputRequired}
                  name="newTeamMember.firstName"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">Last Name</label>}
                  component={TextField}
                  validate={teamInputRequired}
                  name="newTeamMember.lastName"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
            </div>
            <div className="field-combo-wrapper dropdown-select">
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">Participant Type</label>}
                  name="newTeamMember.type"
                  component={DropDownMenu}
                  className="select-field w-select"
                  validate={[teamInputRequired, uniqueTeamMember]}
                  options={teamMemberTypes.options.map((teamMemberOption) => {
                    const { value, label } = teamMemberOption;

                    if (value === 'PRIMARY' || value === 'PRINCIPAL') {
                      return {
                        value,
                        label: `${label} (required)`,
                      };
                    }

                    return teamMemberOption;
                  })}
                />
              </div>
              <div className="field-combo">
                <Field
                  label={<label htmlFor="First-3" className="field-label">Job Title (optional)</label>}
                  component={TextField}
                  name="newTeamMember.title"
                  className="text-field w-input"
                  autoComplete="input-unsupported"
                />
              </div>
            </div>
            {
              !hideFields && (
                <div className="field-combo-wrapper">
                  <div className="field-combo">
                    <Field
                      label={<label htmlFor="First-3" className="field-label">Phone Number</label>}
                      component={TextField}
                      validate={[teamInputRequiredOptional, phone]}
                      normalize={normalizePhone}
                      name="newTeamMember.phone"
                      className="text-field w-input"
                      autoComplete="input-unsupported"
                    />
                  </div>
                  <div className="field-combo">
                    <Field
                      label={<label htmlFor="First-3" className="field-label">Email</label>}
                      component={TextField}
                      validate={[teamInputRequiredOptional, email]}
                      placeholder="e.g. educator@school.com"
                      name="newTeamMember.email"
                      className="text-field w-input"
                      autoComplete="input-unsupported"
                    />
                  </div>
                </div>
              )
            }
            <br />
            <div
              className="submit-button w-button"
              onClick={() => {
                if (newTeamMember && !has(formSyncErrors, 'newTeamMember')) {
                  let normalizedNewTeamMember = newTeamMember;
                  if (normalizedNewTeamMember.type === 'STAFF' || normalizedNewTeamMember.type === 'VOLUNTEER') {
                    normalizedNewTeamMember = omit(normalizedNewTeamMember, ['phone', 'email']);
                  }
                  let updatedTeamMembers = input.value;
                  updatedTeamMembers = updatedTeamMembers.concat(normalizedNewTeamMember);
                  input.onChange(updatedTeamMembers);
                  change('newTeamMember', null);
                }
              }}
            >
              add participant
            </div>
          </Fragment>
        )
      }
      <div className="roster-table team-members">
        <div className="roster-table-headers">
          <div className="column teachers">
            <h4 className="roster-table-header">First Name</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Last Name</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Type</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Title</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Phone</h4>
          </div>
          <div className="column teachers">
            <h4 className="roster-table-header">Email</h4>
          </div>
        </div>
        <div className="roster-content">
          {
            input.value && input.value.map((teamMember, i) => {
              const {
                firstName,
                lastName,
                type,
                title,
                phone: teamMemberPhone,
                email: teamMemberEmail,
              } = teamMember;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="row" key={`${i}-${type}+${firstName}+${lastName}`}>
                  <div className="column teachers">
                    <div className="padding-left-ten">{firstName}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{lastName}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{getLabel(teamMemberTypes.options, type)}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{title || 'N/A'}</div>
                  </div>
                  <div className="column teachers">
                    <div className="padding-left-ten">{teamMemberPhone || 'N/A'}</div>
                  </div>
                  <div className="column teachers email">
                    <div className="padding-left-ten">{teamMemberEmail || 'N/A'}</div>
                  </div>
                  <div className="column teachers delete icon">
                    {
                      !disabled && (
                        <div
                          className="crud-action-icon-wrapper w-inline-block"
                          onClick={() => {
                            confirmAlert({
                              message: 'Are you sure?',
                              buttons: [
                                {
                                  label: 'Cancel',
                                },
                                {
                                  label: 'Delete',
                                  onClick: () => {
                                    const newValue = [...input.value];
                                    pullAt(newValue, i);
                                    input.onChange(newValue);
                                  },
                                },
                              ],
                            });
                          }}
                        >
                          <img src={deleteIcon} width={14} alt="delete" />
                        </div>
                      )
                    }
                  </div>
                  <div className="column teachers delete icon">
                    {
                      !disabled && (
                        <div
                          className="crud-action-icon-wrapper w-inline-block"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal({
                              index: i,
                              ...teamMember,
                            });
                          }}
                        >
                          <img src={editIcon} width={14} alt="edit" />
                        </div>
                      )
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
      {touched && error
        && (
          <span style={{
            fontSize: 12, color: 'red',
          }}
          >
            {error}
          </span>
        )
      }
    </Fragment>
  );
};

TeamMembers.propTypes = {
  input: PropTypes.object.isRequired,
  newTeamMember: PropTypes.object,
  meta: PropTypes.object.isRequired,
  formSyncErrors: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  teamMemberTypes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

TeamMembers.defaultProps = {
  newTeamMember: null,
  disabled: false,
};

export default TeamMembers;
