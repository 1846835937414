const sortGradesServed = (gradesServed) => {
  const data = gradesServed.options;
  const gradeK = data[data.length - 1];
  const gradePK = data[data.length - 2];
  const grades = data.filter(grade => !Number.isNaN(Number(grade.value)));
  grades.unshift(gradeK);
  grades.unshift(gradePK);
  return grades;
};

export default sortGradesServed;
