import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  input,
  label,
  disabled,
  meta: { touched, error },
  ...otherProps
}) => (
  <Fragment>
    <div {...otherProps}>
      <input
        {...input}
        disabled={disabled}
        id={`${input.name}-checkbox`}
        type="checkbox"
        className="display-none w-checkbox-input"
        checked={Boolean(input.value)}
      />
      <label htmlFor={`${input.name}-checkbox`} className="checkbox-label w-form-label">
        {label}
      </label>
    </div>

    {touched && error && <span style={{ color: 'red' }}>{error}</span>}
  </Fragment>
);

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
};

export default Checkbox;
