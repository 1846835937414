import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const ApplicationActions = ({
  submitting,
  handleSubmit,
  saveAsDraft,
  adminView,
  user,
  initialValues,
  match,
  step,
  readOnly,
  program: {
    name,
    programUrl,
  },
}) => {
  const multiStepApplication = name === 'Backpack Buddy' || name === 'Teachers Aid';
  const nextButtonText = multiStepApplication && (step === 1 || step === 2) ? 'Next' : 'Submit Application';

  let adminBackLink = '/';
  let applicationRequired = false;

  if (adminView) {
    switch (name) {
      case 'Backpack Buddy':
      case 'Teachers Aid':
      case 'School Market':
        adminBackLink = `${programUrl}/schools/${initialValues.school.schoolId}/applications`;
        break;
      case 'Kids Cafe':
        adminBackLink = `${programUrl}/sites/${initialValues.site.siteId}/applications`;
        break;
      case 'Partner Services':
        adminBackLink = `${programUrl}/organizations/${initialValues.organization.organizationId}/applications`;
        break;
      default:
        break;
    }
  } else {
    const {
      applicationBackpackBuddyRequired,
      applicationKidsCafeRequired,
      applicationPartnerServicesRequired,
      applicationSchoolMarketRequired,
      applicationTeachersAidRequired,
    } = user;

    switch (name) {
      case 'Backpack Buddy':
        applicationRequired = applicationBackpackBuddyRequired;
        break;
      case 'Teachers Aid':
        applicationRequired = applicationTeachersAidRequired;
        break;
      case 'School Market':
        applicationRequired = applicationSchoolMarketRequired;
        break;
      case 'Kids Cafe':
        applicationRequired = applicationKidsCafeRequired;
        break;
      case 'Partner Services':
        applicationRequired = applicationPartnerServicesRequired;
        break;
      default:
        break;
    }
  }

  return (
    <div className={classnames('next-step', { back: step !== 1 })}>
      {
        step !== 1 && (
          <div>
            <Link
              to={`${programUrl}/application/${match.params.step === 'step-3' ? 'step-2' : 'step-1'}/${match.params.id}`}
              className="btn-fill-p w-button"
            >
              Go Back
            </Link>
          </div>
        )
      }
      <div>
        {
          readOnly ? (
            <Fragment>
              {
                adminView ? (
                  <Link
                    to={adminBackLink}
                    className="form-save w-button"
                  >
                    Exit
                  </Link>
                ) : (
                  <Link
                    to={applicationRequired ? '/' : `${programUrl}/account/applications`}
                    className="form-save w-button"
                  >
                      Exit
                  </Link>
                )
              }
              {
                match.params.id && multiStepApplication && step !== 3 && (
                  <Link
                    to={`${programUrl}/application/${match.params.step === 'step-1' ? 'step-2' : 'step-3'}/${match.params.id}`}
                    className="btn-green w-button"
                  >
                    Next
                  </Link>
                )
              }
            </Fragment>
          ) : (
            <Fragment>
              <input
                type="button"
                value="Save as Draft"
                disabled={submitting}
                className="form-save w-button"
                onClick={handleSubmit(saveAsDraft)}
              />
              <input
                type="submit"
                value={submitting ? 'Please wait...' : nextButtonText}
                disabled={submitting}
                className="btn-green w-button"
              />
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

ApplicationActions.propTypes = {
  adminView: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveAsDraft: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  program: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default ApplicationActions;
