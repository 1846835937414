import gql from 'graphql-tag';

export default gql`
  query {
    enums {
      name
      options {
        value
        label
      }
    }
  }
`;
