import gql from 'graphql-tag';

export default gql`
  query adminConfigTeachersAid {
    adminConfigTeachersAid {
      id
      configTeachersAidId
      summerEnabled
      schoolEnabled
      waitlistEnabled
      lastEdited
    }
  }
`;
