import React, { Fragment } from 'react';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { notificationQuery, adminEditNotificationMutation } from '../../apollo';
import BlankState from '../BlankState';
import Loading from '../Loading';
import handleToastError from '../../helpers/handleToastError';
import NotificationForm from '../NotificationForm';

const Notifications = () => (
  <Fragment>
    <h2>Banner Notifications</h2>
    <Query
      query={notificationQuery}
      fetchPolicy="cache-and-network"
    >
      {({ loading, data: { notification }, error }) => {
        if (loading) return <Loading />;
        if (error) return <BlankState title="Error" subtitle="There was an error loading" />;

        return (
          <Fragment>
            <div className="text-block-14">
              Create notifications to share important messages
              with Backpack Buddy educators.
            </div>
            <div className="top-border">
              <div className="w-form">
                <Mutation
                  mutation={adminEditNotificationMutation}
                  onError={mutationError => handleToastError(mutationError)}
                  onCompleted={() => toast('Update Successful')}
                >
                  {(adminEditNotification) => {
                    const update = values => (
                      adminEditNotification({
                        variables: {
                          input: {
                            ...values,
                          },
                        },
                      })
                    );

                    return (
                      <NotificationForm
                        notification={notification}
                        update={update}
                      />
                    );
                  }}
                </Mutation>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);

export default Notifications;
