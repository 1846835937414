import gql from 'graphql-tag';

export default gql`
  mutation adminEditConfigTeachersAid($input: ConfigTeachersAidInput!) {
    adminEditConfigTeachersAid(input: $input) {
      id
      configTeachersAidId
      schoolEnabled
      summerEnabled
      waitlistEnabled
    }
  }
`;
