import gql from 'graphql-tag';

export default gql`
  query ($applicationBackpackBuddyId: ID!) {
    applicationBackpackBuddy(applicationBackpackBuddyId: $applicationBackpackBuddyId) {
      applicationBackpackBuddyId
      status
      new
      termId
      organizationType
      nonProfitOrganizations
      isContinuation
      educator {
        firstName
        lastName
        phone
        email
      }
      term {
        id
        termId
        type
        year
        startDate
        endDate
        termFormatted
        termFormattedShort
      }
      certififedVerification
      emailSecondaryCoordinator
      willHelpMediaEvents
      willDistributeInfo
      programInterests
      agreeToProgramPurpose
      agreeToProgramResponsibilities
      agreeNondiscrimination
      nameSignFirst
      nameSignSecond
      nameSignThird
      numFreeLunch
      numReducedLunch
      numTotalEnrollment
      submittedAt
      school {
        principal {
          teamMemberId
          firstName
          lastName
          title
          phone
          email
          type
        }
        applicationsBackpackBuddy {
          applicationBackpackBuddyId
          submittedAt
          signedAt
          status
          isContinuation
        }
        schoolId
        name
        type
        districtId
        address1
        address2
        city
        county
        state
        zip
        gradesServed
        teamMembersBackpackBuddy {
          teamMemberId
          firstName
          lastName
          title,
          phone
          email
          type
        }
      }
    }
  }
`;
