/* eslint-disable react/prefer-stateless-function */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../images/calendar.svg';

class CustomInput extends Component {
  render() {
    const { value, onClick, placeholder } = this.props;

    return (
      <div onClick={onClick} type="button" className="link-block-8 w-inline-block pointer">
        <div className="text-block-12">{value ? moment.utc(value).format('M-D-YY') : placeholder}</div>
        <img src={CalendarIcon} width="18" alt="calendar" className="image-4" />
      </div>
    );
  }
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
};

CustomInput.defaultProps = {
  value: null,
  onClick: null,
  placeholder: null,
};

const DatePicker = (props) => {
  const {
    input,
    label,
    meta: { touched, error },
  } = props;

  return (
    <Fragment>
      {label}
      <ReactDatePicker
        customInput={<CustomInput />}
        selected={input.value || null}
        onChange={input.onChange}
        placeholderText="Select a date"
        {...props}
      />
      &nbsp;
      {touched && error && <span style={{ color: 'red' }}>{error}</span>}
    </Fragment>
  );
};

DatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
};

export default DatePicker;
