import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
  Link,
} from 'react-router-dom';
import { Query } from 'react-apollo';
import BackIcon from '../../images/back-icon.svg';
import Loading from '../Loading';
import BlankState from '../BlankState';
import DetailNav from './DetailNav';
import Profile from './Profile';
import Contacts from './Contacts';
import ProgramStatus from './ProgramStatus';
import Applications from './Applications';
import DetailFormWrapper from './DetailFormWrapper';
import {
  adminSiteQuery,
  adminEditSiteProfileMutation,
  adminEditSiteContactsMutation,
  adminUpdateApplicationsMutation,
  adminUpdateProgramStatusMutation,
} from '../../apollo';

const detailRoutes = [{
  path: '/kids-cafe/sites/:id',
  mutation: adminEditSiteProfileMutation,
  component: Profile,
}, {
  path: '/kids-cafe/sites/:id/contacts',
  mutation: adminEditSiteContactsMutation,
  component: Contacts,
}, {
  path: '/kids-cafe/sites/:id/program-status',
  mutation: adminUpdateProgramStatusMutation,
  component: ProgramStatus,
}, {
  path: '/kids-cafe/sites/:id/applications',
  mutation: adminUpdateApplicationsMutation,
  component: Applications,
}];

const SiteDetail = ({ match, ...otherProps }) => (
  <Fragment>
    <Query
      query={adminSiteQuery}
      variables={{
        siteId: match.params.id,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        const loadingData = !data.adminSite && loading;
        if (loadingData || error) {
          return (
            <Fragment>
              <div className="profile-intro">
                <Link
                  to="/kids-cafe/sites"
                  className="back-btn w-inline-block"
                >
                  <img className="image-14" src={BackIcon} alt="back" />
                  <div className="text-block-11">back to sites</div>
                </Link>
              </div>
              {loading && <Loading />}
              {error && <BlankState title="Error" subtitle="There was an error loading" />}
            </Fragment>
          );
        }

        const { adminSite: site } = data;
        return site ? (
          <Fragment>
            <div className="profile-intro">
              <Link
                to="/kids-cafe/sites"
                className="back-btn w-inline-block"
              >
                <img className="image-14" src={BackIcon} alt="back" />
                <div className="text-block-11">back to sites</div>
              </Link>
              <h2 className="purple-headline">{site.name}</h2>
              <div className="divider-2 settings" />
            </div>
            <div className="div-block-13">
              <DetailNav {...otherProps} match={match} />
              <Switch>
                {
                  detailRoutes.map(({ path, mutation, component: Component }) => (
                    <Route
                      key={path}
                      exact
                      path={path}
                      render={() => (
                        <DetailFormWrapper
                          Form={Component}
                          mutation={mutation}
                          site={site}
                          {...otherProps}
                        />
                      )}
                    />
                  ))
                }
                <Route component={() => <Redirect to="/kids-cafe/sites" />} />
              </Switch>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="profile-intro">
              <Link
                to="/kids-cafe/sites"
                className="back-btn w-inline-block"
              >
                <img className="image-14" src={BackIcon} alt="back" />
                <div className="text-block-11">back to sites</div>
              </Link>
            </div>
            <BlankState title="Error" subtitle="There was an error loading" />
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);

SiteDetail.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SiteDetail;
