import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { Query } from 'react-apollo';
import { districtsQuery } from '../../apollo';

const DistrictDropDownMenu = ({
  input,
  label,
  meta: { touched, error },
  ...otherProps
}) => (
  <Query query={districtsQuery}>
    {({ loading: queryLoading, error: queryError, data }) => {
      const districtOptions = sortBy(get(data, 'allDistricts', []).map(district => ({
        label: district.name,
        value: district.districtId,
      })), 'label');

      let placeholder = 'Select School District';
      if (queryLoading) {
        placeholder = 'Loading...';
      }
      if (queryError) {
        placeholder = 'Error loading districts, please refresh.';
      }

      return (
        <div style={{ position: 'relative' }}>
          {label}
          <select disabled={queryLoading || queryError} {...input} {...otherProps}>
            <option value="">{placeholder}</option>
            {
              districtOptions.map(({ value, label: optionLabel }) => (
                <option key={value} value={value}>{optionLabel}</option>
              ))
              }
          </select>
          {
              touched && error && (
                <span style={{
                  position: 'absolute', fontSize: 10, bottom: -18, color: 'red',
                }}
                >
                  {error}
                </span>
              )
            }
        </div>
      );
    }}
  </Query>
);

DistrictDropDownMenu.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
};

export default DistrictDropDownMenu;
