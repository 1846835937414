import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Mutation } from 'react-apollo';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../images/calendar.svg';
import { deleteReportsMutation, schoolReportsQuery } from '../../apollo';
import handleToastError from '../../helpers/handleToastError';

const renderDatepicker = date => (
  <div>
    <button type="button" className="link-block-6 w-inline-block datepicker-select-button">
      <div className="text-block-3">{moment(date).format('MMMM YYYY')}</div>
      <img src={CalendarIcon} width="18" alt="calendar" className="image-5" />
    </button>
  </div>
);

const ReportsDatePicker = (
  {
    selectedReports,
    filterDates,
    onFilterDateChange,
    isAllSelected,
    onSelectAllChange,
    onExport,
    schoolId,
    isAdmin,
    setSelectedReports,
  },
) => {
  const handleDateChange = newFilterDates => onFilterDateChange(newFilterDates);

  const renderDateFilters = (
    <div className="date-range">
      <div className="date-from">
        <div className="text-block-2">From</div>
        <DatePicker
          customInput={renderDatepicker(filterDates.startDate)}
          selected={new Date(filterDates.startDate)}
          showMonthYearPicker
          maxDate={filterDates.endDate}
          onChange={(date) => {
            handleDateChange({ ...filterDates, startDate: moment(date).startOf('month').local().toDate() });
          }}
        />
      </div>
      <div className="date-from">
        <div className="text-block-2">To</div>
        <DatePicker
          customInput={renderDatepicker(filterDates.endDate)}
          selected={new Date(filterDates.endDate)}
          showMonthYearPicker
          minDate={filterDates.startDate}
          onChange={date => handleDateChange({ ...filterDates, endDate: moment(date).endOf('month').local().toDate() })}
        />
      </div>
    </div>
  );

  const renderSelectedReports = (
    <div className="export-reports-div">
      <div className="export-left">
        <div className="reports-selected">
          <div className="div-block-10">
            <div className="text-block-4">{selectedReports.length}</div>
          </div>
        </div>
        <div className="text-block-5">Selected</div>
      </div>
      <div className="export-right">
        <div className="w-form">
          <div id="email-form-2" name="email-form-2" data-name="Email Form 2" className="form">
            { isAdmin && (
              <Mutation
                mutation={deleteReportsMutation}
                variables={{ termMonthIds: selectedReports }}
                refetchQueries={[
                  {
                    query: schoolReportsQuery,
                    variables: {
                      startDate: filterDates.startDate,
                      endDate: filterDates.endDate,
                      schoolId,
                    },
                  },
                ]}
                onError={mutationError => handleToastError(mutationError)}
                onCompleted={() => {
                  toast('Delete Successful');
                  setSelectedReports({});
                }}
                awaitRefetchQueries
              >
                {(deleteReports) => {
                  const confirmDelete = () => {
                    confirmAlert({
                      message: 'Are you sure?',
                      buttons: [
                        {
                          label: 'Cancel',
                        },
                        {
                          label: 'Delete',
                          onClick: deleteReports,
                        },
                      ],
                    });
                  };
                  return (
                    <button
                      onClick={confirmDelete}
                      type="button"
                      value="Delete"
                      className="submit-button w-button"
                      disabled={!selectedReports.length}
                    >
                      Delete
                    </button>
                  );
                }}
              </Mutation>
            )}
            <button onClick={onExport} type="button" value="Export" className="submit-button w-button">
              Export
            </button>
            <div className="w-checkbox">
              <input
                type="checkbox"
                id="checkbox-4"
                name="checkbox-4"
                data-name="Checkbox 4"
                className="w-checkbox-input"
                checked={isAllSelected}
                onChange={onSelectAllChange}
              />
              <label
                htmlFor="checkbox-4"
                className="checkbox-label-3 w-form-label"
              >
                Export
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="date-export">
      {renderDateFilters}
      {renderSelectedReports}
    </div>
  );
};

ReportsDatePicker.propTypes = {
  filterDates: PropTypes.object.isRequired,
  onFilterDateChange: PropTypes.func.isRequired,
  selectedReports: PropTypes.array.isRequired,
  setSelectedReports: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  onSelectAllChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  schoolId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default ReportsDatePicker;
