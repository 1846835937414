import React, { Fragment } from 'react';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { surveysQuery, adminCreateSurveyMutation } from '../apollo';
import BlankState from '../components/BlankState';
import Loading from '../components/Loading';
import handleToastError from '../helpers/handleToastError';
import withAdminLayout from '../helpers/withAdminLayout';
import SurveyForm from '../components/SurveyForm';
import SurveyTable from '../components/SurveyTable';

const AdminSurveysPage = () => (
  <Fragment>
    <h2>Surveys</h2>
    <Query
      query={surveysQuery}
      fetchPolicy="cache-and-network"
    >
      {({ loading, data, error }) => {
        if (loading) return <Loading />;
        if (error) return <BlankState title="Error" subtitle="There was an error loading" />;

        const { surveys } = data;

        return (
          <Fragment>
            <div className="text-block">Share survey links with all educators.</div>
            <div className="top-border">
              <div className="w-form">
                <Mutation
                  mutation={adminCreateSurveyMutation}
                  onError={mutationError => handleToastError(mutationError)}
                  onCompleted={() => toast('Update Successful')}
                  refetchQueries={[{ query: surveysQuery }]}
                  awaitRefetchQueries
                >
                  {(adminCreateSurvey) => {
                    const update = values => (
                      adminCreateSurvey({
                        variables: {
                          input: {
                            ...values,
                          },
                        },
                      })
                    );

                    return (
                      <SurveyForm update={update} />
                    );
                  }}
                </Mutation>

              </div>
              <div className="table-wrapper-2">
                <SurveyTable surveys={surveys} />
              </div>
            </div>
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);

export default withAdminLayout(AdminSurveysPage);
