import gql from 'graphql-tag';

export default gql`
  mutation adminUpdateEducatorUser($input: AdminEducatorUserInput!) {
    adminUpdateEducatorUser(input: $input) {
      id
      educatorId
      updatedAt
      fullName
      firstName
      lastName
      email
      deletedAt
      schools {
        id
        schoolId
        name
        districtId
      }
      sites {
        id
        siteId
        name
      }
      organizations {
        id
        organizationId
        name
      }
    }
  }
`;
