import React from 'react';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import TextField from '../TextField';
import { required, url } from '../../helpers';

const SurveyForm = reduxForm({
  form: 'surveyForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  dispatch,
  reset,
  update,
}) => {
  const submitAndReset = data => (
    update(data).then(() => (
      dispatch(reset('surveyForm'))
    ))
  );

  return (
    <Form onSubmit={handleSubmit(submitAndReset.bind(this))}>
      <div className="field-combo-wrapper-2 w-clearfix">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="title" className="field-label">Survey Title</label>}
            component={TextField}
            validate={required}
            disabled={submitting}
            name="title"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="link" className="field-label">Survey Link</label>}
            component={TextField}
            validate={[required, url]}
            disabled={submitting}
            name="link"
            className="text-field w-input"
          />
        </div>
        <input
          type="submit"
          value={submitting ? 'Please wait...' : 'Add Survey and Share'}
          disabled={submitting}
          className="btn-green solid top-space w-button"
        />
      </div>
    </Form>
  );
});

export default SurveyForm;
