import gql from 'graphql-tag';

export default gql`
  mutation adminEditConfigSchoolMarket($input: ConfigSchoolMarketInput!) {
    adminEditConfigSchoolMarket(input: $input) {
      id
      configSchoolMarketId
      schoolEnabled
      summerEnabled
      waitlistEnabled
    }
  }
`;
