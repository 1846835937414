import { push } from 'connected-react-router';
import { setProgramAction } from '../redux';

const selectProgram = (dispatch, program) => {
  const { programUrl } = program;

  dispatch(setProgramAction(program));
  dispatch(push(programUrl));
};

export default selectProgram;
