import React from 'react';
import PropTypes from 'prop-types';
import ReportDetailsPending from './ReportDetailsPending';
import ReportDetailsSubmitted from './ReportDetailsSubmitted';
import SubmitReportForm from './SubmitReportForm';

const ReportDetails = (props) => {
  const { termMonth, ...otherProps } = props;

  if (!termMonth.distributions.length) {
    return (<ReportDetailsPending termMonth={termMonth} {...otherProps} />);
  }

  if (termMonth.distributions.length) {
    return (
      <>
        <ReportDetailsSubmitted termMonth={termMonth} {...otherProps} />
        <SubmitReportForm
          form={`distributionReport_${termMonth.termMonthId}`}
          termMonth={termMonth}
          {...otherProps}
        />
      </>
    );
  }

  return null;
};

ReportDetails.propTypes = {
  termMonth: PropTypes.object.isRequired,
  schoolId: PropTypes.string.isRequired,
};

export default ReportDetails;
