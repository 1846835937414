import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import BackIcon from '../../images/back-icon.svg';
import SchoolUserDetailForm from '../SchoolUserDetailForm';
import handleToastError from '../../helpers/handleToastError';
import Loading from '../Loading';
import BlankState from '../BlankState';
import { adminEducatorUserDetailsQuery, adminUpdateEducatorUserMutation } from '../../apollo';

const SchoolUserDetail = (props) => {
  const { location, match, program: { programUrl } } = props;

  return (
    <Fragment>
      <Query
        query={adminEducatorUserDetailsQuery}
        skip={match.params.id === 'new'}
        variables={{
          educatorId: match.params.id,
        }}
      >
        {({ loading, data, error }) => {
          if (loading) {
            return (
              <Fragment>
                <div className="profile-intro">
                  <Link
                    to={`${programUrl}/users`}
                    className="back-btn w-inline-block"
                  >
                    <img src={BackIcon} alt="back" className="image-20" />
                    <div>back to users</div>
                  </Link>
                </div>
                <Loading />
              </Fragment>
            );
          }

          if (error) {
            return (
              <Fragment>
                <div className="profile-intro">
                  <Link
                    to={`${programUrl}/users`}
                    className="back-btn w-inline-block"
                  >
                    <img src={BackIcon} alt="back" className="image-20" />
                    <div>back to users</div>
                  </Link>
                </div>
                <BlankState title="Error" subtitle="There was an error loading" />
              </Fragment>
            );
          }

          const adminEducatorUserDetails = get(data, 'adminEducatorUserDetails', {});

          return (
            <Fragment>
              <div className="profile-intro">
                <Link
                  to={`${programUrl}/users`}
                  className="back-btn w-inline-block"
                >
                  <img src={BackIcon} alt="back" className="image-20" />
                  <div>back to users</div>
                </Link>
                <h2 className="purple-headline">{get(adminEducatorUserDetails, 'fullName', '')}</h2>
              </div>
              <Mutation
                mutation={adminUpdateEducatorUserMutation}
                onError={mutationError => handleToastError(mutationError)}
                onCompleted={() => toast('Update Successful')}
              >
                {(adminUpdateEducatorUser, { data: updateData }) => {
                  const update = (values) => {
                    const {
                      id,
                      educatorId,
                      email,
                      firstName,
                      lastName,
                    } = values;
                    const schools = values.schools ? values.schools.map(({ districtName, ...rest }) => rest) : [];
                    const newEducatorValue = {
                      id,
                      educatorId,
                      email,
                      firstName,
                      lastName,
                      schools,
                    };
                    return (
                      adminUpdateEducatorUser({
                        variables: {
                          input: {
                            ...newEducatorValue,
                          },
                        },
                      })
                    );
                  };

                  if (updateData && location.pathname.includes('new')) {
                    return <Redirect to={`${updateData.adminUpdateEducatorUser.educatorId}`} />;
                  }

                  return (
                    <SchoolUserDetailForm
                      {...props}
                      onSubmit={update}
                      adminEducatorUserDetails={adminEducatorUserDetails}
                    />
                  );
                }}
              </Mutation>
            </Fragment>
          );
        }}
      </Query>
    </Fragment>
  );
};

SchoolUserDetail.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default SchoolUserDetail;
