import React, { Fragment } from 'react';

const SchoolMarketAgreement = () => {
  return (
    <Fragment>
      <h4 className="purple-headline boxed-in">Program Agreement</h4>
      <div className="agreement-div scroll">
        <h5 className="black-headline center">Exhibit B: School Market (Brick and Mortar)</h5>
        <h5 className="black-headline">Program Description</h5>
        <p>
          The School Market programs helps alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily accessible source of food assistance. Brick and mortar pantries are set up like small grocery stores at a consistent location within a schools’ campus, with set distribution schedules. School Market welcome middle and high schools to house the market in, markets are open to the community.
        </p>
        <h5 className="black-headline">Program Details – School Programs Partner’s Responsibilities</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item sub"><p><span className="line-item">1.</span> The School Partner <b>MUST</b> have a minimum of 2 distributions per month. If a distribution is canceled School Partner <b>MUST</b> reschedule the missing distribution within that same month, no exceptions.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">2.</span> Intake must be completed on our Link2Feed platform at the point of service. Paper forms are only used for emergencies and must be approved by your School Market Specialist. Please review the School Market workbook page.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">3.</span> Food from other sources may not be mixed in with the food you receive from HFB for your Brick and Mortar.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.</span> Distribute food to program participants in accordance with the pre-determined schedule.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.</span> Follow specific guidelines laid out by school and Houston Food Bank personnel.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">6.</span> Identify a School Market Coordinator to be the primary contact for the School Market Program.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">7.</span> Receive deliveries on the designated day and time agreed upon by both parties.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">8.</span> Provide student volunteers to conduct intake/record visits of School Market clients in Link2Feed platform.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">9.</span> Once the MOU has been established, HFB will provide access to the reporting portal that provides School Programs data, using the School Programs unique ID, that can be checked at any time for data information, utilization, and other report data points.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">10.</span> I understand that the items received cannot be sold, used for other school programs, used for fundraisers, or used for any other purpose other than to provide for the neighbors you are serving. Failure to comply with the program requirements will result in the loss of the School Market Program.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">11.</span> For any questions that you may have about the program, please contact the School Market staff at schoolmarket@houstonfoodbank.org.</p></li>
        </ul>
        <h5 className="black-headline center">Exhibit C: School Market (Mobile Distribution)</h5>
        <h5 className="black-headline">Program Description</h5>
        <p>
          The School Market programs helps alleviate child hunger in the community by providing food to children and their families. School based markets are located on the grounds of a school to provide an easily accessible source of food assistance. Mobile distributions operate through HFB’s large, refrigerated trailers, with a set distribution schedule. School Market welcomes middle and high schools to house the market in, markets are open to the community.
        </p>
        <h5 className="black-headline">Program Details – School Programs Partner’s Responsibilities</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item sub"><p><span className="line-item">1.</span> The School Partner requires a partner to have a minimum of 2 distributions per month. If a distribution is canceled School Partner is responsible for reschedule the canceled distribution within that same month, no exceptions;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">a.</span> Changes to the Distribution Schedule must be made in writing and sent via email to their liaison at least one (1) week in advance of the distribution;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">b.</span> Cancellations due to extreme weather, equipment failure, or natural disasters may be made as needed;</p></li>
          <li className="agreement-list-item subsub"><p><span className="line-item">c.</span> Request cancellations of School Market (Brick and Mortar) distributions by notifying HFB via email at least 24 hours prior to the distribution;</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">2.</span> Intake must be completed on our Link2Feed platform at the point of service. Paper forms are only used for emergencies and must be approved by your School Market Specialist. Please review the School Market workbook page</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">3.</span> Receive the mobile truck on the designated day and time agreed upon by both parties.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.</span> Mobile distributions will adhere to 3 hours per distribution.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.</span> Conduct temperature monitoring and safe keeping of perishable foods (i.e., mobile doors kept closed, re moving limited product at times that is perishable</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">6.</span> Follow specific guidelines laid out by school and Houston Food Bank personnel.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">7.</span> Identify a School Market Coordinator to be the primary contact for the School Market Program.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">8.</span> Provide student volunteers to conduct intake/record visits of School Market clients in Link2Feed platform</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">9.</span> Provide access to a minimum of 8 to 10 parking spots for parking of the mobile market and to allow for set-up and preparation for the distribution.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">10.</span> Provide a safe space for eligible individuals without a vehicle to access the market.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">11.</span> Provide access to sufficient on-site trash receptacles and coordinate all site clean-up.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">12.</span> Once the MOU has been established, HFB will provide access to the reporting portal that provides School Programs data, using the School Programs unique ID, that can be checked at any time for data information, utilization, and other report data points.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">13.</span> I understand that the items received cannot be sold, used for other school programs, used for fundraisers, or used for any other purpose other than to provide for the neighbors you are serving. Failure to comply with the program requirements will result in the loss of the School Market Program at School Partner.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">14.</span> For any questions that you may have about the program, please contact the School Market staff at schoolmarket@houstonfoodbank.org.</p></li>
        </ul>
        <h5 className="black-headline center">Exhibit E: The Locker Project</h5>
        <h5 className="black-headline">Program Description</h5>
        <p>
        The Locker Project was created due to a stigma that surrounds teens and receiving food assistance in school, this program targets families that need food assistance but are not able to attend regular hours of distributions or pantries. The Locker system has 24-hour refrigerated access, allowing families to pick up food conveniently. The program will enable families to shop bi-weekly on the Order Ahead platform for items they would like delivered. The program welcomes middle and high schools to participate.
        </p>
        <h5 className="black-headline">Program Details – School Programs Partner’s Responsibilities</h5>
        <ul className="agreement-text w-list-unstyled">
          <li className="agreement-list-item sub"><p><span className="line-item">1.</span> The Locker Project is limited and requires a committee approval after ensuring that the site meets the requirements for this program to be successful. HFB representative and facilities department will work with district and/or school facilities/maintenance department to ensure proper installation.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">2.</span> All locker equipment (all kiosk, consoles, and internet cradles, etc.) are the property of the Houston Food Bank while in the possession of the school partner that is enrolled in the locker project program. School partner will ensure the locker equipment is not damaged or vandalized outside of normal wearing of the equipment.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">3.</span> If equipment is not responding (off temperature or refrigeration not working, console not responding, kiosk not opening), school partner is responsible for notifying the HFB representative by phone and/or email soon as they have been notified. Houston Food bank will take responsibility of the maintenance of the locker for the duration of the MOU.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">4.</span> School Coordinator will identify the students that are in need of assistance, they will reach out to the families regarding the Locker Program and if interested they will ensure that all student participants {"{their families}"} have a signed permission slip (permission slips are electronic) as well as Link2Feed (Depending on the site) before the start of the program. All Locker Project records should be stored for a minimum of three years at the school partner location.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">5.</span> Distribute healthy, nutritious food to children (their families) free of charge.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">6.</span> Identify students that are food insecure in your school(s) using the guidelines specified in the Student Referral Form, as indicated in the Locker Project Informational Packet.</p></li>
          <li className="agreement-list-item"><p><b>Does my household receive Federal Benefits?</b></p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">a.</span> If you or someone in your household receives at least one of the following federal benefits, you have categorical eligibility for TEFAP. You are automatically eligible to receive TEFAP and do not need to disclose your household’s monthly income.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">b.</span> TEFAP Eligible Federal Benefits:</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">c.</span> Supplemental Nutrition Assistance Program (SNAP)</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">d.</span> Temporary Assistance for Needy Families (TANF)</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">e.</span> Supplemental Security Income (SSI)</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">f.</span> National School Lunch Program (NSLP)</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">g.</span> Non-institutional Medicaid</p></li>
          <li className="agreement-list-item"><p><b>Does my Household income make me eligible?</b></p></li>
          <li className="agreement-list-item sub"><p>If no one in your household receives any of the above federal benefits, you may still qualify for TEFAP based on income. Using the chart below, refer to the income criteria for the number of people in your household. If this dollar amount is equal to or below your household income, you meet the requirements to receive USDA commodities and are eligible for TEFAP.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">7.</span> Participating students (their families) must meet income eligibility with either receiving free or reduced lunch at school or have a letter of extreme circumstances or economic hardship(s) written by parent or School Partner Coordinator.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">8.</span> Identify any food allergies that the enrolled child may have (peanuts, milk, etc.). Flag their name so that those items will not be distributed to them once sent to the school. Contact the Special Projects Coordinator for specific food allergy procedures.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">9.</span> Food will be distributed bi-weekly to students (their families) through the temperature- controlled lockers installed at the enrolled site. Food will be picked up with assigned group pick-up day/time range bi-weekly to the students (their families) that have currently enrolled in the “Locker Project” program.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">10.</span> School Coordinator ensures enrollment of students (their families) in the Locker Project program.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">11.</span> The locker systems vary in size (16-40 compartments), depending on which systems the school acquires they will be responsible for the recruiting of families in order to use the locker at its’ full capacity, thus receiving food bi-weekly per semester. Training will occur during school partner onboarding and student onboarding.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">12.</span> All students (their families) will enroll in the program per semester. If you have students (their families) to enroll for the entire school year, they would just re-enroll for the next semester.</p></li>
          <li className="agreement-list-item sub"><p><span className="line-item">13.</span> For any questions that you may have about the program, please contact the Locker Project staff at Lockerproject@houstonfoodbank.org.</p></li>
        </ul>
      </div>
    </Fragment>
  );
};

export default SchoolMarketAgreement;
