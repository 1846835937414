import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const DetailNav = (props) => {
  const { match } = props;

  return (
    <div className="detail-tabs">
      <NavLink
        exact
        to={`/kids-cafe/sites/${match.params.id}`}
        activeClassName="active"
        className="account-links w-inline-block"
      >
        <div>Site Profile</div>
      </NavLink>
      <NavLink
        exact
        to={`/kids-cafe/sites/${match.params.id}/contacts`}
        activeClassName="active"
        className="account-links w-inline-block"
      >
        <div>Contacts</div>
      </NavLink>
      <NavLink
        exact
        to={`/kids-cafe/sites/${match.params.id}/program-status`}
        activeClassName="active"
        className="account-links w-inline-block"
      >
        <div>Program Status</div>
      </NavLink>
      <NavLink
        exact
        to={`/kids-cafe/sites/${match.params.id}/applications`}
        activeClassName="active"
        className="account-links w-inline-block"
      >
        <div>Applications</div>
      </NavLink>
    </div>
  );
};

DetailNav.propTypes = {
  match: PropTypes.object.isRequired,
};

export default DetailNav;
