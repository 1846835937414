import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class FileInput extends Component {
  adaptFileEventToValue = delegate => (e) => {
    const { setSelectedFileName } = this.props;
    const file = e.target.files[0];

    setSelectedFileName(file.name);
    delegate(file);
  }

  resetInput = (e) => {
    e.target.value = null;
  }

  render() {
    const {
      input: {
        label,
        value: omitValue,
        onChange,
        onBlur,
        ...inputProps
      },
      meta: { touched, error },
      setSelectedFileName,
      ...props
    } = this.props;

    return (
      <Fragment>
        <input
          style={{ display: 'none' }}
          id="file-upload"
          className="text-field w-input"
          onClick={this.resetInput}
          onChange={this.adaptFileEventToValue(onChange)}
          onBlur={this.adaptFileEventToValue(onBlur)}
          type="file"
          {...inputProps}
          {...props}
        />
        {touched && error
          && (
            <span style={{
              position: 'absolute', fontSize: 10, bottom: -8, color: 'red',
            }}
            >
              {error}
            </span>
          )
        }
      </Fragment>
    );
  }
}

FileInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  setSelectedFileName: PropTypes.func.isRequired,
};

export default FileInput;
