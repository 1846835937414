import gql from 'graphql-tag';

export default gql`
  query ($startDate: DateTime!, $endDate: DateTime!, $schoolId: ID) {
    schoolReports (startDate: $startDate, endDate: $endDate, schoolId: $schoolId){
      schoolId
      name
      backpackBuddyId
      termMonths {
        termMonthId
        startDate
        endDate
        termMonthFormatted
        weeklyAllocation
        monthlyAllocation
        distributionReport {
          distributionReportId
          comments
          createdAt
          submittedBy
          educatorId
        }
        distributions {
          distributionId
          studentName
          studentIdNumber
          testimonial
          distributionDateEntries {
            distributionId
            distributionNumber
            termMonthDistributionDateId
          }
        }
        distributionDates {
          date
          termMonthDistributionDateId
        }
      }
    }
  }
`;
