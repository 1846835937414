
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import getErrorMessage from '../../helpers/getErrorMessage';
import { adminSchoolTeachersAidQuery } from '../../apollo';

class DetailFormWrapper extends Component {
  update = async (values) => {
    const {
      client,
      mutation,
    } = this.props;
    const { mutate } = client;

    const { definitions } = mutation;
    const [firstDefinition] = definitions;
    const { name: { value } } = firstDefinition;

    try {
      const { data: { [value]: result } } = await mutate({
        mutation,
        variables: {
          input: {
            ...values,
          },
        },
        refetchQueries: [{
          query: adminSchoolTeachersAidQuery,
          variables: { schoolId: values.schoolId },
        }],
        awaitRefetchQueries: true,
      });

      toast('Update Successful');
      return result;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw error;
    }
  }

  render() {
    const { Form } = this.props;

    return (
      <Form update={this.update} {...this.props} />
    );
  }
}

DetailFormWrapper.propTypes = {
  Form: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  mutation: PropTypes.object,
};

DetailFormWrapper.defaultProps = {
  mutation: null,
};

export default DetailFormWrapper;
