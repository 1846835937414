import React from 'react';
import PropTypes from 'prop-types';

const BlankState = ({ title, subtitle }) => (
  <div className="blank-state">
    <h1>{title}</h1>
    {subtitle && (
      <h3>{subtitle}</h3>
    )}
  </div>
);

BlankState.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

BlankState.defaultProps = {
  subtitle: null,
};

export default BlankState;
