import React from 'react';
import { connect } from 'react-redux';
import { Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { Form, Field, reduxForm } from 'redux-form';
import { confirmAlert } from 'react-confirm-alert';
import maxBy from 'lodash/maxBy';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { required } from '../../../helpers';
import handleToastError from '../../../helpers/handleToastError';
import {
  adminDeleteSiteMutation,
  adminArchiveSiteMutation,
  adminRestoreSiteMutation,
} from '../../../apollo';
import TextField from '../../TextField';
import RadioButtonGroup from '../../RadioButtonGroup';

const ProfileForm = reduxForm({
  form: 'siteProfile',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(({
  handleSubmit,
  submitting,
  update,
  initialValues,
}) => {
  const isDeleted = initialValues.site.deletedAt;

  const adminArchiveRestoreMutations = isDeleted
    ? adminRestoreSiteMutation
    : adminArchiveSiteMutation;

  return (
    <Form onSubmit={handleSubmit(update)}>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Name</label>}
            component={TextField}
            validate={required}
            name="site.name"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">City</label>}
            component={TextField}
            validate={required}
            name="site.city"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">County</label>}
            component={TextField}
            validate={required}
            name="site.county"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Address Line 1</label>}
            component={TextField}
            validate={required}
            name="site.address1"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Address Line 2</label>}
            component={TextField}
            name="site.address2"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">State</label>}
            component={TextField}
            validate={required}
            name="site.state"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Zip</label>}
            component={TextField}
            validate={required}
            name="site.zip"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="field-combo-wrapper-2">
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Closest School</label>}
            component={TextField}
            validate={required}
            name="site.closestSchool"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo-2">
          <Field
            label={<label htmlFor="First-3" className="field-label">Cross Street</label>}
            component={TextField}
            validate={required}
            name="site.crossStreet"
            className="text-field w-input"
          />
        </div>
      </div>
      <div className="radio-question">
        <label htmlFor="First-3" className="field-label">Participated in program last year</label>
        <Field
          name="application.new"
          options={[
            {
              value: false,
              label: 'Yes',
            },
            {
              value: true,
              label: 'No',
            },
          ]}
          component={RadioButtonGroup}
          className="radio-field inline-radio w-radio"
          parse={val => val === 'true'}
          disabled={!initialValues.application}
          validate={initialValues.application ? required : null}
        />
      </div>
      <div className="spacer-15" />
      <div className="values-text">This value is based on the most recent approved application.</div>
      <div className="btn-flex-container">
        <div className="btn-padding">
          <input
            type="submit"
            value={submitting ? 'Please wait...' : 'Save Changes'}
            disabled={submitting}
            className="btn-solid-green w-button"
          />
        </div>
        &nbsp;
        &nbsp;
        &nbsp;
        <Mutation
          mutation={adminArchiveRestoreMutations}
          variables={{
            siteId: initialValues.site.siteId,
          }}
          refetchQueries={['adminSite', 'sites']}
          onError={mutationError => handleToastError(mutationError)}
          onCompleted={() => (
            isDeleted ? toast('Site Restored') : toast('Site Archived')
          )}
        >
          {(mutation, { data, loading }) => {
            const confirmArchiveRestore = () => {
              confirmAlert({
                message: 'Are you sure?',
                buttons: [
                  {
                    label: 'Cancel',
                  },
                  {
                    label: isDeleted ? 'Restore' : 'Archive',
                    onClick: mutation,
                  },
                ],
              });
            };

            if (data) {
              return <Redirect to="/kids-cafe/sites" />;
            }

            return (
              <div className="btn-padding">
                <input
                  type="button"
                  value={isDeleted ? 'Restore Site' : 'Archive Site'}
                  disabled={submitting}
                  className={classnames('form-save w-button', { 'disabled-link': loading })}
                  onClick={confirmArchiveRestore}
                />
              </div>
            );
          }}
        </Mutation>
        <Mutation
          mutation={adminDeleteSiteMutation}
          variables={{
            siteId: initialValues.site.siteId,
          }}
          refetchQueries={['adminSite', 'sites']}
          onError={mutationError => handleToastError(mutationError)}
          onCompleted={() => (
            toast('Site Deleted')
          )}
        >
          {(mutation, { data, loading }) => {
            const confirmDelete = () => {
              confirmAlert({
                message: 'Are you sure? This is a destructive action that will delete all related program(s) data.',
                buttons: [
                  {
                    label: 'Cancel',
                  },
                  {
                    label: 'Delete',
                    onClick: mutation,
                  },
                ],
              });
            };

            if (data) {
              return <Redirect to="/kids-cafe/sites" />;
            }

            return (
              <div className="btn-padding">
                <input
                  type="button"
                  value="Delete Site"
                  disabled={submitting}
                  className={classnames('form-save w-button', { 'disabled-link': loading })}
                  onClick={confirmDelete}
                />
              </div>
            );
          }}
        </Mutation>
      </div>
    </Form>
  );
});

const mapStateToProps = (reduxState, props) => {
  const { site } = props;
  const activeApplications = site.applicationsKidsCafe.filter(({ status }) => status === 'APPROVED');
  const mostRecent = maxBy(activeApplications, o => o.createdAt);

  const {
    siteId,
    name,
    type,
    city,
    county,
    address1,
    address2,
    crossStreet,
    closestSchool,
    state,
    zip,
    deletedAt,
  } = site;

  const initialValues = {
    siteId,
    site: {
      siteId,
      name,
      type,
      city,
      county,
      address1,
      address2,
      crossStreet,
      closestSchool,
      state,
      zip,
      deletedAt,
    },
  };

  if (mostRecent) {
    const isNew = mostRecent.new;
    const {
      applicationKidsCafeId,
    } = mostRecent;

    initialValues.application = {
      applicationKidsCafeId,
      new: isNew,
    };
  }

  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(ProfileForm);
