import gql from 'graphql-tag';

export default gql`
  query adminOrganization ($organizationId: ID!) {
    adminOrganization(organizationId: $organizationId) {
      id
      organizationId
      einNumber
      name
      address1
      address2
      city
      state
      zip
      county
      lastEdited
      lastEditedOrganizationMembers
      firstYearApplied
      statusPartnerServices
      statusPartnerServicesUpdatedAt
      deletedAt
      applicationsPartnerServices {
        id
        applicationPartnerServicesId
        new
        status
        applicationYear
        createdAt
        submittedAt
        submittedBy
      }
      organizationMembers {
        id
        organizationMemberId
        firstName
        lastName
        email
        phone
        type
        title
      }
    }
  }
`;
